import { TwadMasterService } from './../twad-master-list/twad.service';
import { BillingDetails, twadOffice, TwadSchemeMaster } from './../twad-master-list/twad.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';
import { ReportService } from 'src/app/report/report.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-office-master',
  templateUrl: './office-master.component.html',
  styleUrl: './office-master.component.scss'
})
export class OfficeMasterComponent {
  @ViewChild('dialog') dialog: Dialog;
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<twadOffice> = new SearchResult<twadOffice>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;
  twadOfficedialog:boolean = false;
  twadOffice:twadOffice

  constructor(
 
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected twadMasterService:TwadMasterService,
    public reportService:ReportService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
   
    this.cols = [
      
      {
        field: 'twadOfficeName',
        header: 'Twad Office Name',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'officeId',
        header: 'Office Code',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
    ];
    
  }


  ngAfterViewInit() {
    this.updateDialogFont();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    let query = new filterQuery();
    // if(this.roleCode.role.code!== 'SA' && this.roleCode.role.code !== 'TA') {
    //   query = new filterQuery();
    //   query.key = 'levelMaster.id';
    //   query.operation = 'equals';
    //   query.value = this.roleCode.levelMasterId;
    //   this.filterProps.push(query);
    // }
    this.twadMasterService
      .getTwadOfficeFilter({ ...filter }, this.filterProps)
      .subscribe(
        (res: HttpResponse<twadOffice[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: twadOffice[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  

 

  async onLazyLoad(event) {
    this.filterProps = [];
    if(!this.roleCode) {
      await this.getRole();
    }
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  hideDialog() {
    this.twadOfficedialog = false;
    this.ngSelectInvalid = false;
    this.twadOffice = new twadOffice();
  }

  openNew(){
    this.twadOfficedialog = true;
    this.twadOffice = new twadOffice();
  }

  saveTwadOffice(){
    this.spinner.show();
    const isFormValid =
     
      this.twadOffice.twadOfficeName &&
      this.twadOffice.officeId

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.twadMasterService.save(this.twadOffice).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
        this.twadOfficedialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (err) => {
        this.spinner.hide();
        this.onError();
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  
  
  
}
