<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Report List</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>

    <div class="row mt-4">
        <p-table [value]="reportList" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr>

                    <th style="text-align: center;" [ngClass]="fontService.labelClass">Report No

                    </th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="totalCount">Report Name</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">
                        <p-button [label]="list.no" (click)="naviagte(list.path)" styleClass="p-button-link">
                        </p-button>
                    </td>
                    <td style="text-align: center;"> {{ list.name }}</td>

                </tr>
            </ng-template>

        </p-table>
    </div>
</div>