<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">{{headingText}}

                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass">{{headingText}}</span>
          </button>

                </ng-template>
            </p-toolbar>
        </div>
    </div>


    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <p-table [value]="fundDetails" [resizableColumns]="true" [paginator]="true" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount" [lazyLoadOnInit]="true" responsiveLayout="scroll"
            [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'"
            [first]="first" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">Financial Year</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">Level Type</th>
                    <th *ngIf="this.currentPath !== '/twad-Upload-excel-list'" style="text-align: center;" [ngClass]="fontService.labelClass">Grant Level</th>
                    <th *ngIf="this.currentPath !== '/twad-Upload-excel-list'" style="text-align: center;" [ngClass]="fontService.labelClass">Fund Source</th>
                    <th *ngIf="this.currentPath !== '/twad-Upload-excel-list'" style="text-align: center;" [ngClass]="fontService.labelClass">Transaction Date</th>
                    <th *ngIf="this.currentPath === '/twad-Upload-excel-list'" style="text-align: center;" [ngClass]="fontService.labelClass">Bill Date</th>
                    <th [ngClass]="fontService.labelClass">Uploaded Excel File</th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{list?.finyearName}}</td>
                    <td style="text-align: center;">Village</td>
                    <td *ngIf="this.currentPath !== '/twad-Upload-excel-list'" style="text-align: center;">{{list?.componentMasterName}}</td>
                    <td *ngIf="this.currentPath !== '/twad-Upload-excel-list'" style="text-align: center;"><b>{{list.fundSource}}</b></td>
                    <td style="text-align: center;"><b>{{list.transactionDate | date:'dd/MM/yyyy' }}</b></td>
                    <td>
                        <p-button (click)="downloadFile(list?.fileName)" styleClass="p-button-link">{{list.fileName}}</p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>