<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.onboard"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-12 px-4">
            <p-table
            [value]="onBoardReportDto"
            [resizableColumns]="true"
            responsiveLayout="scroll"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
            <tr>
                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillages" | translate }}</th>
                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillagesOnboarded" | translate }}</th>
                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserRegistered" | translate }}</th>
                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserNotRegistered" | translate }}</th>
            </tr>
            <tr>
                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list>
            <tr>
                <td style="text-align: center;">{{list.villageCount}}</td>
                <td style="text-align: center;"><p-button [label]="list.villageOnBoardCount" (click)="loadOnboardVillage()" styleClass="p-button-link"></p-button></td>
                <td style="text-align: center;"><p-button [label]="list.registerMakerCount" (click)="loadMakerVillage(11,'Yes')" styleClass="p-button-link"></p-button></td>
                <td style="text-align: center;"><p-button [label]="list.registerChecker1Count" (click)="loadMakerVillage(12,'Yes')"  styleClass="p-button-link"></p-button></td>
                <td style="text-align: center;"><p-button [label]="list.registerChecker2Count" (click)="loadMakerVillage(13,'Yes')" styleClass="p-button-link"></p-button></td>
                <td style="text-align: center;"><p-button [label]="list.nonRegisterMakerCount" (click)="loadMakerVillage(11,'No')" styleClass="p-button-link"></p-button></td>
                <td style="text-align: center;"><p-button [label]="list.nonRegisterChecker1Count" (click)="loadMakerVillage(12,'No')" styleClass="p-button-link"></p-button></td>
                <td style="text-align: center;"><p-button [label]="list.nonRegisterChecker2Count" (click)="loadMakerVillage(13,'No')" styleClass="p-button-link"></p-button></td>
            </tr>
            </ng-template>
            </p-table>
        </div>
    </div>
   

    <div class="row">
        <div class="col-12 mt-2">
            <p-table [value]="districtList" dataKey="levelMAsterId" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                <ng-template pTemplate="header">
                    <tr>
                        <th rowspan="2" style="text-align: center;width: 3rem"><i class="pi pi-file-excel" style="font-size: 2rem;color:green" (click)="reportDownload()"></i></th>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{headerName}}</th>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillages" | translate }}</th>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillagesOnboarded" | translate }}</th>
                        <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserRegistered" | translate }}</th>
                        <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserNotRegistered" | translate }}</th>
                    </tr>
                    <tr>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-list let-index="rowIndex" let-expanded="expanded">
                    <tr>
                        <td>
                            <button (click)="getBlockDetails(list.levelMAsterId)" type="button" pButton pRipple [pRowToggler]="list" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td style="text-align: center;">{{list.levelMasterName}}</td>
                        <td style="text-align: center;">{{list.villageCount}}</td>
                        <td style="text-align: center;">{{list.villageOnBoardCount}}</td>
                        <td style="text-align: center;">{{list.registerMakerCount}}</td>
                        <td style="text-align: center;">{{list.registerChecker1Count}}</td>
                        <td style="text-align: center;">{{list.registerChecker2Count}}</td>
                        <td style="text-align: center;">{{list.nonRegisterMakerCount}}</td>
                        <td style="text-align: center;">{{list.nonRegisterChecker1Count}}</td>
                        <td style="text-align: center;">{{list.nonRegisterChecker2Count}}</td>
                    </tr>
                </ng-template>
                <ng-template [value]="index" dataKey="levelMAsterId" pTemplate="rowexpansion">
                    <tr>
                        <td colspan="12">
                            <div class="p-p-3">
                                <p-table [value]="blockList" dataKey="levelMAsterId" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th *ngIf="!DMRole" rowspan="2" style="text-align: center;width: 3rem"><i class="pi pi-file-excel" style="font-size: 2rem;color:green" (click)="reportBlockDownload()"></i></th>
                                            <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{header2Name}}</th>
                                            <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillages" | translate }}</th>
                                            <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillagesOnboarded" | translate }}</th>
                                            <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserRegistered" | translate }}</th>
                                            <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserNotRegistered" | translate }}</th>
                                        </tr>
                                        <tr>
                                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-list let-expanded="expanded">
                                        <tr>
                                            <td *ngIf="!DMRole">
                                                <button  (click)="getVillageDetails(list.levelMAsterId)" type="button" pButton pRipple [pRowToggler]="list" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                            </td>
                                            <td style="text-align: center;">{{list.levelMasterName}}</td>
                                            <td style="text-align: center;">{{list.villageCount}}</td>
                                            <td style="text-align: center;">{{list.villageOnBoardCount}}</td>
                                            <td style="text-align: center;">{{list.registerMakerCount}}</td>
                                            <td style="text-align: center;">{{list.registerChecker1Count}}</td>
                                            <td style="text-align: center;">{{list.registerChecker2Count}}</td>
                                            <td style="text-align: center;">{{list.nonRegisterMakerCount}}</td>
                                            <td style="text-align: center;">{{list.nonRegisterChecker1Count}}</td>
                                            <td style="text-align: center;">{{list.nonRegisterChecker2Count}}</td>

                                        </tr>
                                    </ng-template>
                                    <ng-template [value]="index" dataKey="levelMAsterId" pTemplate="rowexpansion">
                                        <tr>
                                            <td colspan="12">
                                                <div class="p-p-3">
                                                    <p-table [value]="VillageList" dataKey="levelMAsterId" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th rowspan="2" style="text-align: center;width: 3rem"><i class="pi pi-file-excel" style="font-size: 2rem;color:green" (click)="reportVillageDownload()"></i></th>
                                                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Village Name</th>
                                                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillages" | translate }}</th>
                                                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.NoOfVillagesOnboarded" | translate }}</th>
                                                                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserRegistered" | translate }}</th>
                                                                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.noOfVillageUserNotRegistered" | translate }}</th>
                                                            </tr>
                                                            <tr>
                                                                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                                                                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                                                                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                                                                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.maker" | translate }}</th>
                                                                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker1" | translate }}</th>
                                                                <th style="text-align: center;" [ngClass]="fontService.labelClass">{{ "reports.checker2" | translate }}</th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-list let-expanded="expanded">
                                                            <tr>
                                                                <!-- <td></td> -->
                                                                <td style="text-align: center;">{{list.levelMasterName}}</td>
                                                                <td style="text-align: center;">{{list.villageCount}}</td>
                                                                <td style="text-align: center;">{{list.villageOnBoardCount}}</td>
                                                                <td style="text-align: center;">{{list.registerMakerCount}}</td>
                                                                <td style="text-align: center;">{{list.registerChecker1Count}}</td>
                                                                <td style="text-align: center;">{{list.registerChecker2Count}}</td>
                                                                <td style="text-align: center;">{{list.nonRegisterMakerCount}}</td>
                                                                <td style="text-align: center;">{{list.nonRegisterChecker1Count}}</td>
                                                                <td style="text-align: center;">{{list.nonRegisterChecker2Count}}</td>


                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>
<p-dialog [(visible)]="enableData" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        {{ "reports.villageList" | translate }}
    </p-header>
    <custom-filter-table *ngIf="enableOnboard" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
    <custom-filter-table *ngIf="enableVillage" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onVillageLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
</p-dialog>