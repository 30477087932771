import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { EmployeeTransfer } from './employee-transfer.model';

@Injectable({ providedIn: 'root' })
export class EmployeeTransferService {
  protected loadProfileDataUrl =
    environment.SERVER_API_URL + 'api/loadProfileData';
    protected transferEmployeeUrl = environment.SERVER_API_URL + 'api/transferEmployee';

  constructor(protected http: HttpClient) {}
  getProfile(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.loadProfileDataUrl, {
      params: options,
      observe: 'response',
    });
  }

  employeeTransfer(payload:EmployeeTransfer): any {
    return this.http.post(this.transferEmployeeUrl,payload, { responseType: 'text' });
  }
}
