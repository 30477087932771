import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { BillingDetails, TwadAccountSummary, twadOffice, TwadSchemeMaster } from './twad.model';
export type EntityArrayResponseType = HttpResponse<BillingDetails[]>;
export type EntityAccountSummaryType = HttpResponse<TwadAccountSummary[]>;
export type EntitySchemeType = HttpResponse<TwadSchemeMaster[]>;
export type EntityOfficeTYpe = HttpResponse<twadOffice[]>;

@Injectable({ providedIn: 'root' })
export class TwadMasterService {
  protected loadProfileDataUrl =
    environment.SERVER_API_URL + 'api/loadProfileData';
    protected transferEmployeeUrl = environment.SERVER_API_URL + 'api/transferEmployee';
    protected resourceFliterUrl = environment.SERVER_API_URL + 'api/getTwadsByFilter';
    protected getTwadAccountSummaryByFilterurl = environment.SERVER_API_URL + 'api/getTwadAccountSummaryByFilter';
    protected getTwadSchemeMasterByFilterUrl = environment.SERVER_API_URL + 'api/getTwadSchemeMasterByFilter';
    protected getTwadOfficeFilterUrl = environment.SERVER_API_URL + 'api/getTwadOfficeFilter';
    protected saveTwadOfficeUrl = environment.SERVER_API_URL + 'api/saveTwadOffice';
    protected saveTwadSchemeMasterUrl = environment.SERVER_API_URL + 'api/saveTwadSchemeMaster';


  constructor(protected http: HttpClient) {}
  filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<BillingDetails[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
  }
  getTwadOfficeFilter(req?: any,filter?:any): Observable<EntityOfficeTYpe> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<twadOffice[]>(this.getTwadOfficeFilterUrl,filter, { params:options, observe: 'response' })
  }
  getTwadSchemeMaster(req?: any,filter?:any): Observable<EntitySchemeType> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<TwadSchemeMaster[]>(this.getTwadSchemeMasterByFilterUrl,filter, { params:options, observe: 'response' })
  }
  twadAccountSummaryByFilter(req?: any,filter?:any): Observable<EntityAccountSummaryType> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<TwadAccountSummary[]>(this.getTwadAccountSummaryByFilterurl,filter, { params:options, observe: 'response' })
  }

  save(payload?:twadOffice): Observable<any> {
    return this.http
    .post<twadOffice>(this.saveTwadOfficeUrl,payload, {observe: 'response' })

  }
  saveTwadSchemeMaster(payload?:TwadSchemeMaster): Observable<any> {
    return this.http
    .post<TwadSchemeMaster>(this.saveTwadSchemeMasterUrl,payload, {observe: 'response' })

  }
}