<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="agency.implementing.agency.list.name">
                        Implementing Agency List
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" iconPos="left" class="p-button-success custom-button"
                        (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.newbutton"></span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-md-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult"
                (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" (emitRow)="onSelectRow($event)"
                [disableEditIcon]="false"></custom-filter-table>
        </div>
    </div>
    <!-- Export to Excel and pdf is removed -->
    <!--     <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <button pButton pRipple icon="pi pi-file-excel" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportexcel"></span>
                    </button>
                    <button pButton pRipple icon="pi pi-file-pdf" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportpdf"></span>
                    </button>
                </ng-template>
                <ng-template pTemplate="right">
                     <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div> -->
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            {{ "agency.add.name" | translate }}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-md-12">
                <div class="form-inner-panel" novalidate>
                    <div class="row gy-2">
                        <div class="col-md-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.detail.name">
                                Implementing Agency Details
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="department.name">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="agency.departmentDto" [items]="departmentList"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{
                  'is-invalid': ngSelectInvalid && !agency.departmentDto
                }" (change)="onDeparmentChange()">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="funds.scheme.name" for="scheme">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="agency.schemeDto" [items]="schemeList" bindLabel="name"
                                [ngClass]="{
                  'is-invalid': ngSelectInvalid && !agency.schemeDto
                }" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control"
                                (change)="onchangeSchema()">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.name" for="agencyName">Agency Name</label>
                            <input type="text" id="agencyName" name="agencyName" (ngModelChange)="checkName()"
                                maxlength="100" autocomplete="off"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [(ngModel)]="agency.name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.name }"
                                class="form-control" />
                                <div *ngIf="showNameError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{ showNameError }}</small>
                                </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="user.mobile.nunmber" for="mobileNo">Mobile No</label>
                            <input type="text" id="mobileNo" class="form-control" name="mobileNo" autocomplete="off"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" [(ngModel)]="agency.mobile"
                                [ngClass]="{ 'is-invalid': !agency.mobile && ngSelectInvalid }"
                                (input)="validateMobile()" (ngModelChange)="checkmobile()" maxlength="10" />

                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                    a valid Mobile Number.</small>
                            </div>
                            <div *ngIf="showMobileNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showMobileUniError">{{showMobileNoError}}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="user.email" for="email">Email</label>
                            <input type="text" id="email" name="email" class="form-control" [(ngModel)]="agency.email"
                                onblur="this.value =this.value.trim();" 
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.email }" (input)="validateEmail()"
                                maxlength="75" autocomplete="off" (ngModelChange)="checkemail()" />
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a
                                    valid Email Address.</small>
                            </div>
                            <div *ngIf="showEmailUniError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showEmailUniError">{{showEmailUniError}}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="user.designaiton" for="designaiton">Designaiton</label>
                            <input type="text" id="designaiton" name="designaiton" class="form-control" maxlength="50"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [(ngModel)]="agency.designation" autocomplete="off"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !agency.designation}" />
                        </div>
                        <!-- <div class="col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"  jhiTranslate="department.name"  for="designaiton">Gst No</label>
                            <input type="text" class="form-control" name="designaiton" [(ngModel)]="agency.gstNo"
                                id="designaiton" />                       
                        </div> -->
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="user.gstNo" for="gstNo">GST No</label>
                            <input type="text" id="gstNo" uppercase  class="form-control" name="gstNo" [(ngModel)]="agency.gstNo"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.gstNo }"  oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" autocomplete="off"
                                (input)="validateGst()" 
                                maxlength="15" (ngModelChange)="checkgstNo()" />
                            <div *ngIf="showGstError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validgst">Please enter a
                                    valid GST Number.</small>
                            </div>
                            <div *ngIf="gstUniError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.gstUniError">{{gstUniError}}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="master.level.name.name" for="levelMasterName">Level Name</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelTypeDto" [items]="levelType" bindLabel="name"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelTypeDto }" appearance="outline"
                                [closeOnSelect]="true" [clearable]="true" class="form-control"
                                (change)="onLevelTypeChange()">
                            </ng-select>
                        </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    jhiTranslate="agency.level.master.name" for="levelName">Level Master Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelMasterDto" [items]="levelMasterList"
                                    bindLabel="name" appearance="outline" (search)="onLevelTypeChange($event)"
                                    [closeOnSelect]="true" [clearable]="true"
                                    [ngClass]="{'is-invalid': !levelMasterDto && ngSelectInvalid}" class="form-control">
                                </ng-select>
                            </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-md-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                Account Details
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.bank.account.name" for="bankAccountName">Bank Account Name</label>
                            <input type="text" class="form-control" name="bankAccountName"
                            maxlength="60" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                            onblur="this.value =this.value.trim();"
                            style="text-transform:uppercase"
                                [(ngModel)]="agency.bankAccName" id="bankAccountName" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.bank.id" for="bankId">Bank Id</label>
                            <input type="text" class="form-control" name="bankId"
                            maxlength="20" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                            onblur="this.value =this.value.trim();"
                            style="text-transform:uppercase"
                            [(ngModel)]="agency.bankId"
                                id="bankId" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.account.number" for="bankAccountNo">Account No</label>
                            <input type="text" class="form-control" name="bankAccountNo"
                            maxlength="17" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                            onblur="this.value =this.value.trim();"
                                [(ngModel)]="agency.accountNumber" id="bankAccountNo" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label for="ifsc" class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.ifscode">IFSC Code</label>
                            <input type="text" name="ifsc" id="ifsc" [(ngModel)]="agency.ifscCode"
                            autocomplete="off"
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                            onblur="this.value =this.value.trim();"
                            uppercase
                                (input)="validateIfsc()" class="form-control" maxlength="11" />
                            <div *ngIf="showIfscError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a
                                    valid IFSCode.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.branch.name" for="branchName">Branch Name</label>
                            <input type="text" name="branchName" id="branchName" class="form-control"
                            maxlength="60" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                            onblur="this.value =this.value.trim();"
                            style="text-transform:uppercase"
                                [(ngModel)]="agency.branchName" maxlength="50" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.branch.mobile.no.name" for="branchMobileNo">Branch Mobile
                                No</label>
                            <input type="text" name="branchMobileNo" autocomplete="off" [(ngModel)]="agency.branchMobNo"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                (input)="validateBmobile()" id="branchMobileNo" class="form-control" maxlength="10" />
                            <div *ngIf="showBMobile" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                    a valid Mobile Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.branch.email.name" for="branchEmail">Branch E-mail</label>
                            <input type="text" id="branchEmail" name="branchEmail" autocomplete="off" [(ngModel)]="agency.branchEmail"
                                (input)="validateBemail()" class="form-control" maxlength="60" />
                            <div *ngIf="emailBranchError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a
                                    valid Email Address.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="agency.branch.branchAddress" for="branchAddress">Branch Address</label>
                            <input type="text" id="branchAddress" name="branchAddress" autocomplete="off" [(ngModel)]="agency.address"
                            maxlength="300" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" iconPos="left" class="p-button-text custom-button"
                (click)="hideDialog()">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.cancelbutton"></span>
            </button>
            <button pButton pRipple *ngIf="!agency.id" icon="pi pi-check" class="p-button-text custom-button"
                (click)="saveAgency()" type="submit">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
            </button>
            <button pButton pRipple *ngIf="agency.id" icon="pi pi-check" class="p-button-text custom-button"
                (click)="saveAgency()" type="submit">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>