import { DepartmentList } from "../department-list/department-list"

export class SchemeList {
    id: number
    name: string
    shortName: string
    code: string
    address: string
    schemeDesc: string
    departmentDto:DepartmentList 
}