<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.user.list">User List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="isStateRole" pButton pRipple class="p-button-danger custom-button" (click)="userDisable()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass" [jhiTranslate]="enableUser"></span>
                    </button>
                    <button *ngIf="isStateRole" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
                </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row pt-2 pb-2" *ngIf="roleCode === 'DA'">
        <div class="col">
            <p-radioButton name="levelTypeName" value="Block" (onClick)="levelTypeChange()" [(ngModel)]="levelTypeName" inputId="levelType2"></p-radioButton>
            <label for="levelType2" class="label-margin">Block</label>
            <p-radioButton name="levelTypeName" value="Village" (onClick)="levelTypeChange()" [(ngModel)]="levelTypeName" inputId="levelType3"></p-radioButton>
            <label for="levelType3" class="label-margin">Village</label>
        </div>
    </div>
    <div class="input-row py-10 pb-2" *ngIf="roleCode === 'DA'">
        <input pInputText type="text" placeholder="Admin User Name" [(ngModel)]="adminUserName" />
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onClear()" class="p-button-sm"></button>
    </div>
    <div class="row" *ngIf="roleCode !=='DA'">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [showProfileButton]="false" [disableEditIcon]="true" [showResetPasswordButton]="showResetPassword"
                (emitRow)="onSelectRow($event)"></custom-filter-table>

        </div>
    </div>
    <div class="row" *ngIf="roleCode=='DA'">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="DASearchResult" [lazyLoadOnInit]="false" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [showProfileButton]="false" [disableEditIcon]="true" [showResetPasswordButton]="showResetPassword"
                (emitRow)="onSelectRow($event)"></custom-filter-table>

        </div>
    </div>
    <p-dialog [(visible)]="userDialog" [style]="{width: '800px',height:'500px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            {{"agency.zonal.add"|translate}}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="userName" [ngClass]="fontService.labelClass" jhiTranslate="userList.name">User Name</label>
                            <input type="text" class="form-control" maxlength="60" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="userName" id="userName" [(ngModel)]="user.userName" [readonly]="user.id" required autofocus [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.userName }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="mobileNo" [ngClass]="fontService.labelClass" jhiTranslate="userList.mobile.number">Mobile No</label>
                            <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" autocomplete="off" name="mobileNo" id="mobileNo" [(ngModel)]="user.mobile" (input)="validateMobile()" [ngClass]="{ 'is-invalid': showMobileError}"
                            />
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                        a valid Mobile Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="email" [ngClass]="fontService.labelClass" jhiTranslate="userList.email">E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="user.email" (input)="validateEmail()" autocomplete="off" maxlength="60" name="email" id="email" [ngClass]="{ 'is-invalid': emailError}" />
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a
                                        valid Email Address.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="roleName" [ngClass]="fontService.labelClass" jhiTranslate="userList.role">Role</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="user.roleDto" [items]="role" bindLabel="name" appearance="outline" (change)="changeRole()" [closeOnSelect]="true" class="form-control" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.roleDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode" [ngClass]="fontService.labelClass" jhiTranslate="userList.levelType.name">Level Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelTypeDto" [items]="levelType" bindLabel="name" appearance="outline" (change)="onLevelType()" [disabled]="true" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelTypeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.state.name">State</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="stateListDto" [items]="stateList" bindLabel="name" appearance="outline" (change)="onStateLevelChange()" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stateListDto }">
                            </ng-select>
                        </div>
                        <!-- <div class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label" for="department">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="user.departmentDto" [items]="departmentList"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control"
                                [clearable]="true">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label" for="scheme">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="user.schemeDto" [items]="schemeList" bindLabel="name"
                                appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control">
                            </ng-select>
                        </div> -->
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto?.name === 'District' || levelTypeDto?.name === 'Block' || levelTypeDto?.name === 'Village'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.district.name">District</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !districtListDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto?.name === 'Block' || levelTypeDto?.name === 'Village'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.block.name">Block</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !BlockLevelDto }">
                            </ng-select>
                        </div>
                        <div *ngIf="levelTypeDto?.name === 'Village'" class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.village.name">Village</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" (change)="onVillageLevelChange()" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !villageLevelDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="userList.select.option" [ngClass]="{ 'is-invalid': ngSelectInvalid && !selectOption }">
                                    Select Option</label><br>
                            <p-radioButton name="option" [disabled]="user.id" inputId="option1" value="Zonal" [(ngModel)]="selectOption"></p-radioButton>
                            <label for="option1" [ngClass]="fontService.labelClass" jhiTranslate="userList.zone.name">Zonal</label> &nbsp;&nbsp;
                            <p-radioButton [disabled]="user.id" name="option" inputId="option2" value="ia" [(ngModel)]="selectOption">
                            </p-radioButton>
                            <label for="option2" [ngClass]="fontService.labelClass" jhiTranslate="userList.implementing.agency.name">Zonal</label>
                        </div>
                        <div *ngIf="selectOption === 'Zonal'" class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label req" for="levelType" [ngClass]="fontService.labelClass" jhiTranslate="userList.zonal.name">Zonal Name</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [items]="zonalList" bindLabel="name" [(ngModel)]="zonalDto" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !zonalDto }">
                            </ng-select>
                        </div>
                        <div *ngIf="selectOption === 'ia'" class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="userList.implementing.agency.name">Implementing Agency</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [items]="iaLIst" [(ngModel)]="iaDto" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !iaDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="password" [ngClass]="fontService.labelClass" jhiTranslate="userList.password">Password</label>
                            <input type="password" class="form-control" maxlength="20" autocomplete="off" name="password" id="password" [readonly]="user.id" [(ngModel)]="user.password" [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.password }" />
                        </div>
                    </div>
                    <!-- <div class="row">
                           
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="password" [ngClass]="fontService.labelClass"
                                jhiTranslate="userList.password">Password</label>
                                <input type="password" class="form-control" maxlength="20" autocomplete="off"
                                    name="password" id="password" [readonly]="user.id" [(ngModel)]="user.password" 
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !user.password }"/>
                            </div>
                        </div> -->
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
                class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton">
            </span></button>
            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveUser()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
                </span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
        </button>
            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
        </button>
        </ng-template>
    </p-confirmDialog>
</div>