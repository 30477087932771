import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '../request/request-util';
import { Login } from '../signin/login.model';
import { AuthUser } from './authuser';

export type EntityArrayResponseType = HttpResponse<AuthUser[]>;

@Injectable({
  providedIn: 'root'
})
export class ResetService {
  constructor( private http: HttpClient) { }
  protected resourceUrl = environment.SERVER_API_URL + 'api/authenticate';
  protected getUser = environment.SERVER_API_URL + 'api/validateUser';
  protected changePassword = environment.SERVER_API_URL + 'api/forgetPassword';
  protected resetPassword = environment.SERVER_API_URL + 'api/changePassword';
 
 
  getUserDetails(user: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getUser}?username=${user}`, { observe: 'response' });
  }

  setPassword(id:string,password:string){
    return this.http.get<any>(`${this.changePassword}?id=${id}&password=${password}`, { observe: 'response' });
  }

  change(login?:AuthUser): Observable<EntityArrayResponseType> {
    return this.http
    .post<AuthUser[]>(this.resetPassword,login, {observe: 'response' })
  }
  
  login(credentials: Login): Observable<void> {
    const options = createRequestOption(credentials);
    return this.http.get<any>(this.resourceUrl, { params: options })
  }

}
