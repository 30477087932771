<div id="landing-page">
    <p-card >
        <div class="row">  
            <div class="col-lg-2">
                <h2>Enter Report No:</h2>
            </div>
            <div class="col-lg-6">
                <input type="text" class="w-100" (keypress)="keyPressNumbersDecimal($event)" pInputText  [(ngModel)]="navigationSearch" />
            </div>
            <div class="col-lg-3">
                <p-button label="Submit" (click)="submitSearch()" />
            </div>
        </div>
    </p-card>
</div>