import { Component } from '@angular/core';

@Component({
  selector: 'app-tneb-dashboard',
  templateUrl: './tneb-dashboard.component.html',
  styleUrl: './tneb-dashboard.component.scss'
})
export class TnebDashboardComponent {

}
