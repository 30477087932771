<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.payments.bulkpaymentList">Commercial Payment List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode?.role?.code === 'DC1' || roleCode?.role?.code === 'VC1' || roleCode?.role?.code === 'BC1'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                        <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.generatevoucher">
                    </span>
                    </button>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedProfiles()"
                        [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                    <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
                </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" (emitRow)="onSelectRow($event)" [enableView]="true" [showRedirectButton]="false"></custom-filter-table>
        </div>
    </div>

</div>