<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="compoMaster.subListName">Component Master List (Sub)</h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success  custom-button" (click)="openNew()"
                        styleClass="button">
                        <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.newbutton">
                    </span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [disableDeleteIcon]="true"
                [disableEditIcon]="true" (searchParam)="onLazyLoad($event)"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="subComponentDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
        [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            {{"compoMaster.add.subComp"|translate}}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="department"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.department.name">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="departmentDto" [items]="departmentList"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control"
                                [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentDto }"
                                >
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.scheme.name">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="schemeDto" [items]="schemeList" bindLabel="name"
                                appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                (change)="onchangeSchema()" class="form-control"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.levelType.name">Level Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelTypeDto" [items]="levelType" bindLabel="name"
                                appearance="outline" (change)="onLevelType()" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelTypeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.grant.level.name">Grant Level</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="subComponent.componentTypeDto" [items]="grantLevel"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                (change)="ongrandLevel()" class="form-control"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !subComponent.componentTypeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.major">Major Component</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="majaorComponentdto" [items]="majaorComponent"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                (change)="onMajorComponent()" class="form-control"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !majaorComponentdto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.minor">Minor Component</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="minorComponentDto" [items]="minorComponent"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !minorComponentDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.sub">Sub Component Name</label>
                            <input type="text" class="form-control" [(ngModel)]="subComponent.name"
                                name="openingBalance" autocomplete="off" id="openingBalance"
                                maxlength="100"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !subComponent.name }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode"  [ngClass]="fontService.labelClass"
                            jhiTranslate="compoMaster.lgd.code.name">LGD Code</label>
                            <input type="text" class="form-control" [(ngModel)]="subComponent.code"
                                name="openingBalance" autocomplete="off" id="openingBalance"
                                maxlength="100"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !subComponent.code }" maxlength="10"/>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button"
                (click)="hideDialog()">
                <span
                class="button-label"
                [ngClass]="fontService.buttonClass"
                jhiTranslate="common.cancelbutton">
                </span>
            </button>
            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" 
            (click)="saveData()">
              <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.savebutton">
                    </span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>