<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.transcationreport"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate"
         [showIcon]="true" styleClass="datepicker"
        [maxDate]="maxDate"></p-calendar>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate"
         [showIcon]="true" styleClass="datepicker"
        [maxDate]="maxDate"></p-calendar>
        <p-dropdown placeholder="Select Component" [options]="compTypeList" [(ngModel)]="selectedcompType" 
        optionLabel="name" showClear="true"></p-dropdown>
        <!-- <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" 
        optionLabel="name" optionValue="value"></p-dropdown> -->
        <button pButton pRipple label="Download" (click)="onSearch()" class="p-button-sm"></button>
        
    </div>
    <div class="row">
        <div *ngIf="dateError" class="text-danger mb-2">
            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                choose valid from and to
                date</small>
        </div>
    </div>
    <div class="row">
    </div>
        