import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FontService } from 'src/app/shared/font-service/font.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {
  roleCode: any;

  constructor(
    public fontService: FontService,
    protected router: Router,
    private loginService: SigninService

  ) { }
  reportList:any;
  async ngOnInit(): Promise<void> {
    await this.getRole();
  }


  naviagte(path) {
    const url = this.router.serializeUrl(this.router.createUrlTree([path]));
    window.open(url, '_blank');
   
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body.role.code;
          if(this.roleCode === 'SA' || this.roleCode === 'SM') {
            this.reportList = [{no:1,name:"Onboard Report",path:"/Onboard-Report"},
            {no:2,name:"Employee Onboard Report",path:"/Employee-Onboard-Report"},
            {no:3,name:"Payment Report",path:"/Payment-Report"},
            {no:4,name:"Bulk Payment Report",path:"/Bulk-Payment-Report"},
            {no:5,name:"Voucher Status Report",path:"/Voucher-Status-Report"},
            {no:6,name:"AccountWise Fund Report",path:"/AccountWise-Fund-Report"},
            {no:7,name:"Nil Balance Report",path:"/Nil-Total-Balance-Report"},
            {no:8,name:"Transaction Report",path:"/Transaction-Report"},
            {no:9,name:"Employee Count Report",path:"/Employee-Count-Report"},
            {no:10,name:"Payment Not Done Report",path:"/Payment-Not-Done-Report"},
            {no:11,name:"Employee Transfer Report",path:"/employee-transfer-Report"}
          ]
          }
          else {
            this.reportList = [{no:1,name:"Onboard Report",path:"/Onboard-Report"},
            {no:2,name:"Employee Onboard Report",path:"/Employee-Onboard-Report"},
            {no:3,name:"Payment Report",path:"/Payment-Report"},
            {no:4,name:"Bulk Payment Report",path:"/Bulk-Payment-Report"},
            {no:5,name:"Voucher Status Report",path:"/Voucher-Status-Report"},
            {no:6,name:"AccountWise Fund Report",path:"/AccountWise-Fund-Report"},
            {no:7,name:"Nil Balance Report",path:"/Nil-Total-Balance-Report"},
            {no:8,name:"Transaction Report",path:"/Transaction-Report"},
            {no:9,name:"Payment Not Done Report",path:"/Payment-Not-Done-Report"},
            {no:10,name:"Employee Transfer Report",path:"/employee-transfer-Report"}
          ]
          }
          
            resolve(this.roleCode)
          },
          
        
         
        
        (onError) => {
          resolve(null);
        }
      );
    });
  }
  


}
