export class TangedcoBill {
  id?: number;
  consumerNo?: string;
  billMonth?: number;
  billYear?: number;
  billAmount?: number;
  reason: string;
  billDate?: string;
  adviceNo?: string;
  dueDate?: string;
  billType?: string;
  installPos?: string;
  trackId?: number;
  status?: number;
  message?: string;
  receiptDate?: Date;
  consumerDemandId?: number;
  createdOn?: Date;
  updatedOn?: Date;
  createdBy?: number;
  updatedBy?: number;
  activeFlag: String;
  paymentInitiated:boolean;
 

}

export class ConsumerDemand {
   id?: number;
   consumerNo?: string;
   billAmount?: number;
   consumerName: string;
   consumerAddress?: string;
   billCycle?: string;
   message?: string;
   lgdCode?: string;
   createdOn?: Date;
   updatedOn?: Date;
   createdBy?: number;
   updatedBy?: number;
   activeFlag: String;
   levelMasterId: number;
}

export class TangedcoBillTmp {
  id?: number;
  consumerNo?: string;
  billMonth?: number;
  billYear?: number;
  billAmount?: number;
  reason: string;
  billDate?: string;
  adviceNo?: string;
  dueDate?: string;
  billType?: string;
  installPos?: string;
  trackId?: number;
  status?: number;
  message?: string;
  receiptDate?: Date;
  consumerDemandId?: number;
  createdOn?: Date;
  updatedOn?: Date;
  createdBy?: number;
  updatedBy?: number;
  activeFlag: String;
  tangedcoBillId: number;
  levelMasterId: number
}

export class TangedcoDescDto{

  id?: number;
  consumerNo?: string;
  tangedcoBillId?: number;
  billMonth?: number;
  billYear?: number;
  billAmount?: number;
  billType?: string;
  activeFlag?: string;
  voucherNo?:string;
  message?:string;
}

export class ConsolidatedBills{
  tangedcoBills: TangedcoDescDto[];
  totalTransactionAmount: number;
}

export class TangedcoService {
  id: number;
  levelId: number;
  levelMasterId: number;
  lgdCode: number;
  consumerNo: string;
  region: number;
  activeFlag: string;
  isApproved: boolean;
  isRejected:boolean;
  isDeleted:boolean;
  createdOn: Date;
  updatedOn: Date;
  createdBy: number;
  updatedBy: number;
  consumerName: string;
  consumerAddress: string;
  status: string;
  consumerLandMark:string;
  habitation: LevelMasterHabitation;
  purpose: TangedcoMasterPurpose;
  edit: boolean;
}

export class TangedcoMasterPurpose {
  id: number;
  description: string;
}

export class LevelMasterHabitation {
  id: number;
  habitationName: string;
  lgdCode: string;
  levelId: number;
  parentId: number;
  createdOn: Date;
  updatedOn: Date;
  createdBy: number;
  updatedBy: number;
}

