import { AgencyDto } from './../agency/agency';
import { MasterComponent } from './../major-component/major-component';
import { LevelMaster } from './../level-master/level-master';
import { limitTransList, FinYear, FormulaTabelModel, limitTransDto } from './limit-translist.module';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';


export type EntityArrayResponseType = HttpResponse<limitTransList[]>;

@Injectable({ providedIn: 'root' })
export class LimitTransListService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getIAComponentLimitTransByFilter');
    protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getIAComponentLimitTransValidByFilter');
    protected getLevelMaster= environment.SERVER_API_URL+('api/getLevelMasterByLevelTypeId');
    protected getComponentbyLevelById = environment.SERVER_API_URL+('api/getComponentTypeByLevelId')
    protected getComponentbyLevelByIdGf = environment.SERVER_API_URL+('api/getComponentTypeByLevelIdGf')
    protected getImplementingAgencyByLevelMasterId = environment.SERVER_API_URL+('api/getImplementingAgencyByLevelMasterId')
    protected getfinYearList = environment.SERVER_API_URL+('api/finYearList')
    protected calcFormula = environment.SERVER_API_URL+('api/formulaCalculation')
    protected formulaTableFilter = environment.SERVER_API_URL+('api/getIAComponentLimitFormulaTempGFByFilter')
    protected getStatusUrl = environment.SERVER_API_URL+('api/statusCheck')
    protected schedulerMethod = environment.SERVER_API_URL+('api/schedulerMethod')
    protected savelimittransUrl = environment.SERVER_API_URL+('api/SaveIAComponentLimitTrans')
 
    
    // protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveProfile');
    // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    constructor(protected http: HttpClient) {}
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<limitTransList[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }
    vaildFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<limitTransList[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
    }
    getLevelMasterById(id:any): Observable<EntityArrayResponseType> {
      return this.http
      .get<any>(this.getLevelMaster,{params:{id:id},observe: 'response' })
    }

    getComponentbyLevel(id:any): Observable<any> {
      return this.http
      .get<MasterComponent[]>(this.getComponentbyLevelById,{params:{id:id},observe: 'response' })
    }

    getComponentbyLevelGf(id:any): Observable<any> {
      return this.http
      .get<MasterComponent[]>(this.getComponentbyLevelByIdGf,{params:{id:id},observe: 'response' })
    }

    getImplementingAgencyByLevelMaster(id:any): Observable<any> {
      return this.http
      .get<AgencyDto[]>(this.getImplementingAgencyByLevelMasterId,{params:{id:id},observe: 'response' })
    }

    getFinYear(): Observable<any> {
      return this.http
      .get<FinYear[]>(this.getfinYearList,{observe: 'response' })
    }

    calcAmountByFormula(limitTransListDto): Observable<any> {
      return this.http.post<any>(this.calcFormula, limitTransListDto,{ observe: 'response' });
    }

    tableFormulaFilter(req?: any,filter?:any): Observable<any> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<FormulaTabelModel[]>(this.formulaTableFilter,filter, { params:options, observe: 'response' })
    }

    getStatus(levelTypeDto:any): any {
      return this.http
      .post<any>(this.getStatusUrl,levelTypeDto,{})
    }

    schedule(levelTypeDto:any): any {
      return this.http
      .post<any>(this.schedulerMethod,levelTypeDto,{})
    }

    save(savelimittrans?:limitTransDto): Observable<any> {
      return this.http
      .post<limitTransDto[]>(this.savelimittransUrl,savelimittrans, {observe: 'response' })
  }



   



    

   

 

    // save(profile?:Profile): Observable<EntityArrayResponseType> {
    //     return this.http
    //     .post<Profile[]>(this.resourceSaveUrl,profile, {observe: 'response' })
    // }
    // delete(id: number): Observable<HttpResponse<{}>> {
    //   return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    // }

  
}