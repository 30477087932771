<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.payments.bulkpayment">
                        Commercial Payment Voucher
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ voucherGenerationDto?.parentVoucher?.voucherNumber}}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ this.voucherGenerationDto.parentVoucher.implementingAgency.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.gst.number.name" | translate }}:<br />{{ this.voucherGenerationDto.parentVoucher.implementingAgency.gstNo }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ departmentName }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ schemeName }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}:<br />{{ levelMasterDto.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass" jhiTranslate="funds.fin.year.name">Fin Year</label>
                            <ng-select id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [disabled]="voucherGenerationDto.parentVoucher.id" [clearable]="true" class="form-control" [items]="finYear" [(ngModel)]="voucherGenerationDto.parentVoucher.finyear" (change)="changeFinyear()"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.parentVoucher.finyear}">
                            </ng-select>
                            <!-- <p *ngIf="finYearError" style="font-size: 14px; color: #ff0000">
                              {{finYearError}}
                            </p> -->
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label>
                            <ng-select id="scheme" [hideSelected]="true" [(ngModel)]="grantlevel" [disabled]="voucherGenerationDto.parentVoucher.id" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="getVoucher()" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>



                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <p-table *ngIf="!voucherGenerationDto.parentVoucher.id" [value]="vendorList" [paginator]="true" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No</th>
                                        <th></th>
                                        <th>Voucher Number</th>
                                        <th>Employee/Vendor Name</th>
                                        <th>Employee/Vendor Ifsc Code</th>
                                        <th>Employee/Vendor Account No</th>
                                        <th>Gross Amount</th>
                                        <th>Transaction Amount</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                    <tr>
                                        <td>{{index+1}}</td>
                                        <td>
                                            <p-checkbox (onChange)="VoucherCheckStatus(list)" [(ngModel)]="list.isDefault" binary="true"></p-checkbox>
                                        </td>
                                        <td>{{ list.voucherNo }}</td>
                                        <td *ngIf="list.personalFlag === 'No'">{{ list.commercialVendorName}}</td>
                                        <td *ngIf="list.personalFlag === 'No'">{{ list.commercialIfscode}}</td>
                                        <td *ngIf="list.personalFlag === 'No'">{{ list.commercialAccountNo}}</td>
                                        <td *ngIf="list.personalFlag === 'Yes'">{{ list.personalVendorName}}</td>
                                        <td *ngIf="list.personalFlag === 'Yes'">{{ list.personalIfscode}}</td>
                                        <td *ngIf="list.personalFlag === 'Yes'">{{ list.personalAccountNo}}</td>
                                        <td *ngIf="list.personalFlag === 'E' || list.personalFlag === 'ER'">{{ list.employeeName}}</td>
                                        <td *ngIf="list.personalFlag === 'E' || list.personalFlag === 'ER'">{{ list.employeeAccountNumber}}</td>
                                        <td *ngIf="list.personalFlag === 'E' || list.personalFlag === 'ER'">{{ list.employeeIFSCCode}}</td>
                                        <td *ngIf="list.personalFlag === 'BE'">Employee Bulk Voucher</td>
                                        <td *ngIf="list.personalFlag === 'BER'">Elected Representative Bulk Voucher</td>
                                        <td *ngIf="list.personalFlag !== 'No' && list.personalFlag !== 'Yes' && list.personalFlag !== 'E' &&  list.personalFlag !== 'ER'"></td>
                                        <td *ngIf="list.personalFlag !== 'No' && list.personalFlag !== 'Yes' && list.personalFlag !== 'E' &&  list.personalFlag !== 'ER'"></td>
                                        <td>{{ list.netAmount}}</td>
                                        <td>{{list.transactionAmount}}</td>
                                        <td> <button pButton pRipple type="button" jhiTranslate="common.view" class="p-button" (click)="viewVoucherDetails(list.id,list.personalFlag)">
                                            </button></td>
                                        <td><button pButton pRipple (click)="revert(list.id)" class="p-button-warning custom-button">
                                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                                    jhiTranslate="common.revertbutton"></span>
                                            </button></td>
                                        <td>
                                            <button pButton pRipple (click)="reject(list.id)" class="p-button-danger custom-button">
                                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                                    jhiTranslate="common.rejectbutton"></span>
                                            </button>
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                            <p-table *ngIf="voucherGenerationDto.parentVoucher.id" [value]="voucherGenerationDto.parentVoucherBillDescList" [paginator]="true" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Voucher Number</th>
                                        <th>Employee/Vendor Name</th>
                                        <th>Employee/Vendor Ifsc Code</th>
                                        <th>Employee/Vendor Account No</th>
                                        <th>Gross Amount</th>
                                        <th>Transaction Amount</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                    <tr>
                                        <td>{{index+1}}</td>
                                        <td>{{ list.childVoucherNumber }}</td>
                                        <td *ngIf="list.personalFlag !== 'BE' && list.personalFlag !== 'BER'">{{ list.vendorName}}</td>
                                        <td *ngIf="list.personalFlag === 'BE'">Employee Bulk Voucher</td>
                                        <td *ngIf="list.personalFlag === 'BER'">Elected Representative Bulk Voucher</td>
                                        <td>{{ list.vendorIFSCCode}}</td>
                                        <td>{{ list.vendorAccountNumber}}</td>
                                        <td>{{ list.netAmount}}</td>
                                        <td>{{list.transactionAmount}}</td>
                                        <td> <button pButton pRipple type="button" jhiTranslate="common.view" class="p-button" (click)="viewVoucherDetails(list.vendorTransactionId,list.personalFlag)">
                                            </button></td>
                                        <td *ngIf="!voucherGenerationDto.parentVoucher.signStatus && enableApproved && !voucherGenerationDto.parentVoucher.signaturePresident && !enableSignButton && list?.activeFlag === 'Yes'">
                                            <button pButton pRipple (click)="reject(list.vendorTransactionId,list)" class="p-button-danger custom-button">
                                                    <span class="button-label" [ngClass]="fontService.buttonClass"
                                                        jhiTranslate="common.rejectbutton"></span>
                                                </button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton && voucherGenerationDto.parentVoucherBillDescList.length !== 0 && !voucherGenerationDto.parentVoucher.signaturePresident && !voucherGenerationDto.parentVoucher.signStatus && !enableView" pButton pRipple class="p-button-success custom-button margin"
                                (click)="genVocher()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.approvebutton">Approve Bulk
                                    Voucher</span>
                            </button>
                            <form *ngIf="enableSignButton" ngNoForm [action]="getActionUrl()" method="POST">
                                <input id="eSignRequest" name="eSignRequest" type="hidden" [value]='param1' />
                                <input id="aspTxnID" name="aspTxnID" type="hidden" [value]='param2' />
                                <input id="Content-Type" name="Content-Type" type="hidden" [value]='param3' />

                                <div class="container text-center mt-10">
                                    <button class="p-button-success custom-button margin" type="submit" onclick="submit()">{{signButtonName}}</button>
                                </div>
                            </form>
                        </div>

                    </p-toolbar>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="voucherGenerationDto.parentVoucher.signaturePresident && !enableSignButton && !disableDownloadButton" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                        </div>
                    </p-toolbar>

                </div>
            </div>

        </div>



    </div>

    <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Payment Alert Box
        </p-header>
        <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
                        <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok"
                            (click)="redirect()">Ok</span>
                    </button>
        </ng-template>
    </p-dialog>