<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Universal Vendors
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>

    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="disableEdit" (emitRow)="onSelectRow($event)" [lazyLoadOnInit]="false"></custom-filter-table>
    </div>
    <p-dialog #dialog [(visible)]="personalVendorsDialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Add Universal Vendor
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row">
                        <div class="row gy-2">

                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.name" for=" name">Name</label>
                                <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="universalVendor.vendorName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.vendorName }" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />
                                <div *ngIf="showNameError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{showNameError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass">Department</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="department" [(ngModel)]="universalVendor.department" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.department }">
                                </ng-select>

                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="email" [ngClass]="fontService.labelClass" jhiTranslate="vendor.email">E-mail</label>
                                <input type="text" class="form-control" [(ngModel)]="universalVendor.emailId" name="email" autocomplete="off" id="email" maxlength="60" onblur="this.value =this.value.trim();" (input)="validateEmail()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.emailId }"
                                />
                                <div *ngIf="emailError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                        Address.</small>
                                </div>
                            </div>








                        </div>

                        <div class="row gy-2 mt-3">
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" for=" gstNo" [ngClass]="fontService.labelClass" jhiTranslate="user.gstNo">GST No</label>
                                <input type="text" class="form-control" name="gstNo" autocomplete="off" [(ngModel)]="universalVendor.gstNo" id="gstNo" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase (input)="validateGst()" maxlength="15" />

                                <div *ngIf="gstError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validgst">Please enter a valid GST
                        Number.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" for="tanNo" [ngClass]="fontService.labelClass" jhiTranslate="user.tanNo">TAN No</label>
                                <input type="text" name="tanNo" autocomplete="off" class="form-control" [(ngModel)]="universalVendor.tanNo" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    uppercase id="tanNo" (input)="validateTan()" maxlength="10" />
                                <div *ngIf="tanError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtan">Please enter a valid TAN
                        Number.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" for="tinNo" [ngClass]="fontService.labelClass" jhiTranslate="user.tinNo">TIN No</label>
                                <input type="text" class="form-control" name="tinNo" autocomplete="off" [(ngModel)]="universalVendor.tinNo" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    uppercase id="tinNo" (input)="validateTin()" maxlength="11" />
                                <div *ngIf="tinError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtin">Please enter a valid TIN
                        Number.</small>
                                </div>
                            </div>

                        </div>

                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                    Vendor Account Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <div class="col-sm-6 col-md-4">
                                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (change)="getBankBranchList()" [items]="bankList" [disabled]="readonly" [(ngModel)]="universalVendor.bankName"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.bankName }">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (search)="getBankBranchList($event)" [items]="bankBranchList" [disabled]="readonly" [(ngModel)]="bankBranch"
                                    (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.bankBranch}">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="universalVendor.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()"
                                />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="universalVendor.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()"
                                />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{universalVendor.bankName}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{universalVendor.bankBranch}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                                <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="readonly" [(ngModel)]="universalVendor.accountNumber" id="accountNo" (change)="accountChanged()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.accountNumber }" />
                                <div *ngIf="accountNoUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.accountNoUniError">{{accountNoUniError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                  Passbook</label>
                                <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="readonly" [(ngModel)]="universalVendor.bankAccName" id="bankAccountName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !universalVendor.bankAccName }" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr" for=" micr">MICR</label>
                                <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly" [(ngModel)]="universalVendor.micr" maxlength="9" id="micr" />
                            </div>
                            <div class="col-12 justify-content-center">
                                <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
                </button>
                                <div *ngIf="accountInvalidError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass">{{accountInvalidError}}</small>
                                </div>
                                <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                                </div>
                            </div>




                        </div>


                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
      </button>

            <button *ngIf="!universalVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveUniversalVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
      </button>
            <button *ngIf="universalVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveUniversalVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
        </ng-template>
    </p-dialog>

</div>