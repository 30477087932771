<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        TWAD Office Master List
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7">

        </div>

        <div class="col-lg-1">
            <button pButton pRipple label="Download" class="p-button-success custom-button" (click)="downloadExport()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
        <div class="col-lg-2">
            <button pButton pRipple label="Check Download Status" class="p-button-success custom-button" (click)="checkStatus()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
        <div class="col-lg-2">
            <button *ngIf="roleCode?.role?.code === 'SA'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
              </button>
        </div>
    </div>


    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [lazyLoadOnInit]="true" [enableAction]="false"></custom-filter-table>
    </div>

    <p-dialog #dialog [(visible)]="twadOfficedialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Twad Office Master
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row">
                        <div class="row gy-2">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass">
                                    Twad Office Master
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-4 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
                            <ng-select id="scheme" [appendTo]="'.p-dialog'" [(ngModel)]="twadOfficeMaster.districtMaster" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true"
                                [clearable]="true" class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOfficeMaster.districtMaster}">
                            </ng-select>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
                            <ng-select id="scheme" [appendTo]="'.p-dialog'" [(ngModel)]="twadOfficeMaster.blockMaster" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true"
                                class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOfficeMaster.blockMaster}">
                            </ng-select>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
                            <ng-select id="scheme" [appendTo]="'.p-dialog'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="twadOfficeMaster.levelMaster" (change)="twadOfficeList()" [closeOnSelect]="true" [clearable]="true"
                                class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOfficeMaster.levelMaster}">
                            </ng-select>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-4 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Beneficiary Code</label>
                            <input type="text" class="form-control" maxlength="60" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="beneficiaryCode" id="beneficiaryCode" [(ngModel)]="twadOfficeMaster.beneficiaryCode" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOfficeMaster.beneficiaryCode }" />
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Twad Office</label>
                            <ng-select id="scheme" [appendTo]="'.p-dialog'" [(ngModel)]="twadOfficeMaster.twadOffice" [items]="twadList" bindLabel="twadOfficeName" appearance="outline" (change)="twadOfficeMaster.officeId = twadOfficeMaster.twadOffice.officeId" (search)="twadOfficeList($event)"
                                [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOfficeMaster.twadOffice}">
                            </ng-select>
                        </div>

                    </div>

                </div>

            </div>

        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
      </button>

            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveTwadOffice()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
        </ng-template>
    </p-dialog>

</div>