<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass"> {{ headingText | translate }}</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode === 'DMAKER' || roleCode === 'VMAKER' || roleCode === 'BMAKER'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                      <span class="button-label" [ngClass]="fontService.buttonClass"
                      jhiTranslate="common.newbutton"></span>
                  </button>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                      (click)="deleteSelectedProfiles()"
                      [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                  <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                      chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                  <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
              </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="input-row py-10 pb-2" *ngIf="roleCode === 'DA' || roleCode === 'SA'">
        <ng-select *ngIf="roleCode === 'DA'" id="scheme" [(ngModel)]="BlockLevelDto" [placeholder]="BlockLevelDto ? '' : 'Block'" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)"
            [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <ng-select *ngIf="roleCode === 'DA'" id="scheme" [(ngModel)]="villageLevelDto" [placeholder]="villageLevelDto ? '' : 'Village'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" change="" [closeOnSelect]="true"
            [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <input *ngIf="roleCode === 'SA'" pInputText type="text" placeholder="LGD Code" [(ngModel)]="lgdCode" />
        <button pButton pRipple label="Search" (click)="loadPage(1)" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="BlockLevelDto=null;villageLevelDto=null;lgdCode=null;loadPage(1)" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" [showDialogTable]="false" [enableApproved]="roleCode==='DA'"
                [enableDeactivate]="roleCode==='SA'" [enableTnebDelete]="roleCode==='DA'" (emitRow)="onSelectRow($event)"></custom-filter-table>
        </div>
    </div>

</div>


<p-dialog #dialog [(visible)]="tangedcoBillDialog" [style]="{ width: '1000px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        {{ "tangedco.consumerDemandAdd" | translate }}
    </p-header>
    <ng-template pTemplate="content">
        <div class="col-12">
            <div class="form-inner-panel">
                <div class="row">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerNo" for=" name"></label>
                            <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.consumerNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerNo}" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" (change)="consumerDetails=null;" [disabled]="readonly" uppercase />
                            <div class="row mt-2">
                                <div class="col-12">
                                    <button *ngIf="tangedcoBills.consumerNo && !readonly" pButton pRipple class="p-button-primary custom-button" (click)="verifyConsumerNo()" [disabled]="consumerDetails" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass" >Verify ConsumerNo</span>
            </button>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="consumerDetails" class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerName" for=" name"></label>
                            <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.consumerName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerName}" [disabled]="true" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />

                        </div>
                        <div *ngIf="consumerDetails" class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.region" for=" name"></label>
                            <input type="number" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.region" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.region}" class="form-control" maxlength="60" [disabled]="true" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />

                        </div>

                    </div>
                    <div *ngIf="consumerDetails" class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.habitation" for=" name"></label>
                            <ng-select id="scheme" [(ngModel)]="tangedcoBills.habitation" [items]="habitationList" bindLabel="habitationName" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly && !isEdit" class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.habitation }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.Purpose" for=" name"></label>
                            <ng-select id="scheme" [(ngModel)]="tangedcoBills.purpose" [items]="purposeList" bindLabel="description" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly && !isEdit" class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.purpose }">
                            </ng-select>
                        </div>
                    </div>
                    <div *ngIf="consumerDetails" class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerAddress" for=" name"></label>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="tangedcoBills.consumerAddress" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerAddress }" cols="100" [disabled]="true"></textarea>
                        </div>


                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerLandmark" for=" name"></label>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="tangedcoBills.consumerLandMark" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerLandMark }" cols="100" [disabled]="readonly && !isEdit"></textarea>
                        </div>
                    </div>
                    <div *ngIf="consumerDetails" class="row">
                        <h1>Bills</h1>
                        <p-table [value]="consumerDetails.billDetail" [paginator]="true" [rows]="5" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[5,10, 25, 50]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.labelClass">Consumer No</th>
                                    <th [ngClass]="fontService.labelClass">Bill Type</th>
                                    <th [ngClass]="fontService.labelClass">Bill Month</th>
                                    <th [ngClass]="fontService.labelClass">Bill Year</th>
                                    <th [ngClass]="fontService.labelClass">Bill Amount</th>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-index="rowIndex">
                                <tr>
                                    <td>{{ data.consumerNo}}</td>
                                    <td>{{ data.billType}}</td>
                                    <td>{{ data.billMonth}}</td>
                                    <td>{{ data.billYear}}</td>
                                    <td>{{ data.billAmount}}</td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button *ngIf="consumerDetails && !readonly " pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="save()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="tangedco.sendForApproval"></span>
        </button>
        <button *ngIf="consumerDetails && isEdit" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="save()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="tangedco.sendForApproval"></span>
        </button>
        <button *ngIf="consumerDetails && approved && !isDelete" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="approveTangedcoMaster()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.approvebutton"></span>
        </button>
        <button *ngIf="consumerDetails && approved && !isDelete" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="rejectTangedcoMaster()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.rejectbutton"></span>
        </button>
        <button *ngIf="deactive && consumerDetails" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="deactiveTangedcoMaster()">
            <span class="button-label" [ngClass]="fontService.buttonClass">Deactivate</span>
        </button>


        <!-- <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
  </button>
        <button *ngIf="isDelete" pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="deleteVendor()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.delete"></span>
  </button>
        <button *ngIf="!personalVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePersonalVendor()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
  </button>
        <button *ngIf="personalVendor.id && !isDelete" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePersonalVendor()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
  </button> -->
    </ng-template>
</p-dialog>
<p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
  </button>
        <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
  </button>
    </ng-template>
</p-confirmDialog>