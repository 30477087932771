import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ReportService } from '../report.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

interface QueryType {
  page: number;
  size: any;
  [key: string]: any; 
}
@Component({
  selector: 'app-payment-status-report',
  templateUrl: './payment-status-report.component.html',
  styleUrls: ['./payment-status-report.component.scss']
})
export class PaymentStatusReportComponent implements OnInit {

  statusList = [
    { 
      name: 'Completed',
      value:'O.K.'
   },
    {
      name: 'Failed',
      value:'failed'
    }
  ];
  selectedStatus: string;
  lgdCode: string;
  voucherNo:string;
  cols:any;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  
  constructor(public fontService:FontService,
    protected reportService:ReportService,
    protected spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.cols=[
      {
        field:'id',
        header:'Id',
        isSelectcolumn:true,
      },
      {
        field:'apiInteractionId',
        header:'Api Interaction Id',
        isSelectcolumn:true,
      },
      {
        field:'creditAccountNo',
        header:'Credit Account No',
        isSelectcolumn:true,
      },
      {
        field:'errorCode',
        header:'Error Code',
        isSelectcolumn:true,
      },
      {
        field:'excepMetadata',
        header:'Exception Metadata',
        isSelectcolumn:true,
      },
      {
        field:'journalNumber',
        header:'Journal Number',
        isSelectcolumn:true,
      },
      {
        field:'paymentCategory',
        header:'Payment Category',
        isSelectcolumn:true,
      },
      {
        field:'processDate',
        header:'Process Date',
        isSelectcolumn:true,
      },
      {
        field:'rejectionReason',
        header:'Rejection Reason',
        isSelectcolumn:true,
      },
      {
        field:'transactionAmount',
        header:'Transaction Amount',
        isSelectcolumn:true,
      },
      {
        field:'transactionStatus',
        header:'Transaction Status',
        isSelectcolumn:true,
      },
      {
        field:'utrNo',
        header:'UTR No',
        isSelectcolumn:true,
      },
      {
        field:'voucherDescId',
        header:'Voucher Desc Id',
        isSelectcolumn:true,
      },
      {
        field:'vendorTransaction',
        header:'Vendor Transaction Id',
        isSelectcolumn:true,
      },
      {
        field:'createdOn',
        header:'Created Date',
        isSelectcolumn:true,
        type:'date'
      }
    ];
  }

  onSearch(){
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query:QueryType = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.lgdCode && { lgdCode: this.lgdCode }),
      ...(this.selectedStatus && { status: this.selectedStatus }),
      ...(this.voucherNo && { voucherNo: this.voucherNo }),
    };
    
    this.reportService.paymentStatusReport({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

}
