import { ProfileService } from 'src/app/module/profile/profie.service';
import { Profile, AddProfile } from './../profile/profile';
import { CommonService } from './../../shared/common.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SearchResult } from 'src/app/shared/models/search-result';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import {
  educationalQualification,
  gender,
  maritalStatus,
  categoryReservation,
  community,
  filterQuery,
} from 'src/app/shared/common.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { Dialog } from 'primeng/dialog';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
// import { saveAs } from 'file-saver';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { LevelMasterDto } from '../level-master/level-master';
import { LevelMasterService } from '../level-master/level-master.service';
import { EmployeeService } from '../employee/employee.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-employee-transfer',
  templateUrl: './employee-transfer.component.html',
  styleUrls: ['./employee-transfer.component.scss'],
})
export class EmployeeTransferComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;

  profileDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Profile> = new SearchResult<Profile>();
  profiles?: Profile[];
  profile?: AddProfile;
  selectedProfiles: Profile[] = [];
  submitted?: boolean;
  filterProps: any;
  dateOfAssumingOffice: Date;
  profileDetails: any = {};
  page: number;
  itemsPerPage: number = 10;
  genderList: gender[];
  educationList: educationalQualification[];
  maritalList: maritalStatus[];
  categoryReservationList: categoryReservation[];
  community: community[];
  name: any;
  otp: number;
  Profiledetails: any;
  enableFeild: boolean;

  // validation fields properties
  resendotp = false;
  sendotp: boolean = false;
  otpOpen = false;
  public aadhaarError: boolean = false;
  public otpError: boolean = false;
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  today: Date;
  inCharge: boolean = false;
  readonly: boolean = true;
  //required fields property
  ngSelectInvalid: boolean = false;
  aadharNoNameError: string;
  panNoError: string;
  mobileNoError: string;
  emailIdError: string;
  txnNo: any;
  rolecode: any;
  otpButtonName: string = 'Send OTP';
  enableAddNewButton: boolean = false;
  showInactive: boolean;
  disableEdit: boolean = true;
  levelType: string = 'Village';
  adminUserName: string;
  levelTypeId: number = 4;
  isSearch: boolean;
  lgdCode: string;
  levelMasterId: any;
  isProfileIncluded: string;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelDto: any;
  blockLevelList: LevelMasterDto[];
  employeeCols = [
    {
      field: 'name',
      header: 'Name',
      isSortable: true,
      isFilterable: true,
      width: 10,
      isSelectcolumn: true,
      jhiTranslate: 'tableHead.name',
    },
    {
      field: 'employeeType',
      header: 'Employee Type',
      isSortable: true,
      isFilterable: false,
      width: 25,
      isSelectcolumn: true,
      type: 'dropDown',
      jhiTranslate: 'tableHead.employeeType',
    },
    {
      field: 'subEmployeeType',
      header: 'Sub Employee Type',
      isSortable: true,
      isFilterable: false,
      width: 25,
      isSelectcolumn: true,
      type: 'dropDown',
      jhiTranslate: 'tableHead.subEmployeeType',
    },
    {
      field: 'createdOn',
      header: 'Created On',
      jhiTranslate: 'tableHead.createdon',
      isSortable: true,
      isFilterable: true,
      width: 13,
      isSelectcolumn: true,
      type: 'date',
    },
  ];
  showTable: boolean;
  districtListDto: any;

  constructor(
    private ProfileService: ProfileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected router: Router,
    private CommonService: CommonService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private levelMasterService: LevelMasterService,
    private employeeService: EmployeeService,
    private localStorageService: LocalStorageService,
    private sessionStorageService : SessionStorageService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.sessionStorageService.clear('employeedetails');
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.rolecode = res.body.role.code;
        this.levelMasterId = res.body.levelMasterId;
        this.onDistrictLevelChange();
        if (res.body.profileStatus == 'No') {
          this.enableAddNewButton = true;
        }
        if (this.rolecode === 'SA' || this.rolecode === 'SUA') {
          this.showInactive = true;
          this.disableEdit = false;
        }
        if (
          this.rolecode === 'VMAKER' ||
          this.rolecode === 'VC1' ||
          this.rolecode === 'VC2' ||
          this.rolecode === 'DA'
        ) {
          this.readonly = false;
        }
        if (this.rolecode === 'DA') {
          this.showInactive = true;
          this.disableEdit = false;
          this.cols = [
            {
              field: 'name',
              header: 'Name',
              isSortable: false,
              isFilterable: false,
              isSelectcolumn: true,
              jhiTranslate: 'tableHead.name',
            },
            {
              field: 'dateOfBirth',
              header: 'Date Of Birth',
              isSortable: false,
              isSelectcolumn: false,
              type: 'date',
              jhiTranslate: 'tableHead.dateOfBirth',
            },
            {
              field: 'panNo',
              header: 'Pan No',
              jhiTranslate: 'tableHead.panno',
              isSortable: false,
              isFilterable: false,
              isSelectcolumn: true,
            },
            {
              field: 'aadhaarNo',
              header: 'Aadhaar',
              jhiTranslate: 'tableHead.aadhaarno',
              isSortable: false,
              isFilterable: false,
              isSelectcolumn: true,
            },
            {
              field: 'termValidUpTo',
              header: 'Term Valid Upto',
              jhiTranslate: 'tableHead.termValidUpTo',
              isSortable: false,
              isSelectcolumn: true,
              type: 'date',
            },
            {
              field: 'adminUser',
              header: 'Admin User Name',
              jhiTranslate: 'tableHead.adminUserName',
              isSortable: false,
              isFilterable: false,
              isSelectcolumn: true,
              type: 'dropDown_with_other_field',
              Dfield: 'userName',
            },
          ];
        } else {
          this.cols = [
            {
              field: 'name',
              header: 'Name',
              isSortable: true,
              isFilterable: true,
              isSelectcolumn: true,
              jhiTranslate: 'tableHead.name',
            },
            {
              field: 'dateOfBirth',
              header: 'Date Of Birth',
              isSortable: true,
              isSelectcolumn: true,
              type: 'date',
              jhiTranslate: 'tableHead.dateOfBirth',
            },
            {
              field: 'panNo',
              header: 'Pan No',
              jhiTranslate: 'tableHead.panno',
              isSortable: true,
              isFilterable: true,
              isSelectcolumn: true,
            },
            {
              field: 'aadhaarNo',
              header: 'Aadhaar',
              jhiTranslate: 'tableHead.aadhaarno',
              isSortable: true,
              isFilterable: true,
              isSelectcolumn: true,
            },
            {
              field: 'termValidUpTo',
              header: 'Term Valid Upto',
              jhiTranslate: 'tableHead.termValidUpTo',
              isSortable: false,
              isSelectcolumn: true,
              type: 'date',
            },
            {
              field: 'adminUser',
              header: 'Admin User Name',
              jhiTranslate: 'tableHead.adminUserName',
              isSortable: true,
              isFilterable: true,
              isSelectcolumn: true,
              type: 'dropDown_with_other_field',
              Dfield: 'userName',
            },
          ];
        }
      },
      (onError) => {}
    );

    this.today = new Date();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  levelTypeChange() {
    if (this.levelType == 'Block') {
      this.levelTypeId = 3;
    } else if (this.levelType == 'Village') {
      this.levelTypeId = 4;
    }
    if (this.isProfileIncluded === 'Yes') {
      this.loadPage(1);
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelId: this.levelTypeId,
    };
    if (this.isSearch && this.adminUserName) {
      query['search'] = this.adminUserName;
      if (this.levelTypeId === 4 && this.lgdCode) {
        query['lgdCode'] = this.lgdCode;
      }
      this.ProfileService.getMakerProfilesByLevel({ ...query }).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    } else if (this.rolecode === 'DA') {
      this.ProfileService.getMakerProfilesByLevel({ ...query }).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  protected onSuccess(
    data: Profile[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.notificationService.alertError(
      'An unexpected error occured,please try again',
      ''
    );
    this.spinner.hide();
    // this.enableFeild = false
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    if (this.rolecode) {
      if (this.isProfileIncluded === 'Yes') {
        this.loadPage(event.page);
      } else if (this.showTable) {
        this.onEmployeeSearch(event.page);
      }
    }
  }

  hideDialog() {
    this.otpOpen = false;
    this.enableFeild = false;
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.profile = new AddProfile();
    this.ngSelectInvalid = false;
    this.profileDialog = false;
    this.resendotp = false;
    this.sendotp = false;
    this.otpButtonName = 'Send OTP';
  }

  onSelectRow($event) {
    if ($event.type === 'transfer' && this.isProfileIncluded === 'Yes') {
      this.router.navigate(['/employee-transfer-page'], {
        queryParams: {
          profileId: $event.value.id,
          levelMasterId: this.levelMasterId,
          villageId: $event.value.adminUser.levelMasterId,
        },
      });
    } else if ($event.type === 'transfer' && this.isProfileIncluded === 'No') {
      let employeeDetails = {'districtId':this.districtListDto.id,'districtName':this.districtListDto.name,'blockId':this.BlockLevelDto.id,'blockName':this.BlockLevelDto.name,'villageId':this.villageLevelDto.id,'villageName':this.villageLevelDto.name,employee:$event.value,'lgdCode':this.villageLevelDto.code}
      this.sessionStorageService.store('employeedetails', employeeDetails);
      this.router.navigate(['/employee-transfer-page'], {
        queryParams: {
          employeeId: $event.value.id,
          levelMasterId: this.levelMasterId,
        },
      });
    }
  }
  onSearch() {
    this.isSearch = true;
    this.loadPage(1);
  }

  onClear() {
    this.isSearch = false;
    this.adminUserName = null;
    this.lgdCode = null;
    this.loadPage(1);
  }

  onDistrictLevelChange($event?) {
    if(!this.districtListDto) {
      this.levelMasterService.getLevelMaster(this.levelMasterId).subscribe(
        (res) => {
         
          this.districtListDto = res.body;
          
        },
        () => {
          this.onError();
        }
      )
    }
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

 

  onEmployeeSearch(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let levelmasterId = 0;
    if (this.levelTypeId === 3) {
      levelmasterId = this.BlockLevelDto.id;
    } else {
      levelmasterId = this.villageLevelDto.id;
    }
    let filter = new filterQuery();
    filter.key = 'levelMasterId';
    filter.operation = 'equals';
    filter.value = levelmasterId;
    let filterquery = [];
    filterquery.push(filter);
    this.employeeService
      .validFilter(
        { size: this.itemsPerPage, page: pageToLoad - 1 },
        filterquery
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.showTable = true;
          this.searchResult.total = Number(res.headers.get('X-Total-Count'));
          this.spinner.hide();
          this.searchResult.items = res.body ?? [];
          this.searchResult = { ...this.searchResult };
        },
        () => {}
      );
  }

  onEmployeeClear() {
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    this.showTable = false;
    this.searchResult.total = 0;
    this.searchResult.items = [];
    this.searchResult = { ...this.searchResult };
  }
}
