import { Agency } from "src/app/module/agency/agency";
import { LevelMaster } from "src/app/module/level-master/level-master";
import { FinYear } from "src/app/module/limit-translist/limit-translist.module";

export class TwadLevel {
    id: number;
    name: string;
    code: string;
    twadCode: string;
    levelMaster: LevelMaster;
    twadParentId: number;
}

export class BillingDetails {
    id: number;
    officeId: number;
    officeName: string;
    beneficiaryCode: string;
    lgdCode: string;
    billSno: number;
    billDate: Date;
    waterChargesDue: number;
    maintenanceChargesDue: number;
    interestDue: number;
    totalDue: number;
    levelMaster: LevelMaster;
    twadLevelMaster: TwadLevel;
    activeFlag: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
}


export interface TwadAccountSummary {
    id: number;
    levelId: number;
    levelMaster: LevelMaster;
    twadLevelMaster: TwadLevel;
    implementingAgency: Agency;
    finYear: FinYear;
    currentAmount: number;
    utilizedAmount: number;
    openingBalance: number;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
}

export class twadOffice {
    id: number;
    twadOfficeName: string;
    officeId:number
}

export class TwadSchemeMaster{
    id: number;
    twadOffice: twadOffice;
    levelMaster: any;
    twadLevelMaster: TwadLevel;
    lgdCode: string;
    districtMaster:any;
    blockMaster:any;
    officeId:number;
    beneficiaryCode:string;
  }