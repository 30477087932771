<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.employeeTransferReport"></h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Download" (click)="download()" class="p-button-sm"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>