import { Component } from '@angular/core';

@Component({
  selector: 'app-tneb-payment-report',
  templateUrl: './tneb-payment-report.component.html',
  styleUrl: './tneb-payment-report.component.scss'
})
export class TnebPaymentReportComponent {

}
