<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5>Update Beneficiary</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-6">
                <div class="card p-3">
                    <div class="row">
                        <h6>Beneficiary Details</h6>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="beneficiaryName">Beneficiary Name</label>
                            <input type="text" class="form-control" readonly required 
                            name="beneficiaryName" autocomplete="off" id="beneficiaryName" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="beneficiaryId">Beneficiary Id</label>
                            <input type="text" class="form-control" readonly required 
                            name="beneficiaryId" autocomplete="off" id="beneficiaryId" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="gender">Gender</label>
                            <ng-select id="gender"
                                appearance="outline"
                                [closeOnSelect]="true" class="form-control" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label class="form-control-label req" for="mobileNo">Mobile No</label>
                            <input type="text" class="form-control" required 
                            name="mobileNo" autocomplete="off" id="mobileNo" />
                        </div>
                        <div class="col-4">
                            <label class="form-control-label" for="email">E-mail</label>
                            <input type="text" class="form-control" 
                            name="email" autocomplete="off" id="email" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-control-label req" for="aadharNo">Aadhar No</label>
                            <input type="text" class="form-control" required 
                            name="name" autocomplete="off" id="aadharNo" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="block">Block</label>
                            <ng-select id="block"
                                appearance="outline"
                                [closeOnSelect]="true" class="form-control" [clearable]="false">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="village">Village</label>
                            <ng-select id="village"
                                appearance="outline"
                                [closeOnSelect]="true" class="form-control" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <h6>Beneficiary Account Details</h6>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="ifscCode">IFSC Code</label>
                            <input type="text" class="form-control" required 
                            name="ifscCode" autocomplete="off" id="ifscCode" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="branchName">Branch Name</label>
                            <input type="text" class="form-control" readonly 
                            name="branchName" autocomplete="off" id="branchName" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="accountHolderName">Account Holder Name</label>
                            <input type="text" class="form-control" required 
                            name="accountHolderName" autocomplete="off" id="accountHolderName" />
                        </div>                   
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-control-label req" for="accountNumber">Account Number</label>
                            <input type="text" class="form-control" required 
                            name="accountNumber"autocomplete="off" id="accountNumber" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="retypeAccountNumber">Retype Account Number</label>
                            <input type="text" class="form-control" required 
                            name="retypeAccountNumber" autocomplete="off" id="retypeAccountNumber" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label req" for="address">Address</label>
                            <input type="text" class="form-control" required 
                            name="address" autocomplete="off" id="address" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-control-label" for="pinCode">Pin Code</label>
                            <input type="text" class="form-control" 
                            name="pinCode" autocomplete="off" id="pinCode" />
                        </div>
                    </div>
                    <div class="row">
                        <p-toolbar styleClass="toolbar">
                            <ng-template pTemplate="left">
                                <button pButton pRipple type="button" label="Update Beneficiary" class="p-button-success"></button>
                                <button pButton pRipple type="button" label="Cancel" class="p-button-danger"></button>
                            </ng-template>
                        </p-toolbar>
                    </div>            
                </div>
            </div>
        </div>
    </div>
</div>