<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="breadcrumb.profile.profileList">
                        Profile List
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="enableAddNewButton" pButton pRipple icon="pi pi-plus " class="p-button-success custom-button" (click)="openNew()" styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
        </button>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                      (click)="deleteSelectedProfiles()"
                      [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                  <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                      chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                  <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
              </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="row pt-2 pb-2" *ngIf="rolecode === 'DA' && !enableAddNewButton">
        <div class="col">
            <p-radioButton name="levelType" value="Block" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType2"></p-radioButton>
            <label for="levelType2" class="label-margin">Block</label>
            <p-radioButton name="levelType" value="Village" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType3"></p-radioButton>
            <label for="levelType3" class="label-margin">Village</label>
        </div>
    </div>
    <div class="input-row py-10 pb-2" *ngIf="rolecode === 'DA' && !enableAddNewButton">
        <input pInputText type="text" placeholder="Admin User Name" [(ngModel)]="adminUserName" />
        <input *ngIf="levelType === 'Village'" pInputText type="text" placeholder="LGD Code" [(ngModel)]="lgdCode" />
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onClear()" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" (emitRow)="onSelectRow($event)" [showInactiveButton]="showInactive" [disableEditIcon]="disableEdit"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="profileDialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            {{ "user.add" | translate }}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="col-sm-12 col-md-12 mb-2">
                        <label class="form-control-label" for="incharge" [ngClass]="fontService.labelClass">Are you in charge?</label><br>
                        <p-radioButton name="option" [disabled]="readonly" [value]="true" [(ngModel)]="inCharge" (onClick)="isIncharge()" label="{{'payments.amount.yes'|translate}}"></p-radioButton>
                        <p-radioButton name="option" [disabled]="readonly" [value]="false" [(ngModel)]="inCharge" (onClick)="isIncharge()" label="{{'payments.amount.no'|translate}}"></p-radioButton>
                    </div>
                    <div class="row gy-2" *ngIf="!enableFeild">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.aadhaarno" for="aadhaar">Aadhaar Number</label>
                            <input type="text" class="form-control" name="aadhaar" autocomplete="off" id="aadhaar" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="profile.aadhaarNo" required autofocus (input)="validateAadhaar()"
                                maxlength="12" />
                            <div *ngIf="aadhaarError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validaadhar">Please enter a valid Aadhaar
                Number</small>
                            </div>
                            <div *ngIf="aadharNoNameError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.aadhaarNoUniError"> {{ aadharNoNameError }}</small>
                            </div>
                            <!-- <button *ngIf="!aadhaarError && !otpOpen && profile.aadhaarNo"  pButton pRipple class="p-button-primary p-button-sm custom-button"
            (click)="sendOtp()" styleClass="button">Send Otp</button> -->
                            <div class="row mt-2">
                                <div class="col-12">
                                    <button *ngIf="!aadharNoNameError && !aadhaarError && !otpOpen && profile.aadhaarNo" pButton pRipple class="p-button-primary custom-button" (click)="sendOtp()" styleClass="button" [disabled]="sendotp">
          <span class="button-label" [ngClass]="fontService.buttonClass" >{{otpButtonName}}</span>
        </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="true">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.otp" for="otp">OTP</label
            >
            <input
              type="text"
              class="form-control"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
              name="otp" autocomplete="off"
              id="otp"
              [(ngModel)]="profile.otp"
              (input)="validateAotp()"
              maxlength="6"
            />
            <div *ngIf="otpError" class="text-danger">
              <small [ngClass]="fontService.smallClass" jhiTranslate="error.validotp">Please enter a valid OTP
                Number.</small>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <button *ngIf="!aadhaarError && !otpOpen && profile.otp && profile.aadhaarNo" pButton pRipple class="p-button-primary custom-button" (click)="validateOtp(profile.otp)"
          styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyotp"></span>
        </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row gy-2" *ngIf="enableFeild">
          <div class="col-sm-6 col-md-4">
            <label class="form-control-label" [ngClass]="fontService.labelClass"
              jhiTranslate="breadcrumb.profile.username" for="name">Name</label>
                            <input type="text" class="form-control" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="name"
                                autocomplete="off" id="name" [(ngModel)]="profile.name" readonly />
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.dateOfBirth" for="dateOfBirth">Date of Birth</label>
                            <p-calendar [(ngModel)]="profile.dateOfBirthUi" styleClass="datepicker" disabled="true"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.gender" for="gender">Gender</label>
                            <input type="text" class="form-control" maxlength="10" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="name"
                                autocomplete="off" id="name" [(ngModel)]="profile.genderDto.name" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.mobile" for="mobile">Mobile No</label>
                            <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" name="mobile" autocomplete="off" [(ngModel)]="profile.mobileNo" id="email" (input)="validateMobile()" id="mobile" [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.mobileNo }"
                                maxlength="10" />
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter a valid Mobile
                Number.</small>
                            </div>
                            <div *ngIf="mobileNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showMobileUniError"> {{ mobileNoError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.email" for="email">Email</label>
                            <input type="text" class="form-control" name="email" autocomplete="off" [(ngModel)]="profile.emailId" id="email" (ngModelChange)="emailCheck()" (input)="validateEmail()" maxlength="60" />
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                Address.</small>
                            </div>
                            <div *ngIf="emailIdError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showEmailUniError">{{emailIdError}}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.educationalQualification" for="educationalQualification">Education
              Qualification</label>
                            <ng-select [appendTo]="'.p-dialog'" id="educationalQualification" [(ngModel)]="profile.educationalQualificationDto" [items]="educationList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.educationalQualificationDto }"
                                [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.maritalStatus" for="maritalStatus">Marital Status</label>
                            <ng-select [appendTo]="'.p-dialog'" id="maritalStatus" [(ngModel)]="profile.maritalStatusDto" [items]="maritalList" [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.maritalStatusDto }" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div *ngIf="rolecode === 'DC2' || rolecode === 'BC2' || rolecode === 'VC2'  ||rolecode === 'SA'" class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.categoryReservation" for="categoryReservation">Category Reservation</label>
                            <ng-select [appendTo]="'.p-dialog'" id="categoryReservation" [(ngModel)]="profile.categoryReservationDto" [items]="categoryReservationList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div *ngIf="rolecode === 'DC2' || rolecode === 'BC2' || rolecode === 'VC2'  ||rolecode === 'SA'" class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.community" for="community">Community</label>
                            <ng-select [appendTo]="'.p-dialog'" id="community" [(ngModel)]="profile.communityDto" [items]="community" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.dateOfAssumingOffice" for="dateOfAssumingOffice">Date Of Assuming Office</label>
                            <p-calendar [(ngModel)]="profile.dateOfAssumingOffice" appendTo="body" [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.dateOfAssumingOffice }" [maxDate]="today"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.termValidUpTo" for="termValidUpTo">Term Valid UpTo</label>
                            <p-calendar [(ngModel)]="profile.termValidUpTo" appendTo="body" [minDate]="today" [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.termValidUpTo }"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.panno" for="pan">PAN No</label>
                            <input type="text" [(ngModel)]="profile.panNo" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                name="pan" autocomplete="off" id="pan" (input)="validatePan()" maxlength="10" />
                            <div *ngIf="panError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a valid PAN
                Number.</small>
                            </div>
                            <div *ngIf="panNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">{{ panNoError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.buildingno" for="doorNo">Building No/Door No</label>
                            <input type="text" [(ngModel)]="profile.doorNo" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                maxlength="20" name="doorNo" autocomplete="off" id="doorNo" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.street" for="street">Street</label>
                            <input type="text" [(ngModel)]="profile.street" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                maxlength="50" name="street" autocomplete="off" id="street" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.landmark" for="landmark">Landmark</label>
                            <input type="text" [(ngModel)]="profile.landmark" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                maxlength="50" name="landmark" autocomplete="off" id="landmark" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.city" for="city">City</label>
                            <input type="text" [(ngModel)]="profile.city" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="50" class="form-control"
                                name="city" autocomplete="off" id="city" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.district" for="district">District</label>
                            <input type="text" [(ngModel)]="profile.district" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                maxlength="20" name="district" autocomplete="off" id="district" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.state" for="state">State</label>
                            <input type="text" [(ngModel)]="profile.state" class="form-control" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                maxlength="20" name="state" autocomplete="off" id="state" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.pincode" for="pinCode">Pin Code</label
            >
            <input
              type="text"
              [(ngModel)]="profile.pinCode"
              class="form-control"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
              maxlength="6"
              name="pinCode" autocomplete="off"
              id="pinCode"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
    </button>
    <button *ngIf="!profile.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
      (click)="saveProfile()">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
    </button>
    <button *ngIf="profile.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
      (click)="saveProfile()">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
    </button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>