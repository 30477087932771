import { Component, OnInit } from '@angular/core';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { limitTransDto, limitTransList, limitTransListDto } from '../limit-translist/limit-translist.module';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { filterQuery } from 'src/app/shared/common.model';
import { DatePipe } from '@angular/common';
import { ComponentType } from '../component-type/component-type';
import { ComponentTypeService } from '../component-type/component-type.service';

@Component({
  selector: 'app-osr-fund',
  templateUrl: './osr-fund.component.html',
  styleUrls: ['./osr-fund.component.scss']
})
export class OsrFundComponent implements OnInit {
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  formulaitemsPerPage: any = 10;
  formulapage: number;
  formulafilterProps: any;
  searchResult: SearchResult<limitTransList> =
    new SearchResult<limitTransList>();
  cols: any;
  formulaCol: any;
  submitted: boolean;
  limitSetDialog: boolean;
  limitTransDto: limitTransDto;
  limitTranslist: limitTransListDto;
  levelMasterList: any;
  agency: any;
  ComponentType: any;
  enableForm: any;
  showText: boolean;
  enableTable: boolean;
  iaCompList: any;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;

  //required fields property
  ngSelectInvalid: boolean = false;
  iaError: any;
  compTypeList: ComponentType[];
  selectedcompType: ComponentType;

  constructor(
    private limitTransListService: LimitTransListService,
    private spinner: NgxSpinnerService,
    public fontService: FontService,
    public validationService: ValidationService,
    private datePipe: DatePipe,
    protected notificationService: NotificationService,
    private componentTypeService:ComponentTypeService,) { }

  ngOnInit(): void {
    this.cols = [
      
      {
        field: 'implementingAgency',
        header: 'IA Name',
        jhiTranslate: 'tableHead.iA.name',
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: false,
      },
      {
        field: 'componentType',
        header: 'IA Components',
        jhiTranslate: 'tableHead.iACompo.name',
        isSortable: true,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: false,
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        width: 13,
        type:'date',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'amount',
        header: 'Amount',
        jhiTranslate: 'tableHead.amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: true,
        type:'amount'
      },
      {
        field: 'fundSource',
        header: 'Fund Source',
        width: 13,
        isSelectcolumn: true,
        isFilterable: true,
      }
    ];
    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<ComponentType[]>) => {
        this.compTypeList=res.body.filter(item => item.name.startsWith('Village'));
    },
      ()=>{});
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    if(this.fromDate && this.toDate) {
      this.startDate=this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
      this.endDate=this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.startDate && { fromDate: this.startDate }),
      ...(this.endDate && { toDate: this.endDate }),
      sort: ['transactionDate,asc'],
    };
    this.filterProps = this.filterProps ?? [];
    let filquery = new filterQuery();
    if(this.selectedcompType) {
      filquery.key = 'componentType.id';
    filquery.operation = 'equals';
    filquery.value = this.selectedcompType.id;
    this.filterProps.push(filquery);
    }
    
   
    this.limitTransListService
      .filter({ ...query }, this.filterProps)
      .subscribe(
        (res: HttpResponse<limitTransList[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: limitTransList[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }


}
