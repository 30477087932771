<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Auto Sweep

                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>


    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <p-table [value]="autoSweep" [resizableColumns]="true" [paginator]="true" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount" [lazyLoadOnInit]="true" responsiveLayout="scroll"
            [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'"
            [first]="first" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">Transaction Date</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Transactions</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Amount</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">File</th>

                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;"><b>{{list.transactionDate | date:'dd/MM/yyyy' }}</b></td>
                    <td style="text-align: center;"><b>{{list.transactionCount}}</b></td>
                    <td style="text-align: center;"><b>{{list.totalAmount |  currency:'INR':'symbol':'1.2-2':'en-IN'}}</b></td>
                    <td style="text-align: center;">
                        <p-button (click)="downloadFile(list?.fileName)" styleClass="p-button-link">{{list.fileName}}</p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>