import { LevelMasterService } from './../../module/level-master/level-master.service';
import { PaymentService } from './../payment-voucher/payment-voucher.service';

import { LevelMasterDto } from './../../module/level-master/level-master';
import { filterQuery } from './../../shared/common.model';
import {
  ComponentDto,
  ComponentType,
} from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import {
  CommercialVendorsDto,
  Vendor,
} from 'src/app/module/commercial-vendors/commercial-vendors';
import {
  CommercialVendorDto,
  paymentVoucherGen,
  VoucherBillDescDto,
  vendorTransactionDto,
  ComponentTypeDto,
  VendorTransaction,
  EmuthraResponseDto,
  EmudhraRequest,
  VendorTransactionBulkDto,
  ParentVoucher,
  ParentVoucherBillDesc,
} from './../payment-voucher/payment-voucher.model';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserService } from 'src/app/module/user/user.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';

@Component({
  selector: 'app-bluk-payment-voucher',
  templateUrl: './bluk-payment-voucher.component.html',
  styleUrls: ['./bluk-payment-voucher.component.scss'],
})
export class BlukPaymentVoucherComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  navbarComponent!: NavbarComponent;
  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  pdfString: any;
  schemeList: SchemeList[];
  selectedVendor: CommercialVendorsDto;
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  levelMasterDto: LevelType;

  voucherGenerationDto: VendorTransactionBulkDto;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: VendorTransaction[];
  selectedscheme: any;
  profileDialog: boolean = false;
  totalDeduction: number;
  dropDownLabel: any;
  maxDate = new Date();
  amount: number;
  description: string;
  hsn: string;
  quantity: number;
  signButtonName: any;
  noRecord: boolean;
  isFileUploaded: boolean;
  uFilename: string;
  viewDoc: boolean = false;
  vFileExt: string;
  vFileUrl: string;
  currentPage: number = 0;
  loadingMore: boolean = false;
  vendorSearchOption: boolean = true;
  accountNo: string;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Rate',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
  ];

  // validation fields properties
  public ifscError = false;
  public panError = false;
  public hsnError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  filterProps: any;
  securityDeposit: number;
  enableGstOnTds: boolean;
  uploadedFiles: any;
  enableLabourChess: boolean;
  enableSecurityDeposit: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  downloadButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enablesign: boolean = false;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean = true;
  buttonText: string;
  checkstatusButton: boolean;
  interactionId: any;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any;
  enableZonalButton: boolean;
  enableApproved: boolean;
  checkForPaymentStatus: boolean;
  componentMasterTable: any;
  typedValue: any;
  filterTimeout: any;
  hasMoreItemsToLoad: boolean = true;
  deleteDialog: boolean;
  departmentName: any;
  schemeName: any;
  disableDownloadButton: boolean = false;
  finYear: FinYear[];
  finYearDto: FinYear;
  enableView: boolean;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private userService: UserService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorageService:SessionStorageService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private dashboardService: DashboardService
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.voucherGenerationDto.parentVoucher.implementingAgency.leveltype
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            if (this.roleCode.role.name.startsWith('Village')) {
              this.grantLevel = res.body.filter(
                (item) =>
                  item.name === 'Village Minimum Grant' ||
                  item.name === 'Village General Funds'
              );
            } else if (this.roleCode.role.name.startsWith('Block')) {
              this.grantLevel = res.body.filter(
                (item) => item.name === 'Panchayat Union General Fund'
              );
            } else if (this.roleCode.role.name.startsWith('District')) {
              this.grantLevel = res.body.filter(
                (item) => item.name === 'District Population Grant'
              );
            } else {
              this.grantLevel = res.body;
            }
          },
          () => {}
        );
      this.majaorComponent = this.componentMasterTable.filter(
        (item) =>
          item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0
      );
      this.minorComponent = this.componentMasterTable.filter(
        (item) =>
          item.parentId === this.majaorComponentdto.id &&
          !item.name.startsWith('Salary')
      );
      this.subComponent = this.componentMasterTable.filter(
        (item) => item.parentId === this.minorComponentDto.id
      );
    });
  }

  async ngOnInit(): Promise<void> {
    this.componentMasterTable = this.sessionStorageService.retrieve(
      'componentMasterTable'
    );
    if (!this.componentMasterTable) {
      await this.loadComp();
    }
    await this.getRole();
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
        let FinYear: string;
        if (new Date().getMonth() + 1 >= 4) {
          FinYear =
            new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
        } else {
          FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
        }
        this.voucherGenerationDto.parentVoucher.finyear = this.finYear.find((_) => _.name === FinYear);
      },
      () => {}
    );
    this.paymentService
      .getByImplemeting(this.roleCode.implementingAgencyId)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.parentVoucher.implementingAgency = res.body;
          this.departmentName = res.body.departmentDto.name;
          this.schemeName = res.body.schemeDto.name;

          this.levelMasterService
            .getLevelMaster(
              this.voucherGenerationDto.parentVoucher.implementingAgency
                .levelMaster
            )
            .subscribe(
              (res: HttpResponse<any>) => {
                this.levelMasterDto = res.body.levelTypeDto;
                this.voucherGenerationDto.parentVoucher.levelMaster = res.body;
              },
              () => {}
            );
          this.componentTypeService
            .find(
              this.voucherGenerationDto.parentVoucher.implementingAgency
                .leveltype
            )
            .subscribe(
              (res: HttpResponse<any>) => {
                this.grantLevel = res.body;
              },
              () => {}
            );
        },
        () => {}
      );
    if (
      this.roleCode.role.code === 'DC2' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.enableApproved = true;
      this.signButtonName = 'Perform e-Sign President';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }

    else if( this.roleCode.role.code === 'DC1' ||
    this.roleCode.role.code === 'BC1' ||
    this.roleCode.role.code === 'VC1') {
      this.signButtonName = 'Perform e-Sign  VP';
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
      this.paymentSucessDialog = true;
      this.paymentSucessText =
        this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError(
        'Error Occurred: ' +
          this.activatedRoute.snapshot.queryParamMap.get('error'),
        ''
      );
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if(this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.paymentService
        .getParentVocherbyId(
          Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
        )
        .subscribe(
          (res: HttpResponse<VendorTransactionBulkDto>) => {
            this.voucherGenerationDto = res.body;

            if (this.voucherGenerationDto.parentVoucher.signStatus && !this.enableView) {
              
              this.readonly = true;
              this.getfile();
            }
            this.date = new Date(
              this.voucherGenerationDto.parentVoucher.transactionDate
            );
            this.departmentName =
              this.voucherGenerationDto.parentVoucher.implementingAgency.department.name;
            this.schemeName =
              this.voucherGenerationDto.parentVoucher.implementingAgency.scheme.name;
            this.levelMasterDto =
              this.voucherGenerationDto.parentVoucher.levelMaster.levelType;

            this.componentTypeService
              .findbyId(
                this.voucherGenerationDto.parentVoucher.componentMasterId
              )
              .subscribe(
                (res: HttpResponse<ComponentType>) => {
                  this.grantlevel = res.body;
                  this.spinner.hide();
                },
                () => {}
              );
          },
          () => {}
        );
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new VendorTransactionBulkDto();
      this.voucherGenerationDto.parentVoucher = new ParentVoucher();
      this.voucherGenerationDto.parentVoucherBillDescList = [];
      this.voucherGenerationDto.parentVoucher.voucherNumber =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
    }
  }

  redirect() {
    this.router.navigate(['/blukpaymentTable'], {
      queryParams: {
        id: String(this.voucherGenerationDto.parentVoucher.id),
      },
    });
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  onLevelType() {
    this.componentTypeService
      .find(
        this.voucherGenerationDto.parentVoucher.implementingAgency.leveltype
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.grantLevel = res.body;
        },
        () => {}
      );
  }

  loadComp(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.paymentService.componentMasterList().subscribe(
        (res) => {
          this.spinner.show();
          this.sessionStorageService.store('componentMasterTable', res.body);
          this.componentMasterTable = res.body;
          resolve(this.componentMasterTable);
          this.spinner.hide();
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  ngAfterViewInit() {
    this.updateDialogFont();
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  genVocher() {
    this.spinner.show();
    this.voucherGenerationDto.parentVoucher.transactionDate = this.date;
    this.paymentService.genBulkVocher(this.voucherGenerationDto).subscribe(
      (res) => {
        this.enableSignButton = true;
        this.getfile();
      },
      (err) => {}
    );
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.parentVoucher.voucherNumber)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          // this.notificationService.alertError('Error!', '');
        }
      );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.parentVoucher.voucherNumber)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          
          if (!this.voucherGenerationDto.parentVoucher.id) {
            this.router.navigate(['/blukpaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour,
              },
            });
          }
          // add more parameters as needed
        },
        (err) => {
          this.disableDownloadButton = true;
        }
      );
  }

  getVoucher() {
    if (this.grantlevel) {
      this.voucherGenerationDto.parentVoucher.componentMasterId =
        this.grantlevel.id;
      this.spinner.show();
      this.paymentService
        .getSign2PendingVoucher({
          levelMasterId: this.roleCode.levelMasterId,
          componentMasterId:
            this.voucherGenerationDto.parentVoucher.componentMasterId,
          finYearId: this.voucherGenerationDto.parentVoucher.finyear.id,
        })
        .subscribe(
          (res: HttpResponse<VendorTransaction[]>) => {
            this.spinner.hide();
            this.vendorList = res.body;
            this.voucherGenerationDto.parentVoucherBillDescList = [];
            this.vendorList.forEach((element) => {
              const subList = new ParentVoucherBillDesc();
              element.isDefault = true;
              subList.childVoucherNumber = element.voucherNo;
              subList.netAmount = element.netAmount;
              subList.vendorTransactionId = element.id;
              subList.transactionAmount = element.transactionAmount;
              if (element.personalFlag === 'No') {
                subList.vendorAccountNumber = element.commercialAccountNo;
                subList.vendorIFSCCode = element.commercialIfscode;
                subList.vendorId = element.commercialVendor;
                subList.vendorName = element.commercialVendorName;
              } else if (element.personalFlag === 'Yes') {
                subList.vendorAccountNumber = element.personalAccountNo;
                subList.vendorIFSCCode = element.personalIfscode;
                subList.vendorId = element.personalVendor;
                subList.vendorName = element.personalVendorName;
              } else if (element.personalFlag === 'E') {
                this.dashboardService
                  .getVoucherSubtableById('E', element.id)
                  .subscribe(
                    (res: HttpResponse<any>) => {
                      subList.vendorAccountNumber = res.body[0].accountNumber;
                      subList.vendorIFSCCode = res.body[0].ifscCode;
                      subList.vendorId = res.body[0].employee.id;
                      subList.vendorName = res.body[0].employee.name;
                      element.employeeId = res.body[0].employee.id;
                      element.employeeName = res.body[0].employee.name;
                      element.employeeAccountNumber = res.body[0].accountNumber;
                      element.employeeIFSCCode = res.body[0].ifscCode;
                    },
                    () => {}
                  );
              }
              else if(element.personalFlag === 'ER') {
                this.dashboardService.
                getVoucherSubtableById('ER',element.id)
                .subscribe(
                  (res: HttpResponse<any>) => {
                    subList.vendorAccountNumber = res.body[0].accountNumber;
                    subList.vendorIFSCCode = res.body[0].ifscCode;
                    subList.vendorId = res.body[0].electedRep.id;
                    subList.vendorName = res.body[0].electedRep.name;
                    element.employeeId = res.body[0].electedRep.id;
                    element.employeeName = res.body[0].electedRep.name;
                    element.employeeAccountNumber = res.body[0].accountNumber;
                    element.employeeIFSCCode = res.body[0].ifscCode;
                  },
                  () => {}
                );
              }
              subList.personalFlag = element.personalFlag;
              subList.componentMasterId =
                this.voucherGenerationDto.parentVoucher.componentMasterId;
              this.voucherGenerationDto.parentVoucherBillDescList.push(subList);
            });
          },
          (err) => {}
        );
    } else {
      this.vendorList = null;
      this.voucherGenerationDto.parentVoucherBillDescList = [];
    }
  }

  getActionUrl() {
    this.sessionStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  changeFinyear (){
    this.voucherGenerationDto.parentVoucher.componentMasterId = null;
    this.grantlevel = null;
    this.vendorList = null;

  }

  VoucherCheckStatus(element: VendorTransaction) {
    if (element.isDefault) {
      this.voucherGenerationDto.parentVoucherBillDescList = this
        .voucherGenerationDto.parentVoucherBillDescList
        ? this.voucherGenerationDto.parentVoucherBillDescList
        : [];
      const subList = new ParentVoucherBillDesc();
      element.isDefault = true;
      subList.childVoucherNumber = element.voucherNo;
      subList.netAmount = element.netAmount;
      subList.vendorTransactionId = element.id;
      subList.transactionAmount = element.transactionAmount;
      if (element.personalFlag === 'No') {
        subList.vendorAccountNumber = element.commercialAccountNo;
        subList.vendorIFSCCode = element.commercialIfscode;
        subList.vendorId = element.commercialVendor;
        subList.vendorName = element.commercialVendorName;
      } else if (element.personalFlag === 'Yes') {
        subList.vendorAccountNumber = element.personalAccountNo;
        subList.vendorIFSCCode = element.personalIfscode;
        subList.vendorId = element.personalVendor;
        subList.vendorName = element.personalVendorName;
      } else if (element.personalFlag === 'E') {
        subList.vendorAccountNumber = element.employeeAccountNumber;
        subList.vendorIFSCCode = element.employeeIFSCCode;
        subList.vendorId = element.employeeId;
        subList.vendorName = element.employeeName;
      }
      else if(element.personalFlag === 'ER') {

      }
      subList.personalFlag = element.personalFlag;
      subList.componentMasterId =
        this.voucherGenerationDto.parentVoucher.componentMasterId;
      this.voucherGenerationDto.parentVoucherBillDescList.push(subList);
    } else {
      const index =
        this.voucherGenerationDto.parentVoucherBillDescList.findIndex(
          (item) => item.vendorTransactionId === element.id
        );
      this.voucherGenerationDto.parentVoucherBillDescList.splice(index, 1);
    }
  }

  viewVoucherDetails(id, personalFlag) {
    const queryParams = { id: id, view: true };
    let urlTree: any;
    switch (personalFlag.toUpperCase()) {
      case 'NO':
        urlTree = this.router.createUrlTree(['/PaymentVoucher'], {
          queryParams,
        });
        break;

      case 'YES':
        urlTree = this.router.createUrlTree(['/payment-personal-voucher'], {
          queryParams,
        });
        break;

      case 'E':
        urlTree = this.router.createUrlTree(['/employeePaymentVoucher'], {
          queryParams,
        });
        break;

      case 'ER':
        urlTree = this.router.createUrlTree(
          ['/electedRepresenativePaymentlComponent'],
          {
            queryParams,
          }
        );
        break;
      case 'BE':
        urlTree = this.router.createUrlTree(['/employeeBulkPayVoucher'], {
          queryParams,
        });
        break;
      case 'BER':
        urlTree = this.router.createUrlTree(['/ERBulkPayVoucher'], {
          queryParams,
        });
        break;
    }
    const url = this.router.serializeUrl(urlTree);

    window.open(url, '_blank');
  }

  revert(id) {
    this.spinner.show();
    this.paymentService.revertTranaction(id).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
        this.getVoucher();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  reject(id,list?:ParentVoucherBillDesc) {
    this.spinner.show();
    this.paymentService.deletedVocher(id).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
        if(list) {
          this.paymentService
          .getParentVocherbyId(
            Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
          )
          .subscribe(
            (res: HttpResponse<VendorTransactionBulkDto>) => {
              this.voucherGenerationDto = res.body;
              this.date = new Date(
                this.voucherGenerationDto.parentVoucher.transactionDate
              );
              this.departmentName =
                this.voucherGenerationDto.parentVoucher.implementingAgency.department.name;
              this.schemeName =
                this.voucherGenerationDto.parentVoucher.implementingAgency.scheme.name;
              this.levelMasterDto =
                this.voucherGenerationDto.parentVoucher.levelMaster.levelType;
              this.componentTypeService
                .findbyId(
                  this.voucherGenerationDto.parentVoucher.componentMasterId
                )
                .subscribe(
                  (res: HttpResponse<ComponentType>) => {
                    this.grantlevel = res.body;
                    this.spinner.hide();
                  },
                  () => {}
                );
            },
            () => {}
          );
        }
        else {
          this.getVoucher();
        }
        
      },
      () => {
        this.spinner.hide();
      }
    );
  }
}
