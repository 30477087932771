import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { DepartmentList } from "../department-list/department-list";
import { AdminUserDtoCreatedby, AdminUserDtoUpdatedBy } from '../limit-set/limit-set';

export class AccountSna {
    schemeName?:string;
    shortName?:string;
    code?:string;
    departName?:string;
    name?:string;
    amount?:number;
    companyName?:string;
    panNo?:string;
    accountNo?:string;
    commercial?:string;
    designation?:string;
    department?:string;
    scheme?:string;
    staffId?:string;
    aadhaar?:string;
    mobileNo?:number;
    email?:string;
    doorNo?:string;
    street?:string;
    landmark?:string;
    city?:string;
    district?:string;
    state?:string;
    pinCode?:string;
    bankName?:string;
    branchName?:string;
    bankAccountNo?:string;
    bankAccountName?:string;
    ifsc?:string;
    micr?:string;
    vendorType?:string;
    registerationNo?:string;
    gstNo?:string;
    tinNo?:string;
    tanNo?:string;
    agencyName?:string;
    schemeCode?:string;
    deptCode?:string;
    levelName?:string;
    levelMasterName?:string;
    bankId?:string;
    branchMobileNo?:string;
    branchEmail?:string;
    branchAddress?:string;  
}

export class  AccountSnaDto{
    id: number
    name: string
    shortName: string
    code: string
    accountNumber: string
    maskedAccountNumber: any
    ifscCode: string
    amount: number
    amountAsString: any
    branch: string
    address: string
    snaDesc: any
    managerMobNo: string
    managerName: string
    branchEmail: string
    departmentDto: DepartmentList
    schemeDto: SchemeList
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedby: AdminUserDtoCreatedby
    adminUserDtoUpdatedBy: AdminUserDtoUpdatedBy
    status: any
  }