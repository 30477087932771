<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Employee Transfer
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="card p-3">
            <div *ngIf="profile && employeeTransfer.isProfileIncluded" class="row mt-3">
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"breadcrumb.profile.username"|translate}}:<br />{{ profile?.profileName}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{"vendor.dateOfBirth"|translate}}:<br />{{ profile?.profileDOB | date:'dd/MM/yyyy'}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"vendor.gender"|translate}}:<br />{{ profile?.genderName}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"vendor.mobile"|translate}}:<br />{{ profile?.mobile}}
                    </p>
                </div>
            </div>
            <div *ngIf="profile && employeeTransfer.isProfileIncluded" class="row mt-3">

                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{"vendor.email"|translate}}:<br />{{ profile?.emailId}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"vendor.aadhaarno"|translate}}:<br />{{ profile?.aadhaarNo}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"vendor.panno"|translate}}:<br />{{ profile?.panNo}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"zonal.district.name"|translate}}:<br />{{ profile?.districtName}}
                    </p>
                </div>
            </div>
            <div *ngIf="profile && employeeTransfer.isProfileIncluded" class="row mt-3">
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"zonal.block.name"|translate}}:<br />{{ profile?.blockName}}
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">{{"zonal.village.name"|translate}}:<br />{{ profile?.villageName}}
                    </p>
                </div>
            </div>

            <div *ngIf="profile || employee" class="row mt-3">
                <div *ngIf="profile" class="col-lg-3">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="employeeTransfer.selectEmployee"></label>
                    <ng-select id="scheme" [appendTo]="'body'" [disabled]="loadingMore" [(ngModel)]="selectedVendor" [items]="vendorList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="search($event)" class="form-control dropdown-width"
                        (scrollToEnd)="onScrollToEnd()"></ng-select>
                </div>
                <div class="col-md-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "employeeTransfer.employeeName" | translate }}:<br />{{ selectedVendor?.name }}
                    </p>
                </div>
                <div class="col-md-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "tableHead.employeeType" | translate }}:<br />{{ selectedVendor?.employeeType?.name }}
                    </p>
                </div>
                <div class="col-md-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "tableHead.subEmployeeType" | translate }}:<br />{{ selectedVendor?.subEmployeeType?.name }}
                    </p>
                </div>

            </div>
            <div *ngIf="profile || employee" class="row mt-3">
                <div class="col-lg-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "vendor.bank.name" | translate }}:<br />{{ selectedVendor?.bankName }}
                    </p>
                </div>
                <div class="col-md-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "agency.branch.name" | translate }}:<br />{{ selectedVendor?.bankBranch }}
                    </p>
                </div>
                <div class="col-md-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "vendor.ifscode" | translate }}:<br />{{ selectedVendor?.ifscCode}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{ "vendor.accountno" | translate }}:<br />{{ selectedVendor?.accountNumber }}
                    </p>
                </div>

            </div>
            <div *ngIf="profile || employee" class="row mt-3">
                <div class="col-sm-6 col-md-3">
                    {{ "employeeTransfer.transferDistrict" | translate }}:<br />{{ profile?.districtName?profile.districtName:employee.districtName }}
                </div>
                <div class="col-sm-6 col-md-3">
                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="employeeTransfer.transferBlock">Block</label>
                    <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div class="col-sm-6 col-md-3">
                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="employeeTransfer.transferVillage">Village</label>
                    <ng-select id="scheme" [appendTo]="'body'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" (change)="onVillageChange()" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="profile && employeeTransfer.isProfileIncluded" class="col-sm-6 col-md-3">
                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="employeeTransfer.SelectTransferUser">Select Transfer
                        User</label>
                    <ng-select id="scheme" [appendTo]="'body'" [items]="userList" bindLabel="userName" appearance="outline" [(ngModel)]="userDto" [closeOnSelect]="true" [clearable]="true" [disabled]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
            </div>
            <div *ngIf="profile || employee" class="row mt-3">
                <div class="col-lg-6">
                    <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass"></label>
                    <div>
                        <small class="mb-2" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                            Maximum file size should be 1MB</small>
                    </div>
                    <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" (onUpload)="onUpload($event)" [customUpload]="true" [disabled]="isFileUploaded" (uploadHandler)="onUpload($event)" auto="true" multiple="false" [showUploadButton]="false" [showCancelButton]="false"
                        chooseLabel="{{'common.upload'|translate}}" maxFileSize="1000000">
                    </p-fileUpload>
                    <div class="row mt-2" *ngIf="isFileUploaded">
                        <div class="col">
                            <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                            <i class="pi pi-times add-margin" (click)="removeDocument()"></i>
                            <i class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" for="dateOfBirth" jhiTranslate="employeeTransfer.transferDate" [ngClass]="fontService.labelClass">Transfer Date</label
                    ><br />
                    <p-calendar
                      [(ngModel)]="employeeTransfer.transaferredDate"
                      [maxDate]="maxDate"
                      styleClass="datepicker"
                    ></p-calendar>
                  </div>
            </div>
            <div  *ngIf="profile || employee" class="row">
               
                <p-toolbar>
                    <div class="p-toolbar-group-start"></div>
                    <div class="p-toolbar-group-end">
                        <button pButton pRipple type="button" label="Submit" (click)="onsave()" class="p-button-success"></button>
                    </div>
                </p-toolbar>
            </div>
        </div>
    </div>
</div>