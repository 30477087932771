import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { User, Role, userDto, LoginDto, DAUserList } from './user';
export type EntityArrayResponseType = HttpResponse<User[]>;
@Injectable()
export class UserService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getAdminUserByFilter');
    protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getAdminUserValidByFilter');
    protected roleUrl = environment.SERVER_API_URL+('api/roleList');
    protected getUserDtoUrl = environment.SERVER_API_URL+('api/getAdminUserDtoObject')
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/SaveAdminUser');
    protected updateSaveUrl = environment.SERVER_API_URL+('api/updateUser');
    protected resourceEditUrl = environment.SERVER_API_URL+('api/getAdminUser');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteUser');
    protected resetUserPasswordUrl = environment.SERVER_API_URL+('api/updateDefaultPassword');
    protected getUserListByDistrictUrl = environment.SERVER_API_URL+('api/getUserListByDistrict');
    protected userActiveStatusUrl = environment.SERVER_API_URL+('api/userActiveStatus');
    protected activatePaymentUsersUrl = environment.SERVER_API_URL+('api/activatePaymentUsers');
    protected deactivatePaymentUsersUrl = environment.SERVER_API_URL+('api/deactivatePaymentUsers');
    constructor(private http: HttpClient) { }

   

    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<User[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    validFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<User[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
    }
    getUserListByDistrict(levelId: number,filter:any,search?:string): Observable<HttpResponse<any>> {
      const options = createRequestOption(filter);
      if(search){
        return this.http.get<DAUserList[]>(`${this.getUserListByDistrictUrl}?levelId=${levelId}&search=${search}`, {params:options, observe: 'response' });
      } else{
        return this.http.get<DAUserList[]>(`${this.getUserListByDistrictUrl}?levelId=${levelId}`, {params:options, observe: 'response' });
      }
    }

    saveUser(user?:userDto): Observable<any> {
      return this.http
      .post<userDto[]>(this.resourceSaveUrl,user, {observe: 'response' })
    }

    updateUser(user?:userDto): Observable<any> {
      return this.http
      .post<userDto[]>(this.updateSaveUrl,user, {observe: 'response' })
    }

    deleteUser(user?:LoginDto): Observable<any> {
      return this.http
      .post<userDto[]>(this.resourceDeleteUrl,user, {observe: 'response' })
    }

    getRole(): Observable<any> {
      // const options = createRequestOption(req);
      return this.http
        .get<Role[]>(this.roleUrl, {  observe: 'response' });
    }

    getUserDto(): Observable<any> {
      // const options = createRequestOption(req);
      return this.http
        .get<userDto>(this.getUserDtoUrl, {  observe: 'response' });
    }

    getUser(id: number): Observable<HttpResponse<any>> {
      return this.http.get<userDto>(`${this.resourceEditUrl}?id=${id}`, { observe: 'response' });
    }

    userActiveStatus(): Observable<HttpResponse<any>> {
      return this.http.get(`${this.userActiveStatusUrl}`, { observe: 'response', responseType: 'text' });
    }

    activatePaymentUsers(): Observable<HttpResponse<any>> {
      return this.http.get(`${this.activatePaymentUsersUrl}`, { observe: 'response', responseType: 'text' });
    }
    deactivatePaymentUsers(): Observable<HttpResponse<any>> {
      return this.http.get(`${this.deactivatePaymentUsersUrl}`, { observe: 'response', responseType: 'text' });
    }
    resetUserPassword(uuid: string): Observable<HttpResponse<any>> {
      return this.http.get<userDto>(`${this.resetUserPasswordUrl}?uuid=${uuid}`, { observe: 'response' });
    }

  
}