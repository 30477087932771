<div class="row">
    <div class="col-md-12">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5>
                Account Balance
                </h5>
            </ng-template>
        </p-toolbar>
    </div>
</div>
<div class="btn_class">
           
    <button  pButton pRipple class="fetch_btn mx-2" (click)="getAccountBalance()">Fetch Account Balance</button>
   
  </div>

  <div class="row">
    <div class="col-md-12 mt-4 mx-4">
    <p-table *ngIf="isTable" [value]="tabledata">
        <ng-template pTemplate="header">
            <tr>
                <th>Village Name</th>
                <th>LGD Code</th>
                <th> Balance</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-index="rowIndex" let-list>
            <tr>
                <td>{{list.villageName}}</td>
                <td>{{list.lgdCode}}</td>
             
                <td>{{list.accountBalance}}</td>
            
            </tr>
        </ng-template>
    </p-table>
</div>

  </div>