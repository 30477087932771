<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.onboard"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-12 px-4">
            <p-table
            [value]="villages"
            [columns]="cols"
            [resizableColumns]="true"
            responsiveLayout="scroll"
            styleClass="p-datatable-gridlines"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10, 25, 50]">
            <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-columns="columns">
            <tr>
                <td><p-button [label]="list.Name" styleClass="p-button-link" (click)="loadData()"></p-button></td>
                <td>{{list.Personal}}</td>
                <td>{{list.Commercial}}</td>
                <td>{{list.Employee}}</td>
                <td>{{list.ER}}</td>
            </tr>
            </ng-template>
            </p-table>
        </div>
    </div>
    
</div>
