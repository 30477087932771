<div class="container-fluid">
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5 [ngClass]="fontService.headingClass">TWAD Account Summary</h5>

            </ng-template>
        </p-toolbar>
    </div>
    <div class="row">
        <div class="col-lg-9">
            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="gender" bindLabel="name" [items]="finYearList" appearance="outline" [(ngModel)]="finYear" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="LoadTangedcoBill(1)" class="form-control">
            </ng-select>
        </div>

        <div class="col-lg-1">
            <button pButton pRipple label="Download" class="p-button-success custom-button" (click)="downloadExport()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
        <div class="col-lg-2">
            <button pButton pRipple label="Check Download Status" class="p-button-success custom-button" (click)="checkStatus()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
    </div>



    <div class="row g-2 mb-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass">
                        TWAD Account Summary</h5>
                </div>
                <p-table [value]="fundDetails" [resizableColumns]="true" [paginator]="true" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount" [lazyLoadOnInit]="true" responsiveLayout="scroll"
                    [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'"
                    [first]="first" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Office Name
                                <p-columnFilter type="text" field="OfficeId" display="menu" [matchModeOptions]="matchModeOptions" [showAddButton]="false" [hideOnClear]="true">
                                </p-columnFilter>
                            </th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Name
                                <p-columnFilter type="text" field="levelMaster" display="menu" [matchModeOptions]="matchModeOptions" [showAddButton]="false" [hideOnClear]="true">
                                </p-columnFilter>
                            </th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">
                                Beneficiary Code
                                <p-columnFilter type="text" field="beneficiaryCode" display="menu" [matchModeOptions]="matchModeOptions" [showAddButton]="false" [hideOnClear]="true">
                                </p-columnFilter>
                            </th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.openingBalance'|translate}}<br>01.04.{{finYear?.name?.slice(0,4)}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Due Amount</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.expenditure'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.balanceAmount'|translate}}<br></th>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td style="text-align: center;"><b>{{list?.twadOffice?.twadOfficeName}}</b></td>
                            <td style="text-align: center;"><b>{{list.levelMaster?.name}}</b></td>
                            <td style="text-align: center;"><b>{{list.beneficiaryCode}}</b></td>
                            <td style="text-align: center;"><b>{{list.openingBalance | currency:'INR':'symbol':'1.2-2':'en-IN'}}</b></td>
                            <td style="text-align: center;"><b>{{list.currentAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</b></td>
                            <td style="text-align: center;"><b>{{list.utilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</b></td>
                            <td style="text-align: center;"><b>{{list.currentAmount - list.utilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</b></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
</div>