import { filterQuery } from './../common.model';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { ASC, DESC, ITEMS_PER_PAGE } from 'src/app/config/pagination.constants';
import { SearchResult } from '../models/search-result';
import { TableColumn } from '../models/table-column';
import * as dayjs from 'dayjs';
import enIN from '@angular/common/locales/en-IN';
import { CurrencyPipe, registerLocaleData } from '@angular/common';

@Component({
  selector: 'custom-filter-table',
  templateUrl: './custom-filter-table.component.html',
  styleUrls: ['./custom-filter-table.component.scss'],
  providers: [CurrencyPipe],
})
export class CustomFilterTableComponent implements OnInit {

  matchModeOptions = [
    { label: "Contains", value: FilterMatchMode.CONTAINS },
    { label: "Equals", value: FilterMatchMode.EQUALS  },
  
  ];
  @ViewChild('dt') tableToExport: ElementRef;
  
  @Output() emitRow = new EventEmitter();
  @Output() searchParam = new EventEmitter();
  @Output() showTable = new EventEmitter();
  @Output() redirectTo=new EventEmitter();
  @Input() disableDeleteIcon = true;
  @Input() showInactiveButton = false;
  @Input() disableEditIcon = true;
  @Input() enableView = false;
  @Input() lazyLoadOnInit = true;
  @Input() paginator = true;
  @Input() showProfileButton = false;
  @Input() showRedirectButton = false;
  @Input() showTableButton = false;
  @Input() showResetPasswordButton = false;
  @Input() showRightButton = false;
  @Input() checkStatusButton = false;
  @Input() showReject = false;
  @Input() showRevert = false;
  @Input() showMultiSelect = false;
  @Input() showTransferButton = false;
  @Input() enableAction = true;
  @Input() showDialogTable = false;
  @Input() enableApproved = false;
  @Input() enableDeactivate = false;
  @Input() enableTnebDelete = false;
  @Input() disableMenu = false;
  @Input() set columnsValues(frozenValue: any[]) {
    this.columnOptions = [];
    this.selectedColumns = [];
    frozenValue?.forEach(frozen => {
      delete frozen.value;
      this.columnOptions.push(frozen);
      if(frozen.isSelectcolumn){
        this.selectedColumns.push(frozen);
      }
    });
  }
  columnOptions: TableColumn[] =[];
  selectedColumns: TableColumn[] =[];
  listItems:Subject<any[]> = new Subject<any[]>();;
  totalCount = 0;
  first = 0;
  sortOrder = 1;
  sortField = 'id';
  @Input() itemsPerPage = ITEMS_PER_PAGE;
  @Input() set filterSearchResult(filterSearchResult: SearchResult<any>) {
    if (!filterSearchResult) {
      return;
    }
    this.listItems.next(filterSearchResult.items);
    this.totalCount = filterSearchResult.total;
    this.totalCount = filterSearchResult.total;
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
  ) { 
    registerLocaleData(enIN);
  }

  ngOnInit(): void {
    const currentPage = this.activatedRoute.snapshot.queryParamMap.get('page');
    const currentSize = this.activatedRoute.snapshot.queryParamMap.get('size');

    const currentSort = this.activatedRoute.snapshot.queryParamMap.get('sort')?.split(',');

    if (currentSort) {
      this.sortField = currentSort[0];
      this.sortOrder = currentSort[1] === ASC ? 1 : -1;
    }

    if (currentPage) {
      this.first =  Number(currentSize) * (Number(currentPage)-1);
    }
  }

  loadFilterValues(event: LazyLoadEvent) {
    let filterDefinitions = [];
    let filterValue = null
    if (Object.keys(event.filters).length !== 0) {
      for (let column of this.selectedColumns) {
        filterValue = event.filters[column.field];
        if (filterValue?.length > 0 && filterValue[0]?.value && column.type != 'dropDown'  && column.type != 'dropDown_with_other_field') {
          let query = new filterQuery()
          query.key = column.field;
          query.operation = filterValue[0].matchMode
          query.value = filterValue[0].value.toUpperCase().trim();
          filterDefinitions.push(query)
        }
        if (filterValue?.length > 0 && filterValue[0]?.value && column.type === 'dropDown' && column.isFilterable) {
          let query = new filterQuery()
          query.key = column.field + '.name';
          query.operation = filterValue[0].matchMode
          query.value = filterValue[0].value.toUpperCase().trim();
          filterDefinitions.push(query)
        }
        if (filterValue?.length > 0 && filterValue[0]?.value && column.type === 'dropDown_with_other_field' && column.isFilterable) {
          let query = new filterQuery()
          query.key = column.field + '.'+column.Dfield;
          query.operation = filterValue[0].matchMode
          query.value = filterValue[0].value.toUpperCase().trim();
          filterDefinitions.push(query)
        }
      }
    }

    let sortDefinitions = {
      predicate: event.sortField,
      ascending: event.sortOrder === 1 ? true : false
    };

    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;

    let filterData = {
      filterDefinitions,
      sortDefinitions,
      page: event.first,
      size: event.rows
    };
    this.searchParam.emit(filterData);
  }


  // onRowSelected(value, type) {
  //   let data = {
  //     value,
  //     type
  //   };
  //   this.emitRow.emit(data);
  // }

  onSelectRow(record: any, type: any) {
    this.emitRow.emit({value: record, type});
  }

  onSelectshowTable(record: any) {
    this.showTable.emit(record);
  }

  getDate(date:Date){
    return dayjs(date)
  }

  navigateProfile(){
    this.router.navigate(['/profile'])
  }

  navigatetoVoucherPage(record: any){
    this.redirectTo.emit(record);    
  }
}