import { Component } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ReportService } from '../report.service';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
import { NotificationService } from 'src/app/alert/notification.service';
import { saveAs } from 'file-saver';
import { SigninService } from 'src/app/auth/signin/signin.service';

@Component({
  selector: 'app-deduction-report',
  templateUrl: './deduction-report.component.html',
  styleUrl: './deduction-report.component.scss'
})
export class DeductionReportComponent {
  ComponentList:any=['Village General Funds'];
  component:any;
  maxDate = new Date();
  fromDate: Date;
  startDate: string;
  toDate: Date;
  endDate: string;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  totalCount = 0;
  first = 0;
  sortOrder = 1;
  sortField = 'id';
  roleCode: any;
  constructor(public fontService:FontService,
    protected reportService:ReportService,
    protected limitTransListService:LimitTransListService,
    protected notificationService:NotificationService,
    private loginService: SigninService,
    protected spinner:NgxSpinnerService) { }

    async ngOnInit(): Promise<void> {
      await this.getRole();
    }
  onSearch() {
    if(!this.component) {
      this.notificationService.alertError('Please Select Component','');
    }
    else {
      this.loadPage(1);
    }
   
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    const date = new Date(this.fromDate);
    this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
    const enddate = new Date(this.toDate);
    this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage
    };
    if(this.fromDate) {
      Query['fromDate']=this.startDate;
      Query['toDate']=this.endDate;
    }
    if(this.roleCode.role.code !== 'SA' && this.roleCode.role.code !== 'SM'){
      Query['levelMasterId']=this.roleCode.levelMasterId;
    }
    this.reportService.getAllDeductions({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.spinner.hide();
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    console.log('event,page',event.page);
    if(this.component && event.page !==1) {
      this.loadPage(event.page);
    }
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  loadFilterValues(event: LazyLoadEvent) {
    let filterDefinitions = [];
    let filterValue = null
    let sortDefinitions = {
      predicate: event.sortField,
      ascending: event.sortOrder === 1 ? true : false
    };

    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;

    let filterData = {
      filterDefinitions,
      sortDefinitions,
      page: event.first,
      size: event.rows
    };
    this.onLazyLoad(filterData);
   
  }
  exportExcel(type){
    const date = new Date(this.fromDate);
    this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
    const enddate = new Date(this.toDate);
    this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
    if(!this.component) {
      this.notificationService.alertError('Please Select Component','');
      return;
    }
    let Query = {
    };
    if(this.roleCode.role.code !== 'SA' && this.roleCode.role.code !== 'SM'){
      Query['levelMasterId']=this.roleCode.levelMasterId;
    }
    if(type === 'ALL'){
     
    }
    else {
      if(this.fromDate) {
        Query['fromDate']=this.startDate;
        Query['toDate']=this.endDate;
      }
    }
    this.reportService.getAllDeductionsExcelDownload({ ...Query }).subscribe(
      (res) => {
        this.saveBlobToExcel(res.body,'Deduction Report');
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
  }
  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

}
