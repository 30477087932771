import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from 'src/app/module/profile/profile';
import { SearchResult } from 'src/app/shared/models/search-result';
import { SwapTransactionDto, VendorTransaction } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { LevelType } from 'src/app/module/level-type/level-type';

@Component({
  selector: 'app-payment-swap-table',
  templateUrl: './payment-swap-table.component.html',
  styleUrls: ['./payment-swap-table.component.scss']
})
export class PaymentSwapTableComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;


  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<SwapTransactionDto> =
    new SearchResult<SwapTransactionDto>();
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
  new SearchResult<any>();
  disableDelete: boolean=true;
  disableEdit: boolean=false;
  
  constructor(
    private dashboardService: DashboardService,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.loginService
    .getuserDto()
    .subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       if(this.roleCode=='DM' || this.roleCode=='BM' || this.roleCode=='SM')
       this.disableEdit=true;
       if(this.roleCode === 'DMAKER' || this.roleCode === 'VMAKER' || this.roleCode === 'BMAKER')
       this.disableDelete=false;      
      },
      (onError) => {}  
    );
    this.cols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'fundGrant',
        header: 'Grant Level',
        jhiTranslate: 'tableHead.grantLevel',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transaction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true,
        type:'amount'
      },
    ];
    // this.loadPage(1);
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate(['/swapPage'], {
          queryParams: {
            VoucherNo: res.body,
          },
        });
      },
      () => {}
    );
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
	query = new filterQuery();
	query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps = this.filterProps ? this.filterProps : [];
	this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService.swapFilter({ ...filter }, this.filterProps).subscribe(
      (res: HttpResponse<SwapTransactionDto[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: SwapTransactionDto[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate(['/swapPage'], {
        queryParams: {
          id: $event.value.id
        },
      });
    } 
	else {
      this.paymentService.deletedSwapVocher($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
  getSubTableDetails($event) {
    this.vouchercols = [
      {
        field: 'levelName',
        header: 'Level Type',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.levelType',
        // type:'dropDown'
      },
      {
        field: 'levelMasterName',
        header: 'Block/Village',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.block/village',
        
      },
      {
        field: 'componentMasterName',
        header: 'Grant Level',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grantLevel',
      },
      {
        field: 'limitAmount',
        header: 'Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.amount',
        type:'amount'
      },
     
    ];
    this.dashboardService.getSwapSubTableListById($event.transactionId).subscribe(
      (res: HttpResponse<any>) => {
        this.enableStatusVoucher = true;
        this.searchVoucherStatusResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.searchVoucherStatusResult.items = res.body ?? [];
        this.searchVoucherStatusResult = { ...this.searchVoucherStatusResult };
      },
      () => {
        this.onError();
      }
    );
  }
}

