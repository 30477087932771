import { LevelMaster } from './../level-master/level-master';
import {
  FinYear,
  FormulaTabelModel,
  limitTransDto,
  limitTransList,
  limitTransListDto,
} from './limit-translist.module';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LimitTransListService } from './limit-translist.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { LevelType } from '../level-type/level-type';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelMasterService } from '../level-master/level-master.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { filterQuery } from 'src/app/shared/common.model';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-limit-translist',
  templateUrl: './limit-translist.component.html',
  styleUrls: ['./limit-translist.component.scss'],
})
export class LimitTranslistComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  formulaitemsPerPage: any = 10;
  formulapage: number;
  formulafilterProps: any;
  searchResult: SearchResult<limitTransList> =
    new SearchResult<limitTransList>();
  searchFormulaResult: SearchResult<FormulaTabelModel> =
    new SearchResult<FormulaTabelModel>();
  cols: any;
  formulaCol: any;
  submitted: boolean;
  limitSetDialog: boolean;
  limitTransDto: limitTransDto;
  levelTypeList: LevelType[];
  limitTranslist: limitTransListDto;
  levelMasterList: any;
  agency: any;
  ComponentType: any;
  finYear: FinYear[];
  enableForm: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  departmentDto: any;
  schemeDto: any;
  showText: boolean;
  enableTable: boolean;
  iaCompList: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  iaError: any;

  constructor(
    private limitTransListService: LimitTransListService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private levelTypeService: LevelTypeService,
    private levelMasterService: LevelMasterService,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'levelType',
        header: 'Level Type',
        jhiTranslate: 'tableHead.levelType',
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'levelMaster',
        header: 'Level Master',
        jhiTranslate: 'tableHead.levelMaster',
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'implementingAgency',
        header: 'IA Name',
        jhiTranslate: 'tableHead.iA.name',
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'componentType',
        header: 'IA Components',
        jhiTranslate: 'tableHead.iACompo.name',
        isSortable: true,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        width: 13,
        type:'date',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'amount',
        header: 'Amount',
        jhiTranslate: 'tableHead.amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type:'amount'
      },
      {
        field: 'fundSource',
        header: 'Fund Source',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
      }
    ];

    this.formulaCol = [
      {
        field: 'levelType',
        header: 'Level Type',
        jhiTranslate: 'tableHead.levelType',
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'levelMaster',
        header: 'Level Master',
        jhiTranslate: 'tableHead.levelMaster',
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'implementingAgency',
        header: 'IA Name',
        jhiTranslate: 'tableHead.iA.name',
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'componentType',
        header: 'IA Components',
        jhiTranslate: 'tableHead.iACompo.name',
        isSortable: true,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'finYear',
        header: 'Fin Year',
        jhiTranslate: 'tableHead.fin.year.name',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'dropDown',
      },
      {
        field: 'amount',
        header: 'Amount',
        jhiTranslate: 'tableHead.amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'formula',
        header: 'Formula',
        jhiTranslate: 'tableHead.formula',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];

    this.loadPage(1);
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.limitTransListService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<limitTransList[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  checkIa(){
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'implementingAgency.id',
        operation: 'equals',
        value: this.limitTransDto.implementingAgencyDto.id,
      },
     
    ];
    if (this.limitTransDto.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: this.limitTransDto.id})
      };
    
    this.limitTransListService.vaildFilter({ ...filterQuery }, filter).subscribe(
      (res: HttpResponse<limitTransList[]>) => {
        if(res.body.length != 0){
          this.iaError = "IA  Already Exists"
        }
        else{
          this.iaError = null
        }
      },
      () => {
        this.onError();
        
      }
    );
  
  }

  protected onSuccess(
    data: limitTransList[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onFormulaLazyLoad($event) {
    this.formulafilterProps = $event.filterDefinitions;
    this.formulaitemsPerPage = $event.size;
    this.loadFormulaTable($event.page);
  }

  async onLevelTypeChange($event?) {
    this.formulafilterProps = null;
    this.showText = false;
    if ($event) {
      this.levelMasterService
        .LevelMasterDtoFilter({ size: 10, page: 0 }, [
          {
            key: 'levelType.id',
            operation: 'contains',
            value: this.limitTranslist.levelTypeDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<any>) => {
            this.levelMasterList = res.body;
          },
          () => { }
        );
    } else {
      let status = await this.getStatus();
      if (status?.amount) {
        this.loadFormulaTable();
      }
      else if (status === null) {
        this.enableTable = false;
        this.enableForm = true;
        const leveltype = this.limitTranslist.levelTypeDto
        this.limitTranslist = new limitTransListDto()
        this.limitTranslist.levelTypeDto = leveltype
      }
      else {
        this.showText = true
      }
      this.levelMasterService
        .LevelMasterDtoFilter({ size: 10, page: 0 }, [
          {
            key: 'levelType.id',
            operation: 'contains',
            value: this.limitTranslist.levelTypeDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<any>) => {
            this.levelMasterList = res.body;
          },
          () => { }
        );
      this.limitTransListService
        .getComponentbyLevelGf(this.limitTranslist.levelTypeDto.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.ComponentType = res.body;
          },
          () => { }
        );
    }
  }

  onLevelTypeChangeTrans($event?) {
    if ($event) {
      this.levelMasterService
        .LevelMasterDtoFilter({ size: 10, page: 0 }, [
          {
            key: 'levelType.id',
            operation: 'contains',
            value: this.limitTransDto.levelTypeDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<any>) => {
            this.levelMasterList = res.body;
          },
          () => { }
        );
    } else {
      this.levelMasterService
        .LevelMasterDtoFilter({ size: 10, page: 0 }, [
          {
            key: 'levelType.id',
            operation: 'contains',
            value: this.limitTransDto.levelTypeDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<any>) => {
            this.levelMasterList = res.body;
          },
          () => { }
        );
      this.limitTransListService
        .getComponentbyLevel(this.limitTransDto.levelTypeDto.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.ComponentType = res.body;
          },
          () => { }
        );
    }
  }

  onLevelMasterChange() {
    this.limitTransListService
      .getImplementingAgencyByLevelMaster(this.limitTransDto.levelMasterDto.id)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.iaCompList = res.body;
        },
        () => { }
      );
  }

  async openNew() {
    this.submitted = false;
    this.limitSetDialog = true;
    this.limitTranslist = new limitTransListDto();
    this.enableForm = false;
    this.showText = false;
    this.limitTransDto = new limitTransDto();

    this.loadValue();
  }

  schedule() {
    if (!this.limitTranslist.status)
      this.limitTransListService
        .schedule(this.limitTranslist.levelTypeDto)
        .subscribe(
          (res: any) => {
            this.limitSetDialog = false;
            this.onLevelTypeChange()
          },
          () => {
            this.onError();
          }
        );
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelTypeList = res.body;
      },
      () => { }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
  }

  hideDialog() {
    this.limitTranslist = new limitTransListDto();
    this.limitTransDto = new limitTransDto();
    this.ngSelectInvalid = false;

    this.limitSetDialog = false;
    this.submitted = false;
  }

  calcAmount() {
    this.onLevelTypeChange()
    this.limitTranslist.departmentId = this.departmentDto.id;
    this.limitTranslist.schemeId = this.schemeDto.id;
    this.limitTransListService
      .calcAmountByFormula(this.limitTranslist)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.limitSetDialog = false
        },
        () => { }
      );
  }

  loadFormulaTable(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let Query = {
      page: pageToLoad - 1,
      size: this.formulaitemsPerPage,
    };
    let query = new filterQuery();
    query.key = 'levelType.name';
    query.operation = 'contains';
    query.value = this.limitTranslist.levelTypeDto.name.toUpperCase();
    this.formulafilterProps = this.formulafilterProps ?? [];
    this.formulafilterProps.push(query);
    this.limitTransListService
      .tableFormulaFilter({ ...Query }, this.formulafilterProps)
      .subscribe(
        (res: HttpResponse<FormulaTabelModel[]>) => {
          this.formulaOnSuccess(
            res.body,
            res.headers,
            pageToLoad,
            !dontNavigate
          );
        },
        () => {
          this.onError();
        }
      );
  }

  protected formulaOnSuccess(
    data: FormulaTabelModel[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    if (data.length === 0) {
      this.showText = true;
    } else {
      this.enableTable = true;
      this.showText = false;
    }
    this.searchFormulaResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchFormulaResult.items = data ?? [];
    this.searchFormulaResult = { ...this.searchFormulaResult };
  }

  getStatus(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService
        .getStatus(this.limitTranslist.levelTypeDto)
        .subscribe(
          (res: any) => {
            resolve(res);
            if (res !== null) {
              this.limitTranslist = res;
            }
          },
          (err: any) => {
            resolve(err);
          }
        );
    });
  }

  saveData() {
    const isFormValid =
      this.limitTransDto.departmentDto &&
      this.limitTransDto.schemeDto &&
      this.limitTransDto.levelTypeDto &&
      this.limitTransDto.levelMasterDto &&
      this.limitTransDto.implementingAgencyDto &&
      this.limitTransDto.componentTypeDto &&
      this.limitTransDto.finYearDto &&
      this.limitTransDto.amount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.limitTransListService
      .save(this.limitTransDto)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.limitSetDialog = false;
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );

  }
}
