import { Component, OnInit } from '@angular/core';
import { Employee, EmployeeDTO, EmployeeTypeDTO, ValidationResponse } from './employee.model';
import { EmployeeService } from './employee.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { environment } from 'src/environments/environment';
import { LevelMasterService } from '../level-master/level-master.service';
import { LevelMasterDto } from '../level-master/level-master';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  employee: EmployeeDTO;
  employeeDialog: boolean;
  employeeTypeList: EmployeeTypeDTO[];
  subEmployeeList: EmployeeTypeDTO[];
  page: number;
  itemsPerPage: number = 10;
  filterProps: any[]=[];
  searchResult: SearchResult<Employee> = new SearchResult<Employee>();
  public ifscError = false;
  public verifyClicked: boolean;
  readonly:boolean;
  //required fields property
  accountInvalidError: any;
  validationRes:ValidationResponse;
  ngSelectInvalid: boolean = false;
  currentEnvironment: string;
  bankBranchDetailsRes: any;
  cols = [
    {
      field: 'uniqueId',
      header: 'Name',
      jhiTranslate: 'tableHead.uniqueId',
      isSortable: true,
      isFilterable: false,
      width: 10,
      isSelectcolumn: true,
    },
    {
      field: 'name',
      header: 'Name',
      isSortable: true,
      isFilterable: false,
      width: 10,
      isSelectcolumn: true,
      jhiTranslate: 'tableHead.name',
    },
    {
      field: 'employeeType',
      header: 'Employee Type',
      isSortable: true,
      isFilterable: false,
      width: 25,
      isSelectcolumn: true,
      type: 'dropDown',
      jhiTranslate: 'tableHead.employeeType',
    },
    {
      field: 'subEmployeeType',
      header: 'Sub Employee Type',
      isSortable: true,
      isFilterable: false,
      width: 25,
      isSelectcolumn: true,
      type: 'dropDown',
      jhiTranslate: 'tableHead.subEmployeeType',
    },
    {
      field: 'bankName',
      header: 'Bank Name',
      jhiTranslate: 'vendor.bank.name',
      isSortable: false,
      isFilterable: true,
      width: 13,
      isSelectcolumn: true,
    },
    {
      field: 'ifscCode',
      header: 'Ifsc Code',
      jhiTranslate: 'vendor.ifscode',
      isSortable: false,
      isFilterable: true,
      width: 13,
      isSelectcolumn: true,
    },
    {
      field: 'accountNumber',
      header: 'Account No',
      jhiTranslate: 'tableHead.account.number',
      isSortable: false,
      isFilterable: true,
      width: 13,
      isSelectcolumn: true,
    },
    {
      field: 'createdOn',
      header: 'Created On',
      jhiTranslate: 'tableHead.createdon',
      isSortable: true,
      isFilterable: true,
      width: 13,
      isSelectcolumn: true,
      type: 'date',
    },
  ];
  bankList: any;
  bankBranch: any;
  bankBranchList: any;
  roleCode:any;
  disableEdit: boolean=false;
  roleName: any;
  levelId: number;
  edit: boolean;
  disableDelete: boolean=true;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelDto: any;
  blockLevelList: LevelMasterDto[];
  districtDto: any;
  districtLevelList: LevelMasterDto[];
  accountNo: string;
  levelType = ['District', 'Block', 'Village'];
  selectedLevelType: string;
  isDelete: boolean;
  vendorName:string
  uniqueId: any;
  constructor(
    private employeeService: EmployeeService,
    protected notificationService: NotificationService,
    public validationService: ValidationService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private commercialVendorservice: CommercialVendorService,
    protected loginService:SigninService,
    private levelMasterService: LevelMasterService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.currentEnvironment=environment.environment;
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.roleName=res.body.role.name;
       this.loadPage(1);
       if (this.roleCode === 'SA') {
        this.disableDelete = false;
        this.onStateLevelChange();
      }
       if(this.roleCode=='DM' || this.roleCode=='BM' || this.roleCode=='SM' || this.roleCode=='SA' || this.roleCode=='SUA')
       this.disableEdit=true;
       if (this.roleName?.startsWith('State') || this.roleCode=='SUA') {
        this.levelId=1;
      } else  if (this.roleName?.startsWith('District')) {
        this.levelId=2;
      } else  if (this.roleName?.startsWith('Block')) {
        this.levelId=3;
      } else  if (this.roleName?.startsWith('Village')) {
        this.levelId=4;
      } 
      },
      (onError) => {
    });
   
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    if (this.vendorName) {
      this.filterProps.push({
        key: 'name',
        operation: 'contains',
        value: this.vendorName.toUpperCase(),
      });
    }
    if (this.accountNo) {
      this.filterProps.push({
        key: 'accountNumber',
        operation: 'contains',
        value: this.accountNo,
      });
    }
    if(this.uniqueId) {
      this.filterProps.push({
        key: 'uniqueId',
        operation: 'contains',
        value: this.uniqueId,
      });
      
    }
    if (this.selectedLevelType === 'District' && this.districtDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.districtDto.id,
      });
    }
    if (this.selectedLevelType === 'Block' && this.BlockLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.BlockLevelDto.id,
      });
    }
    if (this.selectedLevelType === 'Village' && this.villageLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.villageLevelDto.id,
      });
    }
    if (this.roleCode === 'SA') {
      this.employeeService
        .validFilter({ ...filterQuery }, this.filterProps)
        .subscribe(
          (res: HttpResponse<Employee[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    } else {
      this.employeeService
        .filter({ ...filterQuery }, this.filterProps)
        .subscribe(
          (res: HttpResponse<Employee[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    }
    
  }

  protected onSuccess(
    data: Employee[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  protected onError(): void {}

  openNew() {
    this.employee = new EmployeeDTO();
    this.employeeDialog = true;
    this.loadValues();
    this.readonly=false;
  }

  hideDialog() {
    this.employeeDialog = false;
    this.ngSelectInvalid = false;
    this.ifscError = false;
    this.employeeTypeList = null;
    this.subEmployeeList = null;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.bankBranch = null;
    this.edit=false;
    this.isDelete=false;
  }
  accountDetailsChanged() {
    this.verifyClicked = false;
    if(this.currentEnvironment=='uat'|| this.currentEnvironment==='production')
    {
      if(!this.ifscError){
      this.spinner.show();
      this.commercialVendorservice.bankBranchDetails(this.employee.ifscCode)
      .subscribe(
        (res)=>{
          this.spinner.hide();
          this.bankBranchDetailsRes=res.body;
          console.log(this.bankBranchDetailsRes,'bankbranchdetails');
          this.employee.bankBranch=this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Branch_Name;
          this.employee.bankName=this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Bank_Name;
        },
        ()=>{
          this.spinner.hide();
          this.notificationService.alertError('Error Occured','');
        }
      );
      }
    }
  }
  accountChanged(){
    this.verifyClicked=false;
  }

  loadValues() {
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.filterProps = [];
    this.filterProps.push({
      key: 'levelId',
      operation: 'equals',
      value: this.levelId,
    });
    this.employeeService.getEmployeeTypeValidByFilter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<EmployeeTypeDTO[]>) => {
        this.employeeTypeList = res.body;

      },
      () => {
        this.onError();
      }
    );
    // this.employeeService.getEmployeeList().subscribe(
    //   (res: HttpResponse<EmployeeTypeDTO[]>) => {
    //     this.employeeTypeList = res.body;
    //   },
    //   () => {}
    // );
    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
  }

  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.employee.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.employee.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.employee.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.employee.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(this.employee.bankName, this.employee.bankBranch)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.employee.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    if(!this.employee.ifscCode || !this.employee.accountNumber 
      || !this.employee.bankAccName || !this.employee.bankName ||
      !this.employee.bankBranch)
      {
        this.accountInvalidError="Please input valid account number and bank details";
        return;
      }
    this.spinner.show();
    this.verifyClicked = true;
    this.accountInvalidError=null;
    this.commercialVendorservice
      .getaccountValidation(this.employee.accountNumber, this.employee.ifscCode)
      .subscribe(
        (res:any) => {
          // this.validationRes=JSON.parse(res.body);
          this.validationRes=res.body; 
         if (this.validationRes.bankTxnStatus==="true") {
            this.accountInvalidError=null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
          this.readonly=true;
          }
          else{
            this.accountInvalidError='Account Verification Failed due to '+ this.validationRes.bankResponse;
          this.notificationService.alertError(
            'Account Verification Failed due to '+ this.validationRes.bankResponse,
            ''
          );
          this.readonly=false;
          this.verifyClicked=false;
          }
            this.spinner.hide()
          
        },
        (error) => {
          if(error.status === 400){
            this.notificationService.alertError('Account Verification Failed. '+ error.error.message,'');
          this.accountInvalidError='Account Verification Failed. '+ error.error.message;
          }
          else{
            this.notificationService.alertError('Account Verification Failed','');
          this.accountInvalidError="Please input valid account number and branch details";
          }
          this.readonly=false;
          this.verifyClicked=false;
          this.spinner.hide();
        }
      );
  }

  onchangeEmployeeType() {
    this.employee.subEmployeeTypeDto = null;
    this.employeeService
      .getSubEmployeeList(this.employee.employeeTypeDto.id)
      .subscribe(
        (res: HttpResponse<EmployeeTypeDTO[]>) => {
          this.subEmployeeList = res.body;
        },
        () => {}
      );
  }
  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.employee.ifscCode
    );
    !this.employee.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.employee.ifscCode;
  }

  saveData() {
    // if(this.employee.subEmployeeTypeDto===)
    const isFormValid =
      this.validateIfsc() &&
      this.employee.employeeTypeDto &&
      this.employee.subEmployeeTypeDto &&
      this.employee.name &&
      this.employee.grade &&
      this.employee.gpfCpsNo &&
      this.employee.dutyPay &&
      this.employee.dearnessAllowance &&
      this.employee.houseRentAllowance &&
      this.employee.medicalAllowance &&
      this.employee.generalProvidentFund &&
      this.employee.newHealthInsurence &&
      this.employee.familyBenefitFund &&
      this.employee.nhisCorpusFund &&
      this.employee.specialProvidentFund &&
      this.employee.grossAmount &&
      this.employee.totalDeduction &&
      this.employee.netAmount &&
      this.employee.escNet &&
      this.employee.bankName &&
      this.employee.bankBranch &&
      this.employee.accountNumber &&
      this.employee.bankAccName &&
      this.employee.ifscCode &&
      !this.accountInvalidError
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.employeeService.save(this.employee).subscribe(
      (res: HttpResponse<any>) => {
        this.employeeDialog = false;
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.loadPage(1);
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  onSelectRow($event) {
  
      this.employeeService.getemployee($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.readonly=true;
          this.edit=true;
          this.employee = res.body;
          this.employeeDialog = true;
          this.loadValues();
          this.getBankBranchdetais();
          if (this.employee.bankName) {
            this.getBankBranchList();
          }
        },
        () => {
          this.onError();
        }
      );
      if ($event.type === 'select') {
        this.readonly = true;
        this.edit = true;
        this.verifyClicked = true;
      } else {
        this.isDelete = true;
        this.readonly = true;
      }
    
      
    
  }
  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    this.villageLevelDto = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  deleteVendor() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete this ' +
        this.employee.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.employeeService.deletemployee(this.employee.id).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess('Vendor Deleted Sucessfully','');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }
}
