<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Payment Not Done Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-dropdown placeholder="Select Fin Year" [options]="finYearList" [(ngModel)]="finYear" optionLabel="name" showClear="true"></p-dropdown>
        <p-dropdown [options]="months" [(ngModel)]="selectedMonth" optionLabel="name"></p-dropdown>
        <!-- <p-calendar placeholder="Choose Month" [(ngModel)]="date" view="month" monthNavigator="true" yearNavigator="false" dateFormat="M" [readonlyInput]="true"></p-calendar> -->
        <p-dropdown placeholder="Select Component" [options]="compTypeList" [(ngModel)]="selectedcompType" optionLabel="name" showClear="true"></p-dropdown>
        <button pButton pRipple label="Download" (click)="download()" class="p-button-sm"></button>
    </div>
</div>