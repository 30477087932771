import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ConsumerDemand, TangedcoBill, TangedcoService } from "./tangedco-bill-details";
import { Observable } from "rxjs";
import { createRequestOption } from "src/app/auth/request/request-util";
export type ConsumerArrayResponseType = HttpResponse<ConsumerDemand[]>;
export type EntityArrayResponseType = HttpResponse<TangedcoBill[]>;
export type TangedcoArrayResponseType = HttpResponse<TangedcoService[]>;

@Injectable({ providedIn: 'root' })
export class TangedcoBillService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getTangedcoBillByFilter');
    protected resourceFliterVaildUrl = environment.SERVER_API_URL+('api/getConsumerDemandByFilter');
    protected getTangedcoMasterByFilterUrl = environment.SERVER_API_URL+('api/getTangedcoMasterByFilter');
    protected verifyConsumerNoUrl = environment.SERVER_API_URL+('api/verifyConsumerNo');
    protected saveTangedcoMasterUrl = environment.SERVER_API_URL+('api/saveTangedcoMaster');
    protected getTangedcoMasterByDistrictUrl = environment.SERVER_API_URL+('api/getTangedcoMasterByDistrict');
    protected getTangedcoMasterByIdUrl = environment.SERVER_API_URL+('api/getTangedcoMasterById');
    protected approveTangedcoMasterUrl = environment.SERVER_API_URL+('api/approveTangedcoMaster');
    protected billFetchStatusUrl = environment.SERVER_API_URL+('api/billFetchStatus');
    protected getTangedcoBillDetailsUrl = environment.SERVER_API_URL+('api/getTangedcoBillDetails');
    protected rejectTangedcoMasterUrl = environment.SERVER_API_URL+('api/rejectTangedcoMaster');
    protected deactivateMasterUrl = environment.SERVER_API_URL+('api/deactivateMaster');
    protected getHabitationByParentIdUrl = environment.SERVER_API_URL+('api/getHabitationByParentId');
    protected getAllTangedcoPurposeurl = environment.SERVER_API_URL+('api/getAllTangedcoPurpose');
    protected approvedMasterListUrl = environment.SERVER_API_URL+('api/approvedMasterList');
    protected getConsumerDemandValidByFilterUrl = environment.SERVER_API_URL+('api/getConsumerDemandValidByFilter');
    protected getMenuEnabledListUrl = environment.SERVER_API_URL+('api/getMenuEnabledList');
    protected enableTnebTwadMenu = environment.SERVER_API_URL+('api/enableTnebTwadMenu');
    protected getEnabledMenuListUrl = environment.SERVER_API_URL+('api/getEnabledMenuList');
    protected disableMenuVilageUrl =  environment.SERVER_API_URL+('api/disableMenuVilage');
    protected deleteMasterUrl = environment.SERVER_API_URL+('api/deleteMaster');


    constructor(protected http: HttpClient) {}
    filter(req?: any,filter?:any): Observable<ConsumerArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<ConsumerDemand[]>(this.resourceFliterVaildUrl,filter, { params:options, observe: 'response' })
    }
    saveEnableMenu(req?: any,payload?:any): Observable<any> {
      const options = createRequestOption(req);
        return this.http
        .post(this.enableTnebTwadMenu,payload, { params:options, observe: 'response',responseType: 'text'})
    }
    validConsumerfilter(req?: any,filter?:any): Observable<ConsumerArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<ConsumerDemand[]>(this.getConsumerDemandValidByFilterUrl,filter, { params:options, observe: 'response' })
    }
    tangedcoMasterFilter(req?: any,filter?:any): Observable<TangedcoArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<TangedcoService[]>(this.getTangedcoMasterByFilterUrl,filter, { params:options, observe: 'response' })
    }
    vaildFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<TangedcoBill[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    verifyConsumerNo(req?: any): Observable<any> {
      const options = createRequestOption(req);
      return this.http.get<any[]>(this.verifyConsumerNoUrl, {
        params: options,
        observe: 'response',
      });
    }

    getEnabledMenuList(req?: any): Observable<any> {
      const options = createRequestOption(req);
      return this.http.get<any[]>(this.getEnabledMenuListUrl, {
        params: options,
        observe: 'response',
      });
    }

    getMenuEnabledList(req?: any): Observable<any> {
      const options = createRequestOption(req);
      return this.http.get<any[]>(this.getMenuEnabledListUrl, {
        params: options,
        observe: 'response',
      });
    }

    billFetchStatus(): Observable<any> {
      return this.http.get<any[]>(this.billFetchStatusUrl, {
        observe: 'response',
      });
    }

    getTangedcoBillDetails(): Observable<any> {
      return this.http.get<any[]>(this.getTangedcoBillDetailsUrl, {
        observe: 'response',
      });
    }

    etTangedcoMasterByDistrict(req?: any): Observable<any> {
      const options = createRequestOption(req);
      return this.http.get<any[]>(this.getTangedcoMasterByDistrictUrl, {
        params: options,
        observe: 'response',
      });
    }

    approvedMasterList(req?: any): Observable<any> {
      const options = createRequestOption(req);
      return this.http.get<any[]>(this.approvedMasterListUrl, {
        params: options,
        observe: 'response',
      });
    }

    saveTangedcoMaster(payload:TangedcoService): any {
      return this.http.post(this.saveTangedcoMasterUrl,payload, { observe: 'response' });
    }

    getTangedcoMasterById(id: number): Observable<HttpResponse<any>> {
      return this.http.get(`${this.getTangedcoMasterByIdUrl}?id=${id}`, { observe: 'response' });
    }

    getAllTangedcoPurpose(): Observable<HttpResponse<any>> {
      return this.http.get(`${this.getAllTangedcoPurposeurl}`, { observe: 'response' });
    }

    

    getHabitationByParentId(id: number): Observable<HttpResponse<any>> {
      return this.http.get(`${this.getHabitationByParentIdUrl}?parentId=${id}`, { observe: 'response' });
    }

    approveTangedcoMaster(id: number): Observable<HttpResponse<any>> {
      return this.http.get(`${this.approveTangedcoMasterUrl}?masterId=${id}`, { observe: 'response' });
    }

    rejectTangedcoMaster(id: number): Observable<HttpResponse<any>> {
      return this.http.get(`${this.rejectTangedcoMasterUrl}?masterId=${id}`, { observe: 'response' });
    }

    deactivateMaster(id: number): Observable<HttpResponse<any>> {
      return this.http.get(`${this.deactivateMasterUrl}?masterId=${id}`, { observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
      return this.http.get(`${this.deleteMasterUrl}?masterId=${id}`, { observe: 'response' });
    }




    disableMenuVilage(req?: any) {
      const options = createRequestOption(req);
      return this.http.get(this.disableMenuVilageUrl, {
        params: options,
        observe: 'response',
        responseType:'text'
      });
    }

    

    

}
