import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubComponent } from './sub-component';

@Injectable()
export class SubComponentService {
   
    constructor(private http: HttpClient) { }

   
    

   

   

   

}