import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { url } from 'inspector';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit {
  navigationSearch
  roleCode: any;
  constructor(private loginService: SigninService,
    private notificationService: NotificationService,
    private Router: Router,
  ) {
  }
  ngOnInit(): void {
    this.getUserDto();
  }
  dynamicRoute = [
    {
      no: '11',
      url: 'home'
    },
    {
      no: '12',
      url: 'Twad-Dashboard'
    },
    {
      no: '21',
      url: 'user'
    },
    {
      no: '22',
      url: 'zonal'
    },
    {
      no: '23',
      url: 'profile'
    },
    {
      no: '31',
      url: 'agency'
    },
    {
      no: '41',
      url: 'personal-vendors'
    },
    {
      no: '42',
      url: 'commercial-vendors'
    },
    {
      no: '43',
      url: 'employee'
    },
    {
      no: '44',
      url: 'electedRepresentativeComponent'
    },
    {
      no: '45',
      url: 'universal-Vendor'
    },
    {
      no: '46',
      url: 'beneficiary-creation'
    }, {
      no: '47',
      url: 'deduction-accounts'
    },
    {
      no: '51',
      url: 'account-sna'
    },
    {
      no: '61',
      url: 'department-list'
    },
    {
      no: '62',
      url: 'scheme-list'
    },
    {
      no: '71',
      url: 'funds'
    },
    {
      no: '72',
      url: 'opening-balance'
    },
    {
      no: '73',
      url: 'opening-balance'
    },
    {
      no: '81',
      url: 'Tangedco-Bill-Details'
    },
    {
      no: '82',
      url: 'Tangedco-Master-Details'
    },
    {
      no: '83',
      url: 'Tangedco-Master-Details'
    },
    {
      no: '84',
      url: 'Tangedco-Master-Approved'
    },
    {
      no: '85',
      url: 'tneb-report'
    },
    {
      no: '86',
      url: 'tneb-menu-configuration'
    },
    {
      no: '87',
      url: 'menu-enable-list'
    },
    {
      no: '91',
      url: 'payment-personal'
    },
    {
      no: '92',
      url: 'payment'
    },
    {
      no: '93',
      url: 'employeePaymentTable'
    },
    {
      no: '94',
      url: 'electedRepresenativePaymentTableComponent'
    },
    {
      no: '95',
      url: 'employee-bulkPay-list'
    },
    {
      no: '96',
      url: 'ER-bulkPay-list'
    },
    {
      no: '97',
      url: 'Twad-List'
    },
    {
      no: '98',
      url: 'Tangedco-Payment-Table'
    },
   
    {
      no:'910',
      url:'swapTable'
    },
    {
      no: '101',
      url: 'twad-master-list'
    },
    {
      no: '111',
      url: 'RejectFailedPayments'
    },
    {
      no: '121',
      url: 'level-type'
    },
    {
      no: '12.2',
      url: 'level-master'
    },
    {
      no: '123',
      url: 'component-type'
    },
    {
      no: '131',
      url: 'limit-set'
    },
    {
      no: '132',
      url: 'limit-translist'
    },
    {
      no: '133',
      url: 'limit-formulalist'
    },
    {
      no: '141',
      url: 'major-component'
    },
    {
      no: '142',
      url: 'minor-component'
    },
    {
      no: '143',
      url: 'sub-component'
    },
    {
      no: '151',
      url: 'Onboard-Report'
    },
    {
      no: '152',
      url: 'Employee-Onboard-Report'
    },
    {
      no: '153',
      url: 'Payment-Report'
    },
    {
      no: '154',
      url: 'Bulk-Payment-Report'
    },
    {
      no: '155',
      url: 'Voucher-Status-Report'
    },
    {
      no: '156',
      url: 'AccountWise-Fund-Report'
    },
    {
      no: '157',
      url: 'Nil-Total-Balance-Report'
    },
    {
      no: '158',
      url: 'Transaction-Report'
    },
    {
      no: '159',
      url: 'Employee-Count-Report'
    },
    {
      no: '1510',
      url: 'Payment-Not-Done-Report'
    },
    {
      no: '1511',
      url: 'deduction-report'
    },
    {
      no: '1512',
      url: 'twad-report'
    },
    {
      no: '1513',
      url: 'employee-transfer-Report'
    },
    {
      no: '1514',
      url: 'voucher-delay-report'
    },
    {
      no: '161',
      url: 'OwnSourceRevenue'
    },
    {
      no: '171',
      url: 'Vendor-Transaction-Report'
    },
    {
      no: '172',
      url: 'Payment-Status-Report'
    },
    {
      no: '173',
      url: 'Payment-Trans-Report'
    },
    {
      no: '174',
      url: "Api-Request-Response-Report"
    },
    {
      no: '175',
      url: "IA-Component-Report"
    },
    {
      no: '176',
      url: "IA-Component-Trans-Report"
    },
    {
      no: '177',
      url: 'Account-Validation-Log'
    },
    {
      no: '178',
      url: 'DSC-Report'
    },
    {
      no: '181',
      url: 'employee-transfer'
    },
    {
      no: '191',
      url: 'push-notification'
    },
    {
      no:'99',
      url: 'beneficiary-payment-list'
    },
    {
      no:'134',
      url: 'fundExcelUpload'
    },

  ]

  getUserDto() {
    this.loginService.getuserDto().subscribe((res) => {
      this.roleCode = res.body.role.code;
    })
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      var charCode = event.which ? event.which : event.keyCode;
      // Only Numbers 0-9
      let text = event.target.value
      if (text.includes('.')) {
        let decimals = text.split('.');
        if (decimals[1].length >= 2) {
          event.preventDefault();
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  }
  submitSearch() {
    let availableMenu
    if (this.roleCode == 'SA') {
      availableMenu = ['11', '12', '21', '22', '23', '31','41','42','43','44','45','46','47', '51', '61', '62', '71', '72', '73', '84', '85', '91', '92', '93', '94', '95', '96', '97', '98','99', '101', '121', '122', '123', '131', '132', '133', '141', '142', '143', '151', '152', '153', '154', '155', '156', '157', '158', '159', '1510', '1512','1513','1514','191','134']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'VMAKER') {
      availableMenu = ['11', '23', '91', '92', '93', '94', '95', '96','99', '111', '161','81','82','101','41','42','43','44','46','47']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'VC1' || this.roleCode == 'VC2') {
      availableMenu = ['11', '23', '91', '92', '93', '94', '95', '96','99','161']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'DA') {
      availableMenu = ['11', '12', '21', '23', '83', '84', '101', '151', '152', '153', '154', '155', '156', '157', '158', '1510', '1512', '1514','181']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'SM') {
      availableMenu = ['11', '12', '23', '31', '41', '42', '43', '44', '46', '85', '91', '92', '93', '94', '95', '96', '97', '98','99','101', '151', '152', '153', '154', '155', '156', '157', '158', '159', '1510', '1512','1513']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'DMAKER') {
      availableMenu = ['11', '12', '23', '91', '92', '93', '94', '95', '96', '97', '98','99', '101', '111']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'DC1' || this.roleCode == 'DC2') {
      availableMenu = ['11', '12', '23', '91', '92', '93', '94', '95', '96', '97', '98','99','101', '111']
      this.whiteListMenu(availableMenu)
    }
  }
  whiteListMenu(availableMenu) {
    let findMenu
    let result
    findMenu = availableMenu.filter(res => res == this.navigationSearch)
    if (findMenu.length != 0) {
      result = this.dynamicRoute.filter(res => res.no == findMenu)[0]
      this.Router.navigateByUrl(result.url)
    } else {
      this.notificationService.alertError('Page Not Found', '');
    }
  }
}
