import { filterQuery } from 'src/app/shared/common.model';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TangedcoBillService } from '../tangedco-bill-details/tangedco-bill-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-menu-configuration',
  templateUrl: './menu-configuration.component.html',
  styleUrls: ['./menu-configuration.component.scss']
})
export class MenuConfigurationComponent implements OnInit {
  districtLevelList: any;
  districtDto:any;
  blockLevelList: LevelMasterDto[];
  blockDto:any;
  isDistrict:boolean=false;
  isBlock:boolean;
  activeIndex = 0;
  villageList:any
  enableTable: boolean;
  villageIds: any=[];


  constructor( private levelMasterService: LevelMasterService, public fontService: FontService,private tangedcoBillService: TangedcoBillService, private spinner: NgxSpinnerService,private router: Router,protected notificationService: NotificationService,) { }

  ngOnInit(): void {
    this.onStateLevelChange();
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

 
  onDistrictLevelChange($event?) {
   
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onsave(menuType) {
    this.spinner.show();
    let filterQuery = {
      isFullyDistrict:this.isDistrict,
      menuType:menuType

    }
    if(this.isDistrict) {
      filterQuery['districtId'] = this.districtDto.id;
    }
    this.tangedcoBillService.saveEnableMenu({ ...filterQuery },this.villageIds).subscribe(
          (res) => {
            this.spinner.hide();
            this.notificationService.alertSuccess(res.body,'');
            this.router.navigateByUrl('landing-page');

          },
          (err) => {
            this.spinner.hide();
          }
        );
    
  }

  isBlockChange(type) {
    if(this.isBlock) {
      this.villageIds = this.villageIds?this.villageIds:[];
    this.villageList.forEach(element => {
      if(type === 'tneb') {
        element.isTangedcoEnabled = true;
        this.villageIds.push(element.villageId);
      }
      else if(type === 'tnebPayment') {
        element.isTangedcoPaymentEnabled = true;
        this.villageIds.push(element.villageId);
      }
      else {
        element.isTwadEnabled = true;
        this.villageIds.push(element.villageId);
      }
     
    });
    }
    else{
      this.villageList.forEach(element => {
        if(!element.readOnly && type === 'tneb') {
          element.isTangedcoEnabled = false;
        }
        else if(!element.readOnly && type === 'twat') {
          element.isTwadEnabled = false;
        }
        else if(!element.readOnly && type === 'tnebPayment') {
          element.isTangedcoPaymentEnabled = false;
        }
        let index = this.villageIds.findIndex((_) => _.villageId === element.villageId);
      if(index >=0 && !element.readOnly) {
        this.villageIds.splice(index, 1);
      }
      });
     
    }
  }

  isTangedco(list,type) {
    if(list.isTangedcoEnabled && type === 'tneb') {
      this.villageIds.push(list.villageId);
    }
    else if(list.isTwadEnabled && type === 'twat') {
      this.villageIds.push(list.villageId);
    }
    else if(list.isTangedcoPaymentEnabled && type === 'tnebPayment') {
      this.villageIds.push(list.villageId);
    }
    else {
      let index = this.villageIds.findIndex((_) => _ === list.villageId);
      console.log('villageList',index);
      if(index >=0) {
        this.isBlock = false;
        this.villageIds.splice(index, 1);
      }
    }
    console.log('villageList',this.villageIds);
  }

  onBlockChange(type) {
    let filterQuery = {
      blockId:this.blockDto.id
    }
    console.log('type',type);
    
    this.tangedcoBillService
        .getMenuEnabledList({ ...filterQuery })
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageList = res.body;
            this.villageList.forEach(element => {
              if(element.isTangedcoEnabled && type === 'tneb'){
                element.readOnly = true;
              }
              else if(element.isTwadEnabled && type === 'twat') {
                console.log('thisss',this.villageIds);
                element.readOnly = true;
              }
              else if (element.isTangedcoPaymentEnabled && type === 'tnebPayment') {
                element.readOnly = true;
              }
            });
            
            this.enableTable = true;
          },
          () => {}
        );
        
  }

  onTabChange() {
    this.isDistrict = false;
    this.isBlock = false;
    this.districtDto = null;
    this.blockDto = null;
    this.blockLevelList = null;
    this.villageIds = [];
  }

}
