<div class="container-fluid" *ngIf="isStateRole">
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5 [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.name">Dashboard</h5>
            </ng-template>
        </p-toolbar>
    </div>
    <div class="row mb-3 px-4">
        <div class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="gender" bindLabel="name" [items]="finYearList" appearance="outline" [(ngModel)]="finYear" [closeOnSelect]="true" (ngModelChange)="onFinYearChange()" [clearable]="false" class="form-control">
            </ng-select>
        </div>
    </div>
    <div class="mb-3" *ngIf="isStateRole">
        <div class="px-4">
            <div class="row g-3 mb-3">
                <div *ngIf="roleCode !== 'DAD'" class="col-lg-2 col-md-4 col-sm-3">
                    <div class="row mb-3">
                        <p-card [style]="{                         
                            backgroundColor: '#183883', 
                            color: 'white'}" styleClass="sna-card">
                            <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{SValue}} </span> {{'dashBoard.budgetAmount'|translate}}
                            </p>
                            <h5> {{snaBudgetAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
                            <h2>OSR Amount : {{snaOSRAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                            <h2>SFC Amount : {{snaSFCAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                            <h2>CRA Amount : {{craAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                            <h2>CRSA Amount : {{crsaAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                            <h2>CRMA Amount : {{crmaAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                        </p-card>
                    </div>
                    <div class="row mb-3">
                        <p-card [style]="{                         
                            backgroundColor: '#97c95c', 
                            color: 'white'}" styleClass="sna-card">
                            <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{UValue}}
                                </span>{{'dashBoard.utilizedAmount'|translate}}</p>
                            <h5> {{snaUtilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
                        </p-card>
                    </div>
                    <div class="row mb-3">
                        <p-card [style]="{                         
                            backgroundColor: '#FEB135', 
                            color: 'white'}" styleClass="sna-card">
                            <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{BValue}}
                                </span>{{'dashBoard.balanceAmount'|translate}}</p>
                            <h5>{{snaBalanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
                        </p-card>
                    </div>

                </div>
                <div *ngIf="roleCode !== 'DAD'" class="col-lg-10 col-md-8 col-sm-9">
                    <p-tabView (onChange)="handleChange($event)">
                        <p-tabPanel header="District" [disabled]="tabsDisabled">
                            <dx-button class="button-container" text="Back" icon="chevronleft" [visible]="isAdminComp" (onClick)="onBackbtnClick()">
                            </dx-button>
                            <div class="row">
                                <div *ngFor="let dataSource of dataSources;let i = index" class="border col-3">
                                    <dx-pie-chart class="chart-height" id="demoPie1" palette="Material" type="doughnut" innerRadius="0.6" [dataSource]="dataSource" (onPointClick)="onPointClick($event,i)">
                                        <dxo-title [text]="dataSource[0].compName">
                                            <!-- <dxo-subtitle text="District Grant"></dxo-subtitle> -->
                                        </dxo-title>
                                        <dxo-legend [visible]="true" horizontalAlignment="center" verticalAlignment="bottom" itemTextPosition="right"></dxo-legend>
                                        <dxo-export [enabled]="false"></dxo-export>
                                        <dxo-common-series-settings>
                                            <dxo-label [visible]="false"></dxo-label>
                                        </dxo-common-series-settings>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipdemo1"></dxo-tooltip>
                                        <dxi-series argumentField="category" valueField="value">
                                            <dxo-label [visible]="true" [customizeText]="customizeLabelText">
                                                <dxo-connector [visible]="true"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                    </dx-pie-chart>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Block" [disabled]="tabsDisabled">
                            <div class="card mb-2">
                                <p-toolbar>
                                    <div class="p-toolbar-group-start">
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                                                <ng-select id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="roleCode === 'DAD'">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </p-toolbar>
                            </div>
                            <dx-button class="button-container" text="Back" icon="chevronleft" [visible]="isAdminComp" (onClick)="onBackbtnClick()">
                            </dx-button>
                            <div class="row">

                                <div *ngFor="let dataSource of dataSources;let i = index" class="border col-3">
                                    <dx-pie-chart class="chart-height" id="demoPie1" palette="Material" type="doughnut" innerRadius="0.6" [dataSource]="dataSource" (onPointClick)="onPointClick($event,i)">
                                        <dxo-title [text]="dataSource[0].compName">
                                        </dxo-title>
                                        <dxo-legend [visible]="true" horizontalAlignment="center" verticalAlignment="bottom" itemTextPosition="right"></dxo-legend>
                                        <dxo-export [enabled]="false"></dxo-export>
                                        <dxo-common-series-settings>
                                            <dxo-label [visible]="false"></dxo-label>
                                        </dxo-common-series-settings>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipdemo1"></dxo-tooltip>
                                        <dxi-series argumentField="category" valueField="value">
                                            <dxo-label [visible]="true" [customizeText]="customizeLabelText">
                                                <dxo-connector [visible]="true"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                    </dx-pie-chart>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Village" [disabled]="tabsDisabled">
                            <div class="card mb-2">
                                <p-toolbar>
                                    <div class="p-toolbar-group-start">
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                                                <ng-select id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="roleCode === 'DAD'">
                                                </ng-select>
                                            </div>
                                            <div class="col">
                                                <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                                                <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [items]="this.blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" [closeOnSelect]="true" [clearable]="false" class="form-control">
                                                </ng-select>
                                            </div>

                                        </div>
                                    </div>
                                </p-toolbar>
                            </div>
                            <dx-button style="text-align: left;" class="button-container" text="Back" icon="chevronleft" [visible]="isAdminComp" (onClick)="onBackbtnClick()"> </dx-button>
                            <div class="row">

                                <div *ngFor="let dataSource of dataSources;let i = index" class="border col-3">
                                    <dx-pie-chart class="chart-height" id="demoPie1" palette="Material" type="doughnut" innerRadius="0.6" [dataSource]="dataSource" (onPointClick)="onPointClick($event,i)">
                                        <dxo-title [text]="dataSource[0].compName">
                                            <!-- <dxo-subtitle text="District Grant"></dxo-subtitle> -->
                                        </dxo-title>
                                        <dxo-legend [visible]="true" horizontalAlignment="center" verticalAlignment="bottom" itemTextPosition="right"></dxo-legend>
                                        <dxo-export [enabled]="false"></dxo-export>
                                        <dxo-common-series-settings>
                                            <dxo-label [visible]="false"></dxo-label>
                                        </dxo-common-series-settings>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipdemo1"></dxo-tooltip>
                                        <dxi-series argumentField="category" valueField="value">
                                            <dxo-label [visible]="true" [customizeText]="customizeLabelText">
                                                <dxo-connector [visible]="true"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                    </dx-pie-chart>
                                </div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
                <div *ngIf="roleCode === 'DAD'" class="col-lg-12 col-md-8 col-sm-9">
                    <p-tabView (onChange)="handleChange($event)">
                        <p-tabPanel header="District" [disabled]="tabsDisabled">
                            <dx-button class="button-container" text="Back" icon="chevronleft" [visible]="isAdminComp" (onClick)="onBackbtnClick()">
                            </dx-button>
                            <div class="row">
                                <div *ngFor="let dataSource of dataSources;let i = index" class="border col-3">
                                    <dx-pie-chart class="chart-height" id="demoPie1" palette="Material" type="doughnut" innerRadius="0.6" [dataSource]="dataSource" (onPointClick)="onPointClick($event,i)">
                                        <dxo-title [text]="dataSource[0].compName">
                                            <!-- <dxo-subtitle text="District Grant"></dxo-subtitle> -->
                                        </dxo-title>
                                        <dxo-legend [visible]="true" horizontalAlignment="center" verticalAlignment="bottom" itemTextPosition="right"></dxo-legend>
                                        <dxo-export [enabled]="false"></dxo-export>
                                        <dxo-common-series-settings>
                                            <dxo-label [visible]="false"></dxo-label>
                                        </dxo-common-series-settings>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipdemo1"></dxo-tooltip>
                                        <dxi-series argumentField="category" valueField="value">
                                            <dxo-label [visible]="true" [customizeText]="customizeLabelText">
                                                <dxo-connector [visible]="true"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                    </dx-pie-chart>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Block" [disabled]="tabsDisabled">
                            <div class="card mb-2">
                                <p-toolbar>
                                    <div class="p-toolbar-group-start">
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                                                <ng-select id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="roleCode === 'DAD'">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </p-toolbar>
                            </div>
                            <dx-button class="button-container" text="Back" icon="chevronleft" [visible]="isAdminComp" (onClick)="onBackbtnClick()">
                            </dx-button>
                            <div class="row">

                                <div *ngFor="let dataSource of dataSources;let i = index" class="border col-3">
                                    <dx-pie-chart class="chart-height" id="demoPie1" palette="Material" type="doughnut" innerRadius="0.6" [dataSource]="dataSource" (onPointClick)="onPointClick($event,i)">
                                        <dxo-title [text]="dataSource[0].compName">
                                        </dxo-title>
                                        <dxo-legend [visible]="true" horizontalAlignment="center" verticalAlignment="bottom" itemTextPosition="right"></dxo-legend>
                                        <dxo-export [enabled]="false"></dxo-export>
                                        <dxo-common-series-settings>
                                            <dxo-label [visible]="false"></dxo-label>
                                        </dxo-common-series-settings>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipdemo1"></dxo-tooltip>
                                        <dxi-series argumentField="category" valueField="value">
                                            <dxo-label [visible]="true" [customizeText]="customizeLabelText">
                                                <dxo-connector [visible]="true"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                    </dx-pie-chart>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Village" [disabled]="tabsDisabled">
                            <div class="card mb-2">
                                <p-toolbar>
                                    <div class="p-toolbar-group-start">
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                                                <ng-select id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="roleCode === 'DAD'">
                                                </ng-select>
                                            </div>
                                            <div class="col">
                                                <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                                                <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [items]="this.blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" [closeOnSelect]="true" [clearable]="false" class="form-control">
                                                </ng-select>
                                            </div>

                                        </div>
                                    </div>
                                </p-toolbar>
                            </div>
                            <dx-button style="text-align: left;" class="button-container" text="Back" icon="chevronleft" [visible]="isAdminComp" (onClick)="onBackbtnClick()"> </dx-button>
                            <div class="row">

                                <div *ngFor="let dataSource of dataSources;let i = index" class="border col-3">
                                    <dx-pie-chart class="chart-height" id="demoPie1" palette="Material" type="doughnut" innerRadius="0.6" [dataSource]="dataSource" (onPointClick)="onPointClick($event,i)">
                                        <dxo-title [text]="dataSource[0].compName">
                                            <!-- <dxo-subtitle text="District Grant"></dxo-subtitle> -->
                                        </dxo-title>
                                        <dxo-legend [visible]="true" horizontalAlignment="center" verticalAlignment="bottom" itemTextPosition="right"></dxo-legend>
                                        <dxo-export [enabled]="false"></dxo-export>
                                        <dxo-common-series-settings>
                                            <dxo-label [visible]="false"></dxo-label>
                                        </dxo-common-series-settings>
                                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltipdemo1"></dxo-tooltip>
                                        <dxi-series argumentField="category" valueField="value">
                                            <dxo-label [visible]="true" [customizeText]="customizeLabelText">
                                                <dxo-connector [visible]="true"></dxo-connector>
                                            </dxo-label>
                                        </dxi-series>
                                    </dx-pie-chart>
                                </div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>

            </div>
            <div class=" card row g-3 mb-3">
                <div class="col-12">
                    <dx-chart id="chart" title="Fund Report" [dataSource]="fundDS" palette="Material">
                        <dxi-series valueField="allocatedAmount" name="Allocated"></dxi-series>
                        <dxi-series valueField="utilizedAmount" name="Utilized"></dxi-series>
                        <dxi-value-axis [showZero]="true">
                            <dxo-title text="Amount"></dxo-title>
                        </dxi-value-axis>
                        <dxo-argument-axis>
                            <dxo-label overlappingBehavior="rotate" rotationAngle=270></dxo-label>
                        </dxo-argument-axis>
                        <dxo-common-series-settings argumentField="levelMasterName" type="bar">
                            <!-- <dxo-label
                            [visible]="true"
                            [showForZeroValues]="true"
                          >
                          </dxo-label> -->
                        </dxo-common-series-settings>
                        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right">
                        </dxo-legend>
                        <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltipdemo2">
                        </dxo-tooltip>
                    </dx-chart>
                </div>
            </div>
            <div class=" card row g-2 mb-3">
                <div class="col-12">
                    <dx-chart id="chart" title="Vouchers pending Report" palette="Material" [dataSource]="pendingVoucherDS">
                        <dxi-series [valueField]="Sign" [name]="voucherLegendName"></dxi-series>
                        <dxi-value-axis position="left">
                            <dxo-title text="Count"></dxo-title>
                        </dxi-value-axis>
                        <dxo-argument-axis>
                            <dxo-label overlappingBehavior="rotate" rotationAngle=270></dxo-label>
                        </dxo-argument-axis>
                        <dxo-common-series-settings argumentField="levelMasterName" type="Bar">
                            <dxo-label [visible]="true">
                            </dxo-label>
                        </dxo-common-series-settings>
                        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right">
                        </dxo-legend>
                        <dxo-export [enabled]="false"></dxo-export>
                        <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltipdemo2">
                        </dxo-tooltip>
                    </dx-chart>
                </div>
                <div class="col-12 text-center">
                    <p-button label="Maker" [class.remove-border]="removeBorder" styleClass="p-button-secondary p-button-text b-border" (click)="loadSign1()"></p-button>
                    <p-button label="Checker1" styleClass="p-button-secondary p-button-text" (click)="loadSign2()"></p-button>
                    <p-button label="Checker2" styleClass="p-button-secondary p-button-text" (click)="loadSign3()"></p-button>
                </div>
            </div>
            <div class=" card row g-2 mb-3">
                <div class="col-12">
                    <dx-chart id="chart" title="Spent Report on Major Components" palette="Material" [dataSource]="majorSpentData">
                        <dxi-series valueField="totalSpent" nameField="name"></dxi-series>

                        <dxi-value-axis format="currency" currency="INR" position="left">
                            <dxo-title text="Amount"></dxo-title>
                        </dxi-value-axis>
                        <dxo-argument-axis>
                            <dxo-label overlappingBehavior="rotate" rotationAngle=270></dxo-label>
                        </dxo-argument-axis>
                        <dxo-common-series-settings valueField="totalSpent" argumentField="levelMasterName" type="stackedBar">
                            <dxo-label [visible]="false">
                            </dxo-label>
                        </dxo-common-series-settings>
                        <dxo-series-template nameField="name"></dxo-series-template>
                        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right">
                        </dxo-legend>
                        <dxo-export [enabled]="false"></dxo-export>
                        <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltipdemo2">
                        </dxo-tooltip>
                    </dx-chart>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="!isStateRole">
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5 [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.name">Dashboard</h5>
                <!-- <h5 [ngClass]="fontService.headingClass" > - {{levelMasterName}} Panchayat</h5> -->
            </ng-template>
        </p-toolbar>
    </div>
    <div class="row">
        <div class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="gender" bindLabel="name" [items]="finYearList" appearance="outline" [(ngModel)]="finYear" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="onFinYearChange()" class="form-control">
            </ng-select>
        </div>


    </div>
    <!-- <div class=" card mb-2" *ngIf="!isStateRole">
        <div>
            <p-toolbar>
                <div class="p-toolbar-group-start">
                    <div class="row">
                        <div class="col">
                            <label class="form-control-label" for="departCode" [ngClass]="fontService.labelClass"
                                jhiTranslate="dashBoard.levelType">Level Type</label>
                            <ng-select id="scheme" [(ngModel)]="levelTypeDto" (change)="onStateLevelChange()"
                                [items]="levelType" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                [clearable]="false" class="form-control" [disabled]=!isStateRole>
                            </ng-select>
                        </div>
    
                        <div class="col" *ngIf="levelTypeDto?.name === 'District' || 
                    levelTypeDto?.name === 'Block' || levelTypeDto?.name === 'Village'">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                                jhiTranslate="dashBoard.district">District</label>
                            <ng-select id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList"
                                bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()"
                                (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="false"
                                class="form-control" [disabled]=!isStateRole>
                            </ng-select>
                        </div>
                        <div class="col" *ngIf="levelTypeDto?.name === 'Block' || levelTypeDto?.name === 'Village'">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                                jhiTranslate="dashBoard.block">Block</label>
                            <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [items]="this.blockLevelList"
                                bindLabel="name" appearance="outline" (change)="onBlockLevelChange()"
                                (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="false"
                                class="form-control" [disabled]=!isStateRole>
                            </ng-select>
                        </div>
                        <div class="col" *ngIf="levelTypeDto?.name === 'Village'">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                                jhiTranslate="dashBoard.village">Village</label>
                            <ng-select id="scheme" [(ngModel)]="villageLevelDto" [items]="this.villageLevelList"
                                bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)"
                                [closeOnSelect]="true" [clearable]="false" class="form-control"
                                (change)="onVillageLevelChange()" [disabled]=!isStateRole>
                            </ng-select>
                        </div>
                       
                    </div>
    
                    <div class="row">
    
                    </div>
                </div>
            </p-toolbar>
           
           
        </div>
    </div> -->
    <div class="row g-2 mb-5 mt-3" *ngIf="!isStateRole">
        <div class="col-md">
            <p-card [style]="{                         
                backgroundColor: '#183883', 
                color: 'white'}" styleClass="fund-card">
                <!-- <p [ngClass]="fontService.regularBoldClass">{{'dashBoard.budgetAmount'|translate}}</p>
                <b >100%</b>
                <h5 class="justify-content-center">  {{panchayatSAmountINR }}</h5> -->
                <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{SValue}}
                    </span>{{'dashBoard.budgetAmount'|translate}}</p>
                <h5>{{panchayatSanctionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
                <h2 *ngIf="roleCode === 'VMAKER' || roleCode === 'VC1' || roleCode === 'VC2'">OSR Amount - {{panchayatOSRAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                <h2 *ngIf="roleCode === 'VMAKER'  || roleCode === 'VC1' || roleCode === 'VC2'">SFC Amount - {{panchayatSFCAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                <h2 *ngIf="roleCode === 'VMAKER' || roleCode === 'VC1' || roleCode === 'VC2'">CRA Amount - {{panchayatCraAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                <h2 *ngIf="roleCode === 'VMAKER' || roleCode === 'VC1' || roleCode === 'VC2'">CRSA Amount - {{panchayatCrsaAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>
                <h2 *ngIf="roleCode === 'VMAKER' || roleCode === 'VC1' || roleCode === 'VC2'">CRMA Amount - {{panchayatCrmaAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h2>

            </p-card>
        </div>
        <div class="col-md">
            <p-card [style]="{                         
                backgroundColor: '#97c95c', 
                color: 'white'}" styleClass="fund-card">
                <!-- <p  [ngClass]="fontService.regularBoldClass">{{'dashBoard.utilizedAmount'|translate}}</p>
                <b >{{ panchayatUtilizedAmount/panchayatSanctionAmount | percent:'1.2-2' }}</b>
                <h5> {{panchayatUAmountINR}}</h5> -->
                <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{UValue}}
                    </span>{{'dashBoard.utilizedAmount'|translate}}</p>
                <h5>{{panchayatUtilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
            </p-card>
        </div>
        <div class="col-md">
            <p-card [style]="{                         
                backgroundColor: '#FEB135', 
                color: 'white'}" styleClass="fund-card">
                <!-- <p [ngClass]="fontService.regularBoldClass">{{'dashBoard.balanceAmount'|translate}}</p>
                <b >{{ panchayatBalanceAmount/panchayatSanctionAmount | percent:'1.2-2' }}</b>
                <h5>{{panchayatBAmountINR}}</h5> -->
                <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{BValue}}
                    </span>{{'dashBoard.balanceAmount'|translate}}</p>
                <h5>{{panchayatBalanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
            </p-card>
        </div>
        <div class="col-md-3">
            <p-card styleClass="fund-card">
                <!-- <p [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.fundChart">Fund Chart</p> -->
                <dx-pie-chart type="doughnut" id="pie" [dataSource]="fundData">
                    <dxi-series argumentField="name" valueField="amount" name="name" type="doughnut" palette="Material">
                        <!-- <dxo-label [visible]="true" backgroundColor="none" [customizeText]="customizePieLabel" >
                    </dxo-label> -->
                    </dxi-series>
                    <dxo-legend [margin]="0" horizontalAlignment="right" verticalAlignment="top"></dxo-legend>
                    <!-- <dxo-legend [visible]="false"></dxo-legend> -->
                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeChartTooltip">
                    </dxo-tooltip>
                </dx-pie-chart>
            </p-card>
            <!-- <dx-chart  palette="Material" 
id="Fundchart"
[dataSource]="fundData"
[rotated]="true"
>
<dxi-series  type="bar" argumentField="name" valueField="amount" [barPadding]="0.5">
<dxo-label [visible]="true" backgroundColor="#c18e92"></dxo-label>
</dxi-series>
<dxo-argument-axis>
<dxo-label [customizeText]="customizeText"></dxo-label>
</dxo-argument-axis>
<dxi-value-axis  [showZero]="true">
<dxo-tick [visible]="false"></dxo-tick>
<dxo-label [visible]="true"></dxo-label>
</dxi-value-axis>
<dxo-legend [visible]="false"></dxo-legend>
</dx-chart> -->

        </div>
    </div>
    <div class="row g-2 mb-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.grantFundReport">
                        Fund Report on Grant Level</h5>
                </div>
                <p-table [value]="fundDetails" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngIf="(lang==='en')" [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th *ngIf="(lang==='ta')" [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.openingBalance'|translate}}<br>01.04.{{finYear?.name?.slice(0,4)}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.budgetAmount'|translate}}<br>{{'dashBoard.sanction'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.grandTotal1'|translate}}<br>{{'dashBoard.grandTotal2'|translate}}<br>{{'dashBoard.grandTotal3'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.expenditure'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.balanceAmount'|translate}}<br>{{'dashBoard.balance'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{'dashBoard.remainingBalance'|translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">1</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">3 = 1+2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">4</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">5 = 3-4</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td *ngIf="(lang==='en')"><b>{{list.compName}}</b></td>
                            <td *ngIf="(lang==='ta')"><b>{{list.compNameTn}}</b></td>
                            <td style="text-align: center;"><b>{{list.openingBalance}}</b></td>
                            <td style="text-align: center;"><b>{{list.allocatedAmount}}</b></td>
                            <td style="text-align: center;"><b>{{list.grandSanctioned}}</b></td>
                            <td *ngIf="list.compName === 'Village Minimum Grant - B'" style="text-align: center;">
                                <p-button [label]="list.utilizedAmount" (click)="loadSourceAmount(list,list.compName,'Approved')" styleClass="p-button-link"></p-button>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B'" style="text-align: center;">
                                <b>{{list.utilizedAmount}}</b>
                            </td>
                            <td style="text-align: center;"><b>{{list.balanceAmount}}</b></td>
                            <td>
                                <p-progressBar [value]="list.balancePercent" [style]="{'background-color': '#97c95ca8'}"></p-progressBar>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
    <div class="row g-2 mb-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.voucherSummary">
                        Voucher Summary</h5>
                </div>
                <p-table [value]="voucherSummaryDetails" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngIf="(lang==='en')" [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th *ngIf="(lang==='ta')" [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.openingBalance'|translate}}<br>01.04.{{finYear?.name?.slice(0,4)}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.budgetAmount'|translate}}<br>{{'dashBoard.sanction'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.grandTotal1'|translate}}<br>{{'dashBoard.grandTotal2'|translate}}<br>{{'dashBoard.grandTotal3'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated1'|translate}}<br>{{'dashBoard.totalVouchersGenerated2'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated1'|translate}}<br>{{'dashBoard.approvedVouchersGenerated'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated4'|translate}}<br>{{'dashBoard.vouchers'|translate}}<br>{{'dashBoard.rejected'|translate}}<br>{{'dashBoard.Returned'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated1'|translate}}<br>{{'dashBoard.voucherPending'|translate}}<br>{{'dashBoard.forApproval'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.Balancesanction'|translate}}<br>{{'dashBoard.Available'|translate}}<br>{{'dashBoard.generation'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{'dashBoard.remainingBalance'|translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">1</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">3 = 1+2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">4 = 5+6+7</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">5</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">6</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">7</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">8=3-4+6</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td *ngIf="(lang==='en')"><b>{{list.compName}}</b></td>
                            <td *ngIf="(lang==='ta')"><b>{{list.compNameTn}}</b></td>
                            <td style="text-align: center;"><b>{{list.openingBalance}}</b></td>
                            <td style="text-align: center;"><b>{{list.allocatedAmount}}</b></td>
                            <td style="text-align: center;"><b>{{list.grandSanctioned}}</b></td>
                            <td *ngIf="list.compName === 'Village Minimum Grant - B'" style="text-align: center;">
                                <p-button [label]="list.totalVouchersValue" (click)="loadSourceAmount(list,list.compName,'Initialize')" styleClass="p-button-link"></p-button>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B'" style="text-align: center;">
                                <b>{{list.totalVouchersValue}}</b>
                            </td>
                            <td *ngIf="list.compName === 'Village Minimum Grant - B'" style="text-align: center;">
                                <p-button [label]="list.approvedVouchersValue" (click)="loadSourceAmount(list,list.compName,'Approved')" styleClass="p-button-link"></p-button>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B'" style="text-align: center;">
                                <b>{{list.approvedVouchersValue}}</b>
                            </td>
                            <td *ngIf="list.compName === 'Village Minimum Grant - B'" style="text-align: center;">
                                <p-button [label]="list.rejectedVouchersValue" (click)="loadSourceAmount(list,list.compName,'Reject')" styleClass="p-button-link"></p-button>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B'" style="text-align: center;">
                                <b>{{list.rejectedVouchersValue}}</b>
                            </td>
                            <td *ngIf="list.compName === 'Village Minimum Grant - B'" style="text-align: center;">
                                <p-button [label]="list.pendingVouchersValue" (click)="loadSourceAmount(list,list.compName,'Pending')" styleClass="p-button-link"></p-button>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B'" style="text-align: center;">
                                <b>{{list.pendingVouchersValue}}</b>
                            </td>
                            <td style="text-align: center;"><b>{{list.balanceAmount}}</b></td>
                            <td>
                                <p-progressBar [value]="list.balancePercent" [style]="{'background-color': '#97c95ca8'}"></p-progressBar>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
    <div class="card mb-2" *ngIf="!isStateRole">
        <p-toolbar>
            <div class="p-toolbar-group-start">
                <h5 style="float:left;" class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.transactionSummary">Transaction Summary</h5>
                <h5 style="float:left;" [ngClass]="fontService.headingClass">- {{levelMasterName}} Panchayat</h5>
            </div>
            <div class="p-toolbar-group-middle">

            </div>
            <div class="p-toolbar-group-end">
                <ng-select id="scheme" [(ngModel)]="value" [items]="vendorOptions" bindLabel="label" appearance="outline" [closeOnSelect]="true" (change)="onVendorChange()" [clearable]="false" class="form-control dropdown-width">
                    <ng-template ng-option-tmp let-item="item">
                        <span [ngClass]="fontService.labelClass" jhiTranslate="{{item.label}}">{{item.label}}</span>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <div [ngClass]="fontService.labelClass" jhiTranslate="{{item.label}}">
                            {{item.label}}</div>
                    </ng-template>
                </ng-select>
            </div>
        </p-toolbar>
    </div>
    <div class="card mb-2" *ngIf="!isStateRole">
        <p-toolbar>
            <div class="p-toolbar-group-start">
                <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.voucherEsignReport">Voucher E-Sign Report</h5>
            </div>
            <div class="p-toolbar-group-end" *ngIf="roleCode === 'DC2' || roleCode === 'BC2' || roleCode === 'VC2' || roleCode === 'SA' || roleCode === 'SUA'">
                <p-card>
                    <p [ngClass]="fontService.buttonClass" jhiTranslate="common.approved" class="para-style green">
                        Approved:</p><b>{{voucherstatusCount?.approveCount}}</b> |
                    <p [ngClass]="fontService.buttonClass" jhiTranslate="common.rejected" class="para-style red">
                        Rejected:</p><b>{{voucherstatusCount?.rejectCount}}</b> |
                    <p [ngClass]="fontService.buttonClass" jhiTranslate="common.reverted" class="para-style blue">
                        Reverted:</p><b>{{voucherstatusCount?.revertCount}}</b>
                </p-card>
            </div>
        </p-toolbar>
        <div class="pb-2" *ngIf="roleCode === 'DMAKER' || roleCode === 'VMAKER'">
            <div class="row g-2 px-4">
                <div class="col-md-6">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.createdByMaker"> Number Of Vouchers Created </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}: {{voucherCount?.makerCount}}</p>
                        <p [style]="{  backgroundColor: '#97c95c', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(1,true)">
                            {{'dashBoard.value'|translate}}:{{voucherCount?.makerValue| currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>
                <div class="col-md-6">
                    <p-card styleClass="voucher-card orange-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.sentToVP"> Number Of Files Sent To Vice President </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}:{{voucherCount2?.checkerCount}} </p>
                        <p [style]="{  backgroundColor: '#ffc424', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(2,false)">
                            {{'dashBoard.value'|translate}}:{{voucherCount2?.checkerValue| currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>

            </div>
        </div>
        <div class="pb-2" *ngIf="roleCode === 'BMAKER'">
            <div class="row g-2 px-4">
                <div class="col-sm-6 col-md-6">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.createdByMaker"> Number Of Vouchers Created </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}: {{voucherCount?.makerBelowFive}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(1,true,true,false)">{{'dashBoard.value'|translate}}: {{voucherCount?.makerBelowFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</p>
                        </div>
                        <div style="float:right;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount?.makerAboveFive}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(1,true,false,true)">
                                {{'dashBoard.value'|translate}}:{{voucherCount?.makerAboveFiveValue| currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-sm-6 col-md-6">
                    <p-card styleClass="voucher-card orange-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.sentToVP"> Number Of Files Sent To Vice President </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount2?.checker1BelowFive}} </p>
                            <p style="background-color: '#ffc424';" class="para-link" (click)="getVoucherDetails(2,false,true,false)">
                                {{'dashBoard.value'|translate}}:{{voucherCount2?.checker1BelowFiveValue| currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                        <div style="float:right;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount2?.checker1AboveFive}}
                            </p>
                            <p style="background-color: '#ffc424';" class="para-link" (click)="getVoucherDetails(2,false,false,true)">
                                {{'dashBoard.value'|translate}}:{{voucherCount2?.checker1AboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>

                    </p-card>
                </div>
            </div>
        </div>
        <div class="pb-2" *ngIf="roleCode === 'DC1' || roleCode === 'VC1'">
            <div class="row g-2 px-4">
                <div class="col-md-4">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.createdByMaker"> Number Of Vouchers Created By Maker </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}: {{voucherCount?.makerCount}}</p>
                        <p [style]="{  backgroundColor: '#97c95c', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(1,false)">
                            {{'dashBoard.value'|translate}}:{{voucherCount?.makerValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>
                <div class="col-md-4">
                    <p-card styleClass="voucher-card blue-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.pendingWithVP"> Number Of files Pending with Vice President </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}:{{voucherCount2?.checkerCount}} </p>
                        <p [style]="{  backgroundColor: '#1db1f5', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(2,true)">
                            {{'dashBoard.value'|translate}}:{{voucherCount2?.checkerValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>
                <div class="col-md-4">
                    <p-card styleClass="voucher-card orange-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.sentToPresident">Number Of Files Sent To President </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}:{{voucherCount3?.checkerTwoCount}}</p>
                        <p [style]="{  backgroundColor: '#ffc424', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(3,false,false,false,'noSign')">
                            {{'dashBoard.value'|translate}}:{{voucherCount3?.checkerTwoValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>

            </div>
        </div>
        <div class="pb-2" *ngIf="roleCode === 'BC1' || roleCode === 'ZC1'">
            <div class="row g-2 px-4">
                <div class="col-md-4">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.createdByMaker"> Number Of Vouchers Created By Maker </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}: {{voucherCount?.makerBelowFive}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(1,false,true,false)">{{'dashBoard.value'|translate}}: {{voucherCount?.makerBelowFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</p>
                        </div>
                        <div style="float:right;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount?.makerAboveFive}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(1,false,false,true)">
                                {{'dashBoard.value'|translate}}:{{voucherCount?.makerAboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-4">
                    <p-card styleClass="voucher-card blue-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.pendingWithVP"> Number Of files Pending with Vice President </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount2?.checker1BelowFive}} </p>
                            <p style="background-color: '#1db1f5';" class="para-link" (click)="getVoucherDetails(2,true,true,false)">
                                {{'dashBoard.value'|translate}}:{{voucherCount2?.checker1BelowFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                        <div style="float:right;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount2?.checker1AboveFive}}
                            </p>
                            <p style="background-color: '#1db1f5';" class="para-link" (click)="getVoucherDetails(2,true,false,true)">
                                {{'dashBoard.value'|translate}}:{{voucherCount2?.checker1AboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-2">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.approvedByVP"> Approved by VP </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount2?.checker1BelowSigned}} </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(2,true,true,false)">
                                {{'dashBoard.value'|translate}}:{{voucherCount2?.checker1BelowSignedValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-2">
                    <p-card styleClass="voucher-card orange-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.sentToPresident">Number Of Files Sent To President </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount3?.checker2AboveFive}}
                            </p>
                            <p style="background-color: '#ffc424';" class="para-link" (click)="getVoucherDetails(3,false,false,true,'noSign')">
                                {{'dashBoard.value'|translate}}:{{voucherCount3?.checker2AboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>

            </div>
        </div>
        <div class="pb-2" *ngIf="roleCode === 'DC2' || roleCode === 'VC2' || roleCode === 'SA' || roleCode === 'SUA'">
            <div class="row g-2 px-4">
                <div class="col-md-3">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.makerNotSigned"> Vouchers Created By Maker Not Signed </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}: {{voucherCount?.makerCount}}</p>
                        <p [style]="{  backgroundColor: '#97c95c', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(1,false)">
                            {{'dashBoard.value'|translate}}: {{voucherCount?.makerValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>
                <div class="col-md-3">
                    <p-card styleClass="voucher-card blue-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.pendingWithVP"> Number Of Files Pending With Vice President </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}: {{voucherCount2?.checkerCount}}</p>
                        <p [style]="{  backgroundColor: '#1db1f5', 
                        float: 'right'}" class="para-link" (click)="getVoucherDetails(2,false)">
                            {{'dashBoard.value'|translate}}: {{voucherCount2?.checkerValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                        </p>
                    </p-card>
                </div>
                <div class="col-md-3">
                    <p-card styleClass="voucher-card orange-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.pendingWithPresident">
                            Number Of Files Sent To President </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}: {{voucherCount3?.checkerTwoCount}} </p>
                        <p [style]="{  backgroundColor: '#ffc424', 
                        float: 'right'}" class="para-link" *ngIf="roleCode === 'SA' || roleCode === 'SUA'" (click)="getVoucherDetails(3,false,false,false,'noSign')">
                            {{'dashBoard.value'|translate}}:{{voucherCount3?.checkerTwoValue | currency:'INR':'symbol':'1.0-2':'en-IN'}} </p>
                        <p [style]="{  backgroundColor: '#ffc424', 
                        float: 'right'}" class="para-link" *ngIf="roleCode !== 'SA' && roleCode !== 'SUA'" (click)="getVoucherDetails(3,true,false,false,'noSign')">
                            {{'dashBoard.value'|translate}}:{{voucherCount3?.checkerTwoValue | currency:'INR':'symbol':'1.0-2':'en-IN'}} </p>
                    </p-card>
                </div>
                <div class="col-md-3">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.approvedByPresident">
                            Approved By President </h5>
                        <p style="float:left;">{{'dashBoard.count'|translate}}: {{voucherCount3?.checkerTwoSignedCount}}
                        </p>
                        <p [style]="{  backgroundColor: '#97c95c', 
                        float: 'right'}" class="para-link" *ngIf="roleCode === 'SA' || roleCode === 'SUA'" (click)="getVoucherDetails(3,false,false,false,'Sign')">{{'dashBoard.value'|translate}}: {{voucherCount3?.checkerTwoSignedValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</p>
                        <p [style]="{  backgroundColor: '#97c95c', 
                        float: 'right'}" class="para-link" *ngIf="roleCode !== 'SA' && roleCode !== 'SUA'" (click)="getVoucherDetails(3,true,false,false,'Sign')">{{'dashBoard.value'|translate}}: {{voucherCount3?.checkerTwoSignedValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</p>
                    </p-card>
                </div>
            </div>
        </div>
        <div class="pb-2" *ngIf="roleCode === 'BC2'">
            <div class="row g-2 px-4">
                <div class="col-md-3">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.makerNotSigned"> Vouchers Created By Maker Not Signed </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}: {{voucherCount?.makerBelowFive}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(1,false,true,false)">{{'dashBoard.value'|translate}}: {{voucherCount?.makerBelowFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</p>
                        </div>
                        <div style="float:right;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount?.makerAboveFive}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(1,false,false,true)">
                                {{'dashBoard.value'|translate}}:{{voucherCount?.makerAboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-3">
                    <p-card styleClass="voucher-card blue-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.pendingWithVP"> Number Of Files Pending With Vice President </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}: {{voucherCount2?.checker1BelowFive}}
                            </p>
                            <p style="background-color: '#1db1f5';" class="para-link" (click)="getVoucherDetails(2,false,true,false)">{{'dashBoard.value'|translate}}: {{voucherCount2?.checker1BelowFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</p>
                        </div>
                        <div style="float:right;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount2?.checker1AboveFive}}
                            </p>
                            <p style="background-color: '#1db1f5';" class="para-link" (click)="getVoucherDetails(2,false,false,true)">
                                {{'dashBoard.value'|translate}}:{{voucherCount2?.checker1AboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-2">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.approvedByVP"> Approved by VP </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.below5l'|translate}} | {{'dashBoard.count'|translate}}: {{voucherCount2?.checker1BelowSigned}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(2,false,true,false)">{{'dashBoard.value'|translate}}: {{voucherCount2?.checker1BelowSignedValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-2">
                    <p-card styleClass="voucher-card orange-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.pendingWithPresident">
                            Number Of Files Sent To President </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount3?.checker2AboveFive}}
                            </p>
                            <p style="background-color: '#ffc424';" class="para-link" (click)="getVoucherDetails(3,true,false,true,'noSign')">
                                {{'dashBoard.value'|translate}}:{{voucherCount3?.checker2AboveFiveValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
                <div class="col-md-2">
                    <p-card styleClass="voucher-card green-card">
                        <h5 [ngClass]="fontService.regularBoldClass" jhiTranslate="dashBoard.approvedByPresident">
                            Approved By President </h5>
                        <div style="float:left;">
                            <p>{{'dashBoard.above5l'|translate}} | {{'dashBoard.count'|translate}}:{{voucherCount3?.checker2AboveSigned}}
                            </p>
                            <p style="background-color: '#97c95c';" class="para-link" (click)="getVoucherDetails(3,true,false,true,'Sign')">
                                {{'dashBoard.value'|translate}}:{{voucherCount3?.checker2AboveSignedValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}
                            </p>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-2" *ngIf="!isStateRole">
        <p-toolbar>
            <div class="p-toolbar-group-start">
                <h5 [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.paymentStatusReport">
                    Payment Report by Status</h5>
            </div>
        </p-toolbar>
        <div class="row no-gutters align-items-center">
            <div class="col mx-4">
                <p-timeline [value]="events" layout="horizontal" align="top">
                    <ng-template pTemplate="marker" let-event>
                        <span class="custom-marker-2 shadow-2">
                            <i ngClass="pi pi-circle-fill" class="ml-1" style="cursor: pointer;"
                                [style.color]="event.color" [style.font-size]="'0.75rem'"
                                (click)="loadDialog(event.status)"></i>
                        </span>
                    </ng-template>
                    <ng-template pTemplate="opposite" let-event>
                        <span class="ml-1" (click)="loadDialog(event.status)" style="cursor: pointer;" [style.color]="event.color" [ngClass]="fontService.labelClass" jhiTranslate="{{event.status}}">
                            {{event.status}} </span><br />
                        <small class="text-secondary">({{event.count}})</small>
                    </ng-template>
                </p-timeline>
            </div>
        </div>
    </div>
    <div class="card mb-2" *ngIf="!isStateRole">
        <h5 class="mt-2" style="justify-content: center;display: flex;" [ngClass]="fontService.headingClass">
            {{levelMasterName}} Panchayat</h5>
        <p-tabView [(activeIndex)]="activeIndex">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span [ngClass]="fontService.tabClass" jhiTranslate="dashBoard.tabView"></span>
                </ng-template>
                <p-toolbar>
                    <div class="p-toolbar-group-start">
                        <p-dropdown [options]="quickView" [(ngModel)]="selectedQuickView" placeholder="Filter by Period" (ngModelChange)="onQuickViewChange()" optionLabel="name">
                            <ng-template let-item pTemplate="selectedItem">
                                <span [ngClass]="fontService.labelClass" jhiTranslate="{{item.name}}">{{item.name}}</span>
                            </ng-template>
                            <ng-template let-option pTemplate="item">
                                <div [ngClass]="fontService.labelClass" jhiTranslate="{{option.name}}">
                                    {{option.name}}</div>
                            </ng-template>
                        </p-dropdown>
                        <p-dropdown *ngIf="selectedQuickView && selectedQuickView.code === 'mon'" [options]="month" [(ngModel)]="selectedMonth" placeholder="{{'dashBoard.dropdown.selectMonth'|translate}}" (ngModelChange)="onMonthChange()" optionLabel="label">
                            <ng-template let-select pTemplate="selectedItem">
                                <span [ngClass]="fontService.labelClass" jhiTranslate="{{select.label}}">{{select.label}}</span>
                            </ng-template>
                            <ng-template let-option pTemplate="item">
                                <div [ngClass]="fontService.labelClass" jhiTranslate="{{option.label}}">
                                    {{option.label}}</div>
                            </ng-template>
                        </p-dropdown><br>
                        <p-calendar *ngIf="isSelectiveDate" id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate" (ngModelChange)="onDateChange()" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
                        <p-calendar *ngIf="isSelectiveDate" id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate" (ngModelChange)="onDateChange()" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
                        <div *ngIf="dateError && isSelectiveDate" class="text-danger mb-2">
                            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                                choose valid from and to
                                date</small>
                        </div>
                    </div>

                    <div class="p-toolbar-group-end">
                        <p-button (click)="download()" styleClass="p-button-sm p-button-raised p-button-text p-button-info custom-button">
                            <span class="button-label" [ngClass]="fontService.buttonClass">Download</span>
                        </p-button>
                        <p-button (click)="exportToExcel()" styleClass="p-button-sm p-button-raised p-button-text p-button-info custom-button">
                            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.exportexcel"></span>
                        </p-button>
                        <p-button (click)="exportToPdf()" styleClass="p-button-sm p-button-raised p-button-text p-button-info custom-button">
                            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.exportpdf"></span>
                        </p-button>
                    </div>

                </p-toolbar>

                <custom-filter-table id="excel-table" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                    [showTableButton]="true"></custom-filter-table>

            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span [ngClass]="fontService.tabClass" jhiTranslate="dashBoard.spentReport"></span>
                </ng-template>
                <div *ngIf="!noSpentData">
                    <dx-sankey id="sankey" [dataSource]="sankeyData" sourceField="levelTypeName" targetField="name" weightField="value" (onIncidentOccurred)="errorHandler($event)">
                        <dxo-tooltip [enabled]="true" [customizeLinkTooltip]="customizeLinkTooltip">
                        </dxo-tooltip>
                        <dxo-link colorMode="gradient"> </dxo-link>
                        <dxo-node [width]="8" [padding]="30"> </dxo-node>
                    </dx-sankey>
                </div>
                <div *ngIf="noSpentData" class="no-record">
                    <h5 [ngClass]="fontService.regularClass" jhiTranslate="error.notspent">Amount not spent on this component.No record to show</h5>
                </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span [ngClass]="fontService.tabClass" jhiTranslate="dashBoard.chartView"></span>
                </ng-template>
                <div *ngIf="!noMonthSpentData">
                    <dx-chart id="chart" [dataSource]="monthWiseData">
                        <dxi-series argumentField="month" valueField="amount" name="Monthwise Spent" type="bar" palette="Material">
                        </dxi-series>
                        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
                        </dxo-tooltip>
                    </dx-chart>
                </div>
                <div *ngIf="noMonthSpentData">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.spentReport">
                        Monthwise Spent Report</h5>
                    <h5 align="center" [ngClass]="fontService.regularClass" jhiTranslate="error.notspent">
                        Amount not spent on this component.No record to show</h5>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <p-dialog [(visible)]="timelineDialog" [style]="{width: '1000px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            {{ "dashBoard.transactionlist" | translate}}
        </p-header>
        <div *ngIf="enableAll">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchAllResult" (searchParam)="onLazyLoading($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                [showTableButton]="true"></custom-filter-table>
        </div>
        <div *ngIf="enableProcessing">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchProcessingResult" (searchParam)="onLazyLoading($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                [showTableButton]="true"></custom-filter-table>
        </div>
        <div *ngIf="enableSuccess">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchSuccessResult" (searchParam)="onLazyLoading($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                [showTableButton]="true"></custom-filter-table>
        </div>
        <div *ngIf="enableFailure">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchFailureResult" (searchParam)="onLazyLoading($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                [showTableButton]="true"></custom-filter-table>
        </div>
        <div *ngIf="enablePartial">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchPartialResult" (searchParam)="onLazyLoading($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                [showTableButton]="true"></custom-filter-table>
        </div>
    </p-dialog>
    <p-dialog [(visible)]="enableVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideVoucherDialog()">
        <p-header [ngClass]="fontService.headingClass">
            {{ "dashBoard.transactionlist" | translate }}
        </p-header>
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchVoucherResult" (searchParam)="onVoucherDetailsLazyLoad($event)" [disableDeleteIcon]="true" [lazyLoadOnInit]="false" [disableEditIcon]="true" [showRedirectButton]="redriect" (redirectTo)="redirectToVoucher($event)"
            (showTable)="getSubTableDetails($event)" [showTableButton]="subTable"></custom-filter-table>
    </p-dialog>
    <p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
        <p-header [ngClass]="fontService.headingClass">
            {{ "dashBoard.transactionlist" | translate }}
        </p-header>
        <custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="searchVoucherStatusResult" [lazyLoadOnInit]="false" (searchParam)="onSubTableLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="false" [disableEditIcon]="true"></custom-filter-table>
    </p-dialog>
    <p-dialog [(visible)]="enableFundsSource" [style]="{width: '800px',height: '600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableFundsSource=false;this.fundName=null">
        <p-header [ngClass]="fontService.headingClass ">
            {{ fundName }}
        </p-header>
        <p-table [value]="fundSource" [resizableColumns]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="fundName==='Village Minimum Grant - B'" style="text-align: center;" [ngClass]="fontService.labelClass">Component Swap Amount</th>
                    <th *ngIf="fundName==='Village Minimum Grant - B'" style="text-align: center;" [ngClass]="fontService.labelClass">Voucher Amount</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Approved'" style="text-align: center;"><b>{{list.cApprovedVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Approved'" style="text-align: center;"><b>{{list.vApprovedVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Pending'" style="text-align: center;"><b>{{list.cPendingVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Pending'" style="text-align: center;"><b>{{list.vPendingVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Initialize'" style="text-align: center;"><b>{{list.cTotalVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Initialize'" style="text-align: center;"><b>{{list.vTotalVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Reject'" style="text-align: center;"><b>{{list.cRjectedVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                    <td *ngIf="fundName==='Village Minimum Grant - B' && paymentStatus === 'Reject'" style="text-align: center;"><b>{{list.vrjectedVouchersValue | currency:'INR':'symbol':'1.0-2':'en-IN'}}</b></td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>