<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5>Beneficiary Transaction</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">                        
                        <div class="col-md-3">
                            <label class="form-control-label req" for="beneficiaryId">Beneficiary Id</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Enter Beneficiary ID" 
                                name="beneficiaryId" id="beneficiaryId" autocomplete="off">
                                <div class="input-group-append">
                                  <button class="btn btn-info" type="button">Search</button>
                                </div>
                              </div>
                           <!--  <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText placeholder="Enter Beneficiary ID">        
                            </span> -->
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label req" for="vocuherNumber">Voucher Number</label>
                            <input type="text" readonly class="form-control-plaintext" 
                            name="vocuherNumber" autocomplete="off" id="vocuherNumber" value="LoadFromDB">
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label req" for="implementingAgency">Implementing Agency</label>
                            <input type="text" readonly class="form-control-plaintext" 
                            name="implementingAgency" autocomplete="off" id="implementingAgency" value="LoadFromDB">
                        </div>
                        <div class="col-md-3 req">
                            <label class="form-control-label" for="date">Date</label>
                            <input type="text" readonly class="form-control-plaintext" 
                            name="date" autocomplete="off" id="date" value="LoadFromDB">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-control-label" for="beneficiaryname">Beneficiary Name</label>
                            <input type="text" class="form-control" required name="beneficiaryname" autocomplete="off" id="beneficiaryname" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label" for="accountNumber">Account Number</label>
                            <input type="text" class="form-control" name="accountNumber" autocomplete="off" id="accountNumber" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label" for="ifscCode">IFSC Code</label>
                            <input type="text" class="form-control" name="ifscCode" autocomplete="off" id="ifscCode" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-control-label" for="stage">Stage</label>
                            <input type="text" class="form-control" required name="stage" autocomplete="off" id="stage" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label" for="amount">Amount</label>
                            <input type="text" class="form-control" name="amount" autocomplete="off" id="amount" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">                            
                                <label class="form-control-label req" for="vendor">
                                    Select Vendor</label><br>
                                <p-radioButton name="option" [value]="true"
                                    label="Yes"></p-radioButton>
                                <p-radioButton name="option" [value]="false"
                                    label="No"></p-radioButton>                            
                        </div>                                             
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                <label class="form-control-label" for="inlineCheckbox1">Steel</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                <label class="form-control-label" for="inlineCheckbox2">Cement</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                                <label class="form-control-label" for="inlineCheckbox3">Tile</label>
                              </div>
                        </div>
                    </div>
                    <div class="steel mt-3"
                    *ngIf="true">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label req" for="steelVendor">Steel Vendor</label>
                                <ng-select id="steelVendor"
                                    appearance="outline"  placeholder="Select Steel Vendor"
                                    [closeOnSelect]="true" class="form-control" [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelCategory">Steel Category</label>
                                <ng-select id="steelCategory"
                                    appearance="outline"  placeholder="Select Steel Category"
                                    [closeOnSelect]="true" class="form-control" [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelCompName">Steel Company Name</label>
                                <input type="text" class="form-control" name="steelCompName" autocomplete="off" id="steelCompName" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelAccNo">Steel Account No</label>
                                <input type="text" class="form-control" name="steelAccNo" autocomplete="off" id="steelAccNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelIfsc">Steel IFSC Code</label>
                                <input type="text" class="form-control" name="steelIfsc" autocomplete="off" id="steelIfsc" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelPanNo">Steel PAN No</label>
                                <input type="text" class="form-control" name="steelPanNo" autocomplete="off" id="steelPanNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelGstNo">Steel GST No</label>
                                <input type="text" class="form-control" name="steelGstNo" autocomplete="off" id="steelGstNo" />
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelInvoiceNo">Steel Invoice No</label>
                                <input type="text" class="form-control" name="steelInvoiceNo" autocomplete="off" id="steelInvoiceNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelQuantity">Steel Quantity(Kg)</label>
                                <input type="text" class="form-control" name="steelQuantity" autocomplete="off" id="steelQuantity" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelAmount">Steel Amount</label>
                                <input type="text" class="form-control" name="steelAmount" autocomplete="off" id="steelAmount" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="steelDate">Steel Date</label>
                                <input type="text" class="form-control" name="steelDate" autocomplete="off" id="steelDate" />
                            </div>
                        </div>           
                    </div>
                    <div class="cement mt-3"
                    *ngIf="true">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label req" for="cementVendor">Cement Vendor</label>
                                <ng-select id="cementVendor"
                                    appearance="outline"  placeholder="Select Cement Vendor"
                                    [closeOnSelect]="true" class="form-control" [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementCategory">Cement Category</label>
                                <ng-select id="cementCategory"
                                    appearance="outline"  placeholder="Select Cement Category"
                                    [closeOnSelect]="true" class="form-control" [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementCompName">Cement Company Name</label>
                                <input type="text" class="form-control" name="cementCompName" autocomplete="off" id="cementCompName" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementAccNo">Cement Account No</label>
                                <input type="text" class="form-control" name="cementAccNo" autocomplete="off" id="cementAccNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementIfsc">Cement IFSC Code</label>
                                <input type="text" class="form-control" name="cementIfsc" autocomplete="off" id="cementIfsc" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementPanNo">Cement PAN No</label>
                                <input type="text" class="form-control" name="cementPanNo" autocomplete="off" id="cementPanNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementGstNo">Cement GST No</label>
                                <input type="text" class="form-control" name="cementGstNo" autocomplete="off" id="cementGstNo" />
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementInvoiceNo">Cement Invoice No</label>
                                <input type="text" class="form-control" name="cementInvoiceNo" autocomplete="off" id="cementInvoiceNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementQuantity">Cement Quantity(Bags)</label>
                                <input type="text" class="form-control" name="cementQuantity" autocomplete="off" id="cementQuantity" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementAmount">Cement Amount</label>
                                <input type="text" class="form-control" name="cementAmount" autocomplete="off" id="cementAmount" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="cementDate">Cement Date</label>
                                <input type="text" class="form-control" name="cementDate" autocomplete="off" id="cementDate" />
                            </div>
                        </div>           
                    </div>
                    <div class="tile mt-3"
                    *ngIf="true">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label req" for="tileVendor">Tile Vendor</label>
                                <ng-select id="tileVendor"
                                    appearance="outline"  placeholder="Select Teel Vendor"
                                    [closeOnSelect]="true" class="form-control" [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileCategory">Tile Category</label>
                                <ng-select id="tileCategory"
                                    appearance="outline"  placeholder="Select Tile Category"
                                    [closeOnSelect]="true" class="form-control" [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileCompName">Tile Company Name</label>
                                <input type="text" class="form-control" name="tileCompName" autocomplete="off" id="tileCompName" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileAccNo">Tile Account No</label>
                                <input type="text" class="form-control" name="tileAccNo" autocomplete="off" id="tileAccNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileIfsc">Tile IFSC Code</label>
                                <input type="text" class="form-control" name="tileIfsc" autocomplete="off" id="tileIfsc" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tilePanNo">Tile PAN No</label>
                                <input type="text" class="form-control" name="tilePanNo" autocomplete="off" id="tilePanNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileGstNo">Tile GST No</label>
                                <input type="text" class="form-control" name="tileGstNo" autocomplete="off" id="tileGstNo" />
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileInvoiceNo">Tile Invoice No</label>
                                <input type="text" class="form-control" name="tileInvoiceNo" autocomplete="off" id="tileInvoiceNo" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileQuantity">Tile Quantity(No)</label>
                                <input type="text" class="form-control" name="tileQuantity"  autocomplete="off" id="tileQuantity" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileAmount">Tile Amount</label>
                                <input type="text" class="form-control" name="tileAmount" autocomplete="off" id="tileAmount" />
                            </div>
                            <div class="col-md-3">
                                <label class="form-control-label" for="tileDate">Tile Date</label>
                                <input type="text" class="form-control" name="tileDate" autocomplete="off" id="tileDate" />
                            </div>
                        </div>           
                    </div>
                    <div class="row ">                       
                            <div class="col-sm-3 generate mt-3">            
                            <button pButton pRipple label="Generate Voucher" class="p-button-success"></button>
                                <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                            </div>                         
                    </div> 
                   <!--  <div class="row">
                        <p-toolbar styleClass="toolbar">
                            <ng-template pTemplate="left">
                                <button pButton pRipple type="button" label="Generate Voucher" class="p-button-success"></button>
                                <button pButton pRipple type="button" label="Cancel" class="p-button-danger"></button>
                            </ng-template>
                        </p-toolbar>
                    </div>   -->
                </div>
            </div>
        </div>
    </div>
</div>
                   


