import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { FundsTransaction, FundsTransactionDto } from './funds-transaction';

@Injectable()
export class FundsTransactionService {
  protected resourceVaildFliterUrl =
    environment.SERVER_API_URL + 'api//getFundsTransactionValidByFilter';
    protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/getFundsTransactionByFilter';
  protected resourceSaveUrl =
    environment.SERVER_API_URL + 'api/SaveFundsTransaction';
  protected resourceDeleteUrl =
    environment.SERVER_API_URL + 'api/deleteFundsTransaction';
  protected getFundsTransaction =
    environment.SERVER_API_URL + 'api/getFundsTransaction';
  
  constructor(protected http: HttpClient) {}
  filter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<FundsTransaction[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  vaildFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<FundsTransaction[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  save(fundsTransaction?: FundsTransactionDto): Observable<any> {
    return this.http.post<FundsTransactionDto>(
      this.resourceSaveUrl,
      fundsTransaction,
      { observe: 'response' }
    );
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  getFunds(id: number): Observable<HttpResponse<any>> {
    return this.http.get<FundsTransactionDto>(
      `${this.getFundsTransaction}?id=${id}`,
      { observe: 'response' }
    );
  }
}
