import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from './login.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { createRequestOption } from '../request/request-util';
import { Router } from '@angular/router';
type JwtToken = {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  profileActive: string;
  resetPassword: string;
  userRoleCode: string;
  userMobileNumber: string;
  otpId: number;
};
@Injectable({
  providedIn: 'root',
})
export class SigninService {
  private refreshTokenTimeout;
  private intervalId: any;
  public minutes: number = 30;
  public seconds: number = 0;
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    protected router: Router,
  ) {}
  protected resourceUrl = environment.SERVER_API_URL + 'api/authenticate';
  protected getUserDetails = environment.SERVER_API_URL + 'api/getAdminUserDto';
  // protected getCaptchaUrl = environment.SERVER_API_URL + 'api/getCaptcha';
  protected getCaptchaUrl = environment.SERVER_API_URL + 'api/generateCaptcha';
  protected validateCapchaUrl = environment.SERVER_API_URL + 'api/verifyCaptcha';
  protected getOtpUrl = environment.SERVER_API_URL + 'api/otp';
  protected getOtpValidUrl = environment.SERVER_API_URL + 'api/validateOtp'
  protected logOutUrl = environment.SERVER_API_URL + 'api/logOut';
  protected refreshTokenUrl = environment.SERVER_API_URL + 'api/refreshToken';
  protected resendOtpUrl = environment.SERVER_API_URL + 'api/resendOtp';
  protected forceLogOutUrl = environment.SERVER_API_URL + 'api/forceLogOut';
  login(credentials: Login): Observable<JwtToken> {
    const options = createRequestOption(credentials);
    return this.http
      .get<JwtToken>(this.resourceUrl, { params: options })
      .pipe(
        map((response) =>
          this.authenticateSuccess(response, credentials.rememberMe)
        )
      );
  }

  getCapthcaImage(): any {
    return this.http.get(this.getCaptchaUrl, { responseType: 'text' });
  }

  getOtp(): any {
    return this.http.get(this.getOtpUrl, { responseType: 'text' });
  }
  reSendOtp(): any {
    return this.http.get(this.resendOtpUrl, { responseType: 'text' });
  }
  Capthcavalidate(capthca:string,id:number): any {
    return this.http.get(`${this.validateCapchaUrl}?captchaText=${capthca}&id=${id}`, { responseType: 'text' });
  }
  forceLogout(userName:string): any {
    return this.http.get(`${this.forceLogOutUrl}?userName=${userName}`, { responseType: 'text' });
  }
  otpvalidate(id,otp): any {
    return this.http.get(`${this.getOtpValidUrl}?otp=${otp}&id=${id}`, { observe: 'response' });
  }
  getuserDto():any {
    return this.http.get(this.getUserDetails, {observe: 'response' })
  }
  public loadPemFile(filePath: string): Promise<string> {
    return this.http.get(filePath, { responseType: 'text' }).toPromise();
  }
  private authenticateSuccess(
    response: JwtToken,
    rememberMe: boolean,
    refresh = false
  ) {
    const jwt = response.access_token;
    const idToken = response.id_token;
    const refreshToken = response.refresh_token;

    /** TBD temporary fix */
    //  this.localStorageService.clear('authenticationToken');
    //  this.sessionStorageService.clear('authenticationToken');

    //  this.localStorageService.clear('refreshToken');
    //   this.sessionStorageService.clear('refreshToken');
    /** TBD temporary fix */
    this.sessionStorageService.store('authenticationToken', jwt);
    this.localStorageService.clear('authenticationToken');
    this.sessionStorageService.store('refreshToken', refreshToken);
    this.localStorageService.clear('refreshToken');
   this.timeOutFunction(response);
   this.countdown(response);
   return response;
  }

  refreshToken(): Observable<any> {
    let data = {
      refreshToken : this.localStorageService.retrieve('refreshToken') ?? this.sessionStorageService.retrieve('refreshToken')
    };
    return this.http
      .post<JwtToken>(this.refreshTokenUrl, data)
      .pipe(map(response => this.authenticateSuccess(response, true, true)));
  }
  timeOutFunction(token:JwtToken): void {
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe();
    }, token.expires_in*1000);
  }
  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private countdown(response: JwtToken) {
    clearInterval(this.intervalId);
    const totalSeconds = response.expires_in;
    let remainingSeconds = totalSeconds;

    this.intervalId = setInterval(() => {
      remainingSeconds--;

      this.minutes = Math.floor(remainingSeconds / 60);
      this.seconds = remainingSeconds % 60;

      if (this.sessionStorageService.retrieve('authenticationToken')) {
        this.sessionStorageService.store('minutes', this.minutes);
        this.sessionStorageService.store('seconds', this.seconds);
      }

      if (remainingSeconds <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  
  

}
