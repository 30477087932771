import { Injectable } from '@angular/core';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor() {}

  public sendEvent(eventName: string, eventCategory: string, eventLabel: string, eventValue: number) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue
    });
  }

  public setPageView(url: string) {
    gtag('config', 'G-NY4WKDBY33', {
      page_path: url
    });
  }
}
