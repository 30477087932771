import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { TangedcoBillService } from './tangedco-bill-details.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dialog } from 'primeng/dialog';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ConsumerDemand, TangedcoBill } from './tangedco-bill-details';

@Component({
  selector: 'app-tangedco-bill-details',
  templateUrl: './tangedco-bill-details.component.html',
  styleUrls: ['./tangedco-bill-details.component.scss']
})
export class TangedcoBillDetailsComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;


  itemsPerPage: any = 10;
  filterProps: any;
  ngSelectInvalid:boolean
  searchResult: SearchResult<ConsumerDemand> =
    new SearchResult<ConsumerDemand>();
  tangedcosearchResult: SearchResult<TangedcoBill> =
    new SearchResult<TangedcoBill>();
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
  new SearchResult<any>();
  disableEdit: boolean=false;
  disableDelete: boolean=true;
  cosumerDemandId: number;
  tangedcoBillDialog:boolean
  readonly: boolean;
  tangedcoBills: ConsumerDemand;
  habitationList: any;
  constructor(
    protected router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    private tangedcoBillService: TangedcoBillService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.loginService
    .getuserDto()
    .subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       if(this.roleCode=='DM' || this.roleCode=='BM' || this.roleCode=='SM')
       this.disableEdit=true;
       else if(this.roleCode === 'DMAKER' || this.roleCode === 'VMAKER' || this.roleCode === 'BMAKER')
       this.disableDelete=false;
      },
      (onError) => {

      }

    );
    this.cols = [
      {
        field: 'consumerNo',
        header: 'Consumer Number',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'consumerName',
        header: 'Consumer Name',
        //jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true
        //type: 'date',
      },
      {
        field: 'billCycle',
        header: 'Bill Cycle',
        //jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true
        //type:'amount'
      },
      {
        field: 'billAmount',
        header: 'Bill Amount',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
        type: 'amount'
      }
    ];
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }
  openNew() {
    this.tangedcoBills = new ConsumerDemand();
    this.tangedcoBillDialog = true;
    this.readonly = false;
   
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    // let query = new filterQuery();
    // query.key = 'personalFlag';
    // query.operation = 'equals';
    // query.value = 'E';
    // this.filterProps = this.filterProps ? this.filterProps : [];
    // this.filterProps.push(query);
	// query = new filterQuery();
	// query.key = 'activeFlag';
  //   query.operation = 'equals';
  //   query.value = 'YES';
	// this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.tangedcoBillService.filter({ ...filter }, this.filterProps).subscribe(
      (res: HttpResponse<ConsumerDemand[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: ConsumerDemand[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  // onSelectRow($event) {
  //   if ($event.type === 'select') {
  //     this.router.navigate(['/employeePaymentVoucher'], {
  //       queryParams: {
  //         id: $event.value.id,
  //       },
  //     });
  //   }
	// else {
  //     this.paymentService.deletedVocher($event.value.id).subscribe(
  //       (res: HttpResponse<any>) => {
  //         this.loadPage(this.page);
  //       },
  //       () => {
  //         this.onError();
  //       }
  //     );
  //   }
  // }
  getSubTableDetails($event) {
    this.cosumerDemandId = $event;
    this.vouchercols = [
      {
        field: 'consumerNo',
        header: 'Consumer Number',
        isFilterable: false,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.description',
        //type:'dropDown'

      },
      {
        field: 'billMonth',
        header: 'Bill Month',
        isFilterable: false,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.quantity',

      },
      {
        field: 'billYear',
        header: 'Bill Year',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.amount',
        //type:'amount'
      },
      {
        field: 'billAmount',
        header: 'Bill Amount',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.grossTotal',
        type:'amount'
      },
      {
        field: 'dueDate',
        header: 'Due Date',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.grossTotal',
        type:'date'
      },

    ];
    console.log('nwejkbfewjkfwn',$event);
    this.loadTangedcoPage(1);
  }

  loadTangedcoPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    this.enableStatusVoucher = true;
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    query.key = 'consumerDemandId';
    query.operation = 'equals';
    query.value = this.cosumerDemandId;
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
	// query = new filterQuery();
	// query.key = 'activeFlag';
  //   query.operation = 'equals';
  //   query.value = 'YES';
	// this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.tangedcoBillService.vaildFilter({ ...filter }, this.filterProps).subscribe(
      (res: HttpResponse<TangedcoBill[]>) => {
        this.onTangedcoSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );

}

  protected onTangedcoSuccess(
    data: TangedcoBill[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.tangedcosearchResult.items = data ?? [];
    this.tangedcosearchResult = { ...this.tangedcosearchResult };
  }

  hideDialog(){
    this.filterProps = [];
    this.enableStatusVoucher = false;
  }

  

  

}
