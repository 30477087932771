<div id="landing-page">
    <div class="text-center display"><span>Welcome To</span><br>
        <span> TAMILNADU Panchayat simplified accounting system TNPASS!</span>
    </div>

</div>

<div id="landing-page">
    <p-card>
        <div class="row">
            <div class="col-lg-2">
                <h2>Enter Report No:</h2>
            </div>
            <div class="col-lg-6">
                <input type="text" class="w-100" (keypress)="keyPressNumbersDecimal($event)" pInputText [(ngModel)]="navigationSearch" />
            </div>
            <div class="col-lg-3">
                <p-button label="Submit" (click)="submitSearch()" />
            </div>
        </div>
    </p-card>
</div>


<p-confirmDialog #cd [style]="{width: '30vw',height:'15vw'}">

    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
        <span class="button-label" >Ok</span>
    </button>

    </ng-template>
</p-confirmDialog>