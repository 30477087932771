<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.nilbalancereport"></h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Export to Excel" (click)="exportToExcel('nilTotalBalance-table','NilTotalBalanceReport.xlsx')"  class="p-button-success p-button-sm export-button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row pb-2">
        <div class="col">
            <p-radioButton name="levelType" value="District" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType1"></p-radioButton>
            <label for="levelType1" class="label-margin">District</label>
            <p-radioButton name="levelType" value="Block" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType2"></p-radioButton>
            <label for="levelType2" class="label-margin">Block</label>
            <p-radioButton name="levelType" value="Village" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType3"></p-radioButton>
            <label for="levelType3" class="label-margin">Village</label>
        </div>
</div>
<div class="col-12 px-4" *ngIf="nilbalancereport">
    <p-table id="nilTotalBalance-table" [value]="nilbalancereport"  [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="header">
        <tr>
            <th  style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
            <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="componentName" filterMatchMode="icon">Component Name
                <p-columnFilter type="text" field="componentName" display="menu"></p-columnFilter>
              </th>
              <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="totalCount">Count of Panchayat Nil Balance</th>   
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-list let-index="rowIndex">
        <tr>
            <td style="text-align: center;">{{index+1}}</td>
            
            <td style="text-align: center;"><p-button [label]="list.componentName" (click)="loadFundDialog(list.componentId, list.componentName)"  styleClass="p-button-link">         
        </p-button></td>
        <td style="text-align: center;"> {{ list.totalCount }}</td>

        </tr>
    </ng-template>

    </p-table>
    </div>
    <p-dialog #dialog [(visible)]="fundListDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '1300px' }">
        <p-header [ngClass]="fontService.headingClass">
        Nil Balance Report of {{ componentMasterName}}    
        </p-header>
        <ng-template pTemplate="content">
            <div class="input-row">
                <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm export-button"></button>
            </div>
            <div class="row">
                <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
                    <p-tabPanel header="District" *ngIf="!isDMRole">
                        <div class="col-12 px-4" *ngIf="nilbalancereport">
                            <p-table id="district-table" [value]="compFundDto" [resizableColumns]="true" responsiveLayout="scroll" 
                            styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass">S.No</th>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                        <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                    </th>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="totalCount">Count of Panchayat Nil Balance</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">
                                <tr>
                                    <td style="text-align: center;">{{index+1}}</td>
                                    <td style="text-align: center;"><p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button></td>
                                    <td style="text-align: center;"> {{ list.count }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </div> 
                    </p-tabPanel>
                    <p-tabPanel header="Block" *ngIf="levelType!=='District'">
                        <div *ngIf="!compFundBlockDto">Choose distict to get block report</div>
                        <div *ngIf="compFundBlockDto" class="col-12 px-4">
                            <p-table 
                            id="block-table"
                            [value]="compFundBlockDto"
                            [resizableColumns]="true"
                            responsiveLayout="scroll"
                            styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                                        <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                      </th>
                                      <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="count">Count of Panchayat Nil Balance</th>
                                </tr>
                                </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;"><p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button></td>
                                <td style="text-align: center;"> {{ list.count }}</td>
                            </tr>
                            </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Village" *ngIf="levelType!=='District' && levelType!=='Block'">
                        <div *ngIf="!compFundVillageDto">Choose block to get village report</div>
                        <div *ngIf="compFundVillageDto" class="col-12 px-4">
                            <p-table
                            id="village-table"
                            [value]="compFundVillageDto"
                            [resizableColumns]="true"
                            responsiveLayout="scroll"
                            styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                        <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                      </th> 
                                      <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="count">Count of Panchayat Nil Balance</th> 
                                </tr>
                                </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">{{list.levelMasterName}}</td>
                                <td style="text-align: center;"> {{ list.count }}</td>  
                            </tr>
                            </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </ng-template>
    </p-dialog>