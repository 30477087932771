import { AdminUserDtoCreatedby } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { FinYearDto } from '../limit-set/limit-set';
import { FinYear } from '../limit-translist/limit-translist.module';
import { SchemeList } from '../scheme-list/scheme-list';
import { DepartmentList } from './../department-list/department-list';


export class FundsTransaction {
    id: number
    department: DepartmentList
    scheme: SchemeList
    finYear: FinYear
    sanctionAmount: number
    transactionDate: string
    sanctionGoNo: string
    transactionRefNo: string
    filePath: any
    fundSource: string
    utilizedAmount: any
    fileNameOne: any
    fileNameTwo: any
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: AdminUserDtoCreatedby
    adminUserUpdatedBy: AdminUserDtoCreatedby
  }
  export class  FundsTransactionDto{
    id: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
    sanctionAmount: number
    finYearDto: FinYearDto
    transactionDate: Date
    transactionDoneDate: any
    sanctionGoNo: string
    dateOfDepInAcc: string
    transactionRefNo: string
    filePath: any
    fundSource: string
    utilizedAmount: any
    sanctionAmountAsString: string
    utilizedAmountAsString: string
    fileNameOne: any
    fileNameTwo: any
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedby: AdminUserDtoCreatedby
    adminUserDtoUpdatedBy: AdminUserDtoCreatedby
    fileName: any
  }