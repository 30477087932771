import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ReportService } from '../report.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';

interface QueryType {
  page: number;
  size: any;
  [key: string]: any; 
}
@Component({
  selector: 'app-dsc-report',
  templateUrl: './dsc-report.component.html',
  styleUrls: ['./dsc-report.component.scss']
})
export class DscReportComponent implements OnInit {

  vtId:string;
  cols:any;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  
  constructor(public fontService:FontService,
    protected reportService:ReportService,
    protected spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.cols=[
      {
        field:'id',
        header:'Id',
        isSelectcolumn:false,
      },
      {
        field:'signOneErrorMessage',
        header:'signOneErrorMessage',
        isSelectcolumn:true,
      },
      {
        field:'signOneReferenceNo',
        header:'signOneReferenceNo',
        isSelectcolumn:true,
      },
      {
        field:'signOneReturnStatus',
        header:'signOneReturnStatus',
        isSelectcolumn:true,
      },
      {
        field:'signOneTransactionNo',
        header:'signOneTransactionNo',
        isSelectcolumn:true,
      },
      {
        field:'signThreeErrorMessage',
        header:'signThreeErrorMessage',
        isSelectcolumn:true,
      },
      {
        field:'signThreeReferenceNo',
        header:'signThreeReferenceNo',
        isSelectcolumn:true,
      },
      {
        field:'signThreeReturnStatus',
        header:'signThreeReturnStatus',
        isSelectcolumn:true,
      },
      {
        field:'signThreeTransactionNo',
        header:'signThreeTransactionNo',
        isSelectcolumn:true,
      },
      {
        field:'signTwoErrorMessage',
        header:'signTwoErrorMessage',
        isSelectcolumn:true,
      },
      {
        field:'signTwoRefernceNo',
        header:'signTwoRefernceNo',
        isSelectcolumn:true,
      },
      {
        field:'signTwoReturnStatus',
        header:'signTwoReturnStatus',
        isSelectcolumn:true,
      },
      {
        field:'signTwoTransactionNo',
        header:'signTwoTransactionNo',
        isSelectcolumn:true,
      },
      {
        field:'signUserValidation',
        header:'signUserValidation',
        isSelectcolumn:true,
      },
      {
        field:'vendorTransaction',
        header:'Vendor Transaction Id',
        isSelectcolumn:true,
        type: "dropDown_with_other_field",
        Dfield: "id"
      },
      {
        field:'createdOn',
        header:'Created Date',
        isSelectcolumn:true,
        type:'date'
      }
    ];
  }

  onSearch(){
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query:QueryType = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.vtId && { vtId: this.vtId }),
    };
    
    this.reportService.dscReport({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.spinner.hide();
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
}

