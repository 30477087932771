import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { DepartmentList } from "../department-list/department-list"

export class beneficiaryList {
    id: number
    name: string
    staffId: string
    designation: string
    aadhaarNo: string
    micr: any
    panNo: string
    department: DepartmentList
    scheme: SchemeList
    mobile: string
    email: string
    state: string
    district: string
    city: string
    doorNo: string
    street: string
    area: any
    companyState: string
    companyDistrict: string
    companyCity: string
    companyDoorNo: string
    companyStreet: string
    companyArea: any
    companyPinCode: string
    bankAccName: any
    bankId: any
    bankName: string
    bankBranch: string
    pinCode: string
    ifscCode: string
    accountNumber: string
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: number
    adminUserUpdatedBy: number
    activeFlag: string
  }
export class beneficiaryDto {
    id: number
    name: string
    companyName: any
    designation: string
    staffId: string
    aadhaarNo: string
    micr: any
    panNo: string
    gstNo: any
    mobile: string
    email: string
    serviceTaxNo: any
    tanNo: any
    tinNo: any
    state: string
    district: string
    city: string
    doorNo: string
    street: string
    area: any
    pinCode: string
    companyState: string
    companyDistrict: string
    companyCity: string
    companyDoorNo: string
    companyStreet: string
    companyArea: any
    companyPinCode: string
    bankAccName: any
    bankId: any
    bankName: string
    bankBranch: string
    accountNumber: string
    activeFlag: string
    ifscCode: string
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedBy: number
    adminUserDtoUpdatedBy: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
  }
  export class deductionDto {
    id: number
    name: string
 remark:string;
    bankAccountName: any
    bankId: any
    bankName: string
    bankBranch: string
    accountNumber: string
    activeFlag: string
    ifscCode: string
    createdOn: string
    updatedOn: string
    micrCode:string
    adminUserDtoCreatedBy: number
    adminUserDtoUpdatedBy: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
  }