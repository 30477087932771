import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { AutoSweepLog } from './auto-sweep.model';



@Injectable({ providedIn: 'root' })
export class AutoSweepService {
    protected getAllSweepsUrl = environment.SERVER_API_URL + 'api/getAllSweeps';
    protected fetchOsrFileUrl = environment.SERVER_API_URL + 'api/fetchOsrFile';
   
  
  constructor(protected http: HttpClient) {}

 
  getAllSweeps(req?: any, filter?: any): Observable<HttpResponse<AutoSweepLog[]>> {
    const options = createRequestOption(req);
    return this.http.get<AutoSweepLog[]>(this.getAllSweepsUrl, {
      params: options,
      observe: 'response',
    });
  }
 
 
  fetchOsrFile(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.fetchOsrFileUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  
}
