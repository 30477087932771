import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { AdminUserDtoCreatedby } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { AccountSnaDto } from '../account-sna/account-sna';
import { DepartmentList } from './../department-list/department-list';
export class OpeningBalance {
    id: number
    department: DepartmentList
    scheme: SchemeList
    openingBalance: number
    dateOfAob: string
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: AdminUserDtoCreatedby
    adminUserUpdatedBy: AdminUserDtoCreatedby
  }

  export class OpeningBalanceDto {
    id: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
    openingBalanceAsString: string
    openingBalance: number
    fundsDate: any
    dateOfAobAsString: string
    dateOfAob: Date
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedby: AdminUserDtoCreatedby
    adminUserDtoUpdatedBy: AdminUserDtoCreatedby
    }

  export class  SnaFundsDto{
    openingFundsDto:OpeningBalanceDto
    snaDto:AccountSnaDto
  }