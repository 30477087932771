<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5>
                        Funds List
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar id="fromDate" dateFormat="dd/mm/yy" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-calendar id="toDate" dateFormat="dd/mm/yy" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-dropdown placeholder="Select Component" [options]="compTypeList" [(ngModel)]="selectedcompType" optionLabel="name"></p-dropdown>
        <button pButton pRipple label="Search" (click)="loadPage(1)" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="fromDate=null;toDate=null;startDate=null;endDate=null;selectedcompType=null;filterProps=null;loadPage(1)" class="p-button-sm"></button>

    </div>
    <div class="row mt-2">
        <div #customerTable id="entities" class="col-md-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [enableAction]="false" (searchParam)="onLazyLoad($event)"></custom-filter-table>
        </div>
    </div>