import { PushNotificationComponent } from './push_notifcation/push-notification/push-notification.component';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

// Ngx & Others
import { httpInterceptorProviders } from './auth/interceptor';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { missingTranslationHandler } from './config/translation.config';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
// PrimeNg
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { BadgeModule } from 'primeng/badge';

import { CardModule } from 'primeng/card';

import { SharedModule } from './shared/shared.module';

// Services
import { AccountSnaService } from './module/account-sna/account-sna.service';
import { AgencyService } from './module/agency/agency.service';
import { CommercialVendorService } from './module/commercial-vendors/commercial-vendors.service';
import { ComponentTypeService } from './module/component-type/component-type.service';
import { DepartmentListService } from './module/department-list/department-list.service';
import { FundsService } from './module/funds/funds.service';
import { FundsTransactionService } from './module/funds-transaction/funds-transaction.service';
import { LevelMasterService } from './module/level-master/level-master.service';
import { LevelTypeService } from './module/level-type/level-type.service';
import { LimitSetService } from './module/limit-set/limit-set.service';
import { MajorComponentService } from './module/major-component/major-component.service';
import { MinorComponentService } from './module/minor-component/minor-component.service';
import { OpeningBalanceService } from './module/opening-balance/opening-balance.service';
import { PersonalVendorService } from './module/personal-vendors/personal-vendors.service';
import { ProfileService } from './module/profile/profie.service';
import { SchemeListService } from './module/scheme-list/scheme-list.service';
import { SubComponentService } from './module/sub-component/sub-component.service';
import { UserService } from './module/user/user.service';
import { ValidationService } from './shared/validation/validation.service';
import { ZonalService } from './module/zonal/zonal.service';
import { NotificationService } from './alert/notification.service';


// Components
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { AgencyComponent } from './module/agency/agency.component';
import { CommercialVendorsComponent } from './module/commercial-vendors/commercial-vendors.component';
import { ComponentMasterComponent } from './module/component-master/component-master.component';
import { ComponentTypeComponent } from './module/component-type/component-type.component';
import { DashboardComponent } from './dashBoards/dashboard/dashboard.component';
import { DepartmentListComponent } from './module/department-list/department-list.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FundsComponent } from './module/funds/funds.component';
import { FundsTransactionComponent } from './module/funds-transaction/funds-transaction.component';
import { LevelMasterComponent } from './module/level-master/level-master.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';
import { LimitSetComponent } from './module/limit-set/limit-set.component';
import { LimitTranslistComponent } from './module/limit-translist/limit-translist.component';
import { MajorComponentComponent } from './module/major-component/major-component.component';
import { MinorComponentComponent } from './module/minor-component/minor-component.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { OpeningBalanceComponent } from './module/opening-balance/opening-balance.component';
import { PaymentModuleComponent } from './payments/payment-module/payment-module.component';
import { PaymentPersonalComponent } from './payments/payment-personal/payment-personal.component';
import { PaymentPersonalVoucherComponent } from './payments/payment-personal-voucher/payment-personal-voucher.component';
import { TabViewModule } from 'primeng/tabview';

import { PaymentVoucherComponent } from './payments/payment-voucher/payment-voucher.component';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { SchemeListComponent } from './module/scheme-list/scheme-list.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SubComponentComponent } from './module/sub-component/sub-component.component';
import { UserComponent } from './module/user/user.component';
import { ZonalComponent } from './module/zonal/zonal.component';
import { LimitFormulaComponent } from './module/limit-formula/limit-formula.component';
import { UpdateBeneficiaryComponent } from './module/update-beneficiary/update-beneficiary.component';
import { BeneficiaryTransactionComponent } from './module/beneficiary-transaction/beneficiary-transaction.component';
import { AlertComponent } from './alert/alert.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UppercaseInputDirective } from './shared/directives/uppercase-input.directive';


//devExtreme
import { DxChartModule,DxButtonModule } from 'devextreme-angular';
import { DxPieChartModule } from 'devextreme-angular';
import { DxSankeyModule } from 'devextreme-angular';
import { PaymentSuccessComponent } from './payments/payment-success/payment-success.component';
import { EmployeeComponent } from './module/employee/employee.component';
import { EmployeeVoucherComponent } from './payments/employee-voucher/employee-voucher.component';
import { EmployeePaymentTableComponent } from './payments/employee-payment-table/employee-payment-table.component';
import { ElectedRepresentativeComponent } from './module/elected-representative/elected-representative.component';
import { ElectedRepresenativePaymentlComponent } from './payments/elected-represenative-paymentl/elected-represenative-paymentl.component';
import { ElectedRepresenativePaymentTableComponent } from './payments/elected-represenative-payment-table/elected-represenative-payment-table.component';
import { PaymentSwapTableComponent } from './payments/payment-swap-table/payment-swap-table.component';
import { PaymentSwapPageComponent } from './payments/payment-swap-page/payment-swap-page.component';
import { OnboardReportComponent } from './report/onboard-report/onboard-report.component';
import { VendorReportComponent } from './report/vendor-report/vendor-report.component';
import { PaymentReportComponent } from './report/payment-report/payment-report.component';
import { EmployeeOnboardReportComponent } from './report/employee-onboard-report/employee-onboard-report.component';
import { VoucherStatusReportComponent } from './report/voucher-status-report/voucher-status-report.component';
import { AccountwiseFundReportComponent } from './report/accountwise-fund-report/accountwise-fund-report.component';
import { OsrFundComponent } from './module/osr-fund/osr-fund.component';
import { NilBalanceReportComponent } from './report/nil-balance-report/nil-balance-report.component';
import { TranscatonReportComponent } from './report/transcaton-report/transcaton-report.component';
import { HomeComponent } from './home/home.component';
import { EmployeeBulkPayTableComponent } from './payments/employee-bulk-pay-table/employee-bulk-pay-table.component';
import { EmployeeBulkPayVoucherComponent } from './payments/employee-bulk-pay-voucher/employee-bulk-pay-voucher.component';
import { ERBulkPayTableComponent } from './payments/er-bulk-pay-table/er-bulk-pay-table.component';
import { ERBulkPayVoucherComponent } from './payments/er-bulk-pay-voucher/er-bulk-pay-voucher.component';
import { RejectFailedPaymentsComponent } from './payments/reject-failed-payments/reject-failed-payments.component';
import { BulkPaymentReportComponent } from './report/payment-report/bulk-payment-report/bulk-payment-report.component';
import { EmployeeCountReportComponent } from './report/employee-count-report/employee-count-report.component';
import { PaymentNotDoneReportComponent } from './report/payment-not-done-report/payment-not-done-report.component';
import { VendorTransactionReportComponent } from './report/vendor-transaction-report/vendor-transaction-report.component';
import { PaymentStatusReportComponent } from './report/payment-status-report/payment-status-report.component';
import { PaymentTransReportComponent } from './report/payment-trans-report/payment-trans-report.component';
import { ApiReqResReportComponent } from './report/api-req-res-report/api-req-res-report.component';
import { IaComponentReportComponent } from './report/ia-component-report/ia-component-report.component';
import { IaComponentTransReportComponent } from './report/ia-component-trans-report/ia-component-trans-report.component';
import { AccountValidationLogComponent } from './report/account-validation-log/account-validation-log.component';
import { DscReportComponent } from './report/dsc-report/dsc-report.component';
import { FundsExcelUploadComponent } from './module/funds-excel-upload/funds-excel-upload.component';
import { EmployeeTransferComponent } from './module/employee-transfer/employee-transfer.component';
import { EmployeeTransferPageComponent } from './module/employee-transfer-page/employee-transfer-page.component';
import { EmployeeTransferReportComponent } from './report/employee-transfer-report/employee-transfer-report.component';
import { BlukPaymentVoucherComponent } from './payments/bluk-payment-voucher/bluk-payment-voucher.component';
import { BlukPaymentTableComponent } from './payments/bluk-payment-table/bluk-payment-table.component';
import { TangedcoBillDetailsComponent } from './tneb/tangedco-bill-details/tangedco-bill-details.component';
import { TangedcoPaymentVoucherComponent } from './tneb/tangedco-payment-voucher/tangedco-payment-voucher.component';
import { TangedcoPaymentTableComponent } from './tneb/tangedco-payment-table/tangedco-payment-table.component';
import { TangedcoMasterComponent } from './tneb/tangedco-master/tangedco-master.component';
import { UniversalVendorComponent } from './module/universal-vendor/universal-vendor.component';
import { TnebReportComponent } from './report/tneb-report/tneb-report.component';
import { TwadMasterListComponent } from './twad/twad-master-list/twad-master-list.component';
import { TwadPaymentTableComponent } from './twad/twad-payment-table/twad-payment-table.component';
import { TwadPaymentVoucherComponent } from './twad/twad-payment-voucher/twad-payment-voucher.component';
import { TwadDashboardComponent } from './twad/twad-dashboard/twad-dashboard.component';
import { MenuConfigurationComponent } from './tneb/menu-configuration/menu-configuration.component';
import { MenuEnableListComponent } from './tneb/menu-enable-list/menu-enable-list.component';
import { NavigationComponent } from './module/navigation/navigation.component';
import { ReportListComponent } from './report/report-list/report-list.component';
import { BeneficiaryCreationComponent } from './module/beneficiary-creation/beneficiary-creation.component';
import { DeductionAccountsComponent } from './module/deduction-accounts/deduction-accounts.component';
import { BeneficiaryPaymentListComponent } from './payments/beneficiary-payment-list/beneficiary-payment-list.component';
import { BeneficiaryPaymentVoucherComponent } from './payments/beneficiary-payment-voucher/beneficiary-payment-voucher.component';
import { DeductionAccountCountReportComponent } from './report/deduction-account-count-report/deduction-account-count-report.component';
import { TwadReportComponent } from './twad/twad-report/twad-report.component';
import { ThooimaiKavalarPaymentListComponent } from './payments/thooimai-kavalar-payment-list/thooimai-kavalar-payment-list.component';
import { ThooimaiKavalarPaymentVoucherComponent } from './payments/thooimai-kavalar-payment-voucher/thooimai-kavalar-payment-voucher.component';
import { AccountStatementBalancesVillageComponent } from './module/account-statement-balances-village/account-statement-balances-village.component';
import { AccountStatementVillageComponent } from './module/account-statement-village/account-statement-village.component';
import { DeductionReportComponent } from './report/deduction-report/deduction-report.component';
import { VoucherDelayReportComponent } from './report/voucher-delay-report/voucher-delay-report.component';
import { TwadOfficeMasterComponent } from './twad/twad-office-master/twad-office-master.component';
import { TwadAccountSummaryComponent } from './twad/twad-account-summary/twad-account-summary.component';
import { ExcelUploadListComponent } from './module/excel-upload-list/excel-upload-list.component';
import { OfficeMasterComponent } from './twad/office-master/office-master.component';
import { ComponentSwapTableComponent } from './payments/component-swap-table/component-swap-table.component';
import { ComponentSwapPageComponent } from './payments/component-swap-page/component-swap-page.component';
import { TnebDashboardComponent } from './dashBoards/tneb-dashboard/tneb-dashboard.component';
import { TnebPaymentReportComponent } from './report/tneb-payment-report/tneb-payment-report.component';
import { TnebTransactionReportComponent } from './report/tneb-transaction-report/tneb-transaction-report.component';
import { TnebMisReportComponent } from './report/tneb-mis-report/tneb-mis-report.component';
import { AutoSweepListComponent } from './module/auto-sweep-list/auto-sweep-list.component';
import { AadharVerificationPageComponent } from './payments/aadhar-verification-page/aadhar-verification-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    DashboardComponent,
    NavigationComponent,
    NavbarComponent,
    FooterComponent,
    ProfileComponent,
    UserComponent,
    AgencyComponent,
    ZonalComponent,
    PersonalVendorsComponent,
    CommercialVendorsComponent,
    AccountSnaComponent,
    DepartmentListComponent,
    SchemeListComponent,
    FundsComponent,
    OpeningBalanceComponent,
    FundsTransactionComponent,
    LimitSetComponent,
    LevelTypeComponent,
    LevelMasterComponent,
    ComponentMasterComponent,
    ComponentTypeComponent,
    MajorComponentComponent,
    MinorComponentComponent,
    SubComponentComponent,
    PaymentModuleComponent,
    PaymentVoucherComponent,
    LimitTranslistComponent,
    LimitFormulaComponent,
    PaymentPersonalComponent,
    PaymentPersonalVoucherComponent,
    UpdateBeneficiaryComponent,
    BeneficiaryTransactionComponent,
    AlertComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UppercaseInputDirective,
    PaymentSuccessComponent,
    EmployeeComponent,
    EmployeeVoucherComponent,
    EmployeePaymentTableComponent,
    ElectedRepresentativeComponent,
    ElectedRepresenativePaymentlComponent,
    ElectedRepresenativePaymentTableComponent,
    PaymentSwapTableComponent,
    PaymentSwapPageComponent,
    OnboardReportComponent,
    VendorReportComponent,
    PaymentReportComponent,
    VoucherStatusReportComponent,
    EmployeeOnboardReportComponent,
    AccountwiseFundReportComponent,
    OsrFundComponent,
    NilBalanceReportComponent,
    TranscatonReportComponent,
    HomeComponent,
    EmployeeBulkPayTableComponent,
    EmployeeBulkPayVoucherComponent,
    ERBulkPayTableComponent,
    ERBulkPayVoucherComponent,
    RejectFailedPaymentsComponent,
    BulkPaymentReportComponent,
    EmployeeCountReportComponent,
    PaymentNotDoneReportComponent,
    VendorTransactionReportComponent,
    PaymentStatusReportComponent,
    PaymentTransReportComponent,
    ApiReqResReportComponent,
    IaComponentReportComponent,
    IaComponentTransReportComponent,
    AccountValidationLogComponent,
    DscReportComponent,
    FundsExcelUploadComponent,
    BlukPaymentVoucherComponent,
    BlukPaymentTableComponent,
    EmployeeTransferComponent,
    EmployeeTransferPageComponent,
    EmployeeTransferReportComponent,
    ReportListComponent,
    TwadMasterListComponent,
    TwadPaymentTableComponent,
    TwadPaymentVoucherComponent,
    TangedcoBillDetailsComponent,
    TangedcoPaymentTableComponent,
    TangedcoPaymentVoucherComponent,
    TangedcoMasterComponent,
    UniversalVendorComponent,
    TwadDashboardComponent,
    TnebReportComponent,
    MenuConfigurationComponent,
    MenuEnableListComponent,
    PushNotificationComponent,
    TwadReportComponent,
    BeneficiaryCreationComponent,
    DeductionAccountsComponent,
    BeneficiaryPaymentListComponent,
    BeneficiaryPaymentVoucherComponent,
    DeductionAccountCountReportComponent,
    ThooimaiKavalarPaymentListComponent,
    ThooimaiKavalarPaymentVoucherComponent,
    AccountStatementBalancesVillageComponent,
    AccountStatementVillageComponent,
    DeductionReportComponent,
    VoucherDelayReportComponent,
    TwadOfficeMasterComponent,
    TwadAccountSummaryComponent,
    ExcelUploadListComponent,
    OfficeMasterComponent,
    TnebDashboardComponent,
    TnebPaymentReportComponent,
    TnebTransactionReportComponent,
    TnebMisReportComponent,
    AutoSweepListComponent,
    AadharVerificationPageComponent,
    AutoSweepListComponent,
    ComponentSwapTableComponent,
    ComponentSwapPageComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CardModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    NgxExtendedPdfViewerModule,
    ButtonModule,
    MenubarModule,
    TieredMenuModule,
    RippleModule,
    TabViewModule,
    DxSankeyModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    TimelineModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChartModule,
    DxButtonModule,
    DxChartModule,
    DxPieChartModule,
    NgSelectModule,
    BadgeModule,
    NgxSpinnerModule,
    CascadeSelectModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
  ],

  providers: [
    httpInterceptorProviders,
    ProfileService,
    MessageService,
    ConfirmationService,
    AgencyService,
    UserService,
    ZonalService,
    PersonalVendorService,
    CommercialVendorService,
    AccountSnaService,
    DepartmentListService,
    SchemeListService,
    FundsService,
    OpeningBalanceService,
    FundsTransactionService,
    LimitSetService,
    LevelTypeService,
    LevelMasterService,
    ComponentTypeService,
    MajorComponentService,
    MinorComponentService,
    SubComponentService,
    ValidationService,
    NotificationService,
    DatePipe
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
  constructor(

    translateService: TranslateService,
    sessionStorageService: SessionStorageService
  ) {
    // registerLocaleData(locale);
    translateService.setDefaultLang('en');
    // if user have changed language and navigates away from the application and back to the application then use previously choosed language
    const langKey = sessionStorageService.retrieve('locale') ?? 'en';
    translateService.use('en');
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}


