import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<Agency[]>;
import { Agency, AgencyDto } from './agency';

@Injectable({ providedIn: 'root' })
export class AgencyService {
   
    constructor(private http: HttpClient) { }
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getIAgencyByFilter');
    protected resourceVaildFliterUrl = environment.SERVER_API_URL+('api/getIAgencyValidByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveImpementingAgency')
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteImplementingAgency')
    protected getAgencyUrl = environment.SERVER_API_URL+('api/getImplementingAgency')
    

    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<Agency[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    validFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<Agency[]>(this.resourceVaildFliterUrl,filter, { params:options, observe: 'response' })
    }

    save(agency?:AgencyDto): Observable<EntityArrayResponseType> {
        return this.http
        .post<AgencyDto[]>(this.resourceSaveUrl,agency, {observe: 'response' })
    }

    delete(id: number): Observable<HttpResponse<{}>> {
        return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
      }
  
      getAgency(id: number): Observable<HttpResponse<any>> {
        return this.http.get<AgencyDto>(`${this. getAgencyUrl}?id=${id}`, { observe: 'response' });
      }

}