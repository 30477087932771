<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
          <h5 [ngClass]="fontService.headingClass" jhiTranslate="funds.funds.list.name">Funds List</h5>

          <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
        </ng-template>
        <ng-template pTemplate="right">
          <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()"
            styleClass="button"><span class="button-label" [ngClass]="fontService.buttonClass"
              jhiTranslate="common.newbutton"></span></button>
          <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
        </ng-template>
      </p-toolbar>
    </div>
  </div>
  <div class="row">
    <div #customerTable id="entities" class="col-12 px-4">
      <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)"
        [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
    </div>
  </div>
  <p-dialog #dialog [(visible)]="fundsDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
    [style]="{ width: '850px' }">
    <p-header [ngClass]="fontService.headingClass">
      {{"funds.add.budget.name"|translate}}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">
          <div class="row gy-2">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="funds.add.budget.for.scheme.name">Budget for Scheme
              </h6>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.department.name">Department</label>
              <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                [clearable]="true" class="form-control" [items]="departmentList" [(ngModel)]="fund.departmentDto"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fund.departmentDto }">
              </ng-select>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.scheme.name">Scheme</label>
              <ng-select [appendTo]="'.p-dialog'" id="scheme" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                class="form-control" [items]="schemeList" [(ngModel)]="fund.schemeDto"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fund.schemeDto }">
              </ng-select>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.fin.year.name">Fin Year</label>
              <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                class="form-control" [items]="finYear" [(ngModel)]="fund.finYearDto"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !fund.finYearDto }" (ngModelChange)="checkFinYear()">
              </ng-select>
              <!-- <p *ngIf="finYearError" style="font-size: 14px; color: #ff0000">
                {{finYearError}}
              </p> -->
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="budgetAmount" [ngClass]="fontService.labelClass"
                jhiTranslate="funds.budget.amount.name">Budget Amount</label>
              <input type="text" id="budgetAmount" name="budgetAmount" autocomplete="off" class="form-control"
                [(ngModel)]="fund.budgetAmount" [ngClass]="{ 'is-invalid': ngSelectInvalid && !fund.budgetAmount }"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="25" />
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span></button>
      <button pButton pRipple *ngIf="!this.fund.id" icon="pi pi-check" class="p-button-text custom-button"
        (click)="saveData()"><span class="button-label" [ngClass]="fontService.buttonClass"
          jhiTranslate="common.addbutton"></span></button>
      <button pButton pRipple *ngIf="this.fund.id" icon="pi pi-check" class="p-button-text custom-button"
        (click)="saveData()"><span class="button-label" [ngClass]="fontService.buttonClass"
          jhiTranslate="common.savebutton"></span></button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>