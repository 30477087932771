import { Router ,ActivatedRoute, NavigationStart, NavigationEnd} from '@angular/router';
import { Component, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NavbarService } from './layout/navbar/navbar.service';
import { HttpResponse } from '@angular/common/http';
import { GoogleAnalyticsService } from './google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ib-sna-developement';
  inactivityTimeout:any;
  currentRoute: string | undefined;
  showHead: boolean | undefined;
  private intervalId: any;
  public minutes: number = 30;
  public seconds: number = 0;
  lastClickTime: number | null = null;
  constructor(private router: Router,private location: Location,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private navbarService: NavbarService,
    private gaService: GoogleAnalyticsService) {
    // on route change to '/login', set the variable showHead to false
      router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          if (event['url'] == '/login' || event['url'] == '/' || event['url'].startsWith('/error')
          || event['urlAfterRedirects'].startsWith('/error')
          || event['url'].startsWith('/ForgotPassword') || event['url'].startsWith('/ResetPassword') ||  event['url'].startsWith('/aadhar-verification-page')) {
            this.showHead = false;
          } else {
            this.showHead = true;
          }
        }
      });
    }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.gaService.setPageView(event.urlAfterRedirects);
      }
    });
    const id = this.router.routerState.snapshot.root;
  }
  private logoutByPromise(): Promise<string> {

    return new Promise<string>(
      (resolve) => {
        this.navbarService.logout().subscribe(
          () => {
            resolve('SUCCESS')
          },
          () => {
            resolve(null);
          }
        );

      }
    );
  }

  @HostListener("window:mousemove")
  @HostListener("window:keydown")
  resetInactivityTimer() {
    clearTimeout(this.inactivityTimeout);
    this.countdown();
    this.inactivityTimeout = setTimeout(() => {
      this.logout();
    }, 420000); // 7mins.
  }
  logout(): void {

    const token: string | null =
    this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
    if (token) {
      this.navbarService.logout().subscribe(
        (res: HttpResponse<any>) => {
        
      },
      (err) => {}
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate([""]);
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if(this.sessionStorageService.retrieve('refresh')){
      this.sessionStorageService.clear('refresh');
      return;
    }
    this.logout();
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: any) {
  //   event.preventDefault();
  //   this.logout();
  // }

  // @HostListener('contextmenu', ['$event'])
  // onContextMenu(event: MouseEvent) {
  //   event.preventDefault();
  // }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  private countdown() {
    clearInterval(this.intervalId);
    const totalSeconds = 420;
    let remainingSeconds = totalSeconds;

    this.intervalId = setInterval(() => {
      remainingSeconds--;

      this.minutes = Math.floor(remainingSeconds / 60);
      this.seconds = remainingSeconds % 60;

      if (this.sessionStorageService.retrieve('auth')) {
        this.sessionStorageService.store('idle_minutes', this.minutes);
        this.sessionStorageService.store('idle_seconds', this.seconds);
      }

      if (remainingSeconds <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    // Check if Ctrl key is pressed
    if (event.ctrlKey) {
      // Prevent the default action
      event.preventDefault();
    }
  }
  
    
}
