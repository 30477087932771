<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Component Wise Deduction Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-dropdown placeholder="Select Component" [options]="ComponentList" [(ngModel)]="component" showClear="true"></p-dropdown>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Export" (click)="exportExcel('Date')" class="p-button-success p-button-sm export-button"></button>
        <button pButton pRipple label="Export All" (click)="exportExcel('All')" class="p-button-success p-button-sm"></button>
    </div>
    <div *ngIf="searchResult.items" class="row mt-4">
        <p-table [value]="searchResult.items" responsiveLayout="scroll" scrollDirection="both" styleClass="p-datatable-gridlines" columnResizeMode='fit' [paginator]="true" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true"
            [lazy]="true" [totalRecords]="searchResult.total" [currentPageReportTemplate]="searchResult.total > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'" [lazyLoadOnInit]="true" responsiveLayout="scroll" [tableStyle]="{ width: 'max-content'}"
            [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [first]="first">
            <ng-template pTemplate="header">

                <tr>
                    <th style="text-align: center;" rowspan="3">Voucher No</th>
                    <th style="text-align: center;" rowspan="3">Queue No</th>
                    <th style="text-align: center;" rowspan="3">Transaction Date</th>
                    <th style="text-align: center;" colspan="5">Head Wise Deduction Report</th>
                    <th style="text-align: center;" rowspan="3">Total Deduction<br>Amount</th>

                </tr>
                <tr>
                    <th style="text-align: center;">G1</th>
                    <th style="text-align: center;">G2</th>
                    <th style="text-align: center;">G3</th>
                    <th style="text-align: center;">G4</th>
                    <th style="text-align: center;">G5</th>

                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{list?.voucherNo}}</td>
                    <td style="text-align: center;">{{list?.queueNo}}</td>
                    <td style="text-align: center;">{{list?.transactionDate | date:'dd/MM/yyyy'}}</td>
                    <td style="text-align: center;">{{list?.g1}}</td>
                    <td style="text-align: center;">{{list?.g2}}</td>
                    <td style="text-align: center;">{{list?.g3}}</td>
                    <td style="text-align: center;">{{list?.g4}}</td>
                    <td style="text-align: center;">{{list?.g5}}</td>
                    <td style="text-align: center;">{{list?.totalDeductionAmount}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
        </p-table>
    </div>
</div>