import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LevelTypeService } from './level-type.service';
import { LevelType } from './level-type';
import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-level-type',
  templateUrl: './level-type.component.html',
  styleUrls: ['./level-type.component.scss']
})
export class LevelTypeComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;

  levelTypeDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<LevelType> = new SearchResult<LevelType>();
  departmentList: DepartmentList[]
  schemeList: SchemeList[]
  levelType?: LevelType;
  selectedLimitSet: LevelType[] = [];
  submitted?: boolean;
  selectedMapping: string;

  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;

  constructor(
    private levelTypeService: LevelTypeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private limitTransListService: LimitTransListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage()

    this.cols = [
      { field: 'name', header: 'Level Type Name',jhiTranslate: 'tableHead.levelType', width: 5, isSelectcolumn: true },
      { field: 'departmentDto', header: 'Dept Name',jhiTranslate: 'tableHead.department.name', width: 25, isSelectcolumn: true, type: 'dropDown' },
      { field: 'schemeDto', header: 'Scheme Name',jhiTranslate: 'tableHead.scheme.name', width: 25, isSelectcolumn: true, type: 'dropDown' },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show()
    this.levelTypeService
      .filter()
      .subscribe(
        (res: HttpResponse<LevelType[]>) => {
          this.searchResult.total = res.body.length
          this.spinner.hide()

          this.searchResult.items = res.body;
          this.searchResult = { ...this.searchResult };

        },
        () => {
          this.onError();
        }
      );
  }

  protected onError(): void {
  }

  checkName(){
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'name',
        operation: 'equals',
        value: this.levelType.name.toUpperCase(),
      },
     
    ];
    if (this.levelType.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: String(this.levelType.id),
      });
    }
    this.levelTypeService.levelTypefilter({ ...filterQuery }, filter).subscribe(
      (res: HttpResponse<LevelType[]>) => {
        if(res.body.length != 0){
          this.showNameError = "Name Already Exists"
        }
        else{
          this.showNameError = null
        }
      },
      () => {
        this.onError();
        
      }
    );
  
  }

  openNew() {
    this.levelType = new LevelType()
    this.departmentListService
      .filter()
      .subscribe(
        (res: HttpResponse<DepartmentList[]>) => {
          this.departmentList = res.body
        },
        () => {
          this.onError();
        }
      );
    this.schemeListService
      .filter()
      .subscribe(
        (res: HttpResponse<SchemeList[]>) => {
          this.schemeList = res.body
        },
        () => {
          this.onError();
        }
      );
    this.submitted = false;
    this.levelTypeDialog = true;
  }

  hideDialog() {
    this.levelType = new LevelType()
    this.ngSelectInvalid = false;
    this.levelTypeDialog = false;
    this.submitted = false;
  }

  saveData() {
    const isFormValid =
      this.levelType.departmentDto &&
      this.levelType.schemeDto &&
      this.levelType.name;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.levelTypeService
      .save(this.levelType)
      .subscribe(
        (res: HttpResponse<LevelType>) => {
          this.submitted = true;
          this.levelTypeDialog = false;
          this.loadPage();
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }
}