import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { Zonal, ZonalDto } from './zonal';
export type EntityArrayResponseType = HttpResponse<Zonal[]>;

@Injectable()
export class ZonalService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/getZonalByFilter';
  protected resourceSaveUrl = environment.SERVER_API_URL + 'api/saveZonal';

  constructor(protected http: HttpClient) {}

  save(zonal?: ZonalDto): Observable<any> {
    return this.http.post<any[]>(this.resourceSaveUrl, zonal, {
      observe: 'response',
    });
  }
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<Zonal[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
}
