<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 *ngIf="!isTwad" [ngClass]="fontService.headingClass" jhiTranslate="nav.iACompo.limitExcelUpload">

                    </h5>
                    <h5 *ngIf="isTwad" [ngClass]="fontService.headingClass">
                        Twad Excel Upload
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div *ngIf="showStatus" class="container-fluid pb-3">
        <div class="card p-3">
            <div class="row">
                <h1>{{this.status}}</h1>
            </div>

        </div>
    </div>
    <div *ngIf="showForm" class="container-fluid pb-3">
        <div class="card p-3">
            <h5 *ngIf="!isTwad" [ngClass]="fontService.headingClass" jhiTranslate="nav.iACompo.limitExcelUpload">
            </h5>
            <h5 *ngIf="isTwad" [ngClass]="fontService.headingClass">
                Twad Excel Upload
            </h5>
            <div class="row mt-3">
                <div class="col-md-3">
                    <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass" jhiTranslate="funds.fin.year.name">Fin Year</label>
                    <ng-select id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [disabled]="true" [clearable]="true" class="form-control" [items]="finYearList" [(ngModel)]="finYear">
                    </ng-select>

                </div>
                <div class="col-lg-3 col-md-6">
                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="nav.master.levelType"></label>
                    <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="levelType" [items]="levelTypeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control">
                    </ng-select>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{"payments.department.name"|translate}}:<br>{{levelType?.departmentDto?.name}}
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4">
                    <p [ngClass]="fontService.regularBoldClass">
                        {{"payments.scheme.name"|translate}}:<br>{{levelType?.schemeDto?.name}}
                </div>
            </div>
            <div *ngIf="!isTwad" class="row mt-3">
                <div class="col-md-3">
                    <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass" jhiTranslate="funds.select.fund.source.name">Fund Source</label>
                    <ng-select id="scheme" [(ngModel)]="selectedSource" [items]="fundSourceList" appearance="outline" [closeOnSelect]="true" class="form-control" (change)="levelTypeChange()" [clearable]="true">
                    </ng-select>
                </div>
                <div class="col-lg-3 col-md-6">
                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label>
                    <ng-select id="scheme" [hideSelected]="true" [(ngModel)]="grantlevel" [items]="grantLevelList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div class="col-md-3">
                    <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass" jhiTranslate="funds.date.of.transaction.name">Date of Transaction</label>
                    <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="transactionDate" [maxDate]="today" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
            </div>
            <div *ngIf="isTwad" class="row mt-3">

                <div class="col-md-3">
                    <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass">Bill Date</label>
                    <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="transactionDate" [maxDate]="today" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
            </div>
            <div class="row mt-3" *ngIf="grantlevel || isTwad">
                <div class="col-md-3">
                    <button pButton pRipple type="button" label="Download Sample File Template" (click)="downloadFile()" class="p-button-success"></button>
                </div>
                <div class="col-md-9">
                    <div class="col-md-12">
                        <small [ngClass]="fontService.regularClass">Note : Allowed file types are
                            xls,xlsx and
                            Maximum file size should be 1MB</small>
                    </div>
                    <div class="col-md-6">
                        <p-fileUpload accept=".xls,.xlsx" (onUpload)="onUploadfile($event)" maxFileSize="1000000" [customUpload]="true" auto="true" (uploadHandler)="onUploadfile($event)" [multiple]="false" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                        </p-fileUpload>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="right">
                        <button pButton pRipple type="button" label="Upload Excel" (click)="submit()" class="p-button-success"></button>
                    </ng-template>
                </p-toolbar>
            </div>

        </div>
    </div>
    <div *ngIf="showTable" class="container-fluid pb-3">
        <div class="card p-3">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [enableAction]="false"></custom-filter-table>
                </div>
            </div>
            <div class="row">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="right">
                        <button pButton pRipple type="button" label="Approve" (click)="save()" class="p-button-success"></button>
                        <button pButton pRipple type="button" label="Reject" (click)="reject()" class="p-button-danger"></button>
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
    </div>


</div>