<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5>Failed Payment List</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
        <p-tabPanel header="Personal">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="personalCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Commercial">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="commercialCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Employee">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="employeeCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="ER">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="ERCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="EmployeeBulk">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="employeeCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [showRightButton]=true [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="ER Bulk">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="ERCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [showRightButton]=true [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel header="Bulk Sub List">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="bulkListCols" [filterSearchResult]="searchBulkResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showRevert]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Individual Beneficiary">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="beneficiaryCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Village Thooimai Kavalar">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="personalCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="!disableTneb" header="TANGEDCO TNPASS List">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="tangedcoCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showRightButton]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel *ngIf="!disableTneb" header="TANGEDCO TNEB List">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="tangedcoCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showRightButton]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel *ngIf="!disableTneb" header="TANGEDCO Sub List">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="tangedcoBulkSubCols" [filterSearchResult]="searchTnebResult" (emitRow)="onSelectRow($event)" [checkStatusButton]="failureType === 'TANGEDCO'" [showRevert]="failureType !== 'TANGEDCO'" (showTable)="getSubTableDetails($event)"
                        [showTableButton]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="!disableTwad" header="TWAD">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="tangedcoCols" [filterSearchResult]="searchResult" (emitRow)="onSelectRow($event)" [checkStatusButton]=false [showReject]="true" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>


    </p-tabView>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
  </button>
        <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
  </button>
    </ng-template>
</p-confirmDialog>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
    <p-header [ngClass]="fontService.headingClass">
        {{ "dashBoard.transactionlist" | translate }}
    </p-header>
    <custom-filter-table [columnsValues]="tangedcoSubCols" [filterSearchResult]="searchtangdecoResult" [disableDeleteIcon]="true" [paginator]="false" [enableAction]="false" [disableEditIcon]="true"></custom-filter-table>
</p-dialog>