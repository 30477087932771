import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommercialVendorService } from './commercial-vendors.service';
import {
  commercial,
  CommercialVendors,
  CommercialVendorsDto,
  ValidationResponse,
} from './commercial-vendors';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { filterQuery } from 'src/app/shared/common.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { environment } from 'src/environments/environment';
import { LevelMasterService } from '../level-master/level-master.service';
import { LevelMasterDto } from '../level-master/level-master';

@Component({
  selector: 'app-commercial-vendors',
  templateUrl: './commercial-vendors.component.html',
  styleUrls: ['./commercial-vendors.component.scss'],
})
export class CommercialVendorsComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  commercialVendorsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<CommercialVendors> =
    new SearchResult<CommercialVendors>();
  checked: boolean;
  commercialVendor?: CommercialVendorsDto;
  selectedcommercialVendors: CommercialVendors[] = [];
  submitted?: boolean;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any=[];
  commercialList: commercial[];
  bankBranch: any;
  public verifyClicked: boolean;
  validationRes: ValidationResponse;
  currentEnvironment: string;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  accountInvalidError: any;
  readonly: boolean;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  bankBranchList: any = [];
  roleCode: any;
  disableEdit: boolean = false;
  vendorName: string;
  edit: boolean;
  bankBranchDetailsRes: any;
  VendorName: string;
  accountNo: string;
  levelType = ['District', 'Block', 'Village'];
  selectedLevelType: string;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelDto: any;
  blockLevelList: LevelMasterDto[];
  districtLevelList: LevelMasterDto[];
  districtDto: any;
  disableDelete: boolean = true;
  isDelete: boolean;
  uniqueId: any;
  constructor(
    private commercialVendorservice: CommercialVendorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected loginService: SigninService,
    private levelMasterService: LevelMasterService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.currentEnvironment = environment.environment;
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.roleCode = res.body.role.code;
        this.loadPage(1);
        if (
          this.roleCode == 'DM' ||
          this.roleCode == 'BM' ||
          this.roleCode == 'SM' ||
          this.roleCode == 'SA' ||
          this.roleCode == 'SUA'
        )
          this.disableEdit = true;
        if (this.roleCode === 'SA') {
          this.disableDelete = false;
          this.onStateLevelChange();
        }
      },
      (onError) => {}
    );

    this.cols = [
      {
        field: 'uniqueId',
        header: 'Name',
        jhiTranslate: 'tableHead.uniqueId',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'companyName',
        header: 'Name',
        jhiTranslate: 'tableHead.name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'companyName',
        header: 'Designation',
        jhiTranslate: 'tableHead.designation',
        isSortable: true,
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        jhiTranslate: 'vendor.bank.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'ifscCode',
        header: 'Ifsc Code',
        jhiTranslate: 'vendor.ifscode',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'commercial',
        header: 'Commercial',
        jhiTranslate: 'tableHead.vendor.commercial',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.commercialVendorservice.getCommerical().subscribe(
      (res: HttpResponse<commercial[]>) => {
        this.commercialList = res.body;
      },
      () => {}
    );
    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
  }

  openNew() {
    this.commercialVendor = new CommercialVendorsDto();
    this.loadValue();
    this.submitted = false;
    this.commercialVendorsDialog = true;
    this.readonly = false;
  }

  addresschange(check?) {
    if (this.checked) {
      this.commercialVendor.companyDoorNo = this.commercialVendor.doorNo;
      this.commercialVendor.companyStreet = this.commercialVendor.street;
      this.commercialVendor.companyArea = this.commercialVendor.area;
      this.commercialVendor.companyCity = this.commercialVendor.city;
      this.commercialVendor.companyDistrict = this.commercialVendor.district;
      this.commercialVendor.companyState = this.commercialVendor.state;
      this.commercialVendor.companyPinCode = this.commercialVendor.pinCode;
    } else {
      if (!check) {
        this.commercialVendor.companyDoorNo = null;
        this.commercialVendor.companyStreet = null;
        this.commercialVendor.companyArea = null;
        this.commercialVendor.companyCity = null;
        this.commercialVendor.companyDistrict = null;
        this.commercialVendor.companyState = null;
        this.commercialVendor.companyPinCode = null;
      }
    }
  }

  async checkName() {
    this.commercialVendor.name = this.removeExtraSpaces(
      this.commercialVendor.name
    );
    const filterData = await this.getfilterdata(
      'name',
      this.commercialVendor.name
    );
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkCompanyName() {
    this.commercialVendor.companyName = this.removeExtraSpaces(
      this.commercialVendor.companyName
    );
    const filterData = await this.getfilterdata(
      'companyName',
      this.commercialVendor.companyName
    );
    if (filterData.length !== 0) {
      this.showCompanyNameError = 'Name Already Exists';
    } else {
      this.showCompanyNameError = null;
    }
  }

  removeExtraSpaces(text: string): string {
    return text.replace(/\s+/g, ' ').trim();
  }
  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.ngSelectInvalid = false;
    this.commercialVendor = new CommercialVendorsDto();
    this.commercialVendorsDialog = false;
    this.submitted = false;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.bankBranch = null;
    this.edit = false;
    this.isDelete =false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.commercialVendor.mobile
    );
    !this.commercialVendor.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.commercialVendor.mobile;
  }

  async validateEmail() {
    this.emailError = !this.validationService.isEmailValid(
      this.commercialVendor.email
    );
    // const filterData = await this.getfilterdata(
    //   'email',
    //   this.commercialVendor.email
    // );
    // if (filterData.length !== 0) {
    //   this.showeEmailError = 'Email Already Exists';
    // } else {
    //   this.showeEmailError = null;
    // }

    return !this.emailError && !!this.commercialVendor.email;
  }

  async validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.commercialVendor.panNo.toUpperCase()
    );
    !this.commercialVendor.panNo ? (this.panError = false) : true;
    // const filterData = await this.getfilterdata(
    //   'panNo',
    //   this.commercialVendor.panNo
    // );
    // if (filterData.length !== 0) {
    //   this.showPanError = 'Pan No Already Exists';
    // } else {
    //   this.showPanError = null;
    // }

    return !this.panError && !!this.commercialVendor.panNo;
  }

  validateTan() {
    this.tanError = !this.validationService.isTanValid(
      this.commercialVendor.tanNo
    );
    !this.commercialVendor.tanNo ? (this.tanError = false) : true;
    return !this.tanError && !!this.commercialVendor.tanNo;
  }

  validateTin() {
    this.tinError = !this.validationService.isTinValid(
      this.commercialVendor.tinNo
    );
    !this.commercialVendor.tinNo ? (this.tinError = false) : true;
    return !this.tinError && !!this.commercialVendor.tinNo;
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(
      this.commercialVendor.pinCode
    );
    !this.commercialVendor.pinCode ? (this.pinError = false) : true;
    return !this.pinError && !!this.commercialVendor.pinCode;
  }

  accountDetailsChanged() {
    this.verifyClicked = false;
    if (
      this.currentEnvironment == 'uat' ||
      this.currentEnvironment === 'production'
    ) {
      if (!this.ifscError) {
        this.spinner.show();
        this.commercialVendorservice
          .bankBranchDetails(this.commercialVendor.ifscCode)
          .subscribe(
            (res) => {
              this.spinner.hide();
              this.bankBranchDetailsRes = res.body;
              console.log(this.bankBranchDetailsRes, 'bankbranchdetails');
              this.commercialVendor.bankBranch =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Branch_Name;
              this.commercialVendor.bankName =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Bank_Name;
            },
            () => {
              this.spinner.hide();
              this.notificationService.alertError('Error Occured', '');
            }
          );
      }
    }
  }

  accountChanged() {
    this.verifyClicked = false;
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
      ];
      if (this.commercialVendor.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.commercialVendor.id,
        });
      }
      this.commercialVendorservice
        .validFilter({ ...filterQuery }, filter)
        .subscribe(
          (res: HttpResponse<CommercialVendors[]>) => {
            resolve(res.body);
          },
          () => {
            this.onError();
            resolve(null);
          }
        );
    });
  }

  validateCPin() {
    this.cPinError = !this.validationService.isPincodeValid(
      this.commercialVendor.companyPinCode
    );
    !this.commercialVendor.companyPinCode ? (this.cPinError = false) : true;

    return !this.cPinError && !!this.commercialVendor.companyPinCode;
  }

  async validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.commercialVendor.aadhaarNo
    );
    !this.commercialVendor.aadhaarNo ? (this.aadhaarError = false) : true;

    // const filterData = await this.getfilterdata(
    //   'aadhaarNo',
    //   this.commercialVendor.aadhaarNo
    // );
    // if (filterData.length !== 0) {
    //   this.showAadhaarNoError = 'Aadhaar No Already Exists';
    // } else {
    //   this.showAadhaarNoError = null;
    // }

    return !this.aadhaarError && !!this.commercialVendor.aadhaarNo;
  }
  async validateGst() {
    this.gstError = !this.validationService.isGstValid(
      this.commercialVendor.gstNo
    );
    !this.commercialVendor.gstNo ? (this.gstError = false) : true;
    // const filterData = await this.getfilterdata(
    //   'gstNo',
    //   this.commercialVendor.gstNo
    // );

    // if (filterData.length !== 0) {
    //   this.showGstError = 'Gst No Already Exists';
    // } else {
    //   this.showGstError = null;
    // }
    return !this.gstError && !!this.commercialVendor.gstNo;
  }

  checkAccountNo() {
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.commercialVendorservice
      .filter({ ...filterQuery }, [
        {
          key: 'accountNumber',
          operation: 'equals',
          value: this.commercialVendor.accountNumber,
        },
      ])
      .subscribe(
        (res: HttpResponse<CommercialVendors[]>) => {
          if (res.body.length !== 0) {
            this.showaccountNoError = 'Account No Already Exists';
          } else {
            this.showaccountNoError = null;
          }
        },
        () => {
          this.onError();
        }
      );
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.commercialVendor.ifscCode
    );
    !this.commercialVendor.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.commercialVendor.ifscCode;
  }

  saveData() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validatePan() &&
      this.validatePin() &&
      this.validateAadhaar() &&
      this.validateGst() &&
      this.validateIfsc() &&
      this.commercialVendor.name &&
      this.commercialVendor.mobile &&
      this.commercialVendor.email &&
      this.commercialVendor.panNo &&
      this.commercialVendor.doorNo &&
      this.commercialVendor.street &&
      this.commercialVendor.city &&
      this.commercialVendor.district &&
      this.commercialVendor.state &&
      this.commercialVendor.pinCode &&
      this.commercialVendor.companyDoorNo &&
      this.commercialVendor.companyStreet &&
      this.commercialVendor.companyCity &&
      this.commercialVendor.companyDistrict &&
      this.commercialVendor.companyState &&
      this.commercialVendor.companyPinCode &&
      this.commercialVendor.bankBranch &&
      this.commercialVendor.bankName &&
      this.commercialVendor.accountNumber &&
      this.commercialVendor.bankAccName &&
      this.commercialVendor.ifscCode &&
      !this.accountInvalidError &&
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.commercialVendorservice.save(this.commercialVendor).subscribe(
      (res: HttpResponse<any>) => {
        this.commercialVendorsDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (err) => {
        this.onError();
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    var i;
    if (this.filterProps) {
      for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
        if (this.filterProps[i].key === 'name') {
          this.filterProps.splice(i, 1);
        }
      }
    }
    if (this.vendorName) {
      this.filterProps.push({
        key: 'name',
        operation: 'contains',
        value: this.vendorName.toUpperCase(),
      });
    }
    if (this.accountNo) {
      this.filterProps.push({
        key: 'accountNumber',
        operation: 'contains',
        value: this.accountNo,
      });
    }
    if(this.uniqueId) {
      this.filterProps.push({
        key: 'uniqueId',
        operation: 'contains',
        value: this.uniqueId,
      });
      
    }
    if (this.selectedLevelType === 'District' && this.districtDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.districtDto.id,
      });
    }
    if (this.selectedLevelType === 'Block' && this.BlockLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.BlockLevelDto.id,
      });
    }
    if (this.selectedLevelType === 'Village' && this.villageLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.villageLevelDto.id,
      });
    }
    if (this.roleCode === 'SA') {
      this.commercialVendorservice
        .validFilter({ ...filterQuery }, this.filterProps)
        .subscribe(
          (res: HttpResponse<CommercialVendors[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    } else {
      this.commercialVendorservice
        .filter({ ...filterQuery }, this.filterProps)
        .subscribe(
          (res: HttpResponse<CommercialVendors[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    }
  }

  protected onSuccess(
    data: CommercialVendors[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/commercial-vendors'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.commercialVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.commercialVendor.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.commercialVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.commercialVendor.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.commercialVendor.bankName,
        this.commercialVendor.bankBranch
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.commercialVendor.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    if (
      !this.commercialVendor.ifscCode ||
      !this.commercialVendor.accountNumber ||
      !this.commercialVendor.bankAccName ||
      !this.commercialVendor.bankName ||
      !this.commercialVendor.bankBranch
    ) {
      this.accountInvalidError =
        'Please input valid account number and bank details';
      return;
    }
    this.verifyClicked = true;
    this.accountInvalidError = null;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidation(
        this.commercialVendor.accountNumber,
        this.commercialVendor.ifscCode
      )
      .subscribe(
        (res: any) => {
          // this.validationRes=JSON.parse(res.body);
          this.validationRes = res.body;
          if (this.validationRes.bankTxnStatus === 'true') {
            this.accountInvalidError = null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
            this.readonly = true;
          } else {
            this.accountInvalidError =
              'Account Verification Failed due to ' +
              this.validationRes.bankResponse;
            this.notificationService.alertError(
              'Account Verification Failed due to ' +
                this.validationRes.bankResponse,
              ''
            );
            this.readonly = false;
            this.verifyClicked = false;
          }
          this.spinner.hide();
        },
        (error) => {
          if (error.status === 400) {
            this.accountInvalidError =
              'Account Verification Failed. ' + error.error.message;
            this.notificationService.alertError(
              'Account Verification Failed. ' + error.error.message,
              ''
            );
          } else {
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
            this.accountInvalidError =
              'Please input valid account number and branch details';
          }
          this.readonly = false;
          this.verifyClicked = false;
          this.spinner.hide();
        }
      );
  }

  protected onError(): void {}

  onSelectRow($event) {
    this.commercialVendorservice.getcommercialVendor($event.value.id).subscribe(
      (res: HttpResponse<any>) => {
        this.readonly = true;
        this.edit = true;
        this.commercialVendor = res.body;
        this.commercialVendorsDialog = true;
        this.verifyClicked = true;
        this.loadValue();
        if (this.commercialVendor.bankName) {
          this.getBankBranchList();
        }
        this.getBankBranchdetais();
      },
      () => {
        this.onError();
      }
    );
    if ($event.type === 'select') {
      this.readonly = true;
      this.edit = true;
    } else {
      this.isDelete = true;
      this.readonly = true;
    }
  }
  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    this.villageLevelDto = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  deleteVendor() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete this ' +
        this.commercialVendor.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.commercialVendorservice.delete(this.commercialVendor.id).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess('Vendor Deleted Sucessfully','');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }
}
