import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { CompFund, VoucherStatusReportDto } from '../report.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';
import { SigninService } from 'src/app/auth/signin/signin.service';
import * as XLSX from 'xlsx';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { DatePipe } from '@angular/common';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { ComponentFund } from '../report.model';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-accountwise-fund-report',
  templateUrl: './accountwise-fund-report.component.html',
  styleUrls: ['./accountwise-fund-report.component.scss']
})

export class AccountwiseFundReportComponent implements OnInit {
componentFund:ComponentFund;
  fundListDialog: boolean;
  compFundDto:CompFund;
  compFundBlockDto:CompFund;
  compFundVillageDto:CompFund;
  activeIndex=0;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;

  personalFlag: string;
  villageLevelMasterId: any;
  isBMRole: boolean;
  id: any;
  subEmployeeTypeId: any;
  post: any;
  role: string;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;
  dateError: boolean;
  compMasterId: any;
  componentMasterName: any;

  constructor(private reportService:ReportService,
    public fontService:FontService,
    private spinner: NgxSpinnerService,
    private loginService:SigninService,
    private paymentService:PaymentService,
    private componentTypeService:ComponentTypeService,
    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.reportService.getComponentFundsAmount().subscribe(
      (res)=>{
        this.componentFund=res.body;
    },
    (error)=>{});

    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
      },
        ()=>{});
  }

  loadFundDialog(id,name){
    this.fundListDialog=true;
    this.compMasterId=id;
    this.componentMasterName=name;
    this.loadDistrict();

  }

  hideDialog(){
    this.fundListDialog=false;
  }

  exportToExcel(id,filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }

 

  onTabChange(event: any): void {
    if (event.index === 0 && (this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' )) {
      this.compFundBlockDto=null;
      this.compFundVillageDto=null;
    }
    else if(event.index === 0 && (this.roleCode=='DM'|| this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD')) {
      this.compFundVillageDto=null;
    }
  }

  villageLimitExcel(){
    this.spinner.show();
    this.reportService.villageLimitDetailsReport().subscribe(
      (response)=>{
        this.spinner.hide();
        this.saveBlobToExcel(response.body,'village Limit Excel');
      },
      ()=>{
        this.spinner.hide();
      }
    )
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
      }

  loadDistrict(){ 
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
    this.startDate=this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
      componentMasterId: this.compMasterId,
      fromDate: this.startDate,
      toDate: this.endDate,
      levelMasterId:1
    };
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
     {
      this.reportService.getCompFundByDistrict(payload).subscribe(
        (res: HttpResponse<CompFund>) => {
          this.compFundDto=res.body;
        //   this.voucherStatusReportDto.voucherStatusReports.forEach((element)=>{
        //     for (const key in element) {
        //       if (element.hasOwnProperty(key) && element[key] === null) {
        //         element[key] = 0;
        //       }
        //   }
        // })
          this.spinner.hide();
        },
        (err)=>{
          this.spinner.hide();
        })
      }
      else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
        this.loadBlock(this.levelMasterId);
        this.isDMRole=true;
      } else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
        this.loadVillage(this.levelMasterId);
        this.isBMRole=true;
      }
  }

  loadBlock(id){
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
      this.startDate=this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
      this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
      componentMasterId: this.compMasterId,
      levelMasterId: id,
      fromDate: this.startDate,
      toDate: this.endDate
    };
    this.reportService.getCompFundByBlock(payload).subscribe(
      (res: HttpResponse<CompFund>) => {
        this.compFundBlockDto=res.body;
      //   this.voucherStatusReportBlockDto.voucherStatusReports.forEach((element)=>{
      //     for (const key in element) {
      //       if (element.hasOwnProperty(key) && element[key] === null) {
      //         element[key] = 0;
      //       }
      //   }
      // })
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=1;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
          this.activeIndex=0;
        }
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  loadVillage(id){
    this.spinner.show();const payload: any = {
      componentMasterId: this.compMasterId,
      levelMasterId: id,
      fromDate: this.startDate,
      toDate: this.endDate
    };
    this.reportService.getCompFundByVillage(payload).subscribe(
      (res: HttpResponse<CompFund>) => {
      //   res.body.voucherStatusReports.forEach((element)=>{
      //     for (const key in element) {
      //       if (element.hasOwnProperty(key) && (element[key] === null || element[key] === 0)) {
      //         element[key] = "0";
      //       }
      //   }
      // })
      this.compFundVillageDto=res.body;
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=2;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
          this.activeIndex=1;
        } else if(this.roleCode=='BM' || this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
          this.activeIndex=0;
        } 
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }

  export(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' ) {
      if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseFundReport.xlsx');
      } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseFundReport.xlsx');
      } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseFundReport.xlsx');
      }
    }
    else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){ 
      if (this.activeIndex === 0) {
        this.exportToExcel('block-table', 'BlockWiseFundReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('village-table', 'VillageWiseFundReport.xlsx');
      }
    }
    else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){ 
        this.exportToExcel('village-table', 'VillageWiseFundReport.xlsx');
    }
  }

  onSearch(){
    this.activeIndex=0;
      if(this.fromDate && this.toDate)
      {
    this.startDate=this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    if (this.startDate > this.endDate) {
      this.dateError = true;
      return
    }
      this.dateError = false;
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
      this.compFundBlockDto=null;
      this.compFundVillageDto=null;
      this.loadDistrict();
     } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
      this.compFundVillageDto=null;
      this.loadBlock(this.levelMasterId);
     }  else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
      this.loadVillage(this.levelMasterId);
     }
  }

}
