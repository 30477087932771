import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConsumerDemand, TangedcoBill } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details';
import { TangedcoBillService } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { filterQuery } from 'src/app/shared/common.model';
import * as XLSX from 'xlsx';
import { SigninService } from 'src/app/auth/signin/signin.service';

@Component({
  selector: 'app-tneb-report',
  templateUrl: './tneb-report.component.html',
  styleUrls: ['./tneb-report.component.scss']
})
export class TnebReportComponent implements OnInit {
  activeIndex=0;
  tnebsummary: any;
  tnebsummaryBlock: any;
  tnebsummaryVillage: any;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any=[];
  searchResult: SearchResult<ConsumerDemand> =
  new SearchResult<ConsumerDemand>();
  villageId: any;
  cols: any;
  enableStatusVoucher: boolean = false;
  cosumerDemandId: any;
  tangedcosearchResult: SearchResult<TangedcoBill> =
  new SearchResult<TangedcoBill>();
  vouchercols:any;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  
  constructor(
    protected reportService:ReportService,
    public fontService:FontService,
    private tangedcoBillService: TangedcoBillService,
    private loginService:SigninService,
  ) { }

  ngOnInit(): void {
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
       this.loadDistrict();
      },
      
      (error)=>{});
    this.cols = [
      {
        field: 'consumerNo',
        header: 'Consumer Number',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'consumerName',
        header: 'Consumer Name',
        //jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true
        //type: 'date',
      },
      {
        field: 'billCycle',
        header: 'Bill Cycle',
        //jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true
        //type:'amount'
      },
      {
        field: 'billAmount',
        header: 'Bill Amount',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
        type: 'amount'
      }
    ];
   
  }

  loadDistrict() {
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' || this.roleCode == 'TNA') {
      const payload: any = {
        levelType: 'STATE',
        levelMasterId: 1
      };
      this.reportService.getTangedcoDemandReport(payload).subscribe(
        (res) => {
          console.log('res.body',res.body);
          this.tnebsummary = res.body;
        },
        (err)=>{
         
        }
      )
    }
    else {
      this.loadBlock(this.levelMasterId);
      this.isDMRole=true;
    }
   
    
  }

  loadBlock(districtId) {
    const payload: any = {
      levelType: 'DISTRICT',
      levelMasterId: districtId
    };
    this.reportService.getTangedcoDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body',res.body);
        this.tnebsummaryBlock = res.body;
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' || this.roleCode == 'TNA'){
          this.activeIndex=1;
        } else {
          this.activeIndex=0;
        }
        
      },
      (err)=>{
       
      }
    )
  }

  loadVillage(blockId) {
    const payload: any = {
      levelType: 'BLOCK',
      levelMasterId: blockId
    };
    this.reportService.getTangedcoDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body',res.body);
        this.tnebsummaryVillage = res.body;
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' || this.roleCode == 'TNA'){
          this.activeIndex=2;
        } else{
          this.activeIndex=1;
        }
      },
      (err)=>{
       
      }
    )
  }

  loadPage(villageid,page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.villageId = villageid;
    

    
	let query = new filterQuery();
	query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
	this.filterProps.push(query);
  query = new filterQuery();
	query.key = 'levelMasterId';
    query.operation = 'equals';
    query.value = this.villageId;
	this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.tangedcoBillService.validConsumerfilter({ ...filter }, this.filterProps).subscribe(
      (res: HttpResponse<ConsumerDemand[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: ConsumerDemand[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' || this.roleCode === 'TNA'){
      this.activeIndex=3;
    } else{
      this.activeIndex=2;
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}
  onLazyLoad(event) {
    if(this.activeIndex === 3) {
      this.filterProps = [];
      this.filterProps = event.filterDefinitions;
      this.itemsPerPage = event.size;
      this.loadPage(this.villageId,event.page);
    }
    
  }


  onTabChange($event){
    this.filterProps = [];
  }

  getSubTableDetails($event) {
    this.cosumerDemandId = $event;
    this.vouchercols = [
      {
        field: 'consumerNo',
        header: 'Consumer Number',
        isFilterable: false,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.description',
        //type:'dropDown'

      },
      {
        field: 'billMonth',
        header: 'Bill Month',
        isFilterable: false,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.quantity',

      },
      {
        field: 'billYear',
        header: 'Bill Year',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.amount',
        //type:'amount'
      },
      {
        field: 'billAmount',
        header: 'Bill Amount',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.grossTotal',
        type:'amount'
      },
      {
        field: 'dueDate',
        header: 'Due Date',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.grossTotal',
        type:'date'
      },

    ];
    console.log('nwejkbfewjkfwn',$event);
    this.loadTangedcoPage(1);
  }

  loadTangedcoPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    this.enableStatusVoucher = true;
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    query.key = 'consumerDemandId';
    query.operation = 'equals';
    query.value = this.cosumerDemandId;
    let filterProps = [];
    filterProps.push(query);
	// query = new filterQuery();
	// query.key = 'activeFlag';
  //   query.operation = 'equals';
  //   query.value = 'YES';
	// this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.tangedcoBillService.vaildFilter({ ...filter }, filterProps).subscribe(
      (res: HttpResponse<TangedcoBill[]>) => {
        this.onTangedcoSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );

}

  protected onTangedcoSuccess(
    data: TangedcoBill[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.tangedcosearchResult.items = data ?? [];
    this.tangedcosearchResult = { ...this.tangedcosearchResult };
  }

  hideDialog(){
    this.filterProps = [];
    this.enableStatusVoucher = false;
  }

  export(){
    
      if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseTangedcobillReport.xlsx');
      } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseTangedcobillReport.xlsx');
      } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseTangedcobillReport.xlsx');
      }
      else if(this.activeIndex === 3) {
        this.exportToExcel('consumer-table','consumerDemandReport.xlsx')
      }
    
  
  }

  exportToExcel(id,filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }
  

}
