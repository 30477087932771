<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.bulkpayment"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row pb-2">
        <div class="col">
            <!-- <p-radioButton name="levelType" value="District" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType1"></p-radioButton>
            <label for="levelType1" class="label-margin">District</label>
            <p-radioButton name="levelType" value="Block" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType2"></p-radioButton>
            <label for="levelType2" class="label-margin">Block</label> -->
            <p-radioButton name="levelType" value="Village" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType3"></p-radioButton>
            <label for="levelType3" class="label-margin">Village</label>
        </div>
    </div>
    <div class="input-row">
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate"
         [showIcon]="true" styleClass="datepicker"
        [maxDate]="maxDate"></p-calendar>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate"
         [showIcon]="true" styleClass="datepicker"
        [maxDate]="maxDate"></p-calendar>
        <p-dropdown placeholder="Select Component" [options]="compTypeList" [(ngModel)]="selectedcompType" 
        optionLabel="name" showClear="true"></p-dropdown>
        <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" 
        optionLabel="name" optionValue="value"></p-dropdown>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Export to Excel" (click)="export()"  class="p-button-success p-button-sm export-button"></button>
    </div>
    <div class="row">
        <div *ngIf="dateError" class="text-danger mb-2">
            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                choose valid from and to
                date</small>
        </div>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="!isDMRole">
                <div class="col-12 px-4" *ngIf="paymentReportDto">
                    <p-table
                    id="district-table"
                    [value]="paymentReportDto.paymentReportDtos"
                    [resizableColumns]="true"
                    responsiveLayout="scroll"
                    styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                    <tr>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                            <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                          </th>
                        <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Bulk Employee</th>
                        <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Bulk Elected Representative</th>
                    </tr>
                    <tr>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeCount">No of voucher</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeAmount">Amount</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                    </tr>
                    
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                    <tr>
                        <td style="text-align: center;">{{index+1}}</td>
                        <td style="text-align: center;"><p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button></td>
                        <td style="text-align: center;">{{list.employeeCount}}</td>
                        <td style="text-align: center;">{{list.employeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                        <td style="text-align: center;">{{list.electedRepCount}}</td>
                        <td style="text-align: center;">{{list.electedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="2" style="text-align: center;">Total</td>
                            <td style="text-align: center;">{{ paymentReportDto.employeeTotalCount }}</td>
                            <td style="text-align: center;">{{ paymentReportDto.employeeTotalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{ paymentReportDto.electedRepTotalCount }}</td>
                            <td style="text-align: center;">{{ paymentReportDto.electedRepTotalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>
                    </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Block"  *ngIf="levelType!=='District'">
                <div *ngIf="!paymentBlockReportDto">Choose distict to get block report</div>
                <div *ngIf="paymentBlockReportDto" class="col-12 px-4">
                    <p-table 
                    id="block-table"
                    [value]="paymentBlockReportDto.paymentReportDtos"
                    [resizableColumns]="true"
                    responsiveLayout="scroll"
                    styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                    <tr>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                            <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                          </th>
                        <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Bulk Employee</th>
                        <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Bulk Elected Representative</th>
                    </tr>
                    <tr>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeCount">No of voucher</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeAmount">Amount</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                    <tr>
                        <td style="text-align: center;">{{index+1}}</td>
                        <td style="text-align: center;"><p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button></td>
                        <td style="text-align: center;">{{list.employeeCount}}</td>
                        <td style="text-align: center;">{{list.employeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                        <td style="text-align: center;">{{list.electedRepCount}}</td>
                        <td style="text-align: center;">{{list.electedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="2" style="text-align: center;">Total</td>
                            <td style="text-align: center;">{{ paymentBlockReportDto.employeeTotalCount }}</td>
                            <td style="text-align: center;">{{ paymentBlockReportDto.employeeTotalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{ paymentBlockReportDto.electedRepTotalCount }}</td>
                            <td style="text-align: center;">{{ paymentBlockReportDto.electedRepTotalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>
                    </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village" *ngIf="levelType==='Village'">
                <div *ngIf="!paymentVillageReportDto">Choose block to get village report</div>
                <div *ngIf="paymentVillageReportDto" class="col-12 px-4">
                    <p-table
                    id="village-table"
                    [value]="paymentVillageReportDto.paymentReportDtos"
                    [resizableColumns]="true"
                    responsiveLayout="scroll"
                    styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                    <tr>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                        <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                            <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                          </th>
                        <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Bulk Employee</th>
                        <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Bulk Elected Representative</th>
                    </tr>
                    <tr>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeCount">No of voucher</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeAmount">Amount</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                    <tr>
                        <td style="text-align: center;">{{index+1}}</td>
                        <td style="text-align: center;">{{list.levelMasterName}}</td>
                        <td style="text-align: center;"><p-button [label]="list.employeeCount" (click)="loadVoucher(list.levelMasterId,'BE')" styleClass="p-button-link"></p-button></td>
                        <td style="text-align: center;">{{list.employeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                        <td style="text-align: center;"><p-button [label]="list.electedRepCount" (click)="loadVoucher(list.levelMasterId,'BER')" styleClass="p-button-link"></p-button></td>
                        <td style="text-align: center;">{{list.electedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="2" style="text-align: center;">Total</td>
                            <td style="text-align: center;">{{ paymentVillageReportDto.employeeTotalCount }}</td>
                            <td style="text-align: center;">{{ paymentVillageReportDto.employeeTotalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{ paymentVillageReportDto.electedRepTotalCount }}</td>
                            <td style="text-align: center;">{{ paymentVillageReportDto.electedRepTotalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>
                    </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000"
styleClass="ib-dialog" (onHide)="hideDialog()">
<p-header [ngClass]="fontService.headingClass">
    {{ "dashBoard.transactionlist" | translate }}
</p-header>
<p-tabView [(activeIndex)]="activeBulkIndex">
    <p-tabPanel header="Bulk Transaction List">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" 
        [showRedirectButton]="false" [showRightButton]=true (emitRow)="onSelectRow($event)" [lazyLoadOnInit]="false"></custom-filter-table>
    </p-tabPanel>
    <p-tabPanel header="Bulk Sub List">
        <div class="row">
            <div #customerTable id="entities" class="col-12 px-4">
                <custom-filter-table [columnsValues]="bulkListCols" [filterSearchResult]="searchBulkResult" 
                [paginator]="false"></custom-filter-table>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>

</p-dialog>
