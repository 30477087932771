import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<LevelType[]>;
export type EntityResponseType = HttpResponse<LevelType>;

import { LevelType } from './level-type';

@Injectable()
export class LevelTypeService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/levelTypeList');
    protected LevelTypeUrl = environment.SERVER_API_URL+('api/getLevelTypeByFilter')
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveLevelType');
    protected resourceEditUrl = environment.SERVER_API_URL+('api/getLevelType')
    
    // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    constructor(protected http: HttpClient) {}
    filter(): Observable<EntityArrayResponseType> {
      
        return this.http
        .get<LevelType[]>(this.resourceFliterUrl,{observe: 'response' })
    }

    getbyId(id:number): Observable<any> {
      return this.http
      .get<LevelType[]>(`${this.resourceEditUrl}?id=${id}`,{observe: 'response' })
    }

    levelTypefilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
        if(filter === null){
          filter = []
        }
        const options = createRequestOption(req);
          return this.http
          .post<LevelType[]>(this.LevelTypeUrl,filter, { params:options, observe: 'response' })
      }
    save(LevelType?:LevelType): Observable<EntityResponseType> {
        return this.http
        .post<LevelType>(this.resourceSaveUrl,LevelType, {observe: 'response' })
    }
    // delete(id: number): Observable<HttpResponse<{}>> {
    //   return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    // }


}