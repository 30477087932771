<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.user.zonal">Zonal List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()"
                        styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
                    </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult"
                (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true"
                [disableEditIcon]="true"></custom-filter-table>

        </div>
    </div>
    <p-dialog [(visible)]="zonalDialog" [style]="{width: '800px'}" [modal]="true"
        [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            {{"agency.zonal.add"|translate}}
          </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="department"  [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.department.name">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="departmentDto" [items]="departmentList"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control"
                                [clearable]="true">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.scheme.name">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="schemeDto" [items]="schemeList" bindLabel="name"
                                appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                (change)="onchangeSchema()" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="departCode" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.levelType.name">Level Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelTypeDto" [items]="levelType" bindLabel="name"
                                appearance="outline" (change)="onLevelType()" [closeOnSelect]="true"
                                [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.state.name">State</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="stateListDto" [items]="stateList" bindLabel="name"
                                appearance="outline" (change)="onStateLevelChange()" [closeOnSelect]="true"
                                [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.district.name">District</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList"
                                bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()"
                                (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true"
                                class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelTypeDto">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.block.name">Block</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="BlockLevelDto" [items]="this.districtLevelList"
                                bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)"
                                [closeOnSelect]="true" [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.village.name">Village</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="villageList" [multiple]="true" [items]="blockLevelList"
                                bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)"
                                [closeOnSelect]="false" [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4 d-flex flex-column">
                            <label class="form-control-label" for="levelType" [ngClass]="fontService.labelClass"
                            jhiTranslate="zonal.zonal.name">Zonal Name</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="selectedZonalName"  [items]="zonalList"
                                bindLabel="name" appearance="outline" 
                                [closeOnSelect]="true" [clearable]="true" class="form-control">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button"
                (click)="hideDialog()">
                <span
                class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
            </button>
            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" [ngClass]="fontService.buttonClass"
          jhiTranslate="common.savebutton"></span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>