import {
  DescriptionDto,
  PersonalDescDto,
  SwapTransactionDto,
  SwapSubTableDto,
  SwappingTransactionDto,
  SwapIntermediateSubTableDto
} from './../payment-voucher/payment-voucher.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavbarComponent } from './../../layout/navbar/navbar.component';
import { filterQuery } from './../../shared/common.model';
import { ComponentType } from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { Vendor } from 'src/app/module/commercial-vendors/commercial-vendors';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { PersonalVendorService } from 'src/app/module/personal-vendors/personal-vendors.service';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserService } from 'src/app/module/user/user.service';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { AgencyDto } from 'src/app/module/agency/agency';
import { LocalStorageService,SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-swap-page',
  templateUrl: './payment-swap-page.component.html',
  styleUrls: ['./payment-swap-page.component.scss']
})
export class PaymentSwapPageComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;

  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  levelTypeDto: any;
  villageLevelList: LevelMasterDto[];
  villageLevelDto: any;
  voucherGenerationDto: SwappingTransactionDto;
  swapIntermediateSubTableDto:SwapIntermediateSubTableDto;
  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  totalDeduction: number;
  levelMasId: any;
  implementingAgencyDetails:AgencyDto;
  levelMasterName:string;
  // validation fields properties
  public ifscError = false;
  paramId:number;

  //required fields property
  ngSelectInvalid: boolean = false;
  dropDownLabel: string;
  enableNhis: boolean;
  enableCps: boolean;
  enableOtherDeduction: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  description: string;
  amount: number;
  quantity: number;
  signButtonName: string;
  downloadButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean;
  buttonText: string;
  interactionId: any;
  checkstatusButton: boolean;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser:any
  enableZonalButton: boolean;
  enableApproved: boolean;
  schemeList: SchemeList[];
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  grantLevelDto:ComponentType;
  grantLevelList:any;
  componentMasterDto: number;
  componentMasterName: string;
  levelMasterDto: LevelType;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: Vendor[];
  selectedscheme: any;
  descriptionList: DescriptionDto[];
  searchResult: SearchResult<SwapSubTableDto> =
    new SearchResult<SwapSubTableDto>();
    swapSubTableDto:SwapSubTableDto[];
    swapIntermediateSubTablelist:SwapIntermediateSubTableDto[];
  noRecord:boolean; 
  deleteDialog: boolean;
  finYear: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private levelMasterService: LevelMasterService,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private personalVendorService: PersonalVendorService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private sessionStorageService: SessionStorageService
  ) {
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.implementingAgencyDetails.leveltype
        )
        .subscribe(
          (res: HttpResponse<ComponentType>) => {
            this.grantLevel = res.body;
          },
          () => {}
        );
      this.componentTypeService.findbyMasterId(this.grantlevel.id).subscribe(
        (res: HttpResponse<ComponentType>) => {
          this.majaorComponent = res.body;
        },
        () => {}
      );
    });
  }

  
  ngOnInit(): void {
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.levelMasId = res.body.levelMasterId;
        this.levelMasterService.getLevelMaster(this.levelMasId).subscribe(
          (res) => {
            this.levelMasterName=res.body.name;
          },
          () => {
            this.onError();
          }
        );
        this.paymentService
          .getByImplemeting(res.body.implementingAgencyId)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.implementingAgencyDetails =
                res.body;
              this.onLevelType();
              this.levelTypeService
                .getbyId(
                  this.implementingAgencyDetails.leveltype
                )
                .subscribe(
                  (res: HttpResponse<LevelType>) => {
                    this.levelMasterDto = res.body;
                  },
                  () => {}
                );
            },
            () => {}
          );
        this.roleCode = res.body;
        if (
          res.body.role.code === 'DMAKER' ||
          res.body.role.code === 'VMAKER' ||
          res.body.role.code === 'BMAKER'
        ) {
          if(res.body.role.code === 'BMAKER'){
            this.paymentService
                .getZonalCheckers(
                 res.body.levelMasterId
                )
                .subscribe(
                  (res: HttpResponse<LevelType>) => {
                    this.zonalList = res.body;
                  },
                  () => {}
                );
              }
          this.signButtonName = 'Perform e-Sign Secretary';
        } else if (
          res.body.role.code === 'DC1' ||
          res.body.role.code === 'BC1' ||
          res.body.role.code === 'VC1' ||
          res.body.role.code === 'ZC1'
        ) {
          this.enableApproved=true;
          if(res.body.role.code === 'BC1' || res.body.role.code === 'ZC1'){
            this.checkAmount = true
          }
          this.signButtonName = 'Perform e-Sign VP';
          this.enableRevertButton = true;
          this.readonly = true;
        } else if (
          res.body.role.code === 'DC2' ||
          res.body.role.code === 'BC2' ||
          res.body.role.code === 'VC2'
        ) {
          this.enableApproved=true;
          
          this.signButtonName = 'Perform e-Sign President';
          this.enableRevertButton = true;
          this.downloadButton = true;
          this.readonly = true;
        }
        if(
          res.body.role.code === 'BMAKER' || 
          res.body.role.code === 'BC1' ||
          res.body.role.code === 'BC2' ||
          res.body.role.code === 'ZC1'
        ) {
          this.enableZonalButton = true;
        }
      },
      (onError) => {}
    );
    
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
        this.paramId=Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
      this.paymentService
        .updatedSwapVocher(this.paramId)
        .subscribe(
          (res: HttpResponse<SwappingTransactionDto>) => {
            this.spinner.show();
            this.voucherGenerationDto = res.body;
            this.spinner.hide();
            if(this.checkAmount && res.body.swapTransactionDto.transactionAmount <= 500000 && res.body.swapTransactionDto.signatureVicePresident){
              res.body.swapTransactionDto.signaturePresident = "signed"
            }
            if(this.voucherGenerationDto.swapTransactionDto.signaturePresident){
              this.paymentSucessDialog = true;
              this.paymentSucessText = "Swapping Successfully Completed. You can download the pdf";
          }
          if (this.voucherGenerationDto.swapTransactionDto.signStatus) {
            this.readonly = true;
            this.getfile();
          }
            this.date = new Date(
              this.voucherGenerationDto.swapTransactionDto.transDate
            );       
             
            this.levelMasterDto =
              this.voucherGenerationDto.swapTransactionDto.iaComponentLimitDto.levelTypeDto;
           
              this.componentTypeService.findbyId(
                this.voucherGenerationDto.swapTransactionDto.componentType
              )
              .subscribe(
                (res: HttpResponse<ComponentType>) => {
                  this.grantlevel = res.body;
                  this.levelTypeService
              .getbyId(
                this.implementingAgencyDetails?.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;
                  this.onLevelType();
                  this.ongrandLevel('true');
                  this.onMajorComponent('true');
                },
                () => {}
                
              );
                },
                () => {}
              );
            

              this.paymentService
              .getComponentMaster(
                this.voucherGenerationDto.swapTransactionDto.majorComponentId
              )
              .subscribe(
                (res: HttpResponse<any>) => {
                  this.majaorComponentdto = res.body;
                  
                },
                () => {}
              );
              
      this.spinner.hide();
        });
        ()=>{
          this.spinner.hide();
        }
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new SwappingTransactionDto();
      this.voucherGenerationDto.swapTransactionDto =
        new SwapTransactionDto();
      this.voucherGenerationDto.swapTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
    }
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
   
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => {}
    );
  }

  getActionUrl(){
    this.sessionStorageService.store('refresh', 'Yes');   
    return environment.CDAC_URL;
  }

  onLevelType() {
    this.componentTypeService
      .find(
        this.implementingAgencyDetails
          .leveltype
      )
      .subscribe(
        (res: HttpResponse<ComponentType>) => {
          this.grantLevel = res.body;
        },
        () => {}
      );
  }
  ongrandLevel(edit?:any) {
    if(!edit){
      this.majaorComponentdto = null;
    }
    
    this.componentTypeService.findbyMasterId(this.grantlevel.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.majaorComponent = res.body;
      },
      () => {}
    );
    this.paymentService
      .getLimit(
        this.implementingAgencyDetails.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.swapTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => {}
      );
  }
  onMajorComponent(edit?:any) {
   
      this.popupValue();
  }
  onUpload($event) {
    const selectedFile: File = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);
    };
    const formData = new FormData();
    const fileExtension = $event.name.split('.').pop();
    const fileData = new Blob([$event], { type: $event.type });
    formData.append('file', fileData, `file.${fileExtension}`);
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {},
      () => {}
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  popupValue() {
         if (this.activatedRoute.snapshot.queryParamMap.get('id')) 
      {
        this.swapIntermediateSubTablelist = [];
        this.voucherGenerationDto.swapSubTableDto.forEach((element,i) => {
        let voucherList = new SwapIntermediateSubTableDto();
        voucherList.limitAmount = element.limitAmount;
        this.swapIntermediateSubTablelist.push(voucherList);
      }
        );
        this.voucherGenerationDto.swapSubTableDto.forEach((element,i) => {
            this.swapIntermediateSubTablelist[i].id=element.id;      
            this.swapIntermediateSubTablelist[i].levelTypeDto = element.levelName;
            this.swapIntermediateSubTablelist[i].levelType = ['Block','Village'];
        if(element.levelName=='Block'){
        this.levelMasterService.getLevelMaster(element.levelMasterId).subscribe(
          (res) => {
            this.swapIntermediateSubTablelist[i].BlockLevelDto=res.body;
          },
          () => {
            this.onError();
          }
        );
        }
        if(element.levelName=='Village'){
          this.levelMasterService.getLevelMaster(element.levelMasterId).subscribe(
            (res) => {
              this.swapIntermediateSubTablelist[i].villageLevelDto=res.body;
              this.levelMasterService.getLevelMaster(res.body.parentId).subscribe(
                (res) => {
                  this.swapIntermediateSubTablelist[i].BlockLevelDto=res.body;
                },
                () => {
                  this.onError();
                }
              );
            },
            () => {
              this.onError();
            }
          );
         
          }
        this.componentTypeService.findbyId(element.componentMaster)
        .subscribe(
          (res: HttpResponse<ComponentType>) => {
            this.swapIntermediateSubTablelist[i].grantLevelDto = res.body;
          },
          () => {}
        );
        this.swapIntermediateSubTablelist[i].limitAmount=element.limitAmount;
  },
  () => {}
  );
  this.calculateTotalAmount();

      }
      else{
      this.swapIntermediateSubTablelist = [];
    let voucherList = new SwapIntermediateSubTableDto();
    voucherList.levelType = ['Block','Village'];
    this.swapIntermediateSubTablelist.push(voucherList);
      }
    
  }
  addRow() {
    this.swapIntermediateSubTablelist = this.swapIntermediateSubTablelist
    ? this.swapIntermediateSubTablelist: [];
    let voucherList = new SwapIntermediateSubTableDto();
    voucherList.levelType = ['Block','Village'];
    this.swapIntermediateSubTablelist.push(voucherList);  
    
  }
  calculatetotal(amt: SwapIntermediateSubTableDto) {
    
    if (amt.limitAmount === null) {
      amt.limitAmount = 0;
    }
    this.amount = amt.limitAmount;
    this.calculateTotalAmount();
  }
  delete(index: number) {
    this.swapIntermediateSubTablelist.splice(index, 1);
    if (this.swapIntermediateSubTablelist.length === 0) {
      this.voucherGenerationDto.swapTransactionDto.transactionAmount = null;
    } else {
      this.calculateTotalAmount();
    }
  }

  
  onStateLevelChange(i) {
    this.swapIntermediateSubTablelist[i].BlockLevelDto=null;
    this.swapIntermediateSubTablelist[i].villageLevelDto=null;
    this.swapIntermediateSubTablelist[i].grantLevelDto=null;
   
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.swapIntermediateSubTablelist[i].blockLevelList=res.body;
            if(this.swapIntermediateSubTablelist[i].levelTypeDto=='Block')
            {
            this.componentTypeService.find(this.swapIntermediateSubTablelist[0].blockLevelList[0].levelType.id).subscribe(
              (res: HttpResponse<ComponentType>) => {
                this.swapIntermediateSubTablelist[i].grantLevelList = res.body;  
              },
              () => {}
            );
          }
          () => {}
          }
        );       
  }
  
  onBlockLevelChange(i) {
    this.swapIntermediateSubTablelist[i].villageLevelDto=null;
    this.swapIntermediateSubTablelist[i].grantLevelDto=null;
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.swapIntermediateSubTablelist[i].BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.swapIntermediateSubTablelist[i].villageLevelList = res.body;
            if(this.swapIntermediateSubTablelist[i].levelTypeDto=='Village')
            {
            this.componentTypeService.find(this.swapIntermediateSubTablelist[0].villageLevelList[0].levelType.id).subscribe(
              (res: HttpResponse<ComponentType>) => {
                this.swapIntermediateSubTablelist[i].grantLevelList = res.body;  
              },
              () => {}
            );
          }
          () => {}
          });
          
          () => {}
  }
    
  onVillageLevelChange() {
    
  }
  loadGrantLevel() {
    this.componentTypeService.find(this.levelTypeDto.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.grantlevel = res.body[0];
        this.grantLevel = res.body;
        this.ongrandLevel();
      },
      () => {
        this.onError();
      }
    );
  }
  protected onError(): void {
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.levelMasterDto &&
      this.grantlevel &&
      this.majaorComponentdto &&
      this.voucherGenerationDto.swapTransactionDto
        .panchayatResolutionNumber &&
      this.voucherGenerationDto.swapTransactionDto.remark &&
      this.voucherGenerationDto.swapTransactionDto &&
      this.voucherGenerationDto.swapTransactionDto.transactionAmount

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.swapIntermediateSubTablelist.forEach((element)=>{
    const isVal=element.grantLevelDto && element.BlockLevelDto && element.levelTypeDto 
    && ((element.levelTypeDto!=='Block' && element.villageLevelDto) || (element.levelTypeDto==='Block'))
    && element.limitAmount
    if (!isVal) {
      this.ngSelectInvalid = true;     
    }
    });
    if(this.ngSelectInvalid){
      return;
    }
    
    this.ngSelectInvalid = false;
    this.spinner.show();
    
    if (this.activatedRoute.snapshot.queryParamMap.get('id') && this.roleCode.role.code === 'DMAKER') 
    {
    this.voucherGenerationDto.swapSubTableDto.splice(0,this.voucherGenerationDto.swapSubTableDto.length-1);
    }
    if (this.roleCode.role.code === 'DMAKER'){
    this.voucherGenerationDto.swapSubTableDto=[];
    this.swapIntermediateSubTablelist.forEach((element)=>
    {
    let voucherList = new SwapSubTableDto();
    voucherList.id=element.id;
    voucherList.limitAmount = element.limitAmount;
    this.voucherGenerationDto.swapSubTableDto.push(voucherList);
  });

   this.swapIntermediateSubTablelist.forEach((element,i) => {
    this.voucherGenerationDto.swapSubTableDto[i].levelMasterId= element.villageLevelDto?.id?element.villageLevelDto?.id:element.BlockLevelDto?.id;
    this.voucherGenerationDto.swapSubTableDto[i].levelMasterName=element.villageLevelDto?.name?element.villageLevelDto?.name:element.BlockLevelDto?.name;
    this.voucherGenerationDto.swapSubTableDto[i].componentMaster=element.grantLevelDto?.id;
    this.voucherGenerationDto.swapSubTableDto[i].componentMasterName=element.grantLevelDto?.name;
    if(element.villageLevelDto){
      this.voucherGenerationDto.swapSubTableDto[i].levelId=element.villageLevelDto.levelTypeDto?element.villageLevelDto.levelTypeDto.id:element.villageLevelDto.levelType.id;
    }
    else{
      this.voucherGenerationDto.swapSubTableDto[i].levelId=element.BlockLevelDto.levelTypeDto?element.BlockLevelDto.levelTypeDto.id:element.BlockLevelDto.levelType.id;
    }
    // this.voucherGenerationDto.swapSubTableDto[i].levelId=element.villageLevelDto?element.villageLevelDto.levelTypeDto.id:element.BlockLevelDto.levelTypeDto.id;
    this.voucherGenerationDto.swapSubTableDto[i].levelName=element.levelTypeDto;
    this.voucherGenerationDto.swapSubTableDto[i].limitAmount=element.limitAmount;
    this.voucherGenerationDto.swapSubTableDto[i].voucherNumber=this.voucherGenerationDto.swapTransactionDto.voucherNo;
    this.voucherGenerationDto.swapSubTableDto[i].implementingAgency=null;
    this.voucherGenerationDto.swapSubTableDto[i].swapTransactionId=null;
  });
}
    this.voucherGenerationDto.swapTransactionDto.componentType =
      this.grantlevel.id;
      this.voucherGenerationDto.swapTransactionDto.fundGrant=this.grantlevel.name;
    this.voucherGenerationDto.swapTransactionDto.implementingAgency=this.implementingAgencyDetails.id;
    this.voucherGenerationDto.swapTransactionDto.implementingAgencyName=this.implementingAgencyDetails.name;
    this.voucherGenerationDto.swapTransactionDto.majorComponentId =
      this.majaorComponentdto.id;
    this.voucherGenerationDto.swapTransactionDto.majorComponentName =
      this.majaorComponentdto.name;
      this.voucherGenerationDto.swapTransactionDto.levelId=this.levelMasterDto.id;
      this.voucherGenerationDto.swapTransactionDto.levelMasterId=this.levelMasId;
          this.voucherGenerationDto.swapTransactionDto.levelMasterName=this.levelMasterName;
      this.voucherGenerationDto.swapTransactionDto.department=this.implementingAgencyDetails.departmentDto.id;
      this.voucherGenerationDto.swapTransactionDto.scheme=this.implementingAgencyDetails.schemeDto.id;
      this.voucherGenerationDto.swapTransactionDto.schemeName=this.implementingAgencyDetails.schemeDto.name;

    this.paymentService.genSwapVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        this.readonly = true;
        this.spinner.hide();
        this.getfile();
        this.notificationService.alertSuccess('Saved Successfully', '');
      },

      (error) => {
        this.spinner.hide();
        if (error.status == 400)
        {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher','');

        } else if(error.status == 500) {
          this.notificationService.alertError('An unexpected error occurred, please delete the voucher if generated','');
          this.router.navigate(['/swapTable'], {});
        } else{
          this.notificationService.alertError('An unexpected error occurred','');
        }
      }
    );
  }

  getfile() {
    this.paymentService
      .getSwapfile(this.voucherGenerationDto.swapTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          // add more parameters as needed
        },
        (error) => {
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ){
          this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: '+this.voucherGenerationDto.swapTransactionDto.voucherNo,'');
          this.router.navigate(['/swapTable'], {});
          }
          else {
            this.deleteDialog=true;            
          }
        }
      );
  }

  hideDialog(){
    this.deleteDialog=false;
    this.router.navigate(['/swapTable'], {});
  }

  getPdf() {
    this.paymentService
      .getSwapPdfFile(this.voucherGenerationDto.swapTransactionDto.voucherNo)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
        },
        (err) => {
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertSwapTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/swapTable'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.paymentService
      .deletedSwapVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/swapTable'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  calculateTotalAmount() {
    if (this.swapIntermediateSubTablelist.length !== 0) {
      let intitalTotal = 0;
      this.swapIntermediateSubTablelist.forEach((element) => {
        intitalTotal += Number(element.limitAmount);
      });
      this.voucherGenerationDto.swapTransactionDto.transactionAmount=intitalTotal;
    }
  }
 
  PaymentSucess(){ 
    
  }
}