import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { LimitFormulaGF } from "./limit-formula";
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SearchResult } from "src/app/shared/models/search-result";
import { Router } from '@angular/router';
import { LimitFormulaGFService } from "./limit-formula.service";
import { FontService } from "src/app/shared/font-service/font.service";

@Component({
  selector: 'app-limit-formula',
  templateUrl: './limit-formula.component.html',
  styleUrls: ['./limit-formula.component.scss']
})
export class LimitFormulaComponent implements OnInit {

  constructor(private limitFormulaGFService: LimitFormulaGFService, 
	private messageService: MessageService,
	private confirmationService: ConfirmationService,
	private spinner:NgxSpinnerService,
	public fontService:FontService,
	private router:Router) { }

  limitSetDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<LimitFormulaGF> = new SearchResult<LimitFormulaGF>();

 limitSet?: LimitFormulaGF;

  selectedLimitFormula: LimitFormulaGF[] = [];

  submitted?: boolean;

  selectedMapping: string;
  itemsPerPage: any =10;
  page: number;
  filterProps: any;


 
  ngOnInit() {
   this.loadPage(1)
    this.cols = [
      { field: 'levelType', header: 'Level Type',jhiTranslate: 'tableHead.levelType', width: 13, isSelectcolumn: true ,type:"dropDown",isFilterable: true},
      { field: 'levelMaster', header: 'Level Master',jhiTranslate: 'tableHead.levelMaster', width: 25, isSelectcolumn: true ,type:"dropDown",isFilterable: true},
      { field: 'implementingAgency', header: 'IA Name',jhiTranslate: 'tableHead.iA.name', isSelectcolumn: true ,type:"dropDown",isFilterable: true},
      { field: 'componentType', header: 'IA Components', jhiTranslate: 'tableHead.iACompo.name', isSortable: true, isSelectcolumn: true, type:"dropDown",isFilterable: true},
      { field: 'formula', header: 'Formula',jhiTranslate: 'tableHead.formula', width: 13, isSelectcolumn: true }
    ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
		this.spinner.show()
		const pageToLoad: number = page ?? this.page ?? 1;
	
		let filterQuery = {
		  page: pageToLoad - 1,
		  size: this.itemsPerPage,
		};
    this.filterProps = this.filterProps ?? []
		this.limitFormulaGFService
		  .filter({ ...filterQuery},this.filterProps)
		  .subscribe(
			(res: HttpResponse<LimitFormulaGF[]>) => {
			  this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
			},
			() => {
			  this.onError();
			}
		  );
	  }

	  protected onSuccess(
		data: LimitFormulaGF[]|null,
		headers: HttpHeaders,
		page: number,
		navigate: boolean
	  ): void {
		this.searchResult.total = Number(headers.get('X-Total-Count'));
		this.spinner.hide()
		this.page = page;
		if (navigate) {
		  this.router.navigate(["/limit-formulalist"], {
			queryParams: {
			  page: this.page-1,
			  size: this.itemsPerPage,
			  
			},
		  });
		}
		this.searchResult.items = data ?? [];
		this.searchResult = { ...this.searchResult };
	  }
	
	  protected onError(): void {
	  }

	

	onLazyLoad(event) {
		this.filterProps = event.filterDefinitions
		this.itemsPerPage = event.size;
		this.loadPage(event.page);
	  }

  openNew() {
    this.submitted = false;
    this.limitSetDialog = true;
  }

  

  hideDialog() {
    this.limitSetDialog = false;
    this.submitted = false;
  }

  saveAgency() {
    this.submitted = true;
    this.limitSetDialog = false;

  }

  

 

}
