import { PushNotification } from './push-notification.model';
import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';
import { SigninService } from 'src/app/auth/signin/signin.service';
import * as XLSX from 'xlsx';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { DatePipe } from '@angular/common';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { saveAs } from 'file-saver';
import { PushNotificationService } from './push-notification.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrl: './push-notification.component.scss'
})
export class PushNotificationComponent {
maxDate = new Date();
pushNotification:PushNotification;
  PushNotificationDialog: boolean;
  ngSelectInvalid:boolean;
  searchResult: SearchResult<PushNotification> =
    new SearchResult<PushNotification>();
  cols:any;
  filterProps: any;
  itemsPerPage: number = 10;
  page: number;
  constructor(
    public fontService:FontService,
    private spinner: NgxSpinnerService,
    private pushNotificationService:PushNotificationService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,) { }
ngOnInit(): void {
  this.loadPage(1);
  this.cols = [
    {
      field: 'message',
      header: 'Message',
      isFilterable: true,
      isSelectcolumn: true,
    },
    {
      field: 'date',
      header: 'Date',
      isFilterable: true,
      isSelectcolumn: true,
      type:'date',
    },
    {
      field: 'startTime',
      header: 'Start Time',
      isFilterable: true,
      isSelectcolumn: true,
      type:'dateTime',
    },
    {
      field: 'endTime',
      header: 'End Time',
      isFilterable: true,
      isSelectcolumn: true,
      type:'dateTime',
    },
   
   
  ];
  
}

openNew() {
  this.pushNotification = new PushNotification();
  
  this.PushNotificationDialog = true;
 
}



savePushNotiy() {
  const isFormValid =
  
    this.pushNotification.message &&
    this.pushNotification.date &&
    this.pushNotification.startTime &&
    this.pushNotification.endTime

    
  if (!isFormValid) {
    this.ngSelectInvalid = true;
    return;
  }

 
  this.pushNotificationService.save(this.pushNotification).subscribe(
    (res: HttpResponse<any>) => {
      this.PushNotificationDialog = false;
      this.loadPage(1);
      // this.notificationService.alertSuccess('Saved Successfully', '');
    },
    (err) => {
      // this.onError();
      // if (err.error.status === 400) {
      //   this.notificationService.alertError('Error! ' + err.error.title, '');
      // } else {
      //   this.notificationService.alertError('Unexpected Error!', '');
      // }
    }
  );
}

hideDialog(){
  this.PushNotificationDialog = false;
  this.pushNotification = new PushNotification();
  this.ngSelectInvalid = false;

}

loadPage (page) {
  this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
   
   
  this.pushNotificationService.filter(Query).subscribe(
    (res: HttpResponse<any>) => {
      this.searchResult.total = res.body.length;
      this.spinner.hide();
      this.searchResult.items = res.body ?? [];
      this.searchResult = { ...this.searchResult };
      console.log('res',res);
     
      
    },
    (err) => {
     
    }
  );
}

async onLazyLoad(event) {
 
  this.filterProps = event.filterDefinitions;
  this.itemsPerPage = event.size;
  this.loadPage(event.page);
}
onSelectRow($event) {
  this.confirmationService.confirm({
    message: 'Are you sure want to delete this record',
    accept: () => {
      this.spinner.show();
      this.pushNotificationService
      .delete($event.value.id)
      .subscribe(
        (res) => {
         this.loadPage(1);
        },
        () => {
          
          
        }
      );
    },
    reject: () => {}
});
}

}
