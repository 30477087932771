import { Component, OnInit } from '@angular/core';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ReportService } from '../report.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { NgxSpinnerService } from 'ngx-spinner';

interface QueryType {
  page: number;
  size: any;
  [key: string]: any; 
}
@Component({
  selector: 'app-ia-component-report',
  templateUrl: './ia-component-report.component.html',
  styleUrls: ['./ia-component-report.component.scss']
})
export class IaComponentReportComponent implements OnInit {
  finYearList: FinYear[];
  finYear: FinYear;
  lgdCode: string;
  voucherNo:string;
  cols:any;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  
  constructor(public fontService:FontService,
    protected reportService:ReportService,
    protected limitTransListService:LimitTransListService,
    protected spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYearList = res.body;
      },
      () => {}
    );
    this.cols=[
      {
        field:'implementingAgency',
        header:'Implementing Agency',
        isSelectcolumn:true,
        type:'dropDown'
      },
      {
        field:'componentType',
        header:'Component Type',
        isSelectcolumn:true,
        type:'dropDown'
      },
      {
        field:'finYear',
        header:'Fin Year',
        isSelectcolumn:true,
        type:'dropDown'
      },
      {
        field:'levelMaster',
        header:'Level Master',
        isSelectcolumn:false,
        type:'dropDown'
      },
      {
        field:'levelType',
        header:'Level Type',
        isSelectcolumn:false,
        type:'dropDown'
      },
      {
        field:'sanctionAmount',
        header:'Sanction Amount',
        isSelectcolumn:true,
      },
      {
        field:'utilizedAmount',
        header:'Utilized Amount',
        isSelectcolumn:true,
      },
      {
        field:'createdOn',
        header:'Created Date',
        isSelectcolumn:true,
        type:'date'
      },
      {
        field:'updatedOn',
        header:'Updated Date',
        isSelectcolumn:true,
        type:'date'
      },
    ];
  }

  onSearch(){
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query:QueryType = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      finYearId:this.finYear.id,
      ...(this.lgdCode && { lgdCode: this.lgdCode }),
    };
    
    this.reportService.iaComponentReport({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.spinner.hide();
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

}
