import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { LevelMaster } from 'src/app/module/level-master/level-master';
import { environment } from 'src/environments/environment';
import { AppConfig, Dashboard, VoucherStatusCount } from './dashboard';
import { filterQuery } from 'src/app/shared/common.model';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ReportService } from 'src/app/report/report.service';
export type EntityListResponseType = HttpResponse<Dashboard>;
export type EntityArrayResponseType = HttpResponse<LevelMaster[]>;

@Injectable({ providedIn: 'root' })
export class DashboardService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/getByDistrict';
  protected downLoadURL =
    environment.SERVER_API_URL + 'api/downloadVendorTransaction';
  protected voucherCountUrl =
    environment.SERVER_API_URL + 'api/getVoucherMaker';
  protected levelMasterFilter =
    environment.SERVER_API_URL + 'api/getLevelMasterByFilter';
  protected getFundReportUrl =
    environment.SERVER_API_URL + 'api/getFundDetails';
  protected getMonthWiseChartReportUrl =
    environment.SERVER_API_URL + 'api/getMonthWiseChart';
  protected getMonthWiseReportUrl =
    environment.SERVER_API_URL + 'api/getMonthWiseReport';
  protected downloadMonthWiseReportUrl =
    environment.SERVER_API_URL + 'api/downloadMonthWiseReport';
  protected spendByCompTypeUrl =
    environment.SERVER_API_URL + 'api/getSpendByComponentTYpe';
  protected VoucherBillDescURL =
    environment.SERVER_API_URL + 'api/getVoucherBillDescByFilter';
    protected voucherBillDescBeneficiaryURL =
    environment.SERVER_API_URL + 'api/getBeneficiaryDescByFilter';
  protected VoucherPersonalDescURL =
    environment.SERVER_API_URL + 'api/getPersonalDescByFilter';
  protected VoucherBillDescEmployeeURL =
    environment.SERVER_API_URL + 'api/getVoucherBillDescEmployeeByFilter';
  protected VoucherBillDescEmployeeRepURL =
    environment.SERVER_API_URL + 'api/getVoucherBillDescElectedRepByFilter';
  protected SwapSubTableListURL =
    environment.SERVER_API_URL + 'api/getSwapSubTableListById';
  protected getVoucherStatusUrl = environment.SERVER_API_URL + 'api/getVoucherStatus';
  protected getPendingVoucherCountUrl = environment.SERVER_API_URL + 'api/getVoucherCountByLevel';
  protected getFundByLevelUrl = environment.SERVER_API_URL + 'api/getFundByLevel';
  protected getSnadetailsUrl = environment.SERVER_API_URL + 'api/getSnadetails';
  protected paymentTimelineUrl = environment.SERVER_API_URL + 'api/paymentTimeline';
  protected paymentSubTableUrl = environment.SERVER_API_URL + 'api/paymentSubTable';
  protected getMajorSpentUrl = environment.SERVER_API_URL + 'api/getMajorSpent';
  protected getCompFundByLevelUrl = environment.SERVER_API_URL + 'api/getCompFundByLevel';
  protected getPanchayatFundReportUrl = environment.SERVER_API_URL + 'api/getFundDetailsByLevelMaster';
  protected getMajorCompSpentUrl = environment.SERVER_API_URL + 'api/getMajorCompSpentByLevel';
  protected voucherSummaryurl = environment.SERVER_API_URL + 'api/voucherSummary';
  protected getDescByVendorTransIdUrl = environment.SERVER_API_URL + 'api/getDescByVendorTransId';
  protected getTwadBillDescByVendorTransactionIdUrl = environment.SERVER_API_URL + 'api/getTwadBillDescByVendorTransactionId';
  protected getTwadDashboardDataUrl = environment.SERVER_API_URL + 'api/getTwadDashboardData';
  protected getTwadTangedcoVoucherSummaryDataUrl = environment.SERVER_API_URL + 'api/getTwadTangedcoVoucherSummaryData';
  protected getTangedcoDashboardDataUrl = environment.SERVER_API_URL + 'api/getTangedcoDashboardData';

  filterProps: any;

  constructor(protected http: HttpClient,
    protected reportService: ReportService) { }
  getReport(req?: any): Observable<EntityListResponseType> {
    const options = createRequestOption(req);
    return this.http.get<Dashboard>(this.resourceFliterUrl, {
      params: options,
      observe: 'response',
    });
  }

  getFundReport(levelMasterId: number, finYear: number) {
    return this.http.get<any>(
      `${this.getFundReportUrl}?levelMasterId=${levelMasterId}&finyearId=${finYear}`,
      { observe: 'response' }
    );
  }
  getMajorReport(parentId, levelId, finYear: number) {
    return this.http.get<any>(
      `${this.getMajorSpentUrl}?parentId=${parentId}&levelId=${levelId}&finyearId=${finYear}`,
      { observe: 'response' }
    );
  }
  getMajorCompSpentByLevel(parentId, levelId, compTypeId) {
    return this.http.get<any>(
      `${this.getMajorCompSpentUrl}?parentId=${parentId}&levelId=${levelId}&compTypeid=${compTypeId}`,
      { observe: 'response' }
    );
  }

  getSnadetails() {
    return this.http.get<any>(this.getSnadetailsUrl);
  }
  getFunddetails(levelMasterId: number, finYear: number) {
    return this.http.get<any>(
      `${this.getPanchayatFundReportUrl}?levelMasterId=${levelMasterId}&finyearId=${finYear}`,
      { observe: 'response' }
    );
  }

  getVoucherSummary(levelMasterId: number, finYear: number) {
    return this.http.get<any>(
      `${this.voucherSummaryurl}?levelMasterId=${levelMasterId}&finyearId=${finYear}`,
      { observe: 'response' }
    );
  }


  getCompFundByLevel(levelId, parentId, compTypeId, finYear: number) {
    return this.http.get<any>(
      `${this.getCompFundByLevelUrl}?levelId=${levelId}&parentId=${parentId}&compTypeId=${compTypeId}&finyearId=${finYear}`,
      { observe: 'response' }
    );
  }


  paymentTimeline(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<VoucherStatusCount>(this.paymentTimelineUrl, {
      params: options,
      observe: 'response',
    });
  }

  getTwadDashboardData(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.getTwadDashboardDataUrl, {
      params: options,
      observe: 'response',
    });
  }

  getTwadTangedcoVoucherSummaryData(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.getTwadTangedcoVoucherSummaryDataUrl, {
      params: options,
      observe: 'response',
    });
  }
  getTangedcoDashboardData(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.getTangedcoDashboardDataUrl, {
      params: options,
      observe: 'response',
    });
  }

  getpaymentSubTable(levelMasterId: number, status: string, activeFlag: string, flag: string) {
    return this.http.get<any>(
      `${this.paymentSubTableUrl}?activeFlag=${activeFlag}&flag=${flag}&levelMasterId=${levelMasterId}&status=${status}`,
      { observe: 'response' }
    );
  }

  getVoucherStatusCount(filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    return this.http.post<VoucherStatusCount>(this.getVoucherStatusUrl, filter, {
      observe: 'response',
    });
  }

  downloadDocuments(req) {
    const options = createRequestOption(req);
    return this.http.get(this.downLoadURL, {
      observe: 'response', responseType: 'text', params: options,
    });
  }

  getVoucherSubtableById(personalFlag: string, id: number): Observable<any> {
    let query = new filterQuery();
    switch (personalFlag.toUpperCase()) {
      case 'NO':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = id;
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(this.VoucherBillDescURL, this.filterProps, {
          observe: 'response',
        });
        break;

      case 'YES':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = id;
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(this.VoucherPersonalDescURL, this.filterProps, {
          observe: 'response',
        });
        case 'BP':
          query.key = 'vendortransactionId';
          query.operation = 'equals';
          query.value = id;
          this.filterProps = [];
          this.filterProps.push(query);
          return this.http.post<any>(this.voucherBillDescBeneficiaryURL, this.filterProps, {
            observe: 'response',
          });
        break;
      case 'E':
        query.key = 'vendorTransactionDto';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(this.VoucherBillDescEmployeeURL, this.filterProps, {
          observe: 'response',
        });

      case 'ER':
        query.key = 'vendorTransactionDto';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(this.VoucherBillDescEmployeeRepURL, this.filterProps, {
          observe: 'response',
        });
      case 'BE':
        return this.reportService.getBulkEmployeeVouchersByVendorId(id);
      case 'BER':
        return this.reportService.getBulkElectedRepVouchersByVendorId(id);
      case 'T':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.get<any>(`${this.getDescByVendorTransIdUrl}?vendorTransId=${Number(id)}`, {
          observe: 'response',
        });
      case 'TWAD':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.get<any>(`${this.getTwadBillDescByVendorTransactionIdUrl}?vendorTransactionId=${Number(id)}`, {
          observe: 'response',
        });
    }


  }
  getSwapSubTableListById(id: number): Observable<any> {
    return this.http.get<any>(`${this.SwapSubTableListURL}?id=${id}`, {
      observe: 'response',
    });
  }

  getPendingVoucher(id: number, finyear: number): Observable<any> {
    return this.http.get<any>(`${this.getPendingVoucherCountUrl}?parentId=${id}&finyearId=${finyear}`, {
      observe: 'response',
    });
  }

  getFundByLevel(id: number, finyear: number): Observable<any> {
    return this.http.get<any>(`${this.getFundByLevelUrl}?parentId=${id}&finyearId=${finyear}`, {
      observe: 'response',
    });
  }


  getMonthWiseReport(filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }

    return this.http.post<LevelMaster[]>(
      this.getMonthWiseChartReportUrl,
      filter,
      {
        observe: 'response',
      }
    );
  }
  getMonthWiseData(filter?: any, selectedMonth?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    return this.http.post<LevelMaster[]>(
      `${this.getMonthWiseReportUrl}?month=${selectedMonth}`,
      filter,
      {
        observe: 'response',
      }
    );
  }

  downloadMonthWiseData(req): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(
      `${this.downloadMonthWiseReportUrl}`,
      {
        observe: 'response', responseType: 'text', params: options,
      }
    );
  }
  getSpendByComponentTYpe(id: string, finyear: number) {
    return this.http.get<any>(`${this.spendByCompTypeUrl}?id=${id}&finyearId=${finyear}`, {
      observe: 'response',
    });
  }
  getVocherCount(finYear: number, activeFlag: string, id: number, count: number, flag?, adminUserId?: number) {
    if (adminUserId && flag) {
      return this.http.get<any>(
        `${this.voucherCountUrl}?activeFlag=${activeFlag}&flag=${flag}&levelMasterId=${id}&adminUserId=${adminUserId}&count=${count}&finyearId=${finYear}`,
        { observe: 'response' }
      );
    }
    else if (flag && !adminUserId) {
      return this.http.get<any>(
        `${this.voucherCountUrl}?activeFlag=${activeFlag}&flag=${flag}&levelMasterId=${id}&count=${count}&finyearId=${finYear}`,
        { observe: 'response' }
      );
    }
    else if (!flag && !adminUserId) {
      return this.http.get<any>(
        `${this.voucherCountUrl}?activeFlag=${activeFlag}&levelMasterId=${id}&count=${count}&finyearId=${finYear}`,
        { observe: 'response' }
      );
    }
    else if (adminUserId && !flag) {
      return this.http.get<any>(
        `${this.voucherCountUrl}?activeFlag=${activeFlag}&levelMasterId=${id}&adminUserId=${adminUserId}&count=${count}&finyearId=${finYear}`,
        { observe: 'response' }
      );
    }
  }
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<LevelMaster[]>(this.levelMasterFilter, filter, {
      params: options,
      observe: 'response',
    });
  }

  config: AppConfig = {
    theme: 'lara-light-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true,
  };

  private configUpdate = new Subject<AppConfig>();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }
}
