import { NotificationService } from './../../alert/notification.service';
import { TangedcoBillService } from './../tangedco-bill-details/tangedco-bill-details.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dialog } from 'primeng/dialog';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { TangedcoService } from '../tangedco-bill-details/tangedco-bill-details';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { ConfirmationService } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tangedco-master',
  templateUrl: './tangedco-master.component.html',
  styleUrls: ['./tangedco-master.component.scss']
})
export class TangedcoMasterComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;


  itemsPerPage: any = 10;
  filterProps: any;
  ngSelectInvalid:boolean
  searchResult: SearchResult<TangedcoService> =
    new SearchResult<TangedcoService>();
  
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
  new SearchResult<any>();
  disableEdit: boolean=false;
  disableDelete: boolean=true;
  cosumerDemandId: number;
  tangedcoBillDialog:boolean
  readonly: boolean;
  tangedcoBills: TangedcoService;
  consumerDetails: any;
  levelMasterId: any;
  approved: boolean;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelList: LevelMasterDto[];
  villageLevelDto:any;
  habitationList: any;
  purposeList: any;
  currentPath: string;
  headingText: string;
  lgdCode:string;
  deactive: boolean;
  isEdit: boolean;
  isDelete: boolean;
  constructor(
    protected router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    private tangedcoBillService: TangedcoBillService,
    private notificationService:NotificationService,
    private levelMasterService: LevelMasterService,
    private confirmationService: ConfirmationService,
    private location: Location,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.currentPath = this.location.path();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }
  openNew() {
    this.tangedcoBills = new TangedcoService();
    this.tangedcoBillDialog = true;
    this.readonly = false;
    this.loadValues();
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    // query.key = 'personalFlag';
    // query.operation = 'equals';
    // query.value = 'E';
    // this.filterProps = this.filterProps ? this.filterProps : [];
    // this.filterProps.push(query);
    if(this.roleCode === 'VMAKER' || this.roleCode === 'VC1' || this.roleCode  === 'VC2') {
      query = new filterQuery();
      query.key = 'levelMasterId';
        query.operation = 'equals';
        query.value = this.levelMasterId;
        this.filterProps.push(query);
    }
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if(this.villageLevelDto) {
      filter['villageId'] = this.villageLevelDto.id;
    }
    if(this.lgdCode) {
      filter['villageLgd'] = this.lgdCode;
    }
    if(this.roleCode === 'DA' && this.currentPath === '/Tangedco-Master-Details') {
      console.log('thisrolecode',this.roleCode);
      this.tangedcoBillService.etTangedcoMasterByDistrict({ ...filter }).subscribe(
        (res: HttpResponse<TangedcoService[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
    }
    else if((this.roleCode === 'DA'|| this.roleCode === 'SA') && this.currentPath === '/Tangedco-Master-Approved') {
      this.tangedcoBillService.approvedMasterList({ ...filter }).subscribe(
        (res: HttpResponse<TangedcoService[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
    }
    else {
      this.tangedcoBillService.tangedcoMasterFilter({ ...filter }, this.filterProps).subscribe(
        (res: HttpResponse<TangedcoService[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.spinner.hide();
        },
        () => {
          this.onError();
          this.spinner.hide();
        }
      );
    }
    
  }

  protected onSuccess(
    data: TangedcoService[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    data.forEach(element => {
      if(element.isApproved && element.activeFlag !== 'No') {
        element.status = 'Active';
      }
      else if(element.isRejected && element.activeFlag !== 'No') {
        element.status = 'Reject';
        if(this.roleCode === 'VMAKER') {
          element.edit = true;
        }
      }
      else if(element.isDeleted && element.activeFlag === 'No') {
        element.status = 'Deleted';
        
      }
      else if(element.activeFlag === 'No')  {
        element.status = 'Inactive'
      }
    });
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  async onLazyLoad(event) {
    if(!this.roleCode) {
      await this.getRole();
    }
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
   console.log('lodggdgd',$event);
   if ($event.type === 'select') {
    this.spinner.show();
    this.tangedcoBillService.getTangedcoMasterById($event.value.id).subscribe(
      (res) => {
        console.log('saveData',res)
        this.tangedcoBills = res.body;
        this.verifyConsumerNo();
        this.loadValues();
        this.readonly = true;
        this.isEdit = true;
        this.tangedcoBillDialog = true;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  else if($event.value.isRejected && this.roleCode === 'DA' && $event.type === 'delete') {
    this.deleteTangedcoMaster($event.value.consumerNo,$event.value.id);
  }
  else {
    this.spinner.show();
    this.tangedcoBillService.getTangedcoMasterById($event.value.id).subscribe(
      (res) => {
        console.log('saveData',res)
        this.tangedcoBills = res.body;
        this.verifyConsumerNo();
        
        this.readonly = true;
        this.approved = true;
        this.tangedcoBillDialog = true;
        if(this.roleCode === 'SA') {
          this.deactive = true;
          this.approved = false;
        }
        this.spinner.hide();

        
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
      
   
   
   console.log('event',$event);
  }
  
  verifyConsumerNo(){
    this.spinner.show();
    this.tangedcoBillService.verifyConsumerNo({'consumerNo':this.tangedcoBills.consumerNo}).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
        console.log('res',res.body);
        if(res.body.consumerName !== 'NA') {
          this.consumerDetails = res.body;
          this.tangedcoBills.consumerName = this.consumerDetails.consumerName;
          this.tangedcoBills.consumerAddress = this.consumerDetails.consumerAddress;
          this.tangedcoBills.lgdCode = this.consumerDetails.lgdCode;
          this.tangedcoBills.levelMasterId = this.levelMasterId;
          this.tangedcoBills.levelId = 4;
          this.tangedcoBills.region = Number(this.tangedcoBills.consumerNo.charAt(1));
          console.log('logfff',this.tangedcoBills);
        }
        else {
          this.notificationService.alertError('Customer No is Invalid','');
        }
        
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  hideDialog(){
    this.consumerDetails = null;
    this.ngSelectInvalid = false;
    this.tangedcoBillDialog = false;
    this.readonly = false;
    this.approved = false;
    this.deactive = false;
    this.isEdit = false;
    this.isDelete = false;
  }

  save(){
   
    const isFormValid =
    this.tangedcoBills.consumerNo &&
    this.consumerDetails &&
    this.tangedcoBills.region &&
    this.tangedcoBills.consumerLandMark &&
    this.tangedcoBills.habitation &&
    this.tangedcoBills.purpose
  if (!isFormValid) {
    this.ngSelectInvalid = true;
    return;
  }
  this.spinner.show();
    this.tangedcoBillService.saveTangedcoMaster(this.tangedcoBills).subscribe(
      (res) => {
        console.log('saveData',res)
        this.spinner.hide();
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.hideDialog();
      },
      (err) => {
        this.notificationService.alertError(err.error.errorKey,'');
      }
    );
  }

  approveTangedcoMaster() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to approve this Consumer No ' +
        this.tangedcoBills.consumerNo,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.tangedcoBillService.approveTangedcoMaster(this.tangedcoBills.id).subscribe(
      (res) => {
        console.log('saveData',res)
        this.notificationService.alertSuccess('Approved Successfully', '');
        this.spinner.hide();
        this.hideDialog();
        this.loadPage(1);
      },
      (err) => {
        this.spinner.hide();
      }
    );
        
      },
      reject: () => {},
    });
   
  }

  rejectTangedcoMaster() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to reject this Consumer No  ' +
        this.tangedcoBills.consumerNo,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.tangedcoBillService.rejectTangedcoMaster(this.tangedcoBills.id).subscribe(
          (res) => {
            console.log('saveData',res)
            this.notificationService.alertSuccess('Reject Successfully', '');
            this.spinner.hide();
            this.hideDialog();
            this.loadPage(1);
          },
          (err) => {
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });

  }
  deactiveTangedcoMaster() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to Deactivate this Consumer No  ' +
        this.tangedcoBills.consumerNo,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.tangedcoBillService.deactivateMaster(this.tangedcoBills.id).subscribe(
          (res) => {
            console.log('saveData',res)
            this.notificationService.alertSuccess('Deactivate Successfully', '');
            this.spinner.hide();
            this.hideDialog();
            this.loadPage(1);
          },
          (err) => {
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });

    
 
}

deleteTangedcoMaster(cusumerNo,id) {
  this.confirmationService.confirm({
    message:
      'Are you sure you want to Delete this Consumer No  ' +
      cusumerNo,
    icon: 'pi pi-unlock',
    accept: () => {
      this.spinner.show();
      this.tangedcoBillService.deactivateMaster(id).subscribe(
        (res) => {
          console.log('saveData',res)
          this.notificationService.alertSuccess('Delete Successfully', '');
          this.spinner.hide();
          this.loadPage(1);
        },
        (err) => {
          this.spinner.hide();
        }
      );
    },
    reject: () => {},
  });
}

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {

          this.roleCode = res.body.role.code;
       this.levelMasterId = res.body.levelMasterId;

       if (this.roleCode === 'DA') {
        if(this.currentPath === '/Tangedco-Master-Details') {
          this.headingText = 'tangedco.approveList';
        }
        else {
          this.headingText = 'tangedco.approvedList';
        }
        
        this.cols = [
          {
            field: 'villageLgd',
            header: 'LGD',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'blockName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.block',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'villageName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.villagePan',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'region',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.region',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'consumerNo',
            header: 'Consumer Number',
            jhiTranslate: 'tangedco.consumerNo',
            isFilterable: true,
            isSelectcolumn: true,
          },
         
          {
            field: 'consumerName',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.consumerName',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'consumerAddress',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.consumerAddress',
            isSortable: true,
            isSelectcolumn: true
          },

          {
            field: 'purpose',
            header: 'Admin User Name',
            jhiTranslate: 'tangedco.Purpose',
            isSortable: false,
            isFilterable: false,
            isSelectcolumn: true,
          },
    
          {
            field: 'status',
            header: 'Status',
            isSortable: true,
            isSelectcolumn: true
          },
         
        ];
       }
       else if(this.roleCode === 'SA') {
        if(this.currentPath === '/Tangedco-Master-Details') {
          this.headingText = 'tangedco.approveList';
        }
        else {
          this.headingText = 'tangedco.approvedList';
        }
        
        this.cols = [
          {
            field: 'villageLgd',
            header: 'LGD',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'districtName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.district',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'blockName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.block',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'villageName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.villagePan',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'region',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.region',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'consumerNo',
            header: 'Consumer Number',
            jhiTranslate: 'tangedco.consumerNo',
            isFilterable: true,
            isSelectcolumn: true,
          },
         
          {
            field: 'consumerName',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.consumerName',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'consumerAddress',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.consumerAddress',
            isSortable: true,
            isSelectcolumn: true
          },

          {
            field: 'purpose',
            header: 'Admin User Name',
            jhiTranslate: 'tangedco.Purpose',
            isSortable: false,
            isFilterable: false,
            isSelectcolumn: true,
            type: "dropDown_with_other_field",
            Dfield: "description"
          },
    
          {
            field: 'status',
            header: 'Status',
            isSortable: true,
            isSelectcolumn: true
          },
         
        ];
       }
       else {
        this.cols = [
          {
            field: 'consumerNo',
            header: 'Consumer Number',
          jhiTranslate: 'tangedco.consumerNo',
            isFilterable: true,
            isSelectcolumn: true,
          },
          {
            field: 'consumerName',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.consumerName',
            isSortable: true,
            isSelectcolumn: true
          },
          {
            field: 'consumerAddress',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.consumerAddress',
            isSortable: true,
            isSelectcolumn: true
          },
         
          {
            field: 'region',
            header: 'Consumer Name',
            jhiTranslate: 'tangedco.region',
            isSortable: true,
            isSelectcolumn: true
          },
    
          {
            field: 'status',
            header: 'Status',
            isSortable: true,
            isSelectcolumn: true
          },
         
        ];
       }
       
       this.onDistrictLevelChange();
       if(this.roleCode=='DM' || this.roleCode=='BM' || this.roleCode=='SM')
       this.disableEdit=true;
       else if(this.roleCode === 'DMAKER' || this.roleCode === 'VMAKER' || this.roleCode === 'BMAKER')
       this.disableDelete=false;
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  onDistrictLevelChange($event?) {
   
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  loadValues() {
    this.tangedcoBillService.getHabitationByParentId(this.levelMasterId).subscribe(
      (res: HttpResponse<[]>) => {
        this.habitationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.tangedcoBillService.getAllTangedcoPurpose().subscribe(
      (res: HttpResponse<[]>) => {
        this.purposeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    
  }

}
