import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LevelTypeService } from '../level-type/level-type.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelType } from '../level-type/level-type';
import { ComponentTypeService } from '../component-type/component-type.service';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { FinYear } from '../limit-translist/limit-translist.module';
import { FundsExcelUploadService } from './funds-excel-upload.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/alert/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { fundTempModel } from './funds-excel-upload.model';
import { SearchResult } from 'src/app/shared/models/search-result';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-funds-excel-upload',
  templateUrl: './funds-excel-upload.component.html',
  styleUrls: ['./funds-excel-upload.component.scss'],
})
export class FundsExcelUploadComponent implements OnInit {
  levelTypeList: LevelType[];
  levelType: LevelType;
  grantLevelList: any;
  grantlevel: any;
  finYearList: FinYear[];
  finYear: FinYear;
  selectedSource: string;
  fundSourceList = ['SFC', 'OSR'];
  transactionDate: Date;
  selectedFile: File;
  itemsPerPage: number = 10;
  page: number;
  searchResult: SearchResult<fundTempModel> = new SearchResult<fundTempModel>();
  cols: any;
  status: any;
  showForm: boolean;
  showTable: boolean;
  showStatus: boolean;
  today: Date;
  isTwad: string;

  constructor(
    public fontService: FontService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private limitTransListService: LimitTransListService,
    private fundsExcelUploadService: FundsExcelUploadService,
    private datePipe: DatePipe,
    protected notificationService: NotificationService,
    protected router: Router,
    private spinner: NgxSpinnerService,
    protected activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.today = new Date();
    let payload = {};
    this.isTwad = this.activatedRoute.snapshot.queryParamMap.get('isTwad');
    if(this.activatedRoute.snapshot.queryParamMap.get('isTwad')) {
      this.selectedSource = 'TWAD';
      payload['fundSource'] = 'TWAD';
    }
    else {
      payload['fundSource'] = 'IaComponentLimit';
    }
    this.fundsExcelUploadService.statusCheck(payload).subscribe(
      (res) => {
        this.status = res.body;
        switch (this.status) {
          case 'Migrated Data Saved Successfully.':
            this.showForm = true;
            break;
          case 'Data migrated successfully. Verify and Proceed':
            this.showTable = true;
            break;
          case 'None of the processes are inititated. YOU CAN UPLOAD THE EXCEL.':
            this.showForm = true;
            break;
          default:
            this.showStatus = true;
            break;
        }
      },
      (error) => {
        this.notificationService.alertError(error.error, '');
        this.loadPage(1);
      }
    );
    const levelTypeObservable = this.levelTypeService.filter();
    const limitTransListObservable = this.limitTransListService.getFinYear();
    forkJoin([levelTypeObservable, limitTransListObservable]).subscribe(
      (responses) => {
        this.levelTypeList = responses[0].body;
        let FinYear = ''
        if (new Date().getMonth() + 1 >= 4) {
          FinYear =
            new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
        } else {
          FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
        }
        this.levelTypeList = this.levelTypeList.filter(level => level.name === 'Village');
        this.levelType = this.levelTypeList[0];
        this.finYearList = responses[1].body;
        this.finYear = this.finYearList.find((_) => _.name === FinYear);
        this.finYearList = this.finYearList.filter(year => year.name >= FinYear);
      },
      (error) => {
        // Handle error
        console.error('Error:', error);
       
      }
    );

    let filterQuery = {
      page: 0,
      size: 10,
    };
    if(this.isTwad) {
      this.cols = [
        {
          field: 'lgdCode',
          header: 'LGD Code',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },
        {
          field: 'billSno',
          header: 'Bill S:No',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },
        {
          field: 'billDate',
          header: 'Bill Date',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
          type:'date'
        },
        {
          field: 'maintenaceChargesDue',
          header: 'Maintenace Charges Due',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
          type:'amount'
        },
        {
          field: 'waterChargesDue',
          header: 'Water Charges Due',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
          type:'amount'
        },
        {
          field: 'interestDue',
          header: 'Interest Due',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
          type:'amount'
        },
        {
          field: 'totalDue',
          header: 'Total Due',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
          type:'amount'
        },
        {
          field: 'officeName',
          header: 'Office Name',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },
        {
          field: 'beneficiaryCode',
          header: 'beneficiary Code',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },

  
       
       
      ];
    }
    else {
      this.cols = [
        {
          field: 'finYear',
          header: 'Fin Year',
          jhiTranslate: 'tableHead.fin.year.name',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type: 'dropDown',
        },
        {
          field: 'fundSource',
          header: 'Fund Source',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },
        {
          field: 'accountNumber',
          header: 'Account Number',
          jhiTranslate: 'tableHead.account.number',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },
  
        {
          field: 'lgdCode',
          header: 'LGD Code',
          width: 13,
          isSelectcolumn: true,
          isFilterable: false,
        },
  
        {
          field: 'levelMaster',
          header: 'Component Type',
          jhiTranslate: 'reports.village',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type: 'dropDown',
        },
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type:'date'
        },
        {
          field: 'existingSanctionAmount',
          header: 'Existing Sanction Amount',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type: 'amount',
        },
        {
          field: 'currentMonthSanctionedAmount',
          header: 'Current Month Sanctioned Amount',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type: 'amount',
        },
        {
          field: 'cumulativeSanctionedAmount',
          header: 'Cumulative Sanctioned Amount',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type: 'amount',
        },
        {
          field: 'componentType',
          header: 'Component Type',
          jhiTranslate: 'tableHead.compoType',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
          type: 'dropDown',
        },
      ];
    }

    
  }
  levelTypeChange() {
    this.spinner.show();
    this.componentTypeService.find(this.levelType.id).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
        this.grantlevel = null;
        if(this.selectedSource === 'OSR') {
          this.grantLevelList = res.body.filter(item => item.name ==='Village General Funds');
          this.grantlevel = this.grantLevelList[0];
        }
        else {
          this.grantLevelList = res.body;
        }
      },
      () => {}
    );
  }
  onUploadfile($event) {
    const i = $event.files.length - 1;
    this.selectedFile = $event.files[i] as File;
    console.log('this.selectedFile', this.selectedFile);
  }

  submit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('finYearId', this.finYear.id.toString());
    formData.append('departmentId', this.levelType.departmentDto.id.toString());
    formData.append('schemeId', this.levelType.schemeDto.id.toString());
    formData.append(
      'transactionDate',
      this.datePipe.transform(new Date(this.transactionDate), 'yyyy-MM-dd') +
        ' 12:00:00.000'
    );
    formData.append('levelTypeId', this.levelType.id.toString());
    if(!this.isTwad) {
      formData.append('componentMasterId', this.grantlevel.id);
    }
    formData.append('fundSource', this.selectedSource);
    if(this.isTwad) {
      this.fundsExcelUploadService.twadUploadFile(formData).subscribe(
        (response) => {
          this.spinner.hide();
          console.log('File uploaded successfully', response);
          this.notificationService.alertSuccess(response.body, '');
          this.router.navigate(['/twad-Upload-excel-list'], {});
        },
        (error) => {
          this.spinner.hide();
          console.error('Error uploading file', error);
        }
      );
    }
    else {
      this.fundsExcelUploadService.uploadFile(formData).subscribe(
        (response) => {
          this.spinner.hide();
          console.log('File uploaded successfully', response);
          this.notificationService.alertSuccess(response.body, '');
          this.router.navigate(['/excel-Upload-list'], {});
        },
        (error) => {
          this.spinner.hide();
          console.error('Error uploading file', error);
        }
      );
    }
    
  }

  save() {
    this.spinner.show();
    if(this.isTwad) {
      this.fundsExcelUploadService.twadsaveBill().subscribe(
        (response) => {
          console.log('save uploaded successfully', response);
          // this.notificationService.alertSuccess(response, '');
          
        },
        (error) => {
          
        }
      );
      this.spinner.hide();
      this.notificationService.alertSuccess('Excel Approved Sucessfully', '');
      this.router.navigate(['/twad-Upload-excel-list'], {});
    }
    else {
      this.fundsExcelUploadService.saveLimitFund().subscribe(
        (response) => {
          console.log('save uploaded successfully', response);
          // this.notificationService.alertSuccess(response, '');
          
        },
        (error) => {
          
        }
      );
      this.spinner.hide();
      this.notificationService.alertSuccess('Excel Approved Sucessfully', '');
      this.router.navigate(['/excel-Upload-list'], {});
    }
   
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    const pageToLoad: number = page ?? this.page ?? 1;
    this.spinner.show();
    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if(this.isTwad) {
      this.fundsExcelUploadService.getAllTwadBillsTempByFilter({ ...filterQuery },[]).subscribe(
        (res) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.showTable = true;
          this.spinner.hide();
          
        },
        (err) => {
          if(err.status === 400) {
            this.showForm = true;
            this.spinner.hide();
          }
         
        }
      );
    } 
    else {
      this.fundsExcelUploadService.vaildFilter({ ...filterQuery }).subscribe(
        (res) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          this.showTable = true;
          this.spinner.hide();
          
        },
        (err) => {
          if(err.status === 400) {
            this.showForm = true;
            this.spinner.hide();
          }
         
        }
      );
    }
    
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    if (this.showTable) {
      this.loadPage(event.page);
    }
  }

  protected onSuccess(
    data: fundTempModel[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  downloadFile() {
    let payload = {};
    payload['fundSource'] = this.selectedSource;
    this.fundsExcelUploadService.downloadLimitTemplate(payload).subscribe(
      (response) => {
        this.spinner.hide();
        this.saveBlobToExcel(response.body,this.selectedSource + ' Template');
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
  }

  reject() {
    this.spinner.show();
    if(this.isTwad) {
      this.fundsExcelUploadService.rejectTwadBills().subscribe(
        (response) => {
          console.log('save uploaded successfully', response);
          // this.notificationService.alertSuccess(response, '');
          
        },
        (error) => {
          
        }
      );
    }
    else {
      this.fundsExcelUploadService.rejectAllocation().subscribe(
        (response) => {
          console.log('save uploaded successfully', response);
          // this.notificationService.alertSuccess(response, '');
          
        },
        (error) => {
          
        }
      );
    }
    this.spinner.hide();
    this.notificationService.alertSuccess('Excel Rejected Sucessfully', '');
    this.router.navigate(['/landing-page'], {});
  }

     
}
