import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ReportService } from '../report.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

interface QueryType {
  page: number;
  size: any;
  [key: string]: any; 
}
@Component({
  selector: 'app-account-validation-log',
  templateUrl: './account-validation-log.component.html',
  styleUrls: ['./account-validation-log.component.scss']
})
export class AccountValidationLogComponent implements OnInit {

  accountNo:string;
  cols:any;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  
  constructor(public fontService:FontService,
    protected reportService:ReportService,
    protected spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.cols=[
      {
        field:'accountName',
        header:'Account Name',
        isSelectcolumn:true,
      },
      {
        field:'accountNo',
        header:'Account No',
        isSelectcolumn:true,
      },
      {
        field:'bankResponse',
        header:'Bank Response',
        isSelectcolumn:true,
      },
      {
        field:'id',
        header:'Id',
        isSelectcolumn:false,
      },
      {
        field:'ifscCode',
        header:'IFSC Code',
        isSelectcolumn:false,
      },
      {
        field:'interactionId',
        header:'Interaction Id',
        isSelectcolumn:true,
      },
      {
        field:'txnStatus',
        header:'Transaction Status',
        isSelectcolumn:true,
      },
      {
        field:'createdOn',
        header:'Created Date',
        isSelectcolumn:true,
        type:'date'
      }
    ];
  }

  onSearch(){
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query:QueryType = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.accountNo && { accountNo: this.accountNo }),
    };
    
    this.reportService.accountValidationLog({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.spinner.hide();
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

}


