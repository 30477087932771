import { Role } from './../../module/user/user';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from 'src/app/module/profile/profile';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from 'src/app/shared/models/search-result';
import { VendorTransaction, VendorTransactionBulkDto } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';

@Component({
  selector: 'app-bluk-payment-table',
  templateUrl: './bluk-payment-table.component.html',
  styleUrls: ['./bluk-payment-table.component.scss']
})
export class BlukPaymentTableComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<any> =
    new SearchResult<any>();
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
  new SearchResult<any>();
  disableEdit: boolean=false;
  disableDelete: boolean=true;
  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  message: string;

  ngOnInit(): void {
   
     
       
     
    this.cols = [
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'transactionDate',
        header: 'Voucher Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
        jhiTranslate: 'tableHead.transaction.date'
      },

      {
        field: 'signMessage',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: true,
      },
    ];
    // this.loadPage(1);
  }
  captureWSMessage(data: any) {
    throw new Error('Method not implemented.');
  }
  
  ngAfterViewInit() {
    this.updateDialogFont();
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate(['/blukpaymentVoucher'], {
          relativeTo: this.route,
          queryParams: {
            VoucherNo: res.body,
          },
        });
      },
      () => {}
    );
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
   
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
	query = new filterQuery();
	query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
	this.filterProps.push(query);
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelMasterId: this.roleCode.levelMasterId,
    };
    this.paymentService.getparentVoucherlist({ ...Query }).subscribe(
      (res: HttpResponse<any>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: any | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    data.forEach(element => {
      if(element.signMessage === 'Sign 2 Pending' && (this.roleCode.role.code === 'DC1' || this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'VC1')) {
        element.edit = true;
      }
      else if(element.signMessage === 'Sign 3 Pending' && (this.roleCode.role.code === 'DC2' || this.roleCode.role.code === 'BC2' || this.roleCode.role.code === 'VC2')) {
        element.edit = true;
      }
      else {
        element.edit = false;
      }
    });
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  async onLazyLoad(event) {
    await this.getRole();
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate(['/blukpaymentVoucher'], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
    else if ($event.type === 'view') {
      this.router.navigate(['/blukpaymentVoucher'], {
        queryParams: {
          id: $event.value.id,
          view: true
        },
      });
    }    
	else{
		this.paymentService
		  .deletedVocher($event.value.id)
		  .subscribe(
			(res: HttpResponse<any>) => {
				this.loadPage(this.page)
			},
			() => {
			  this.onError();
			}
		  );
	}
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
            
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }
 
 

}
