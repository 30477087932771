<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.deduction"></h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm export-button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel *ngIf="isDistrictTrue" header="District">
                <div class="col-12 px-4" *ngIf="districtDeductionsummary">
                    <p-table id="district-table" [value]="districtDeductionsummary" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Block Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Deduction Account Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Active Count </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">In-Active Count</th>
                          

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.blockCount}}</td>
                                <td style="text-align: center;">{{list.villageCount}}</td>
                                <td style="text-align: center;">{{list.deductionAccVillageCount}}</td>
                                <td style="text-align: center;">{{list.activeCount}}</td>
                                <td style="text-align: center;">{{list.inActiveCount}}</td>
                    
                                
                            </tr>
                        </ng-template>

                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Block">
                <div *ngIf="!blockDeductionSummary">Choose distict to get block report</div>
                <div *ngIf="blockDeductionSummary" class="col-12 px-4">
                    <p-table id="block-table" [value]="blockDeductionSummary" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Deduction Account Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Active Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">In-Active Count</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.villageCount}}</td>
                                <td style="text-align: center;">{{list.deductionAccVillageCount}}</td>
                                <td style="text-align: center;">{{list.activeCount}}</td>
                                <td style="text-align: center;">{{list.inActiveCount}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!villageDeductionSummary">Choose block to get village report</div>
                <div *ngIf="villageDeductionSummary" class="col-12 px-4">
                    <p-table id="village-table" [value]="villageDeductionSummary" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Deduction Account Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Active Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">In-Active Count</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadPage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>

                                <td style="text-align: center;">{{list.deductionAccVillageCount}}</td>
                                <td style="text-align: center;">{{list.activeCount}}</td>
                                <td style="text-align: center;">{{list.inActiveCount}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <!-- <p-tabPanel header="Consumer Demand">
                <div *ngIf="searchResult.total === 0">Choose village to get consumer Demand report</div>
                <div *ngIf="searchResult.total !== 0" class="col-12 px-4">
                    <div #customerTable id="entities" class="col-12 px-4">
                        <custom-filter-table id="consumer-table" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                            [showDialogTable]="true"></custom-filter-table>
                    </div>
                </div>
            </p-tabPanel> -->
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <!-- <p-header [ngClass]="fontService.headingClass">
  {{ "dashBoard.transactionlist" | translate }}
</p-header> -->
    <custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="tangedcosearchResult" [disableDeleteIcon]="true" [paginator]="false" [disableEditIcon]="true"></custom-filter-table>
</p-dialog>
