import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { LevelMasterDto } from '../level-master/level-master';
import { LevelMasterService } from '../level-master/level-master.service';
import { PersonalVendors, PersonalVendorsDto, ValidationResponse } from '../personal-vendors/personal-vendors';
import { PersonalVendorService } from '../personal-vendors/personal-vendors.service';
import { beneficiaryDto, beneficiaryList } from './beneficiary.model';
import { BeneficiaryService} from './beneficiary-service.service';

@Component({
  selector: 'app-beneficiary-creation',
  templateUrl: './beneficiary-creation.component.html',
  styleUrl: './beneficiary-creation.component.scss'
})
export class BeneficiaryCreationComponent {
  @ViewChild('dialog') dialog: Dialog;

  personalVendorsDialog: boolean = false;
  beneficiaryCreationDialog:boolean =false
  cols: any[];
  searchResult: SearchResult<beneficiaryList> =
    new SearchResult<beneficiaryList>();
  beneficiary?: beneficiaryDto;
  selectedPersonalVendors: beneficiaryList[] = [];
  submitted?: boolean;
  selectedMapping: string;
  checked: boolean;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any=[];
  validationRes: ValidationResponse;
  readonly: boolean;
  vendorName: string;
  currentEnvironment: string;
  bankBranchDetailsRes: any;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  public verifyClicked: boolean;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showStaffError: string;
  showMobileUniError: string;
  emailUniError: string;
  aadhaarNoUniError: string;
  panNoUniError: string;
  accountNoUniError: any;
  bankList: any;
  bankBranchList: any;
  bankBranch: any;
  accountInvalidError: any;
  roleCode: any;
  disableEdit: boolean = false;
  edit: boolean;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  districtLevelList: LevelMasterDto[];
  districtDto: any;
  blockLevelList: LevelMasterDto[];
  villageLevelDto: any;
  accountNo: string;
  levelType = ['District', 'Block', 'Village'];
  selectedLevelType: string;
  disableDelete: boolean = true;
  isDelete: boolean;
  uniqueId: any;
  constructor(
  
    private beneficaryService:BeneficiaryService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private commercialVendorservice: CommercialVendorService,
    protected loginService: SigninService,
    private levelMasterService: LevelMasterService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.currentEnvironment = environment.environment;
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.roleCode = res.body.role.code;
        if (
          this.roleCode == 'DM' ||
          this.roleCode == 'BM' ||
          this.roleCode == 'SM' ||
          this.roleCode == 'SA' ||
          this.roleCode == 'SUA'
        )
          this.disableEdit = true;
        this.loadPage(1);
        if (this.roleCode === 'SA') {
          this.disableDelete = false;
          this.onStateLevelChange();
        }
      },
      (onError) => {}
    );

    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
    this.cols = [
      {
        field: 'name',
        header: 'Name',
        jhiTranslate: 'tableHead.name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field:'uniqueId',
        header: 'UniqueId',
        jhiTranslate: 'tableHead.uniqueId',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
     
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'ifscCode',
        header: 'IfscCode',
        jhiTranslate: 'tableHead.ifscCode',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        jhiTranslate: 'tableHead.bankName',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
  
      {
        field: 'bankBranch',
        header: 'Branch',
        jhiTranslate: 'tableHead.branch.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'aadhaarNo',
        header: 'Aadhaar No',
        jhiTranslate: 'tableHead.aadhaarno',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
     
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.updateDialogFont();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.beneficiary = new beneficiaryDto();
    this.submitted = false;
    this.beneficiaryCreationDialog = true;
    this.readonly = false;
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
      ];
      if (this.beneficiary.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.beneficiary.id,
        });
      }
      this.beneficaryService
        .validFilter({ ...filterQuery }, filter)
        .subscribe(
          (res: HttpResponse<beneficiaryList[]>) => {
            resolve(res.body);
          },
          () => {
            this.onError();
            resolve(null);
          }
        );
    });
  }

  addresschange(check?) {
    if (this.checked) {
      this.beneficiary.companyDoorNo = this.beneficiary.doorNo;
      this.beneficiary.companyStreet = this.beneficiary.street;
      this.beneficiary.companyArea = this.beneficiary.area;
      this.beneficiary.companyCity = this.beneficiary.city;
      this.beneficiary.companyDistrict = this.beneficiary.district;
      this.beneficiary.companyState = this.beneficiary.state;
      this.beneficiary.companyPinCode = this.beneficiary.pinCode;
    } else {
      if (!check) {
        this.beneficiary.companyDoorNo = null;
        this.beneficiary.companyStreet = null;
        this.beneficiary.companyArea = null;
        this.beneficiary.companyCity = null;
        this.beneficiary.companyDistrict = null;
        this.beneficiary.companyState = null;
        this.beneficiary.companyPinCode = null;
      }
    }
  }

  async checkName() {
    const filterData = await this.getfilterdata(
      'name',
      this.beneficiary.name
    );
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkstaffId() {
    const filterData = await this.getfilterdata(
      'staffId',
      this.beneficiary.staffId
    );
    if (filterData.length !== 0) {
      this.showStaffError = 'Staff Already Exists';
    } else {
      this.showStaffError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata(
      'mobile',
      this.beneficiary.mobile
    );
    if (filterData.length !== 0) {
      this.showMobileUniError = 'Mobile No Already Exists';
    } else {
      this.showMobileUniError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata(
      'email',
      this.beneficiary.email
    );
    if (filterData.length !== 0) {
      this.emailUniError = 'Email Already Exists';
    } else {
      this.emailUniError = null;
    }
  }

  async checkaadharNo() {
    const filterData = await this.getfilterdata(
      'aadhaarNo',
      this.beneficiary.aadhaarNo
    );
    if (filterData.length !== 0) {
      this.aadhaarNoUniError = 'Aadhaar No Already Exists';
    } else {
      this.aadhaarNoUniError = null;
    }
  }

  async checkPanNo() {
    const filterData = await this.getfilterdata(
      'panNo',
      this.beneficiary.panNo
    );
    if (filterData.length !== 0) {
      this.panNoUniError = 'Pan No Already Exists';
    } else {
      this.panNoUniError = null;
    }
  }

  async checkAccountNo() {
    const filterData = await this.getfilterdata(
      'accountNumber',
      this.beneficiary.accountNumber
    );
    if (filterData.length !== 0) {
      this.accountNoUniError = 'account No Already Exists';
    } else {
      this.accountNoUniError = null;
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    var i;
    if (this.filterProps) {
      for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
        if (this.filterProps[i].key === 'name') {
          this.filterProps.splice(i, 1);
        }
      }
    }
    if (this.vendorName) {
      this.filterProps.push({
        key: 'name',
        operation: 'contains',
        value: this.vendorName.toUpperCase(),
      });
    }
    if (this.accountNo) {
      this.filterProps.push({
        key: 'accountNumber',
        operation: 'contains',
        value: this.accountNo,
      });
    }
    if(this.uniqueId) {
      this.filterProps.push({
        key: 'uniqueId',
        operation: 'contains',
        value: this.uniqueId,
      });
      
    }
    if (this.selectedLevelType === 'District' && this.districtDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.districtDto.id,
      });
    }
    if (this.selectedLevelType === 'Block' && this.BlockLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.BlockLevelDto.id,
      });
    }
    if (this.selectedLevelType === 'Village' && this.villageLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.villageLevelDto.id,
      });
    }
    console.log('resss', this.roleCode);
    if (this.roleCode === 'SA') {
      this.beneficaryService
        .validFilter({ ...filterQuery }, this.filterProps)
        .subscribe(
          (res: HttpResponse<beneficiaryList[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    } else {
      this.beneficaryService
        .validFilter({ ...filterQuery }, this.filterProps)
        .subscribe(
          (res: HttpResponse<beneficiaryList[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    }
  }
  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.beneficiary.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.beneficiary.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.beneficiary.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.beneficiary.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.beneficiary.bankName,
        this.beneficiary.bankBranch
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.beneficiary.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    if (
      !this.beneficiary.ifscCode ||
      !this.beneficiary.accountNumber ||
      !this.beneficiary.bankAccName ||
      !this.beneficiary.bankName ||
      !this.beneficiary.bankBranch
    ) {
      this.accountInvalidError =
        'Please input valid account number and bank details';
      return;
    }
    this.verifyClicked = true;
    this.accountInvalidError = null;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidation(
        this.beneficiary.accountNumber,
        this.beneficiary.ifscCode
      )
      .subscribe(
        (res: any) => {
          // this.validationRes=JSON.parse(res.body);
          this.validationRes = res.body;
          if (this.validationRes.bankTxnStatus === 'true') {
            this.accountInvalidError = null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
            this.readonly = true;
          } else {
            this.accountInvalidError =
              'Account Verification Failed due to ' +
              this.validationRes.bankResponse;
            this.notificationService.alertError(
              'Account Verification Failed due to ' +
                this.validationRes.bankResponse,
              ''
            );
            this.readonly = false;
            this.verifyClicked = false;
          }
          this.spinner.hide();
        },
        (error) => {
          if (error.status === 400) {
            this.accountInvalidError =
              'Account Verification Failed. ' + error.error.message;
            this.notificationService.alertError(
              'Account Verification Failed. ' + error.error.message,
              ''
            );
          } else {
            this.accountInvalidError =
              'Please input valid account number and branch details';
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
          }
          this.readonly = false;
          this.verifyClicked = false;
          this.spinner.hide();
        }
      );
  }

  protected onSuccess(
    data: beneficiaryList[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/beneficiary-creation'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    console.log(event)
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.beneficiary = new PersonalVendorsDto();
    this.ngSelectInvalid = false;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.beneficiaryCreationDialog = false;
    this.submitted = false;
    this.bankBranch = null;
    this.edit = false;
    this.isDelete = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.beneficiary.mobile
    );
    !this.beneficiary.mobile ? (this.showMobileError = false) : true;

    return !this.showMobileError && !!this.beneficiary.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(
      this.beneficiary.email
    );
    !this.beneficiary.email ? (this.emailError = false) : true;

    return !this.emailError && !!this.beneficiary.email;
  }

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.beneficiary.panNo
    );
    !this.beneficiary.panNo ? (this.panError = false) : true;

    return !this.panError && !!this.beneficiary.panNo;
  }

  validateTan() {
    this.tanError = !this.validationService.isPanValid(
      this.beneficiary.tanNo
    );
    !this.beneficiary.tanNo ? (this.tanError = false) : true;

    return !this.tanError && !!this.beneficiary.tanNo;
  }

  validateTin() {
    this.tinError = !this.validationService.isPanValid(
      this.beneficiary.tinNo
    );
    !this.beneficiary.tinNo ? (this.tinError = false) : true;

    return !this.tinError && !!this.beneficiary.tinNo;
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(
      this.beneficiary.pinCode
    );
    !this.beneficiary.pinCode ? (this.pinError = false) : true;

    return !this.pinError && !!this.beneficiary.pinCode;
  }

  validateCPin() {
    this.cPinError = !this.validationService.isPincodeValid(
      this.beneficiary.companyPinCode
    );
    !this.beneficiary.companyPinCode ? (this.cPinError = false) : true;

    return !this.cPinError && !!this.beneficiary.companyPinCode;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.beneficiary.aadhaarNo
    );
    !this.beneficiary.aadhaarNo ? (this.aadhaarError = false) : true;

    return !this.aadhaarError && !!this.beneficiary.aadhaarNo;
  }

  validateGst() {
    this.gstError = !this.validationService.isPanValid(
      this.beneficiary.gstNo
    );
    !this.beneficiary.gstNo ? (this.gstError = false) : true;

    return !this.gstError && !!this.beneficiary.gstNo;
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.beneficiary.ifscCode
    );
    !this.beneficiary.ifscCode ? (this.ifscError = false) : true;

    return !this.ifscError && !!this.beneficiary.ifscCode;
  }
  accountDetailsChanged() {
    this.verifyClicked = false;
    if (
      this.currentEnvironment == 'uat' ||
      this.currentEnvironment === 'production'
    ) {
      if (!this.ifscError) {
        this.spinner.show();
        this.commercialVendorservice
          .bankBranchDetails(this.beneficiary.ifscCode)
          .subscribe(
            (res) => {
              this.spinner.hide();
              this.bankBranchDetailsRes = res.body;
              console.log(this.bankBranchDetailsRes, 'bankbranchdetails');
              this.beneficiary.bankBranch =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Branch_Name;
              this.beneficiary.bankName =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Bank_Name;
            },
            () => {
              this.spinner.hide();
              this.notificationService.alertError('Error Occured', '');
            }
          );
      }
    }
  }

  accountChanged() {
    this.verifyClicked = false;
  }

  saveBeneficiary() {
    const isFormValid =
      this.validateMobile() &&
      this.validatePin() &&
      this.validateAadhaar() &&
      this.validateIfsc() &&
      this.beneficiary.name &&
    // this.beneficiary.designation &&
      // this.beneficiary.staffId &&
      this.beneficiary.doorNo &&
      this.beneficiary.street &&
      this.beneficiary.city &&
      this.beneficiary.district &&
      this.beneficiary.state &&
      this.beneficiary.companyDoorNo &&
      this.beneficiary.companyStreet &&
      this.beneficiary.companyDistrict &&
      this.beneficiary.companyCity &&
      this.beneficiary.companyState &&
      // this.beneficiary.bankId &&
      // this.beneficiary.bankBranch &&
      // this.beneficiary.bankName &&
      this.beneficiary.bankAccName &&
      this.beneficiary.accountNumber &&
      this.beneficiary.ifscCode
      !this.accountInvalidError &&
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
 

    this.ngSelectInvalid = false;
    this.beneficaryService.save(this.beneficiary).subscribe(
      (res: HttpResponse<any>) => {
        this.beneficiaryCreationDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (err) => {
        this.onError();
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  onSelectRow($event) {
    this.beneficaryService.getbeneFiciary($event.value.id).subscribe(
      (res: HttpResponse<any>) => {
        this.readonly = true;
        this.edit = true;
        this.beneficiary = res.body;
        this.beneficiaryCreationDialog = true;
        this.verifyClicked = true;
        if (this.beneficiary.bankName) {
          this.getBankBranchList();
          this.getBankBranchdetais();
        }
      },
      () => {
        this.onError();
      }
    );
    if ($event.type === 'select') {
      this.readonly = true;
      this.edit = true;
    } else {
      this.isDelete = true;
      this.readonly = true;
    }
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.villageLevelDto = null;
    this.BlockLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    this.villageLevelDto = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  deleteVendor() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete this ' +
        this.beneficiary.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.beneficaryService.delete(this.beneficiary.id).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess('Vendor Deleted Sucessfully','');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }
}
