import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';

@Component({
  selector: 'app-employee-transfer-report',
  templateUrl: './employee-transfer-report.component.html',
  styleUrls: ['./employee-transfer-report.component.scss']
})
export class EmployeeTransferReportComponent implements OnInit {

  constructor(public reportService:ReportService,
    private spinner:NgxSpinnerService,
    public fontService: FontService) { }

  ngOnInit(): void {
  }

  download(){
    this.spinner.show();
    this.reportService.employeeTransferReport().subscribe(
      (response)=>{
        this.spinner.hide();
        this.saveBlobToExcel(response.body,'Employee Transfer Report');
      },
      ()=>{
        this.spinner.hide();
      }
    )
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
      }

}
