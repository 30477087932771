<div class="row bg">
    <div class="col-sm-6 col-md-6 login-form ">
        <div class="font-login mb-4" *ngIf="!otpverified">Forgot Password?</div>
        <div class="font-login mb-4" *ngIf="otpverified">Enter your new Password</div>
        <div class="login-content" *ngIf="!otpverified">
            <form class="form" role="form" (ngSubmit)="verifyotp()" [formGroup]="otpForm">
                <div class="form-group mb-4">
                    <input id="username" type="text" maxlength="55" class="form-control full" placeholder="USERNAME" formControlName="username" name="username" uppercase required autocomplete="off">
                    <div *ngIf="inValidUser" class="text-danger mt-2">
                        <small>Please enter valid user name</small>
                    </div>
                </div>
                <div class="form-group mb-4" *ngIf="showOtp">
                    <input id="otp" name="otp" maxlength="6" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" formControlName="otp" type="text" class="form-control full" placeholder="ENTER OTP">
                    <div *ngIf="inValidOtp" class="text-danger mt-2">
                        <small>Invalid OTP</small>
                    </div>
                    <div class="mt-2">
                        Resend OTP in: {{ otpTimer }} seconds
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col" *ngIf="showOtp">
                        <button type="submit" class="btn btn-secondary">Submit</button>
                    </div>
                    <div class="col text-end">
                        <button type="button" [disabled]="disableButton" class="btn btn-secondary" (click)="sendotp()">{{otpButtonName}}</button>
                    </div>
                </div>
                <div class="footer-logo">
                    <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                    <img class="ib-logo" src="/./assets/img/logo 1.svg" alt="Image">
                </div>
            </form>
        </div>
        <div class="login-content" *ngIf="otpverified">
            <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="forgotPwdForm">

                <div class="form-group mb-4 eye-container">
                    <input id="password" name="password" maxlength="16" autocomplete="off" formControlName="password" [type]="hide ? 'password' : 'text'" required class="form-control full" placeholder="NEW PASSWORD">
                    <i class="pi pi-eye" *ngIf="hide" (click)="hide=false"></i>
                    <i class="pi pi-eye-slash" *ngIf="!hide" (click)="hide=true"></i>
                </div>
                <div class="form-group mb-4 eye-container">
                    <input id="confirmPassword" maxlength="16" name="confirmPassword" autocomplete="off" formControlName="confirmPassword" class="form-control full" [type]="phide ? 'password' : 'text'" required placeholder="CONFIRM NEW PASSWORD">
                    <i class="pi pi-eye" *ngIf="phide" (click)="phide=false"></i>
                    <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide=true"></i>
                </div>
                <div *ngIf="pwdMismatch" class="text-danger mb-2">
                    <small>The passwords you entered do not match. Please re-enter</small>
                </div>
                <div *ngIf="passwordError" class="text-danger mb-2">
                    <small>{{passwordError}}</small>
                </div>
                <div class="mb-4">
                    <button type="submit" [disabled]="!forgotPwdForm.valid" class="btn btn-secondary">Submit</button>
                </div>
                <div class="footer-logo">
                    <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                    <img class="ib-logo" src="/./assets/img/logo 1.svg" alt="Image">
                </div>
            </form>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 login-caption">
        <div class="row">
            <div class="col-6 g-0 image">
                <img class="image" src="/./assets/img/1 1.png" alt="Image">

            </div>
            <div class="col-6 gy-0 graphic-wrapper">
                <img class="graphic" src="/./assets/img/4343973 1.svg" alt="Image">
            </div>
        </div>
        <div class="row gx-0 align-items-center caption">
            <div class="col-2" style="display:table">
                <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image">
            </div>
            <div class="col-10 ">
                <p class="tn-font">ஊரக வளர்ச்சி மற்றும் ஊராட்சித் துறை</p>
                <p>Department of Rural Development and Panchayat Raj</p>
            </div>
        </div>
    </div>
</div>