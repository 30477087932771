import { userDto } from './../../module/user/user';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { MenuItem } from 'primeng/api';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { TranslateService } from '@ngx-translate/core';
import screenfull from 'screenfull';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NavbarService } from './navbar.service';
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'ib-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  isStateadmin = true
  openAPIEnabled?: boolean;
  version = '';
  isFullScreenEnabled: boolean = false;
  hidden: boolean = true;
  headerItems: MenuItem[] = [
    {
      // label: 'usermenu.myAccount',
      label: this.translateService.instant('usermenu.myAccount'),
      icon: 'pi pi-user',
      routerLink: '/',
    },
    {
      // label: 'usermenu.settings',
      label: this.translateService.instant('usermenu.settings'),
      icon: 'pi pi-cog',
      routerLink: '/',
    },
    // {
    //   label: 'Logout',
    //   icon: 'pi pi-sign-out',
    //   routerLink: '/'
    // }
  ];

  items = [
    { routerLink: 'home', jhiTranslate: 'dashboard.name' },
    {
      label: 'Profile',
      routerLink: 'profile',
      jhiTranslate: 'breadcrumb.profile.name',
    },

    {
      label: 'User',
      jhiTranslate: 'breadcrumb.user.name',
      items: [
        {
          label: 'User List',
          routerLink: 'user',
          jhiTranslate: 'breadcrumb.user.list',
        },
        {
          label: 'Zonal List',
          routerLink: 'home',
          jhiTranslate: 'breadcrumb.zonal.list',
        },
        {
          label: 'Profile List',
          routerLink: 'profile',
          jhiTranslate: 'breadcrumb.profile.name',
        },
      ],
    },
  ];

  public selectedLanguage: string = 'English';
  showMenu = false;
  menu: any;

  isFullScreen = false;
  userName: any;
  disableMenu: boolean;
  role: any;
  hidePaymentStatus: boolean;
  roleCode: string;
  name: any;
  disableTneb: boolean;
  disableTwad: boolean;
  disableTnebPayment: boolean;
  constructor(
    private loginService: SigninService,
    public sessionStorageService: SessionStorageService,
    public localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer2,
    private fontService: FontService,
    private navbarService: NavbarService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.fontService.setFontClass(this.translateService.currentLang);
    });
  }
  ngOnInit() {
    this.navbarService.getMenu().subscribe(
      (res: HttpResponse<any>) => {
        this.menu = res.body;
      },
      () => { }
    );
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.userName = this.sessionStorageService.retrieve('profilename');
        this.name = res.body.userName;
        this.sessionStorageService.store('levelmasterId',res.body.levelMasterId);
        this.sessionStorageService.store('roleCode',res.body.role.code)
        if(!res.body.isTangedcoEnabled && (res.body.role.code ==='VMAKER' || res.body.role.code ==='VC1' || res.body.role.code === 'VC2')){
          this.disableTneb = true;
        }
        if(!res.body.isTangedcoPaymentEnabled && (res.body.role.code ==='VMAKER' || res.body.role.code ==='VC1' || res.body.role.code === 'VC2')){
          this.disableTnebPayment = true;
        }
        if (!res.body.isTwadEnabled && (res.body.role.code === 'VMAKER' || res.body.role.code === 'VC1' || res.body.role.code === 'VC2')) {
          this.disableTwad = true;
        }
        if (res.body.profileStatus === 'No') {
          this.sessionStorageService.store('refresh', 'Yes');
          this.disableMenu = true;
          this.router.navigate(['/profile']);
        } else {
          this.disableMenu = false;
        }
        if (res.body.role.name.startsWith('District') && res.body.role.code !== 'DAD') {
          this.hidden = false;
        }
        this.roleCode = res.body.role.code;
      },
      (onError) => { }
    );
    this.setLanguage('en');
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  hideMenu(menuName) {
    const index = this.menu?.findIndex((_) => _.menuTab === menuName);

    if (index >= 0) {
      return false;
    } else {
      return true;
    }
  }
  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => { },
      (err) => { }
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate(['']);
  }

  setLanguage(language: string): void {
    this.translateService.use(language);
    this.translateService.reloadLang(language);
    if (language === 'en') {
      this.selectedLanguage = 'nav.en';
      this.renderer.removeClass(document.body, 'tamil-font');
    } else if (language === 'ta') {
      this.selectedLanguage = 'nav.ta';
      this.renderer.addClass(document.body, 'tamil-font');
    }
    // Update the text content of the button using translation
    this.translateService
      .get(this.selectedLanguage)
      .subscribe((translation: string) => {
        const button = document.querySelector('.dropdown-toggle');
        if (button instanceof HTMLElement) {
          button.textContent = translation;
        }
      });

    // Update the body and dialog fonts
    const bodyElement = document.getElementsByTagName('body')[0];
    if (language === 'ta') {
      bodyElement.classList.add('tamil-font');
    } else {
      bodyElement.classList.remove('tamil-font');
    }

    const dialogElement = document.querySelectorAll('.p-dialog-title');
    if (dialogElement) {
      dialogElement.forEach((element) => {
        if (language === 'en') {
          element.classList.add('english-heading');
          element.classList.remove('tamil-heading');
        } else {
          element.classList.add('tamil-heading');
          element.classList.remove('english-heading');
        }
      });
    }
    this.cdr.detectChanges();
  }

  // setLanguage(language: string): void {
  //   // this.clearTranslationCache();
  //   this.translateService.use(language);
  //   // this.translateService.reloadLang(language);
  //   if (language === "en") {
  //     this.selectedLanguage = "English";
  //     this.renderer.removeClass(document.body, 'tamil-font');

  //   }
  //   else if (language === "ta") {
  //     this.selectedLanguage = "Tamil";
  //     this.renderer.addClass(document.body, 'tamil-font');

  //   }

  //   const bodyElement = document.getElementsByTagName('body')[0];
  //   if (language === 'ta') {
  //     bodyElement.classList.add('tamil-font');
  //   } else {
  //     bodyElement.classList.remove('tamil-font');
  //   }

  //   const dialogElement = document.querySelector('.p-component-overlay');
  //   if (dialogElement) {
  //     if (language === 'ta') {
  //       dialogElement.classList.add('tamil-font');

  //     } else {
  //       dialogElement.classList.remove('tamil-font');
  //     }
  //   }
  //   // this.cdr.detectChanges();
  // }

  setLanguageOld(language: string): void {
    this.translateService.use(language);
    if (language === 'en') {
      this.selectedLanguage = 'English';
    } else if (language === 'ta') {
      this.selectedLanguage = 'Tamil';
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  toggleFullScreen() {
    const sf = screenfull as any; // cast to any to avoid typescript errors
    if (sf.isEnabled) {
      sf.toggle();
      this.isFullScreen = !this.isFullScreen;
    }
  }
}
