<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="tangedco.menuConfiguartion">

                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange()">
            <p-tabPanel header="TANGEDCO">
                <div class="card p-3">
                    <div class="row mt-3">
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                            <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="districtDto" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div *ngIf="!isDistrict" class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                            <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="blockDto" [items]="blockLevelList" (change)="onBlockChange('tneb')" bindLabel="name" appearance="outline" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div *ngIf="districtDto" class="col-sm-6 col-md-3">
                            <p-checkbox [(ngModel)]="isDistrict" binary="true"></p-checkbox>
                            <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass">Is Enable Fully District</label>
                        </div>
                        <div *ngIf="blockDto && !isDistrict" class="col-sm-6 col-md-3">
                            <p-checkbox [(ngModel)]="isBlock" (onChange)="isBlockChange('tneb')" binary="true"></p-checkbox>
                            <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass">Is Enable Fully Block</label>
                        </div>
                    </div>
                    <div *ngIf="blockDto && !isDistrict && enableTable" class="row mt-3">
                        <p-table [value]="villageList" [resizableColumns]="true" responsiveLayout="scroll" [paginator]="true" [rows]="20" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,20, 25, 50]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;"></th>
                                    <th style="text-align: center;">Village Name</th>
                                    <th style="text-align: center;"> Village Lgd Code</th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">

                                <tr>
                                    <td style="text-align: center;">
                                        <p-checkbox [disabled]="list.readOnly" (onChange)="isTangedco(list,'tneb')" [(ngModel)]="list.isTangedcoEnabled" binary="true"></p-checkbox>
                                    </td>
                                    <td style="text-align: center;">{{list.villageName}}</td>
                                    <td style="text-align: center;">{{list.villageLgd}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="row">
                        <p-toolbar>
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-end">
                                <button pButton pRipple type="button" label="Submit" (click)="onsave('TANGEDCO')" class="p-button-success"></button>
                            </div>
                        </p-toolbar>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="TWAD">
                <div class="card p-3">
                    <div class="row mt-3">
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                            <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="districtDto" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div *ngIf="!isDistrict" class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                            <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="blockDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockChange('twat')" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div *ngIf="districtDto" class="col-sm-6 col-md-3">
                            <p-checkbox [(ngModel)]="isDistrict" binary="true"></p-checkbox>
                            <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass">Is Enable Fully District</label>
                        </div>
                        <div *ngIf="blockDto && !isDistrict" class="col-sm-6 col-md-3">
                            <p-checkbox [(ngModel)]="isBlock" (onChange)="isBlockChange('twat')" binary="true"></p-checkbox>
                            <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass">Is Enable Fully Block</label>
                        </div>
                    </div>
                    <div *ngIf="blockDto && !isDistrict && enableTable" class="row mt-3">
                        <p-table [value]="villageList" [resizableColumns]="true" responsiveLayout="scroll" [paginator]="true" [rows]="20" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,20, 25, 50]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;"></th>
                                    <th style="text-align: center;">Village Name</th>
                                    <th style="text-align: center;"> Village Lgd Code</th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">

                                <tr>
                                    <td style="text-align: center;">
                                        <p-checkbox [disabled]="list.readOnly" (onChange)="isTangedco(list,'twat')" [(ngModel)]="list.isTwadEnabled" binary="true"></p-checkbox>
                                    </td>
                                    <td style="text-align: center;">{{list.villageName}}</td>
                                    <td style="text-align: center;">{{list.villageLgd}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="row">
                        <p-toolbar>
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-end">
                                <button pButton pRipple type="button" label="Submit" (click)="onsave('TWAD')" class="p-button-success"></button>
                            </div>
                        </p-toolbar>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="TANGEDCO Payment">
                <div class="card p-3">
                    <div class="row mt-3">
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                            <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="districtDto" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div *ngIf="!isDistrict" class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                            <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="blockDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockChange('tnebPayment')" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true"
                                class="form-control dropdown-width">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div *ngIf="districtDto" class="col-sm-6 col-md-3">
                            <p-checkbox [(ngModel)]="isDistrict" binary="true"></p-checkbox>
                            <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass">Is Enable Fully District</label>
                        </div>
                        <div *ngIf="blockDto && !isDistrict" class="col-sm-6 col-md-3">
                            <p-checkbox [(ngModel)]="isBlock" (onChange)="isBlockChange('tnebPayment')" binary="true"></p-checkbox>
                            <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass">Is Enable Fully Block</label>
                        </div>
                    </div>
                    <div *ngIf="blockDto && !isDistrict && enableTable" class="row mt-3">
                        <p-table [value]="villageList" [resizableColumns]="true" responsiveLayout="scroll" [paginator]="true" [rows]="20" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,20, 25, 50]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center;"></th>
                                    <th style="text-align: center;">Village Name</th>
                                    <th style="text-align: center;"> Village Lgd Code</th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">

                                <tr>
                                    <td style="text-align: center;">
                                        <p-checkbox [disabled]="list.readOnly" (onChange)="isTangedco(list,'tnebPayment')" [(ngModel)]="list.isTangedcoPaymentEnabled" binary="true"></p-checkbox>
                                    </td>
                                    <td style="text-align: center;">{{list.villageName}}</td>
                                    <td style="text-align: center;">{{list.villageLgd}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="row">
                        <p-toolbar>
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-end">
                                <button pButton pRipple type="button" label="Submit" (click)="onsave('TANGEDCOPAYMENT')" class="p-button-success"></button>
                            </div>
                        </p-toolbar>
                    </div>
                </div>
            </p-tabPanel>

        </p-tabView>

    </div>
</div>