export class OtherDepartments {
    id: number;
    name: string;
    createdOn?: Date;
    updatedOn?: Date;
    createdBy?: number;
    updatedBy?: number;
    activeFlag: string;
}

export class UniversalVendor {
    id?: number;
    vendorName: string;
    gstNo: string;
    emailId: string;
    tinNo: string;
    tanNo: string;
    levelMasterId: number;
    department: OtherDepartments;
    bankAccName: string;
    bankName: string;
    bankBranch: string;
    ifscCode: string;
    accountNumber: string;
    createdOn?: Date;
    updatedOn?: Date;
    createdBy?: number;
    updatedBy?: number;
    activeFlag: string;
    micr: string;
}
