<p-table #dt [value]="listItems | async" scrollDirection="both" styleClass="dyanamic-table" columnResizeMode='fit' [paginator]="paginator" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount"
    [lazyLoadOnInit]="lazyLoadOnInit" responsiveLayout="scroll" [columns]="selectedColumns" [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [sortOrder]="sortOrder" [sortField]="sortField"
    [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'" [first]="first">
    <ng-template pTemplate="caption">
        <p-multiSelect *ngIf="showMultiSelect" [options]="columnOptions" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns">
        </p-multiSelect>
        <!-- <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="Search keyword" />
      </span> -->
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let column of columns">
                <th pReorderableColumn [pSortableColumn]="column.field" scope="col">
                    <!-- <th *ngIf="column.isSortable && column.type !== 'dropDown'" pReorderableColumn [pSortableColumn]="column.field"
          scope="col"> -->
                    <div class="head-wrapper">
                        <span class="left" jhiTranslate={{column.jhiTranslate}}>{{column.header}}</span>
                        <span class="right"> <p-columnFilter *ngIf="column.isFilterable" type="text" field={{column.field}} display="menu"
                [matchModeOptions]="matchModeOptions" [showAddButton]="false" [hideOnClear]="true">
              </p-columnFilter></span>
                    </div>


                </th>
                <!-- <th *ngIf="column.type === 'dropDown'" style="min-width:230px">
          <span jhiTranslate={{column.jhiTranslate}}>{{column.header}}</span>
          <p-columnFilter  field="{{column.field}}Id" [showMenu]="false" matchMode="equals">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [ngModel]="value" [options]="column.option" (onChange)="filter($event.value)" placeholder="Select" >
                <ng-template let-option pTemplate="item">
                  <span [class]="'customer-badge status-' + option.value">{{option[0]}}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th> -->

            </ng-container>
            <th *ngIf="enableAction" alignFrozen="right" pFrozenColumn>
                Action
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-record let-columns="columns" let-rowData let-index="rowIndex">
        <!-- <tr (click)="onSelectRow(record, 'select')" > -->
        <tr>
            <ng-container *ngFor="let col of columns" [ngSwitch]="col.type">
                <td *ngSwitchCase="'date'">
                    {{rowData[col.field]| date:'dd/MM/yyyy' }}
                </td>
                <td *ngSwitchCase="'dateTime'">
                    {{ rowData[col.field] | date : 'dd/MMM/yy HH:mm:ss' }}
                </td>
                <td *ngSwitchCase="'dropDown'">
                    {{(rowData[col.field])?.name}}
                </td>
                <td *ngSwitchCase="'dropDown_with_other_field'">
                    {{ rowData?.[col.field]?.[col.Dfield] }}
                </td>
                <td *ngSwitchCase="'amount'">
                    {{ rowData[col.field] | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                </td>
                <td *ngSwitchCase="'type'">
                    {{(rowData[col.field])?.name}}
                </td>
                <td *ngSwitchCase="'crfield'">
                    <div *ngIf="rowData[col.cfield] === 'CR'"> {{(rowData[col.field])}}</div>
                    <div *ngIf="rowData[col.cfield] !== 'CR'"> -</div>
                </td>
                <td *ngSwitchCase="'dbfield'">
                    <div *ngIf="rowData[col.cfield] === 'DR'"> {{(rowData[col.field])}}</div>
                    <div *ngIf="rowData[col.cfield] !== 'DR'"> -</div>
                </td>
                <td *ngSwitchCase="'color'">
                    <div *ngIf="rowData[col.field] === 'Not Initiated' || rowData[col.field] === 'Processing'" style="color:#F59E0B;">{{rowData[col.field]}}</div>
                    <div *ngIf="rowData[col.field] === 'Success'" style="color:#22C55E;">{{rowData[col.field]}}</div>
                    <div *ngIf="rowData[col.field] === 'Failed'" style="color:#EF4444;">{{rowData[col.field]}}</div>
                    <div *ngIf="rowData[col.field] === 'Partially Done'" style="color:#183883;">{{rowData[col.field]}}</div>

                </td>
                <td *ngIf="!col.toolTip && !col.type">
                    {{rowData[col.field]}}
                </td>
                <td *ngIf="col.toolTip && !col.type" [pTooltip]="rowData[col.toolTipText]">
                    {{rowData[col.field]}}
                </td>

            </ng-container>
            <td alignFrozen="right" pFrozenColumn>
                <button *ngIf="enableApproved && !record?.isApproved && !record?.isRejected" pButton pRipple type="button" label="Approve/Reject" class="p-button-text" (click)="onSelectRow(record, 'approve')">
                </button>
                <button *ngIf="enableTnebDelete && record?.isRejected" pButton pRipple type="button" label="Delete" class="p-button-text" (click)="onSelectRow(record, 'delete')">
                </button>


                <button *ngIf="enableDeactivate && record?.activeFlag === 'Yes'" pButton pRipple type="button" label="Deactivate" class="p-button-text" (click)="onSelectRow(record, 'deactivate')">
                </button>

                <button *ngIf="disableMenu" pButton pRipple type="button" label="Disable" class="p-button-text" (click)="onSelectRow(record, 'disable')">
                </button>


                <button *ngIf="!disableEditIcon" pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-info" (click)="onSelectRow(record, 'select')">
        </button>
                <button *ngIf="record?.edit" pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-info" (click)="onSelectRow(record, 'select')">
        </button>
                <!-- <button pButton pRipple type="button" icon="pi pi-eye"
          class="p-button-rounded p-button-text p-button-info">
        </button> -->
                <button *ngIf="!disableDeleteIcon" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="onSelectRow(record, 'delete')">
        </button>
                <button *ngIf="record?.delete" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="onSelectRow(record, 'delete')">
        </button>
                <button *ngIf="enableView" pButton pRipple type="button" label="View" class="p-button-text" (click)="onSelectRow(record, 'view')">
        </button>
                <button *ngIf="showInactiveButton" pButton pRipple type="button" label="Inactive" class="p-button-text" (click)="onSelectRow(record, 'delete')">
        </button>
                <button *ngIf="showTransferButton" pButton pRipple type="button" label="Transfer" class="p-button-text" (click)="onSelectRow(record, 'transfer')">
        </button>

                <button *ngIf="showProfileButton" pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-text p-button-info" (click)="navigateProfile()">
        </button>
                <button *ngIf="showRedirectButton" pButton pRipple type="button" icon="pi pi-arrow-circle-right" class="p-button-rounded p-button-text" (click)="navigatetoVoucherPage(record)">
        </button>
                <button *ngIf="showTableButton" pButton pRipple type="button" icon="pi pi-th-large" class="p-button-rounded p-button-text" (click)="onSelectshowTable(record)">
        </button>
                <button *ngIf="showResetPasswordButton" pButton pRipple type="button" icon="pi pi-unlock" class="p-button-rounded p-button-text" (click)="onSelectRow(record, 'resetPwd')">
        </button>
                <button *ngIf="checkStatusButton && !record?.showReject" pButton pRipple type="button" label="Confirm" class="p-button-text" (click)="onSelectRow(record, 'status')">
      </button>
                <button *ngIf="showReject" pButton pRipple type="button" label="Reject" class="p-button-text" (click)="onSelectRow(record, 'delete')">
    </button>
                <button *ngIf="showRevert" pButton pRipple type="button" label="Revert" class="p-button-text" (click)="onSelectRow(record, 'delete')">
    </button>
                <button *ngIf="record?.showReject" pButton pRipple type="button" label="Revert" class="p-button-text" (click)="onSelectRow(record, 'delete')">
    </button>
                <button *ngIf="showRightButton" pButton pRipple type="button" icon="pi pi-angle-right" class="p-button-rounded p-button-text" (click)="onSelectRow(record, 'loadBulk')">
      </button>
                <button *ngIf="showDialogTable" pButton pRipple type="button" icon="pi pi-th-large" class="p-button-rounded p-button-text" (click)="onSelectshowTable(record?.id)">
        </button>
            </td>
        </tr>
    </ng-template>
</p-table>