import { Component } from '@angular/core';

@Component({
  selector: 'app-tneb-transaction-report',
  templateUrl: './tneb-transaction-report.component.html',
  styleUrl: './tneb-transaction-report.component.scss'
})
export class TnebTransactionReportComponent {

}
