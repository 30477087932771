import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { LimitAllocationHistory, fundTempModel } from './funds-excel-upload.model';


@Injectable({ providedIn: 'root' })
export class FundsExcelUploadService {
  protected uploadExcelUrl =
    environment.SERVER_API_URL + 'api/iaLimitAllocationExcel/upload';
  protected statusCheckUrl = environment.SERVER_API_URL + 'api/CheckUploadStatus';
  protected getIAComponentLimitTempUrl = environment.SERVER_API_URL + 'api/getIAComponentLimitTempValidByFilter';
  protected saveIaComponentLimitAndFundTransactionUrl = environment.SERVER_API_URL + 'api/saveIaComponentLimitAndFundTransaction';
  protected downloadLimitTemplateUrl = environment.SERVER_API_URL + 'api/downloadLimitTemplate';
  protected rejectAllocationUrl = environment.SERVER_API_URL + 'api/rejectAllocation';
  protected getLimitHistoryByFilterUrl = environment.SERVER_API_URL + 'api/getLimitHistoryByFilter';
  protected twadUploadUrl = environment.SERVER_API_URL + 'api/twadUpload';
  protected getAllTwadBillsTempByFilterUrl = environment.SERVER_API_URL + 'api/getAllTwadBillsTempByFilter';
  protected saveTwadBillsUrl = environment.SERVER_API_URL + 'api/saveTwadBills';
  protected rejectTwadBillsUrl = environment.SERVER_API_URL + 'api/rejectTwadBills';
   
  
  constructor(protected http: HttpClient) {}

  uploadFile(formData: FormData): Observable<any> {
    return this.http.post(this.uploadExcelUrl, formData,{observe: 'response',responseType: 'text'});
  }
  twadUploadFile(formData: FormData): Observable<any> {
    return this.http.post(this.twadUploadUrl, formData,{observe: 'response',responseType: 'text'});
  }
  saveLimitFund() {
    return this.http.post(this.saveIaComponentLimitAndFundTransactionUrl,{responseType: 'text'});
  }
  rejectAllocation() {
    return this.http.get(this.rejectAllocationUrl,{responseType: 'text'});
  }
  twadsaveBill() {
    return this.http.post(this.saveTwadBillsUrl,{responseType: 'text'});
  }
  rejectTwadBills() {
    return this.http.get(this.rejectTwadBillsUrl,{responseType: 'text'});
  }
  statusCheck(req): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.statusCheckUrl,{observe: 'response',params: options,responseType: 'text'});
  }
  vaildFilter(req?: any, filter?: any): Observable<HttpResponse<fundTempModel[]>> {
    const options = createRequestOption(req);
    return this.http.get<fundTempModel[]>(this.getIAComponentLimitTempUrl, {
      params: options,
      observe: 'response',
    });
  }
  getLimitHistoryByFilter(req?: any,filter?:any): Observable<HttpResponse<LimitAllocationHistory[]>> {
    const options = createRequestOption(req);
    return this.http.post<LimitAllocationHistory[]>(this.getLimitHistoryByFilterUrl,filter,{
      params: options,
      observe: 'response',
    });
  }
  getAllTwadBillsTempByFilter(req?: any,filter?:any): Observable<HttpResponse<any[]>> {
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.getAllTwadBillsTempByFilterUrl,filter,{
      params: options,
      observe: 'response',
    });
  }
  downloadLimitTemplate(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.downloadLimitTemplateUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  
}
