import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { PaymentReportDto } from '../report.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { VendorTransaction } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { filterQuery } from 'src/app/shared/common.model';
import { DatePipe, formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { ComponentType } from 'src/app/module/component-type/component-type';

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {
  paymentReportDto:PaymentReportDto;
  paymentBlockReportDto:PaymentReportDto;
  paymentVillageReportDto:PaymentReportDto;
  activeIndex=0;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  cols: any;
  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  page: number;
  enableStatusVoucher: boolean;
  personalFlag: string;
  villageLevelMasterId: any;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;
  dateError: boolean;
  compTypeList:ComponentType[];
  selectedcompType:any;
  compId: any=null;

  statusList = [
    { 
      name: 'Completed',
      value:'O.K.'
   }
  ];
  selectedStatus: any;
  isBMRole: boolean;


  constructor(public reportService:ReportService,
      public fontService: FontService,
      private spinner: NgxSpinnerService,
      private loginService:SigninService,
      private paymentService:PaymentService,
      private datePipe: DatePipe,
      private componentTypeService:ComponentTypeService
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.selectedStatus=this.statusList[0];
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
       this.loadDistrict();
      },
      (error)=>{});

      this.componentTypeService.filter().subscribe(
        (res: HttpResponse<ComponentType[]>) => {
          this.compTypeList=res.body.filter(item => item.name.startsWith('Village'));
        },
        ()=>{});
      
    this.cols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        jhiTranslate: 'tableHead.voucher.no',
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transaction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type:'amount'
      },
      {
        field: 'subComponentName',
        header: 'Sub Component Name',
        jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isSelectcolumn: true,
      }
    ];
  }

  onTabChange(event: any): void {
    if (event.index === 0 && (this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' )) {
      this.paymentBlockReportDto=null;
      this.paymentVillageReportDto=null;
    }
    else if(event.index === 0 && (this.roleCode=='DM'|| this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode==='DAD')) {
      this.paymentVillageReportDto=null;
    }
  }

  loadDistrict(){
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
    this.startDate=this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
      parentId: 1,
      fromDate: this.startDate,
      toDate: this.endDate,
      status:this.selectedStatus
    };
    if (this.selectedcompType) {
      payload.id = this.selectedcompType.id;
    }      
       
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
     {
      this.reportService.paymentDistrictReport(payload).subscribe(
        (res: HttpResponse<PaymentReportDto>) => {
          this.paymentReportDto=res.body;
          this.paymentReportDto.paymentReportDtos.forEach((element)=>{
            for (const key in element) {
              if (element.hasOwnProperty(key) && element[key] === null) {
                element[key] = 0;
              }
          }
        })
          this.spinner.hide();
        },
        (err)=>{ 
          this.spinner.hide();
        })
      }
      else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode==='DAD'){
        this.loadBlock(this.levelMasterId);
        this.isDMRole=true;
      } else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
        this.loadVillage(this.levelMasterId);
        this.isBMRole=true;
      }
  }

  loadBlock(id){
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
      this.startDate=this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
      this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
      parentId: id,
      fromDate: this.startDate,
      toDate: this.endDate,
      status:this.selectedStatus
    };
    if (this.selectedcompType) {
      payload.id = this.selectedcompType.id;
    }  
    this.reportService.paymentBlockReport(payload).subscribe(
      (res: HttpResponse<PaymentReportDto>) => {
        this.paymentBlockReportDto=res.body;
        this.paymentBlockReportDto.paymentReportDtos.forEach((element)=>{
          for (const key in element) {
            if (element.hasOwnProperty(key) && element[key] === null) {
              element[key] = 0;
            }
        }
      })
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=1;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode==='DAD'){
          this.activeIndex=0;
        }
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  loadVillage(id){
    this.spinner.show();
    const payload: any = {
      parentId: id,
      fromDate: this.startDate,
      toDate: this.endDate,
      status:this.selectedStatus
    };
    if (this.selectedcompType) {
      payload.id = this.selectedcompType.id;
    } 
    this.reportService.paymentVillageReport(payload).subscribe(
      (res: HttpResponse<PaymentReportDto>) => {
        res.body.paymentReportDtos.forEach((element)=>{
          for (const key in element) {
            if (element.hasOwnProperty(key) && element[key] === null) {
              element[key] = "0";
            }
        }
      })
      this.paymentVillageReportDto=res.body;

        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=2;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode==='DAD'){
          this.activeIndex=1;
        } else if(this.roleCode=='BM' || this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
          this.activeIndex=0;
        } 
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }

  loadVoucher(id,flag:string){
    this.personalFlag=flag;
    this.villageLevelMasterId=id;
    this.enableStatusVoucher=true;
    this.loadPage(1);
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
  //   var i;
  //   if (this.filterProps){
  //       for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //         if (this.filterProps[i].key === 'levelMasterId') {
  //           this.filterProps.splice(i, 1);
  //         }
  //       }
  //     for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //       if (this.filterProps[i].key === 'personalFlag') {
  //         this.filterProps.splice(i, 1);
  //       }
  //     }
  //     for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //       if (this.filterProps[i].key === 'activeFlag') {
  //         this.filterProps.splice(i, 1);
  //       }
  //   }
  //   for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //     if (this.filterProps[i].key === 'transactionStatus') {
  //       this.filterProps.splice(i, 1);
  //     }
  //   }
  //   for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //     if (this.filterProps[i].key === 'fromDate') {
  //       this.filterProps.splice(i, 1);
  //     }
  //   }
  //   for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //     if (this.filterProps[i].key === 'toDate') {
  //       this.filterProps.splice(i, 1);
  //     }
  //   }
  //   for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
  //     if (this.filterProps[i].key === 'id') {
  //       this.filterProps.splice(i, 1);
  //     }
  // }
  // }
  //   let query = new filterQuery();
  //   query.key = 'levelMasterId';
  //   query.operation = 'equals';
  //   query.value = this.villageLevelMasterId;
  //   this.filterProps = this.filterProps ? this.filterProps : [];
  //   this.filterProps.push(query);
  //   query = new filterQuery();
  //   query.key = 'personalFlag';
  //   query.operation = 'equals';
  //   query.value = this.personalFlag;
  //   this.filterProps.push(query);
  //   query = new filterQuery();
  //   query.key = 'activeFlag';
  //   query.operation = 'equals';
  //   query.value = 'YES';
  //   this.filterProps.push(query);
  //   query = new filterQuery();
  //     query.key = 'transactionStatus';
  //     query.operation = 'equals';
  //     query.value = this.selectedStatus;
  //   this.filterProps.push(query);
  //   query = new filterQuery();
  //   query.key = 'fromDate';
  //   query.operation = 'equals';
  //   query.value = this.startDate;
  //   this.filterProps.push(query);
  //   query = new filterQuery();
  //   query.key = 'toDate';
  //   query.operation = 'equals';
  //   query.value = this.endDate;
  //   this.filterProps.push(query);
  // if(this.selectedcompType){
  //   query = new filterQuery();
  //   query.key = 'id';
  //   query.operation = 'equals';
  //   query.value = this.selectedcompType.id;
  //   this.filterProps.push(query);
  // }
  
  const payload: any = {
    levelMasterId:this.villageLevelMasterId,
    fromDate: this.startDate,
    toDate: this.endDate,
    status:this.selectedStatus,
    page: pageToLoad - 1,
    size: this.itemsPerPage
  };
  switch (this.personalFlag) {
    case 'YES':
      payload.flag = 'Yes';
      break;
    case 'NO':
      payload.flag = 'No';
      break;
    default:
      payload.flag = this.personalFlag;
      break;
  }
  if (this.selectedcompType) {
    payload.id = this.selectedcompType.id;
  } 
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.reportService.getPaymentVouchersByVillage( payload).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: VendorTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.spinner.hide();
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog(){
    this.enableStatusVoucher = false;
    this.filterProps=[];
    this.itemsPerPage=10;
  }

  export(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' ) {
      if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWisePaymentReport.xlsx');
      } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWisePaymentReport.xlsx');
      } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWisePaymentReport.xlsx');
      }
    }
    else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode==='DAD'){ 
      if (this.activeIndex === 0) {
        this.exportToExcel('block-table', 'BlockWisePaymentReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('village-table', 'VillageWisePaymentReport.xlsx');
      }
    }
    else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){ 
        this.exportToExcel('village-table', 'VillageWisePaymentReport.xlsx');
    }
  }

  exportToExcel(id,filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }

  onSearch(){
    this.activeIndex=0;
      if(this.fromDate && this.toDate)
      {
    this.startDate=this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    if (this.startDate > this.endDate) {
      this.dateError = true;
      return
    }
      this.dateError = false;
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
      this.paymentBlockReportDto=null;
      this.paymentVillageReportDto=null;
      this.loadDistrict();
     } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode==='DAD'){
      this.paymentVillageReportDto=null;
      this.loadBlock(this.levelMasterId);
     }  else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
      this.loadVillage(this.levelMasterId);
     }
  }

}
