export class EmployeeDTO {
  id: number;
  employeeTypeDto: EmployeeTypeDTO;
  subEmployeeTypeDto: SubEmployeeTypeDTO;
  levelId: number;
  levelMasterId: number;
  name: string;
  grade: string;
  gpfCpsNo: number;
  dutyPay: number;
  dearnessAllowance: number;
  houseRentAllowance: number;
  medicalAllowance: number;
  generalProvidentFund: number;
  newHealthInsurence: number;
  familyBenefitFund: string;
  nhisCorpusFund: number;
  specialProvidentFund: number;
  cca: number;
  otherAllowances: number;
  otherDeductions: number;
  grossAmount: number;
  totalDeduction: number;
  netAmount: number;
  escNet: number;
  consolidatePay: number;
  adhocIncrease: number;
  additionalAllowance: number;
  powerPumpOperatorAllowance: number;
  createdOn: string;
  micr: any;
  bankAccName: any;
  bankId: any;
  bankName: string;
  bankBranch: string;
  accountNumber: string;
  ifscCode: string;
}

export class Employee {
  id: number;
  employeeType: EmployeeTypeDTO;
  subEmployeeType: SubEmployeeTypeDTO;
  levelId: number;
  levelMasterId: number;
  name: string;
  grade: string;
  gpfCpsNo: number;
  dutyPay: number;
  dearnessAllowance: number;
  houseRentAllowance: number;
  medicalAllowance: number;
  generalProvidentFund: number;
  newHealthInsurence: number;
  familyBenefitFund: string;
  nhisCorpusFund: number;
  specialProvidentFund: number;
  grossAmount: number;
  totalDeduction: number;
  netAmount: number;
  escNet: number;
  consolidatePay: any;
  adhocIncrease: any;
  additionalAllowance: any;
  powerPumpOperatorAllowance: any;
  createdOn: string;
  updatedOn: string;
  activeFlag: string;
  bankAccName: string;
  bankBranch: string;
  bankName: string;
  accountNumber: string;
  ifscCode: string;
  uniqueId:string;
}

export class EmployeeTypeDTO {
  id: number;
  name: string;
  createdOn: Date;
}

export class SubEmployeeTypeDTO {
  id: number;
  name: string;
  employeeType: EmployeeTypeDTO;
  createdOn: Date;
}

export class ValidationResponse {
  accountNo: string;
  accountName: string;
  bankTxnStatus: string;
  ifsc: string;
  bankResponse: string;
}
