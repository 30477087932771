<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Notification
                    </h5>

                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>

                </ng-template>
            </p-toolbar>
        </div>
    </div>


    <div class="row mt-3">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false" (emitRow)="onSelectRow($event)" [disableDeleteIcon]="false" (searchParam)="onLazyLoad($event)" [disableEditIcon]="true"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="PushNotificationDialog" [style]="{ width: '850px' }" header="User Details" [modal]="true" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            Notification
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="transactionDate">Start Time</label>
                            <p-calendar [(ngModel)]="pushNotification.startTime" [showTime]="true" [showSeconds]="true" appendTo="body" [ngClass]="{ 'is-invalid': ngSelectInvalid && !pushNotification.startTime}"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="transactionDate">End Time</label>
                            <p-calendar [(ngModel)]="pushNotification.endTime" [showTime]="true" [showSeconds]="true" appendTo="body" [ngClass]="{ 'is-invalid': ngSelectInvalid && !pushNotification.endTime}"></p-calendar>
                        </div>


                    </div>
                    <div class="row gy-2">

                        <div class="col-md-3">
                            <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass">Date</label>
                            <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="pushNotification.date" [ngClass]="{ 'is-invalid': ngSelectInvalid && !pushNotification.date }" appendTo="body"></p-calendar>
                        </div>
                        <div class="col-md-9">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass">Message</label><br>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="pushNotification.message" [ngClass]="{ 'is-invalid': ngSelectInvalid && !pushNotification.message }" cols="100"></textarea>
                        </div>



                    </div>

                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
          jhiTranslate="common.cancelbutton"></span>
      </button>
            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePushNotiy()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
        </button>
            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
        </button>
        </ng-template>
    </p-confirmDialog>
</div>