import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  DescriptionDto,
  PersonalDescDto,
  VendorTransaction,
  VoucherBillDescEmployeeList,
  vendorTransactionDto,
} from './../payment-voucher/payment-voucher.model';
import { NavbarComponent } from './../../layout/navbar/navbar.component';
import { filterQuery } from './../../shared/common.model';
import { ComponentType } from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { Vendor } from 'src/app/module/commercial-vendors/commercial-vendors';
import {
  CommercialVendorDto,
  paymentVoucherGen,
  VoucherDto,
  VoucherGstList,
} from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { PersonalVendorService } from 'src/app/module/personal-vendors/personal-vendors.service';
import {
  PersonalVendors,
  PersonalVendorsDto,
} from 'src/app/module/personal-vendors/personal-vendors';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { EmployeeService } from 'src/app/module/employee/employee.service';
import {
  Employee,
  EmployeeDTO,
  EmployeeTypeDTO,
} from 'src/app/module/employee/employee.model';
import { UserService } from 'src/app/module/user/user.service';
import { LocalStorageService,SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';

@Component({
  selector: 'app-employee-voucher',
  templateUrl: './employee-voucher.component.html',
  styleUrls: ['./employee-voucher.component.scss'],
})
export class EmployeeVoucherComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  totalDeduction: number;

  // validation fields properties
  public ifscError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  dropDownLabel: string;
  enableNhis: boolean;
  enableCps: boolean;
  enableOtherDeduction: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  description: string;
  amount: number;
  quantity: number;
  signButtonName: string;
  downloadButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  employeeList: Employee;
  enableEmployee: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean=true;
  buttonText: string;
  interactionId: any;
  checkstatusButton: boolean;
  checkAmount: boolean;
  checkForPaymentStatus: boolean;
  componentMasterTable: any;
  deleteDialog: boolean;
  enableView: boolean;
  bulkVoucherDialog: boolean;
  finYear: any;
  finYearList: FinYear[];

  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private personalVendorService: PersonalVendorService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private limitTransListService:LimitTransListService

  ) {
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
            .leveltype
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            if(this.roleCode.role.name.startsWith('Village')){
              this.grantLevel = res.body.filter(item => item.name === 'Village Salary');
            } else if(this.roleCode.role.name.startsWith('Block')){
              this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
            } else if(this.roleCode.role.name.startsWith('District')){
              this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
            } else{
              this.grantLevel=res.body;
            }
          },
          () => {}
        );

        this.majaorComponent=this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
        this.minorComponent=this.componentMasterTable.filter(item =>  item.parentId === this.majaorComponentdto.id);
        this.subComponent=this.componentMasterTable.filter(item =>  item.parentId === this.minorComponentDto.id );
    });
  }

  schemeList: SchemeList[];
  selectedVendor: PersonalVendorsDto;
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: Vendor[];
  selectedscheme: any;
  profileDialog: boolean = false;
  descriptionList: DescriptionDto[];
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  selectedEmployee: EmployeeDTO;
  zonalList: LevelType;
  selectedZonalUser:any
  enableZonalButton: boolean;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Gross Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transaction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
  ];
  async ngOnInit(): Promise<void> {
    this.componentMasterTable= this.sessionStorageService.retrieve('componentMasterTable');
    if(!this.componentMasterTable){
      await this.loadComp();
    }
    await this.getRole();
    this.spinner.show();
        this.paymentService
          .getByImplemeting(this.roleCode.implementingAgencyId)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto =
                res.body;
              this.onLevelType();
              this.levelTypeService
                .getbyId(
                  this.voucherGenerationDto.vendorTransactionDto
                    .implementingAgencyDto.leveltype
                )
                .subscribe(
                  (res: HttpResponse<LevelType>) => {
                    this.levelMasterDto = res.body;
                  },
                  () => {}
                );
            },
            () => {}
          );
        if (
          this.roleCode.role.code === 'DMAKER' ||
          this.roleCode.role.code === 'VMAKER' ||
          this.roleCode.role.code === 'BMAKER'
        ) {
          if(this.roleCode.role.code === 'BMAKER'){
            this.paymentService
                .getZonalCheckers(
                  this.roleCode.levelMasterId
                )
                .subscribe(
                  (res: HttpResponse<LevelType>) => {
                    this.zonalList = res.body;
                  },
                  () => {}
                );
              }
          this.signButtonName = 'Perform e-Sign Secretary';
        } else if (
          this.roleCode.role.code === 'DC1' ||
          this.roleCode.role.code === 'BC1' ||
          this.roleCode.role.code === 'VC1' ||
          this.roleCode.role.code === 'ZC1'
        ) {
          if(this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1'){
            this.checkAmount = true
          }
          this.signButtonName = 'Perform e-Sign VP';
          this.enableRevertButton = true;
          this.readonly = true;
        } else if (
          this.roleCode.role.code === 'DC2' ||
          this.roleCode.role.code === 'BC2' ||
          this.roleCode.role.code === 'VC2'
        ) {
          this.signButtonName = 'Perform e-Sign President';
          this.enableRevertButton = true;
          this.downloadButton = true;
          this.readonly = true;
        }
        if(
          this.roleCode.role.code === 'BMAKER' || 
          this.roleCode.role.code === 'BC1' ||
          this.roleCode.role.code === 'BC2' ||
          this.roleCode.role.code === 'ZC1'
        ) {
          this.enableZonalButton = true;
        }
     
    if (this.activatedRoute.snapshot.queryParamMap.get('response')){
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')){
      this.notificationService.alertError('Error Occurred: '+this.activatedRoute.snapshot.queryParamMap.get('error'),'');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if(this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.employeeService.filter({ size: 30, page: 0 }, []).subscribe(
        (res: HttpResponse<Employee>) => {
          this.employeeList = res.body;
          this.enableEmployee = true;
        },
        () => {}
      );
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = res.body;
            this.finYear = this.voucherGenerationDto.vendorTransactionDto.finYearDto;
            // if(this.voucherGenerationDto.vendorTransactionDto.parentVoucherNumber && !this.enableView){
            //   this.readonly = true;
            //   this.bulkVoucherDialog = true;
            // }
            if(this.checkAmount && res.body.vendorTransactionDto.transactionAmount <= 500000 && res.body.vendorTransactionDto.signatureVicePresident){
              res.body.vendorTransactionDto.signaturePresident = "signed"
            }
            if (this.voucherGenerationDto.vendorTransactionDto.signStatus && !this.enableView) {
              this.readonly = true;
              this.enableSignButton = true;
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
              .getUser(res.body?.vendorTransactionDto?.zonalChecker)
              .subscribe(
                (res: HttpResponse<any>) => {
                  this.selectedZonalUser = res.body;
                },
                () => {}
              );
            }
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            if(this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              switch (this.voucherGenerationDto.vendorTransactionDto.transactionStatus) {
                case 'O.K.':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Success';
                  break;
                case 'failed':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Failed';
                  break;
                case 'processing':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Processing';
                  break;
                default:
                  break;
              }
            }
            
            this.enableEmployee = true;
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
             
            this.majaorComponentdto=this.componentMasterTable.find(item =>  item.id === this.voucherGenerationDto.vendorTransactionDto.majorComponentId);
            this.minorComponentDto=this.componentMasterTable.find(item =>  item.id === this.voucherGenerationDto.vendorTransactionDto.minorComponentId);
            this.subComponentDto=this.componentMasterTable.find(item =>  item.id === this.voucherGenerationDto.vendorTransactionDto.subComponentId);

            this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.spinner.hide();
                  this.levelMasterDto = res.body;
                  this.onLevelType();
                  this.ongrandLevel('true');
                  this.onMajorComponent('true');
                  this.onMinorComponent('true');
                },
                () => {}
              );
          },
          () => {}
        );
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYearList = res.body;
          let FinYear = ''
          if (new Date().getMonth() + 1 >= 4) {
            FinYear =
              new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
          } else {
            FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
          }
          this.finYear = this.finYearList.find((_) => _.name === FinYear);
          
          
        },
        () => { }
      )
      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.voucherBillDescEmployeeList = [];
      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
      this.voucherGenerationDto.voucherBillDescEmployeeList = [];
      let employeeDescDto = new VoucherBillDescEmployeeList();
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo + '_' + 1;
      employeeDescDto.quantity = 0;
      employeeDescDto.amount = 0;
      employeeDescDto.grossAmount = 0;
      this.voucherGenerationDto.voucherBillDescEmployeeList.push(
        employeeDescDto
      );
    }
    this.paymentService.getDescrptionList().subscribe(
      (res: HttpResponse<DescriptionDto[]>) => {
        this.descriptionList = [];
        res.body.forEach((element) => {
          if (element.name.startsWith('Salary')) {
            this.descriptionList.push(element);
          }
        });
      },
      () => {}
    );
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
        this.spinner.hide();
      },
      () => {}
    );
    
  }
  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  loadComp():Promise<any> {
    return new Promise<any>((resolve) => {
      this.paymentService.componentMasterList().subscribe(
        (res)=>{
          this.spinner.show();
          this.sessionStorageService.store('componentMasterTable',res.body)
          this.componentMasterTable=res.body;
          resolve(this.componentMasterTable)
          this.spinner.hide();
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => {}
    );
  }

  onLevelType() {
    this.componentTypeService
      .find(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
          .leveltype
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          if(this.roleCode.role.name.startsWith('Village')){
            this.grantLevel = res.body.filter(item => item.name === 'Village Salary' || item.name === 'Village General Funds');
          } else if(this.roleCode.role.name.startsWith('Block')){
            this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
          } else if(this.roleCode.role.name.startsWith('District')){
            this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
          } else{
            this.grantLevel=res.body;
          }
        },
        () => {}
      );
  }
  getActionUrl(){
    this.sessionStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;

  }
  ongrandLevel(edit?:any) {
    if(!edit){
      this.majaorComponentdto = null;
      this.minorComponentDto = null;
      this.subComponentDto = null;
     
    }
    this.majaorComponent=this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);

    this.paymentService
      .getLimit(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => {}
      );
  }
  onMajorComponent(edit?:any) {
    if(!edit){
      this.minorComponentDto = null;
      this.subComponentDto = null;
      this.voucherGenerationDto.voucherBillDescEmployeeList = [];
      let employeeDescDto = new VoucherBillDescEmployeeList();
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo + '_' + 1;
      employeeDescDto.quantity = 0;
      employeeDescDto.amount = 0;
      employeeDescDto.grossAmount = 0;
      this.voucherGenerationDto.voucherBillDescEmployeeList.push(
        employeeDescDto
      );
    }
    this.minorComponent=this.componentMasterTable.filter(item =>  item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));
  }

  show(){
    this.spinner.show();
  }

  calculateOtherDeduction() {
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage =
      Number(
        this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage
      );
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage /
          100) *
        this.voucherGenerationDto.vendorTransactionDto.netAmount
      ).toFixed(2)
    );
    this.calculateTotalAmount();
  }

  onUpload($event) {
    const selectedFile: File = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);
    };
    const formData = new FormData();
    const fileExtension = $event.name.split('.').pop();
    const fileData = new Blob([$event], { type: $event.type });
    formData.append('file', fileData, `file.${fileExtension}`);
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {},
      () => {}
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  onMinorComponent(edit?:any) {
    if(!edit){
      this.subComponentDto = null;
      this.voucherGenerationDto.voucherBillDescEmployeeList = [];
      let employeeDescDto = new VoucherBillDescEmployeeList();
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo + '_' + 1;
      employeeDescDto.quantity = 0;
      employeeDescDto.amount = 0;
      employeeDescDto.grossAmount = 0;
      this.voucherGenerationDto.voucherBillDescEmployeeList.push(
        employeeDescDto
      );
    }
    this.subComponent=this.componentMasterTable.filter(item =>  item.parentId === this.minorComponentDto.id );


    // if (this.minorComponentDto.name.startsWith('Salary')) {
      this.employeeService.filter({ size: 30, page: 0 }, []).subscribe(
        (res: HttpResponse<Employee>) => {
          this.employeeList = res.body;
        },
        () => {}
      );
    // pm 
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term.toUpperCase();
    let filterquery = [];
    filterquery.push(filter);
    this.personalVendorService
      .filter({ size: 10, page: 0 }, filterquery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => {}
      );
  }

  popupValue() {
    this.voucherGenerationDto.personalDescDtoList = [];
    let personalDescDto = new PersonalDescDto();
    personalDescDto.quantity = 0;
    personalDescDto.amount = 0;
    personalDescDto.grossAmount = 0;
    this.amount = 0;
    this.quantity = 0;
    this.description = null;

    this.voucherGenerationDto.personalDescDtoList.push(personalDescDto);

    //this.selectedVendor.gstNo = null
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
      new PersonalVendorsDto();
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto.id =
      this.selectedVendor.id;
    // this.voucherGenerationDto.commercialVendorDto.gstNo = this.selectedVendor.gstNo
    this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto.panNo =
      this.selectedVendor.panNo;
    this.calculateTotalAmount();
  }
  addRow() {
    this.voucherGenerationDto.voucherBillDescEmployeeList = this
      .voucherGenerationDto.voucherBillDescEmployeeList
      ? this.voucherGenerationDto.voucherBillDescEmployeeList
      : [];
    let employeeDescDto = new VoucherBillDescEmployeeList();
    if (this.voucherGenerationDto.voucherBillDescEmployeeList.length !== 0) {
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo +
        '_' +
        this.voucherGenerationDto.voucherBillDescEmployeeList.length +
        1;
    } else {
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo + '_' + 1;
    }
    employeeDescDto.quantity = 0;
    employeeDescDto.amount = 0;
    employeeDescDto.grossAmount = 0;
    this.voucherGenerationDto.voucherBillDescEmployeeList.push(employeeDescDto);

    // this.calculateOtherDeduction();
  }
  calculatetotal(amt: VoucherBillDescEmployeeList) {
    amt.name = amt.employeeDto.name;
    amt.grade = amt.employeeDto.grade;
    amt.accountNumber = amt.employeeDto.accountNumber;
    amt.ifscCode = amt.employeeDto.ifscCode;
    amt.bankAccName = amt.employeeDto.bankAccName;
    amt.bankBranch = amt.employeeDto.bankBranch;
    amt.bankName = amt.employeeDto.bankName;
    amt.gpfCpsNo = amt.employeeDto.gpfCpsNo;
    if (amt.quantity === null) {
      amt.quantity = 0;
    }
    if (amt.amount === null) {
      amt.amount = 0;
    }
    this.amount = amt.amount;
    this.quantity = amt.quantity;
    if(amt.quantity>amt.amount)
    {
      amt.quantity=null;
      this.notificationService.alertError('Leave Deduction should not be greater than Amount','');
      amt.grossAmount=null;
    }
    else{
      amt.grossAmount =  amt.amount - amt.quantity;
    }
    this.calculateTotalAmount();
  }
  delete(index: number) {
    this.voucherGenerationDto.voucherBillDescEmployeeList.splice(index, 1);
    if (this.voucherGenerationDto.voucherBillDescEmployeeList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
    } else {
      this.calculateTotalAmount();
    }
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isPanValid(
      this.selectedVendor.ifscCode
    );
    !this.selectedVendor.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.selectedVendor.ifscCode;
  }
  addDescritption(list: string) {
    this.description = list;
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =
    this.levelMasterDto &&
    this.grantlevel &&
    this.majaorComponentdto &&
    this.minorComponentDto &&
    this.subComponentDto &&
    this.voucherGenerationDto.vendorTransactionDto
      .panchayatResolutionNumber &&
    this.voucherGenerationDto.vendorTransactionDto.remark &&
    this.voucherGenerationDto.vendorTransactionDto.grossAmount &&
    this.voucherGenerationDto.vendorTransactionDto &&
    this.voucherGenerationDto.vendorTransactionDto.netAmount;

  if (!isFormValid) {
    this.ngSelectInvalid = true;
    return;
  }
  
  if(this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser){
    this.notificationService.alertError('Please Select the Zonal User','')
    return;
  }
  this.voucherGenerationDto.voucherBillDescEmployeeList.forEach((element)=>{
    const isVal=element.employeeDto && element.descriptionDto && element.amount ;
    if (!isVal) {
      this.ngSelectInvalid = true;     
    }
    if(element.quantity>element.amount)
    {
      this.notificationService.alertError('Leave Deduction should not be greater than Amount','');
      element.quantity=null;
      element.grossAmount=null;
      this.ngSelectInvalid = true; 
    }
    });
    if(this.ngSelectInvalid){
      return;
    }
  this.ngSelectInvalid = false;
    this.spinner.show();
    if(this.selectedZonalUser?.id){
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker = this.selectedZonalUser?.id
    }
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;

    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
      this.selectedVendor;

    this.voucherGenerationDto.vendorTransactionDto.majorComponentId =
      this.majaorComponentdto.id;
    this.voucherGenerationDto.vendorTransactionDto.majorComponentName =
      this.majaorComponentdto.name;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentId =
      this.minorComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentName =
      this.minorComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.subComponentId =
      this.subComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.subComponentName =
      this.subComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'E';

    this.paymentService.genVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        // const blob = new Blob([response.body], { type: 'application/pdf' });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'file.pdf';
        // document.body.appendChild(link);
        // window.open(url, '_blank');
        // link.click();
        // URL.revokeObjectURL(url);
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        // this.router.navigate(['/payment-personal']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },

      (error) => {
        this.spinner.hide();
        if (error.status == 400)
        {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher','');

        } else if(error.status == 500) {
          this.notificationService.alertError('An unexpected error occurred, please delete the voucher if generated','');
          this.router.navigate(['/employeePaymentTable'], {});
        } else{
          this.notificationService.alertError('An unexpected error occurred','');
        }
      }
    );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if(!this.voucherGenerationDto.vendorTransactionDto.id){
            this.router.navigate(['/employeePaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        (error) => {
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ){
          this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: '+this.voucherGenerationDto.vendorTransactionDto.voucherNo,'');
          this.router.navigate(['/employeePaymentTable'], {});
          }
          else {
            this.deleteDialog=true;            
          }
        }
      );
  }

  hideDialog(){
    this.deleteDialog=false;
    this.router.navigate(['/employeePaymentTable'], {});
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/employeePaymentTable'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.paymentService
      .deletedVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/employeePaymentTable'], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }
          this.spinner.hide();
        }
      );
  }

  calculateTotalAmount() {
    if (this.voucherGenerationDto?.voucherBillDescEmployeeList.length !== 0) {
      let intitalTotal = 0;
      let intitalTotalWithTax = 0;
      this.voucherGenerationDto?.voucherBillDescEmployeeList.forEach(
        (element) => {
          intitalTotal += Number(element.amount);
          intitalTotalWithTax += element.grossAmount;
        }
      );
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.grossAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount;
    }
  }

  getvendorTrans() {
    if (this.selectedVendor) {
      this.paymentService
        .getListPersonalVendorTransction(this.selectedVendor.id)
        .subscribe(
          (res: any) => {
            this.searchResult.items = res.body ?? [];
            this.searchResult.total = 5;
            this.searchResult = { ...this.searchResult };
            this.profileDialog = true;
          },
          () => {}
        );
    }
  }
  redirect() {
    this.router.navigate(['/employeePaymentVoucher'], {
      queryParams: {
        id: String(this.voucherGenerationDto.vendorTransactionDto.id),
      },
    });
  }

  paymentStatus() {
    this.paymentService
      .getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          if (res.body.transactionStatus === 'O.K.') {
            this.disablepaymentButton = true;
          }  
          else if (res.body.transactionStatus !== null) {
            this.buttonText = 'Re-Initiate payment';
            this.checkstatusButton = true;
            this.interactionId = res.body.apiInteractionId;
            if(this.checkForPaymentStatus){
              this.checkPaymentStatus();
            }
          }
        },
        (err) => {}
      );
  }

  PaymentSucess() {
    this.spinner.show();
    this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
      (res: any) => {
        this.paymentSucessDialog = true;
        this.paymentSucessText = res.body;
        this.disablepaymentButton = true;
      },
      (err) => {
        console.log(err);
        if (String(err.status).startsWith('5')) {
          this.checkstatusButton = true;
          this.spinner.hide();
        }
        else if(err.status ===400){
          this.notificationService.alertError(err.error,'');
        }
        this.spinner.hide();
      }
    );
  }
  checkTransactionStatus(){
    this.checkForPaymentStatus=true;
    this.paymentStatus();
  }

  checkPaymentStatus() {
    this.spinner.show();
    this.checkForPaymentStatus=false;
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:"+res.body.transactionStatus.JRNL_NO,'')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false; 
          // this.disablepaymentButton = false; 
          this.spinner.hide();
          }
          else if (res.body.transactionStatus.TRANSTATUS === "Success"){
            this.checkstatusButton = false;
            this.paymentSucessDialog=true;
            this.paymentSucessText="Transaction Success for Reference No:",res.body.transactionStatus.JRNL_NO;
          this.spinner.hide();
          }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  bulkVoucherRedriect() {
    this.router.navigate(['/blukpaymentTable'], {});
  }

  submitForm(){
    if(this.roleCode.role.code !== 'VC2') {
      this.sessionStorageService.store('paymentType', this.voucherGenerationDto.vendorTransactionDto.personalFlag);
      this.sessionStorageService.store('voucherNo',this.voucherGenerationDto.vendorTransactionDto.voucherNo);
      this.sessionStorageService.store('userId',this.roleCode.id);
      this.sessionStorageService.store('otpAttempts',3);
      this.router.navigateByUrl('/aadhar-verification-page');
    }
    else {
      this.spinner.show();
      this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          if(!this.voucherGenerationDto.vendorTransactionDto.id){
            this.router.navigate(['/employeePaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          this.sessionStorageService.store('refresh', 'Yes');
          const formElement = document.createElement('form');
          formElement.method = 'POST';
          formElement.action = environment.CDAC_URL;
          let inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "eSignRequest";
          inputElement.value = res.body.paramOne;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "aspTxnID";
          inputElement.value = res.body.paramTwo;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "Content-Type";
          inputElement.value = res.body.paramThree;
          formElement.appendChild(inputElement);
          document.body.appendChild(formElement);
          formElement.submit();
      
        },
        (error) => {
          this.spinner.hide();
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ){
          this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: '+this.voucherGenerationDto.vendorTransactionDto.voucherNo,'');
          this.router.navigate(['/employeePaymentTable'], {});
          }
          else {
            this.deleteDialog=true;            
          }
        }
      );
    }
    
  }
}
