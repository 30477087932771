import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SchemeListService } from './scheme-list.service';
import { SchemeList } from './scheme-list';
import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { DepartmentList } from '../department-list/department-list';

@Component({
  selector: 'app-scheme-list',
  templateUrl: './scheme-list.component.html',
  styleUrls: ['./scheme-list.component.scss']
})
export class SchemeListComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;
  schemeListDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<SchemeList> = new SearchResult<SchemeList>();
  schemeList?: SchemeList;
  selectedDepartmentList: SchemeList[] = [];
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;

  //required fields property
  ngSelectInvalid: boolean = false;
  departmentList: DepartmentList[];
  showNameError: string;
  showCodeError: string;
  showShortNameError: string;

  constructor(
    private schemeListService: SchemeListService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private departmentListService: DepartmentListService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {

    this.loadPage()
    this.cols = [
      { field: 'name', header: 'Scheme Name', jhiTranslate: 'tableHead.scheme.name', isSortable: true, width: 25, isSelectcolumn: true },
      { field: 'shortName', header: 'Short Name', jhiTranslate: 'tableHead.short.name', isSortable: true, width: 25, isSelectcolumn: true },
      { field: 'code', header: 'Code', jhiTranslate: 'tableHead.code.name', isSortable: true, width: 15, isSelectcolumn: true },
      { field: 'departmentDto', header: 'Dept Name', jhiTranslate: 'tableHead.department.name', width: 13, isSelectcolumn: true, type: 'dropDown' },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show()

    this.schemeListService
      .filter()
      .subscribe(
        (res: HttpResponse<SchemeList[]>) => {
          this.searchResult.total = res.body.length
          this.spinner.hide()

          this.searchResult.items = res.body;
          this.searchResult = { ...this.searchResult };

        },
        () => {
          this.onError();
        }
      );
  }

  protected onError(): void {
  }

  openNew() {
    this.schemeList = new SchemeList();
    this.submitted = false;
    this.schemeListDialog = true;
    this.loadValue()
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        }
      ];
      if (this.schemeList.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.schemeList.id,
        });
      }
      this.schemeListService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<SchemeList[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  async checkName(){
    const filterData = await this.getfilterdata('name', this.schemeList.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkcode(){
    const filterData = await this.getfilterdata('code', this.schemeList.code);
    if (filterData.length !== 0) {
      this.showCodeError = 'Code Already Exists';
    } else {
      this.showCodeError = null;
    }

  }

  async checkShortName(){
    const filterData = await this.getfilterdata('short Name', this.schemeList.shortName);
    if (filterData.length !== 0) {
      this.showShortNameError = 'short Name Already Exists';
    } else {
      this.showShortNameError = null;
    }
  }





  loadValue(){
    this.departmentListService
      .filter()
      .subscribe(
        (res: HttpResponse<DepartmentList[]>) => {
          this.departmentList = res.body
        },
        () => {
          this.onError();
        }
      );
  }

  hideDialog() {
    this.schemeList = new SchemeList();
    this.ngSelectInvalid = false;

    this.schemeListDialog = false;
    this.submitted = false;
  }

  saveData() {
    const isFormValid =
      this.schemeList.departmentDto &&
      this.schemeList.name &&
      this.schemeList.shortName &&
      this.schemeList.address;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.schemeListService
      .save(this.schemeList)
      .subscribe(
        (res: HttpResponse<SchemeList>) => {
          this.schemeListDialog = false;
          this.loadPage();
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
    this.submitted = true;

  }

  onSelectRow($event) {
    if ($event.type === "select") {
      this.schemeListDialog = true;
      this.schemeList = $event.value
    }
  }
}