import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DatePipe } from '@angular/common';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { HttpResponse } from '@angular/common/http';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';

interface Month {
  index: number;
  name: string;
}

@Component({
  selector: 'app-payment-not-done-report',
  templateUrl: './payment-not-done-report.component.html',
  styleUrls: ['./payment-not-done-report.component.scss']
})

export class PaymentNotDoneReportComponent implements OnInit {

  date: Date;
  finYearList: FinYear[];
  finYear: FinYear;
  compTypeList:ComponentType[];
  selectedcompType:ComponentType;
  months: Month[] = [
    { index: 1, name: 'January' },
    { index: 2, name: 'February' },
    { index: 3, name: 'March' },
    { index: 4, name: 'April' },
    { index: 5, name: 'May' },
    { index: 6, name: 'June' },
    { index: 7, name: 'July' },
    { index: 8, name: 'August' },
    { index: 9, name: 'September' },
    { index: 10, name: 'October' },
    { index: 11, name: 'November' },
    { index: 12, name: 'December' }
  ];

  selectedMonth: Month;

  constructor(public reportService:ReportService,
    private spinner:NgxSpinnerService,
    public fontService: FontService,
    private componentTypeService:ComponentTypeService,
    private datePipe: DatePipe,
    private limitTransListService: LimitTransListService) { }

  ngOnInit(): void {
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYearList = res.body;
      },
      () => {}
    );
    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<ComponentType[]>) => {
        this.compTypeList=res.body.filter(item => item.name.startsWith('Village'));
        this.selectedcompType=this.compTypeList[0]
      },
      ()=>{});
  }

  download(){
    this.spinner.show();
      let month=this.selectedMonth.index;
      let year;
      if (month >= 4) {
        year =this.finYear.name.split('-')[0];
      } else {
        year = this.finYear.name.split('-')[1];
      }
      let startDate=this.datePipe.transform(new Date(year, month-1, 1),'dd-MM-yyyy');
      let endDate=this.datePipe.transform(new Date(year, month, 0, 23, 59, 59, 999),'dd-MM-yyyy');
      let payload={
        finYearId:this.finYear.id,
        compId: this.selectedcompType.id,
        fromDate:startDate,
        toDate:endDate
      };
      this.reportService.paymentNotInitiatedReport(payload)
        .subscribe(
          (response) => {
            this.spinner.hide();
            this.saveBlobToExcel(response.body,'Payment Not Done Report');
          },
          (error)=>{
            this.spinner.hide();
          });
    }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
    }


}
