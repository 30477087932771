<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.payments.slist" >SwapTable Payment List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode === 'DMAKER' || roleCode === 'VMAKER' || roleCode === 'BMAKER'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()"
                        styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.generatevoucher"></span>                    
                    </button>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedProfiles()"
                        [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                    <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
                </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="(roleCode === 'DMAKER' || roleCode === 'VMAKER' || roleCode === 'BMAKER')?false:true" 
            [disableEditIcon]="disableEdit" (emitRow)="onSelectRow($event)"
            [showRedirectButton]="false" (showTable)="getSubTableDetails($event)" [showTableButton]="true"></custom-filter-table>
        </div>
    </div>
  
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000"
styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
<p-header [ngClass]="fontService.headingClass">
    {{ "dashBoard.transactionlist" | translate }}
</p-header>
<custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="searchVoucherStatusResult"
    (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="false"
    [disableEditIcon]="true" ></custom-filter-table>
</p-dialog>
