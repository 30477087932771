import { Component, OnInit } from '@angular/core';
import { SearchResult } from 'src/app/shared/models/search-result';
import { AccountStatementService } from './account-statement.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-account-statement-balances-village',
  templateUrl: './account-statement-balances-village.component.html',
  styleUrl: './account-statement-balances-village.component.scss'
})
export class AccountStatementBalancesVillageComponent implements OnInit{
  cols:any;
  searchResult: SearchResult<any> =
  new SearchResult<any>();
  villageName: any;
  lgdCode: any;
  date:Date=new Date();
  accountBalance: string;
  isTable:boolean=false;
  tabledata:any=[]
  today: string;
  constructor(private accountService:AccountStatementService,
    public localStorageService: LocalStorageService,
    public sessionStorageService: SessionStorageService,
    public datePipe:DatePipe,
    private spinner: NgxSpinnerService,
    private notificationService:NotificationService,){

  }

  ngOnInit() {
    this.spinner.show()
   this.today= this.datePipe.transform(this.date, 'dd-MM-yyyy')
    let levelMasterId=this.sessionStorageService.retrieve('levelMasterId');
    this.accountService.getLevelMasterById(levelMasterId).subscribe(res=>{
     
      this.villageName=res.body.name;
      this.lgdCode=res.body.code
 
      this.spinner.hide()
    },(err=>{
      this.spinner.hide()
    }))
}
  onLazyLoad(data){
    
  }
  getAccountBalance(){
this.spinner.show()
  this.accountService.getAccountBalance().subscribe(res=>{
    this.accountBalance=res['body']['AccountEnquiry_Response']['Body']['Payload']['Account_Balance'];
    this.tabledata=[{
      'villageName':this.villageName,
      'lgdCode':this.lgdCode,
      'today':this.today,
      'accountBalance':this.accountBalance
    }]
    this.isTable=true;
    this.spinner.hide()
  },(err=>{
    this.notificationService.alertError(err.error,'')
    this.spinner.hide()
  }))
  }
}
