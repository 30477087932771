<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Enabled Menu List</h5>
                </ng-template>
                <ng-template pTemplate="right">

                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                      (click)="deleteSelectedProfiles()"
                      [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                  <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                      chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                  <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
              </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="input-row py-10 pb-2">
            <input pInputText type="text" placeholder="LGD Code" [(ngModel)]="lgdCode" />
            <button pButton pRipple label="Search" (click)="loadPage(1)" class="p-button-sm"></button>
            <button pButton pRipple label="Clear" (click)="lgdCode=null;loadPage(1)" class="p-button-sm"></button>
        </div>
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange()">
            <p-tabPanel header="TANGEDCO">
                <div *ngIf="activeIndex === 0" #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" [disableMenu]="true" (emitRow)="onSelectRow($event)" [showDialogTable]="false"></custom-filter-table>
                </div>

            </p-tabPanel>
            <p-tabPanel header="TWAD">
                <div *ngIf="activeIndex === 1" #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" [disableMenu]="true" (emitRow)="onSelectRow($event)" [showDialogTable]="false"></custom-filter-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="TANGEDCO Payment">
                <div *ngIf="activeIndex === 2" #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" [disableMenu]="true" (emitRow)="onSelectRow($event)" [showDialogTable]="false"></custom-filter-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
    </button>
            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
    </button>
        </ng-template>
    </p-confirmDialog>


</div>