<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Consumer Demand List</h5>
                </ng-template>
                <ng-template pTemplate="right">

                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                      (click)="deleteSelectedProfiles()"
                      [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                  <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                      chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                  <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
              </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)" [showDialogTable]="true"></custom-filter-table>
        </div>
    </div>

</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <!-- <p-header [ngClass]="fontService.headingClass">
  {{ "dashBoard.transactionlist" | translate }}
</p-header> -->
    <custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="tangedcosearchResult" [disableDeleteIcon]="true" [paginator]="false" [disableEditIcon]="true"></custom-filter-table>
</p-dialog>

<p-dialog #dialog [(visible)]="tangedcoBillDialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        {{ "tangedco.consumerDemandAdd" | translate }}
    </p-header>
    <ng-template pTemplate="content">
        <div class="col-12">
            <div class="form-inner-panel">
                <div class="row">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerNo" for=" name"></label>
                            <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.consumerNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerNo}" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />

                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerName" for=" name"></label>
                            <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.consumerName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerName}" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />

                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.billAmount" for=" name"></label>
                            <input type="number" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.billAmount" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.billAmount}" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />

                        </div>
                    </div>
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.billCycle" for=" name"></label>
                            <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="tangedcoBills.billCycle" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.billCycle}" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase />

                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.consumerAddress" for=" name"></label>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="tangedcoBills.consumerAddress" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.consumerAddress }" cols="100" [disabled]="readonly"></textarea>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="tangedco.message" for=" name"></label>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="tangedcoBills.message" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tangedcoBills.message }" cols="100" [disabled]="readonly"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <!-- <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
  </button>
        <button *ngIf="isDelete" pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="deleteVendor()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.delete"></span>
  </button>
        <button *ngIf="!personalVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePersonalVendor()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
  </button>
        <button *ngIf="personalVendor.id && !isDelete" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePersonalVendor()">
    <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
  </button> -->
    </ng-template>
</p-dialog>