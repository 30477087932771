import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComponentType, ComponentDto } from './component-type';



export type EntityArrayResponseType = HttpResponse<ComponentType[]>;
export type EntityResponseType = HttpResponse<ComponentType>;

@Injectable()
export class ComponentTypeService {
   
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/componentTypeList');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveComponentType');
    protected resourcegetById = environment.SERVER_API_URL+('api/getComponentTypeLevelId')
    protected getcomponentByParentId = environment.SERVER_API_URL+('api/getComponentMasterByParentId')
    protected getcomponentByMasterId = environment.SERVER_API_URL+('api/getComponentMasterByMasterId')
    protected getresourceById = environment.SERVER_API_URL+('api/getComponentType')

    constructor(protected http: HttpClient) {}
    filter(): Observable<EntityArrayResponseType> {
      
        return this.http
        .get<ComponentType[]>(this.resourceFliterUrl,{ observe: 'response' })
    }
    find(id:number): Observable<EntityResponseType> {
        return this.http
        .get<ComponentType>(this.resourcegetById,{params:{levelId:id},observe: 'response' })
    }
    findbyId(id:number): Observable<EntityResponseType> {
        return this.http
        .get<ComponentType>(this.getresourceById,{params:{id:id},observe: 'response' })
    }
    findbyParentId(id:number): Observable<any> {
        return this.http
        .get<any>(this.getcomponentByParentId,{params:{id:id},observe: 'response' })
    }
    findbyMasterId(id:number): Observable<any> {
        return this.http
        .get<any>(this.getcomponentByMasterId,{params:{id:id},observe: 'response' })
    }

    save(comppentType?:ComponentDto): Observable<any> {
        return this.http
        .post<any>(this.resourceSaveUrl,comppentType, {observe: 'response' })
    }
}