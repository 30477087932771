import { LevelMasterDto } from 'src/app/module/level-master/level-master';

export interface Zonal {
    blockName?:string;
    zonalName?:string;
    villageName?:string;
    department?:string;
    scheme?:string;
    levelType?:string;   
}

export class ZonalDto {
    id: number
    name: string
    villageLevelMaster: LevelMasterDto
    blockLevelMaster: LevelMasterDto
    villageFilter: any
    activeFlag: string
    multipleName: any
  }