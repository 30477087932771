<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
          <h5 [ngClass]="fontService.headingClass" jhiTranslate="department.scheme.list.name">Scheme List</h5>
          <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
        </ng-template>
        <ng-template pTemplate="right">
          <button *ngIf="searchResult.total === 0" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()"
            styleClass="button"> <span class="button-label" [ngClass]="fontService.buttonClass"
              jhiTranslate="common.newbutton"></span>
          </button>
        </ng-template>
        <!-- <ng-template pTemplate="right">
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                    <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
                </ng-template> -->
      </p-toolbar>
    </div>
  </div>
  <div class="row">
    <div #customerTable id="entities" class="col-12 px-4">
      <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [disableDeleteIcon]="true"
      [disableEditIcon]="false" (emitRow)="onSelectRow($event)"></custom-filter-table>
    </div>
  </div>
  <p-dialog #dialog [(visible)]="schemeListDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
    [style]="{ width: '850px' }">
    <p-header [ngClass]="fontService.headingClass">
      {{"department.add.scheme.name"|translate}}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">
          <div class="row gy-2">
            <div class="col-sm-12 col-md-12">
              <label class="form-control-label" for="schemeName" [ngClass]="fontService.labelClass"
                jhiTranslate="department.dept.name.name">Department Name</label>
              <ng-select id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                [clearable]="true" class="form-control" [disabled]="schemeList.id" [items]="departmentList"
                [(ngModel)]="schemeList.departmentDto"></ng-select>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="schemeName" [ngClass]="fontService.labelClass"
                jhiTranslate="department.scheme.list.name">Scheme Name</label>
              <input type="text" id="schemeName" name="schemeName" autocomplete="off" maxlength="100"
                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" class="form-control"
                [(ngModel)]="schemeList.name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeList.name }" (ngModelChange)="checkName()" />
                <div *ngIf="showNameError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError" >{{showNameError}}</small>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="shortCode" [ngClass]="fontService.labelClass"
                jhiTranslate="department.scheme.code.name">Scheme Code</label>
              <input type="text" [(ngModel)]="schemeList.code" class="form-control" maxlength="20"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" 
                name="shortCode" autocomplete="off" id="shortCode"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeList.code }" (ngModelChange)="checkcode()"/>
                <div *ngIf="showCodeError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.showCodeError" >{{showCodeError}}</small>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="shortName" [ngClass]="fontService.labelClass"
                jhiTranslate="department.short.name.name">Short Name</label>
              <input type="text" class="form-control" [(ngModel)]="schemeList.shortName" name="shortName"
                autocomplete="off" id="shortName"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeList.shortName }" (ngModelChange)="checkShortName()"/>
                <div *ngIf="showShortNameError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.showShortNameError">{{showShortNameError}}</small>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="address" [ngClass]="fontService.labelClass"
                jhiTranslate="department.address.name">Address</label>
              <input type="text" class="form-control" [(ngModel)]="schemeList.address" maxlength="300"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" name="address" autocomplete="off" id="address"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeList.address }" />
            </div>
            <div class="col-sm-12 col-md-8">
              <label class="form-control-label" for="schemeDescription" [ngClass]="fontService.labelClass"
                jhiTranslate="department.scheme.description.name">Scheme Description</label>
              <textarea id="w3review" name="w3review" rows="4" [(ngModel)]="schemeList.schemeDesc" cols="50"></textarea>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span></button>
      <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span></button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>