import { ComponentTypeDto, AdminUserDtoCreatedby } from './../limit-set/limit-set';
import { ImplementingAgencyDto } from './../../payments/payment-voucher/payment-voucher.model';
import { SchemeList } from './../scheme-list/scheme-list';
import { DepartmentList } from './../department-list/department-list';
import { LevelType } from '../level-type/level-type';
import { LevelMaster } from '../level-master/level-master';
import { ComponentType } from '../component-type/component-type';
import { LevelTypeDto } from '../limit-formula/limit-formula';
export class limitTransList {
    id: number
    department: DepartmentList
    scheme: SchemeList
    levelType: LevelType
    levelMaster: LevelMaster
    implementingAgency: ImplementingAgencyDto
    finYear: FinYear
    componentType: ComponentTypeDto
    transactionDate: string
    amount: number
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: number
    adminUserUpdatedBy: any
  }

  export class limitTransListDto {
    id: number
    departmentId: number
    schemeId: number
    levelTypeDto: LevelTypeDto
    finYearDto: FinYear
    componentTypeDto: ComponentTypeDto
    amount: number
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: number
    adminUserUpdatedBy: number
    status:string
  }

  export class FinYear {
    id: number
    name: string
  }

  export class FormulaTabelModel {
    id: number
    departmentId: number
    schemeId: number
    levelType: LevelType
    levelMaster: LevelMaster
    implementingAgency: ImplementingAgencyDto
    finYear: FinYear
    componentType: ComponentType
    formula: number
    amount: number
    createdOn: string
    createdBy: any
    updatedBy: number
  }

  export class limitTransDto  {
    id: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
    levelTypeDto: LevelType
    levelMasterDto: LevelMaster
    implementingAgencyDto: ImplementingAgencyDto
    finYearDto: FinYear
    componentTypeDto: ComponentTypeDto
    amount: number
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: number
    adminUserUpdatedBy: any
  }