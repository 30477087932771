<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Profile/Employee List
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">

                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                      (click)="deleteSelectedProfiles()"
                      [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                  <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                      chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                  <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
              </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="row pt-2 pb-2" *ngIf="rolecode === 'DA' && !enableAddNewButton">
        <div class="col">
            <p-radioButton name="levelType" value="Yes" [(ngModel)]="isProfileIncluded" (onClick)="levelType='Village';" inputId="levelType2"></p-radioButton>
            <label for="levelType2" class="label-margin">Both Profile And Vendor</label>
            <p-radioButton name="levelType" value="No" [(ngModel)]="isProfileIncluded" (onClick)="levelType='Village';levelTypeChange();onEmployeeClear()" inputId="levelType3"></p-radioButton>
            <label for="levelType3" class="label-margin">Vendor Only</label>
            <p-radioButton *ngIf="isProfileIncluded" name="levelType" value="Block" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType2"></p-radioButton>
            <label for="levelType2" *ngIf="isProfileIncluded" class="label-margin">Block</label>
            <p-radioButton *ngIf="isProfileIncluded" name="levelType" value="Village" (onClick)="levelTypeChange()" [(ngModel)]="levelType" inputId="levelType3"></p-radioButton>
            <label for="levelType3" *ngIf="isProfileIncluded" class="label-margin">Village</label>
        </div>
    </div>
    <div class="input-row py-10 pb-2" *ngIf="rolecode === 'DA' && !enableAddNewButton && isProfileIncluded === 'Yes'">
        <input pInputText type="text" placeholder="Admin User Name" [(ngModel)]="adminUserName" />
        <input *ngIf="levelType === 'Village'" pInputText type="text" placeholder="LGD Code" [(ngModel)]="lgdCode" />
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onClear()" class="p-button-sm"></button>
    </div>
    <div class="input-row py-10 pb-2" *ngIf="rolecode === 'DA' && !enableAddNewButton && isProfileIncluded === 'No'">
        <ng-select id="scheme" [(ngModel)]="BlockLevelDto" [placeholder]="BlockLevelDto ? '' : 'Block'" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true"
            [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <ng-select *ngIf="levelType === 'Village'" id="scheme" [(ngModel)]="villageLevelDto" [placeholder]="villageLevelDto ? '' : 'Village'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" change="" [closeOnSelect]="true"
            [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <button pButton pRipple label="Search" (click)="onEmployeeSearch(1)" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onEmployeeClear()" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div *ngIf="isProfileIncluded === 'Yes'" #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" (emitRow)="onSelectRow($event)" [showTransferButton]="true" [disableEditIcon]="true"></custom-filter-table>
        </div>
        <div *ngIf="isProfileIncluded === 'No'" #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="employeeCols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" (emitRow)="onSelectRow($event)" [showTransferButton]="true" [disableEditIcon]="true"></custom-filter-table>
        </div>
    </div>

    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>