import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';

@Component({
  selector: 'app-vendor-report',
  templateUrl: './vendor-report.component.html',
  styleUrls: ['./vendor-report.component.scss']
})
export class VendorReportComponent implements OnInit {
  villages:any[];
  cols=[{field:'Name',header:'Name'},
  {field:'Personal',header:'Personal'},
  {field:'Commercial',header:'Commercial'},
  {field:'Employee',header:'Employee'},
  {field:'ER',header:'ER'}
  ]

  constructor(public fontService:FontService) { }

  ngOnInit(): void {
    this.villages = [
      { Name: 'abc', Personal: 51, Commercial: 40, Employee: 54406, ER: 43342 },
      { Name: 'df', Personal: 51, Commercial: 40, Employee: 54406, ER: 43342 },
      { Name: 'dgfs', Personal: 51, Commercial: 40, Employee: 54406, ER: 43342 },
      { Name: 'det', Personal: 51, Commercial: 40, Employee: 54406, ER: 43342 },
      { Name: 'kh', Personal: 51, Commercial: 40, Employee: 54406, ER: 43342 },
      { Name: 'as', Personal: 51, Commercial: 40, Employee: 54406, ER: 43342 },
  ];
  }
  loadData(){
    
  }
}
