<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="payments.personal.payment.voucher.bname">
                        Beneficiary Payment Voucher</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row voucherfont">
                        <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                            Add Voucher Generation</h6>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"payments.voucher.number.name"|translate}}:<br />{{ voucherGenerationDto?.vendorTransactionDto?.voucherNo }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"date.name"|translate}}:<br />{{ date | date:'dd/MM/yyyy' }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"implementing.agency.name"|translate}}:<br />{{ this.voucherGenerationDto.vendorTransactionDto?.implementingAgencyDto?.name }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.villagegst.number.name"|translate}}:<br />{{ this.voucherGenerationDto.vendorTransactionDto?.implementingAgencyDto?.gstNo }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.limit.amount.name"|translate}}:
                                <br />{{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.realizedLimitAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.department.name"|translate}}:<br>{{this.voucherGenerationDto.vendorTransactionDto?.implementingAgencyDto?.departmentDto?.name}}
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.scheme.name"|translate}}:<br>{{this.voucherGenerationDto.vendorTransactionDto?.implementingAgencyDto?.schemeDto?.name}}
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.level.type.name"|translate}}:<br>{{levelMasterDto?.name}}
                            </p>
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "funds.fin.year.name" | translate }}:<br />{{ finYear?.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
        <div class="row">
          <div class="col">
            <div class="card p-3">
              <div class="row">
                <h6></h6>
                <div class="col-md-4">
                  <p>Department:<br>{{voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.departmentDto.name}}</p>
                </div>
                <div class="col-md-4">
                  <p>Scheme:<br>{{ voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.schemeDto.name}}</p>
                </div>
                <div class="col-md-4">
                  <p>Level Type:<br>{{levelMasterDto.name}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="grantlevel" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="true" (change)="ongrandLevel()" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.major.component">Major
                Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="majaorComponentdto" [disabled]="true" [items]="majaorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="onMajorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !majaorComponentdto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.minor.component">Minor
                Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="minorComponentDto" [disabled]="true" [items]="minorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="onMinorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !minorComponentDto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.sub.component">Sub
                Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="subComponentDto" [disabled]="true" [items]="subComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width" [ngClass]="{'is-invalid': ngSelectInvalid && !subComponentDto}">
                            </ng-select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="subComponentDto" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-2">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.panchayat.resolution.number.name">Panchayat Resolution No</label>
                            <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.panchayatResolutionNumber" name="name" autocomplete="off"
                                id="levelType" [readonly]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.vendorTransactionDto.panchayatResolutionNumber }" />
                        </div>
                        <div class="col-lg-5">
                            <div class="row">
                                <div class="col">
                                    <p-radioButton name="vendorOption" [disabled]="readonly" value="beneficiarySelectOption" [(ngModel)]="vendorSearchOption" label="Search by Beneficiary" (onClick)="changeSearchOption('beneficiarySelect')"></p-radioButton>
                                </div>
                                <div class="col">
                                    <p-radioButton name="vendorOption" [disabled]="readonly" value="beneficiaryAccnoSearchOption" [(ngModel)]="vendorSearchOption" label="Search by Account Number" (onClick)="changeSearchOption('accountNo')"></p-radioButton>
                                </div>
                                <div class="col">
                                    <p-radioButton name="vendorOption" [disabled]="readonly" value="beneficiaryUnIdSearchOption" [(ngModel)]="vendorSearchOption" label="Search by Unique Id" (onClick)="changeSearchOption('uniqueId')"></p-radioButton>
                                </div>

                                <div *ngIf="vendorSearchOption =='beneficiaryUnIdSearchOption'">
                                    <span class="p-inputgroup">
                                    <input type="text" class="form-control search_input mt-1"  maxlength="20" [(ngModel)]="uniqueId" name="uniqueId" autocomplete="off"
                                        id="uniqueId" [readonly]="readonly" />
                                    <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly" (click)="beneficiaryFilterByUniqueId()" ></button>
                                    </span>
                                    <!-- [ngClass]="{ 'is-invalid': !accountNo }" -->
                                </div>
                                <div *ngIf="vendorSearchOption =='beneficiaryAccnoSearchOption'">
                                    <span class="p-inputgroup">
                                    <input type="text" class="form-control search_input mt-1" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="20" [(ngModel)]="accountNo" name="accountNo" autocomplete="off"
                                        id="accountNo" [readonly]="readonly"  />
                                    <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly" (click)="beneficiaryFilterByAccount()" ></button>
                                    </span>
                                    <!-- [ngClass]="{ 'is-invalid': !accountNo }" -->
                                </div>
                                
                            </div>
                            <div class="row" *ngIf="vendorSearchOption =='beneficiarySelectOption'">
                                <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="selectedBeneficiary" [items]="beneficiaryList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="search($event)" [disabled]="readonly  || loadingMore || !vendorSearchOption"
                                    (change)="popupValue()" class="form-control search_input mt-1" [ngClass]="{ 'is-invalid': ngSelectInvalid && !selectedBeneficiary.name }" maxlength="50" (scrollToEnd)="onScrollToEnd()">
                                    <ng-template ng-header-tmp>
                                        <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly || !vendorSearchOption" (click)="vendorFilter()"></button>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="row">
                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">Name:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedBeneficiary?.name}}</p>
                                </div>
                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.accno.name"|translate}}:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedBeneficiary?.accountNumber}}</p>
                                </div>
                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.bank.name"|translate}}:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedBeneficiary?.bankName}}</p>
                                </div>

                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.ifsc.code.name"|translate}}:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedBeneficiary?.ifscCode}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="enableZonalButton" class="col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Zonal User</label>
                            <ng-select id="scheme" [disabled]="readonly" [(ngModel)]="selectedZonalUser" [items]="zonalList" bindLabel="userName" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div *ngIf="selectedBeneficiary?.name" class="col-md-6">
                            <div class="mt-1 p-2" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.last.transaction.name">last 5 Transaction</div>
                            <button pButton pRipple (click)="getvendorTrans()" class="p-button-success custom-button" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.view"></span></button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="subComponentDto && selectedBeneficiary?.name" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <!--  <div class="col-12">
                            <label class="form-control-label" for="schemeDescription">GST Applicable</label><br>
                            <p-radioButton name="option" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.gstApplicable" value="option1" label="Yes"></p-radioButton>
                            <p-radioButton name="option" value="option2" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.gstApplicable" label="No"></p-radioButton>
                        </div> -->
                        <div class="col-md-12 mt-2" *ngIf="selectedBeneficiary?.name">
                            <p-table [value]="voucherGenerationDto.beneficiaryDescDtoList" [resizableColumns]="true" responsiveLayout="scroll" style="overflow-y: visible">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.sno'|translate}}</th>
                                        <th *ngIf="enableEmployee" [ngClass]="fontService.labelClass">
                                            {{'payments.table.employee'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.description'|translate}}</th>
                                        <!-- <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.noOfDaysAttended'|translate}}
                                        </th> -->
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.amount'|translate}} </th>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.grossTotal'|translate}}</th>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.delete'|translate}}</th>
                                        <th style="text-align: right">
                                            <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" styleClass="button" (click)="addRow()" [disabled]="readonly">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                          jhiTranslate="common.row"></span></button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td *ngIf="enableEmployee">
                                            <ng-select [appendTo]="'body'" id="scheme" [items]="employeeList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control" (change)="list.amount = selectedEmployee.escNet" [(ngModel)]="selectedEmployee"
                                                appendTo="body">
                                            </ng-select>
                                        </td>
                                        <!-- <td>
                                            <ng-select [appendTo]="'body'" id="scheme" style="min-width:210px" [(ngModel)]="list.descriptionDto" [items]="descriptionList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="readonly"
                                                appendTo="body" (change)="addDescritption(list.descriptionDto)" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.descriptionDto }">
                                            </ng-select>
                                        </td> -->
                                        <td>
                                            <input type="text" pInputText [(ngModel)]="list.descriptionDto" style="min-width:360px" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.descriptionDto }" [readonly]="readonly" />
                                        </td>
                                        <!-- <td>
                                            <p-inputNumber id="field_price" [(ngModel)]="list.quantity" (ngModelChange)="calculatetotal(list)" [readonly]="readonly" maxlength="15" [ngClass]="{'is-invalid': ngSelectInvalid && !list.quantity}"></p-inputNumber>
                                        </td> -->
                                        <td>
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.amount" (ngModelChange)="calculatetotal(list)" [readonly]="readonly" maxlength="15" [ngClass]="{'is-invalid': ngSelectInvalid && !list.amount}"></p-inputNumber>

                                        </td>

                                        <td>
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.grossAmount" mode="decimal" [maxFractionDigits]="2" [readonly]="true">
                                            </p-inputNumber>
                                        </td>
                                        <td>
                                            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" [disabled]="readonly" (click)="delete(index)"></button>
                                        </td>
                                        <td></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div *ngIf="selectedBeneficiary?.name" class="row mt-3 tds">
                        <div class="col-lg-6" *ngIf="!enableEmployee">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="form-control-label" for="schemeDescription" [ngClass]="fontService.labelClass">{{"payments.amount.select.nhis.name"|translate}}</label><br>
                                    <p-radioButton name="option" [disabled]="true" [value]="true" [(ngModel)]="enableNhis" (onClick)="this.voucherGenerationDto.vendorTransactionDto.nhisPercentage = 9;this.calculateTotalAmount()" label="{{'payments.amount.yes'|translate}}"></p-radioButton>
                                    <p-radioButton name="option" [disabled]="true" [value]="false" [(ngModel)]="enableNhis" label="{{'payments.amount.no'|translate}}" (onClick)="voucherGenerationDto.vendorTransactionDto.nhisPercentage =0;calculateTotalAmount()"></p-radioButton>
                                </div>
                                <div class="col-sm-3 pt-xs-2" *ngIf="enableNhis">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">NHIS %</label><br>
                                    <p-inputNumber class="full-width" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.nhisPercentage" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" name="name" autocomplete="off" id="levelType" [max]="28" [min]="0" [readonly]="readonly"
                                        (ngModelChange)="calculateTotalAmount()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5 pt-xs-2" *ngIf="enableNhis">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.select.nhis.amount"></label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="this.voucherGenerationDto.vendorTransactionDto.nhis" name="name" autocomplete="off" [readonly]="readonly" id="levelType"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4 pt-sm-2 pt-xs-2">
                                    <label class="form-control-label" for="schemeDescription" [ngClass]="fontService.labelClass">{{"payments.amount.select.cps.name"|translate}}</label><br>
                                    <p-radioButton [disabled]="true" name="option" [value]="true" [(ngModel)]="enableCps" (onClick)="this.voucherGenerationDto.vendorTransactionDto.cpsPercentage = 10;calculateTotalAmount()" label="{{'payments.amount.yes'|translate}}"></p-radioButton>
                                    <p-radioButton [disabled]="true" name="option" [value]="false" [(ngModel)]="enableCps" label="{{'payments.amount.no'|translate}}" (onClick)="this.voucherGenerationDto.vendorTransactionDto.cpsPercentage =0;calculateTotalAmount()"></p-radioButton>
                                </div>
                                <div class="col-sm-3 pt-sm-2 pt-xs-2" *ngIf="enableCps">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">CPS %</label><br>
                                    <p-inputNumber class="full-width" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.cpsPercentage" name="name" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" autocomplete="off" id="levelType" min="1" max="28" [readonly]="readonly"
                                        (ngModelChange)="calculateTotalAmount()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5 pt-sm-2 pt-xs-2" *ngIf="enableCps">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.select.cps.amount"></label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="this.voucherGenerationDto.vendorTransactionDto.cps" name="name" autocomplete="off" [readonly]="readonly" id="levelType"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4 col-12 pt-sm-2 pt-xs-2">
                                    <label class="form-control-label" for="schemeDescription" [ngClass]="fontService.labelClass">{{"payments.amount.select.other.deduction.name"|translate}}</label><br>
                                    <p-radioButton [disabled]="true" name="option" [value]="true" [(ngModel)]="enableOtherDeduction" (onClick)="this.calculateTotalAmount()" label="{{'payments.amount.yes'|translate}}"></p-radioButton>
                                    <p-radioButton [disabled]="true" name="option" [value]="false" [(ngModel)]="enableOtherDeduction" label="{{'payments.amount.no'|translate}}" (onClick)="voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage=0;voucherGenerationDto.vendorTransactionDto.otherDeductionIt=0;calculateTotalAmount()"></p-radioButton>
                                </div>
                                <div class="col-sm-3 pt-sm-2 pt-xs-2" *ngIf="enableOtherDeduction">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.other.deduction.name"></label><br>
                                    <p-inputNumber class="full-width" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" name="name" autocomplete="off" min="1" max="28" [readonly]="readonly" id="levelType"
                                        (ngModelChange)="calculateOtherDeduction()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5 pt-sm-2 pt-xs-2" *ngIf="enableOtherDeduction">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.other.deduction.amount.name"></label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.otherDeductionIt" name="name" autocomplete="off" [readonly]="readonly" id="levelType"></p-inputNumber>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <label class="form-control-label pt-lg-0 pt-xs-2 pt-sm-2 req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label><br>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.remark" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.vendorTransactionDto.remark }" cols="100" [disabled]="readonly"></textarea>
                        </div>
                        <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2" *ngIf="voucherGenerationDto.vendorTransactionDto.netAmount">
                            <table class="snippet">
                                <tr>
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.total.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.netAmount | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.nhis">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.nhis.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.nhis | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.cps">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.cps.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.cps | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.otherDeductionIt">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.other.deduction.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.otherDeductionIt | currency:'INR':'symbol':'1.2-2':'en-IN'|| "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="totalDeduction">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.total.deduction.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ totalDeduction | currency:'INR':'symbol':'1.2-2':'en-IN'|| "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.transactionAmount">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.transaction.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto .transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'|| "Nill" }}
                                    </td>
                                </tr>
                                <!-- <div class="row " *ngIf="!readonly">
                  <div class="col-sm-3 generate mt-3">
                    
                    <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                  </div>
                </div> -->
                                <!-- <div class="row" *ngIf="enableRevertButton">
                  <div class="col-6"></div>
                  <div class="col-2 generate mt-3">
                    <button pButton pRipple label="Revert" (click)="revert()" class="p-button-danger"></button><br>
                  </div>
                  <div class="col-2 generate mt-3">
                    <button pButton pRipple label="Approve" (click)="genVocher()" class="p-button-success"></button><br>

                  </div>
                  <div class="col-2 generate mt-3">
                    <button pButton pRipple label="Reject" (click)="reject()" class="p-button-danger"></button>
                  </div>

                </div> -->
                            </table>

                        </div>
                    </div>
                    <p-toolbar *ngIf="selectedBeneficiary?.name && !enableRevertButton && !enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.generatevoucher"></span>
              </button>
                            <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" (click)="submitForm()">{{signButtonName}}</button>
                        </div>
                    </p-toolbar>
                    <p-toolbar *ngIf="selectedBeneficiary?.name && enableRevertButton && !enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div *ngIf="!enableSignButton && !voucherGenerationDto?.vendorTransactionDto?.signaturePresident && !this.enableView" class="p-toolbar-group-end">
                            <button pButton pRipple (click)="revert()" class="p-button-warning custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.revertbutton"></span>
              </button>
                            <button *ngIf="!voucherGenerationDto?.vendorTransactionDto?.revertStatus && enableApproved" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.approvebutton"></span>
              </button>
                            <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.rejectbutton"></span>
              </button>
                        </div>
                        <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" (click)="submitForm()">{{signButtonName}}</button>
                        <!-- <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && !enableRePaymentButton && !disablepaymentButton && !checkstatusButton" pButton pRipple [label]="buttonText" (click)="PaymentSucess()" class="p-button-success"></button> -->
                        <p *ngIf="checkstatusButton && !this.enableView" style="font-size: 18px;color:red;font-weight:700;">Payment Failed</p>
                        <!-- <button *ngIf="checkstatusButton" pButton pRipple label="Check Transaction Status" (click)="checkTransactionStatus()" class="p-button-success"></button> -->
                    </p-toolbar>
                    <p-toolbar *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident">
                        <div class="p-toolbar-group-start">
                            <p *ngIf="this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus === 'Failed'" style="font-size: 18px;color:red;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
                            <p *ngIf="this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus === 'Success'" style="font-size: 18px;color:green;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
                        </div>
                        <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && roleCode.role.code !== 'DAD'  && voucherGenerationDto?.vendorTransactionDto?.transactionStatus !== 'Failed'" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                    </p-toolbar>

                </div>
            </div>
        </div>
        <p-dialog [(visible)]="profileDialog" [style]="{ width: '1020px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-header [ngClass]="fontService.headingClass">
                {{ "payments.lastTransaction" | translate }}
            </p-header>
            <p-table *ngIf="!noRecord" [value]="searchResult.items" [resizableColumns]="true" styleClass="dyanamic-table p-datatable-gridlines" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ngClass]="fontService.labelClass">{{'payments.viewTable.voucherNo'|translate}}</th>
                        <th [ngClass]="fontService.labelClass">{{'payments.viewTable.vendorName'|translate}}</th>
                        <th [ngClass]="fontService.labelClass">{{'payments.viewTable.vendorAccount'|translate}}</th>
                        <th [ngClass]="fontService.labelClass">{{'payments.viewTable.transactionDate'|translate}}</th>
                        <th [ngClass]="fontService.labelClass">{{'payments.viewTable.rate'|translate}}</th>
                        <th [ngClass]="fontService.labelClass">{{'payments.viewTable.totTransAmount'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index="rowIndex">
                    <tr>
                        <td>{{ data.vendorTransaction.voucherNo }}</td>
                        <td>{{ data.vendorTransaction.personalVendorName }}</td>
                        <td>{{ data.vendorTransaction.personalAccountNo }}</td>
                        <td>{{ data.vendorTransaction.transDate | date : "shortDate" }}</td>
                        <td>{{ data.vendorTransaction.grossAmount }}</td>
                        <td>{{ data.vendorTransaction.transactionAmount }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="noRecord">
                <h5 class="text-center" [ngClass]="fontService.regularClass" jhiTranslate="error.norecord">No transactions found
                </h5>
            </div>
        </p-dialog>
        <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-header [ngClass]="fontService.headingClass">
                Payment Alert Box
            </p-header>
            <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
            <ng-template pTemplate="footer">
                <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
          <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok" (click)="redirect()">Ok</span>
        </button>
            </ng-template>
        </p-dialog>
    </div>
</div>
<p-dialog [(visible)]="deleteDialog" [style]="{ width: '500px', height: '200px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        <b class="text-danger">Reject Dialog</b>
    </p-header>
    <p>Something went wrong with the voucher, please reject the voucher</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-danger">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Reject"
          (click)="reject()"
          >Reject</span
        >
      </button>
    </ng-template>

</p-dialog>
<!-- <p-dialog [(visible)]="bulkVoucherDialog" [style]="{ width: '500px', height: '200px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        <b class="text-danger">Bulk Voucher Dialog</b>
    </p-header>
    <p>This Voucher Already in Bulk Voucher and Bulk Voucher Number is {{voucherGenerationDto.vendorTransactionDto.parentVoucherNumber}}</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-danger">
    <span
      class="button-label"
      [ngClass]="fontService.buttonClass"
      label="Ok"
      (click)="bulkVoucherRedriect()"
      >Ok</span
    >
  </button>
    </ng-template>

</p-dialog> -->