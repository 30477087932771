<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">IA Component Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <input pInputText pKeyFilter="alphanum" placeholder="Enter LGD Code" [(ngModel)]="lgdCode" />
        <p-dropdown class="req" placeholder="Select Fin Year" [options]="finYearList" [(ngModel)]="finYear" 
        optionLabel="name" showClear="true"></p-dropdown>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
    </div>
    <div class="row mt-4">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false"
        (searchParam)="onLazyLoad($event)" [showMultiSelect]="true"></custom-filter-table>
    </div>
    