<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">DSC Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <input pInputText pKeyFilter="alphanum" placeholder="Enter Vendor Transaction Id" [(ngModel)]="vtId" />
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
    </div>
    <div class="row mt-4">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false"
        (searchParam)="onLazyLoad($event)" [showMultiSelect]="true"></custom-filter-table>
    </div>
    