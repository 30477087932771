<div class="row bg">
    <div class="col-sm-6 col-md-6 login-form ">
        <div class="font-login mb-4">Reset Password</div>
        <div class="login-content">
            <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="resetPwdForm">
                <div class="form-group mb-4 eye-container">
                    <input id="password" name="password" maxlength="16" autocomplete="off" formControlName="password" [type]="phide ? 'password' : 'text'" required class="form-control full" placeholder="NEW PASSWORD">
                    <i class="pi pi-eye" *ngIf="phide" (click)="phide=false"></i>
                    <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide=true"></i>
                </div>
                <div class="form-group mb-4 eye-container">
                    <input id="confirmPassword" name="confirmPassword" maxlength="16" autocomplete="off" formControlName="confirmPassword" class="form-control full" required placeholder="CONFIRM NEW PASSWORD" [type]="cphide ? 'password' : 'text'">
                    <i class="pi pi-eye" *ngIf="cphide" (click)="cphide=false"></i>
                    <i class="pi pi-eye-slash" *ngIf="!cphide" (click)="cphide=true"></i>
                </div>
                <div *ngIf="pwdMismatch" class="text-danger mb-2">
                    <small>New Password and Confirm New Password should be same. Please re-enter</small>
                </div>
                <div class="mb-4">
                    <button type="submit" [disabled]="!resetPwdForm.valid" class="btn btn-secondary">Submit</button>
                </div>
                <div class="footer-logo">
                    <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                    <img class="ib-logo" src="/./assets/img/logo 1.svg" alt="Image">
                </div>
            </form>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 login-caption">
        <div class="row">
            <div class="col-6 g-0 image">
                <img class="image" src="/./assets/img/1 1.png" alt="Image">

            </div>
            <div class="col-6 gy-0 graphic-wrapper">
                <img class="graphic" src="/./assets/img/4343973 1.svg" alt="Image">
            </div>
        </div>
        <div class="row gx-0 align-items-center caption">
            <div class="col-2" style="display:table">
                <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image">
            </div>
            <div class="col-10 ">
                <p class="tn-font">ஊரக வளர்ச்சி மற்றும் ஊராட்சித் துறை</p>
                <p>Department of Rural Development and Panchayat Raj</p>
            </div>
        </div>
    </div>
</div>