import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { filterQuery } from 'src/app/shared/common.model';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ConsumerDemand } from '../tangedco-bill-details/tangedco-bill-details';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { TangedcoBillService } from '../tangedco-bill-details/tangedco-bill-details.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-menu-enable-list',
  templateUrl: './menu-enable-list.component.html',
  styleUrls: ['./menu-enable-list.component.scss']
})
export class MenuEnableListComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;


  itemsPerPage: any = 10;
  filterProps: any;
  ngSelectInvalid:boolean
  searchResult: SearchResult<any> =
    new SearchResult<any>();
  tangedcosearchResult: SearchResult<any> =
    new SearchResult<any>();
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
  new SearchResult<any>();
  disableEdit: boolean=false;
  disableDelete: boolean=true;
  cosumerDemandId: number;
  tangedcoBillDialog:boolean
  readonly: boolean;
  tangedcoBills: ConsumerDemand;
  habitationList: any;
  activeIndex = 0;
  lgdCode:string;
  constructor(
    protected router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    private tangedcoBillService: TangedcoBillService,
    private confirmationService: ConfirmationService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.cols = [
      
      {
        field: 'districtName',
        header: 'District Name',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'blockName',
        header: 'Block Name',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'villageName',
        header: 'Village Name',
        //jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true
        //type: 'date',
      },
      {
        field: 'villageLgd',
        header: 'Lgd Code',
        isSelectcolumn: true,
        isFilterable: false
        //type:'amount'
      },
      
    ];
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }
  
  loadPage(page?: number, dontNavigate?: boolean): void {
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
     
    };
    if(this.lgdCode) {
      filter['lgdcode'] = this.lgdCode;
    }
    if(this.activeIndex === 0){
      filter['menuType'] = "TANGEDCO";
    }
    else if(this.activeIndex === 1) {
      filter['menuType'] = "TWAD";
    }
    else {
      filter['menuType'] = "TANGEDCOPAYMENT";
    }
    this.spinner.show();
    this.tangedcoBillService.getEnabledMenuList({ ...filter }).subscribe(
      (res: HttpResponse<ConsumerDemand[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: ConsumerDemand[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'disable') {
      this.confirmationService.confirm({
        message: 'Are you Sure want to disable this village ' + $event.value.villageName,
        accept: () => {
          this.spinner.show();
          let filter = {
            levelMasterId:$event.value.villageId
          };
          if(this.activeIndex === 0){
            filter['menuType'] = "TANGEDCO";
          }
          else if(this.activeIndex === 1) {
            filter['menuType'] = "TWAD";
          }
          else {
            filter['menuType'] = "TANGEDCOPAYMENT";
          }
          this.tangedcoBillService.disableMenuVilage({ ...filter }).subscribe(
            (res) => {
              this.spinner.hide();
              this.loadPage(1)
            },
            () => {
              this.onError();
            }
          );
        },
        reject: () => {}
    });
     
    }
	
  }

  onTabChange() {
    this.lgdCode = null;
  }
 

}
