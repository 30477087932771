export class EmployeeTransfer {
    id: number;
    employeeId: number;
    profileId: number;
    districtFromName: string;
    districtFromId: number;
    blockFromName: string;
    blockFromId: number;
    villageFromName: string;
    villageFromId: number;
    districtToName: string;
    districtToId: number;
    blockToName: string;
    blockToId: number;
    villageToName: string;
    villageToId: number;
    transaferredDate: Date;
    isProfileIncluded: boolean;
    transferToUserId: number;
    filePath: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
    villageToLgd:string;
    villageFromLgd:string;
}