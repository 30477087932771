<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass"  jhiTranslate="payments.swap.payment.voucher.name">
                        Swap Payment Voucher</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row voucherfont">
                        <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                            Add Voucher Generation</h6>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"payments.voucher.number.name"|translate}}:<br />{{ voucherGenerationDto.swapTransactionDto?.voucherNo }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"date.name"|translate}}:<br />{{ date | date:'dd/MM/yyyy' }}</p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"implementing.agency.name"|translate}}:<br />{{ this.implementingAgencyDetails.name }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.gst.number.name"|translate}}:<br />{{ this.implementingAgencyDetails.gstNo }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.limit.amount.name"|translate}}:<br />{{ this.voucherGenerationDto.swapTransactionDto .iaComponentLimitDto?.realizedLimitAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.department.name"|translate}}:<br>{{this.implementingAgencyDetails.departmentDto.name}}
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.scheme.name"|translate}}:<br>{{this.implementingAgencyDetails.schemeDto.name}}
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"payments.level.type.name"|translate}}:<br>{{levelMasterDto.name}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="grantlevel" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly" (change)="ongrandLevel()" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.major.component">Major Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="majaorComponentdto" [disabled]="readonly" [items]="majaorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="onMajorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !majaorComponentdto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.panchayat.resolution.number.name">Panchayat Resolution No</label>
                            <input type="text" class="form-control full" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [(ngModel)]="voucherGenerationDto.swapTransactionDto.panchayatResolutionNumber" name="name" autocomplete="off"
                                id="levelType" [readonly]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.swapTransactionDto.panchayatResolutionNumber }" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="majaorComponentdto" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <p-table [value]="swapIntermediateSubTablelist" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.sno'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{"payments.level.type.name"|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.block'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.village'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.grant.level.name'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{"payments.limit.amount.name"|translate}}</th>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.delete'|translate}}</th>
                                        <th style="text-align: right">
                                            <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" styleClass="button" (click)="addRow()" [disabled]="readonly">
                          <span class="button-label" [ngClass]="fontService.buttonClass"
                          jhiTranslate="common.row"></span></button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            <ng-select [appendTo]="'body'" id="levelType" style="min-width:210px" [(ngModel)]="list.levelTypeDto" (change)="onStateLevelChange(index)" [items]="list.levelType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control"
                                                [disabled]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.levelTypeDto }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select [appendTo]="'body'" style="min-width:210px" id="scheme" [(ngModel)]="list.BlockLevelDto" [items]="list.blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange(index)" (search)="onStateLevelChange(index)" [closeOnSelect]="true"
                                                [clearable]="false" class="form-control" [disabled]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.BlockLevelDto }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select [appendTo]="'body'" id="levelType" style="min-width:210px" [(ngModel)]="list.villageLevelDto" [items]="list.villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [closeOnSelect]="true" [clearable]="false"
                                                class="form-control village" [disabled]="readonly || list.levelTypeDto === 'Block'" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.villageLevelDto && list.levelTypeDto !== 'Block' }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select [appendTo]="'body'" id="scheme" style="min-width:210px" [(ngModel)]="list.grantLevelDto" [items]="list.grantLevelList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="readonly"
                                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.grantLevelDto }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.limitAmount"  (ngModelChange)="calculatetotal(list)" [readonly]="readonly" maxlength="15" [ngClass]="{'is-invalid': ngSelectInvalid && !list.limitAmount}"></p-inputNumber>
                                        </td>
                                        <td>
                                            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" [disabled]="readonly" (click)="delete(index)"></button>
                                        </td>
                                        <td></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="row mt-3 g-2 tds">
                        <div class="col-lg-4">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label><br>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="voucherGenerationDto.swapTransactionDto.remark" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.swapTransactionDto.remark }" cols="100" [disabled]="readonly"></textarea>
                        </div>
                        <div class="col-lg-8 text-end">
                            <table class="snippet">
                                <tr *ngIf="
                    voucherGenerationDto.swapTransactionDto
                      .transactionAmount !== 0
                  ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.viewTable.totTransAmount" | translate }}: 
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.swapTransactionDto .transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <p-toolbar *ngIf=" !enableRevertButton">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
                  <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.generatevoucher"></span>
                </button>
                            <form *ngIf="enableSignButton" ngNoForm [action]="getActionUrl()" method="POST">
                                <input id="eSignRequest" name="eSignRequest" type="hidden" [value]='param1' />
                                <input id="aspTxnID" name="aspTxnID" type="hidden" [value]='param2' />
                                <input id="Content-Type" name="Content-Type" type="hidden" [value]='param3' />

                                <div class="container text-center mt-10">
                                    <button class="p-button-success custom-button margin" type="submit" onclick="submit()">{{signButtonName}}</button>
                                </div>
                            </form>
                        </div>
                    </p-toolbar>
                    <p-toolbar *ngIf="enableRevertButton">
                        <div class="p-toolbar-group-start"></div>
                        <div *ngIf="!enableSignButton && !voucherGenerationDto.swapTransactionDto?.signaturePresident" class="p-toolbar-group-end">
                            <button pButton pRipple (click)="revert()" class="p-button-warning custom-button">
                  <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.revertbutton"></span>
                </button>
                            <button *ngIf="!voucherGenerationDto.swapTransactionDto?.revertStatus && enableApproved" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
                  <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.approvebutton"></span>
                </button>
                            <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
                  <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.rejectbutton"></span>
                </button>
                        </div>
                        <form *ngIf="enableSignButton && !voucherGenerationDto.swapTransactionDto.signaturePresident" ngNoForm [action]="getActionUrl()" method="POST">
                            <input id="eSignRequest" name="eSignRequest" type="hidden" [value]='param1' />
                            <input id="aspTxnID" name="aspTxnID" type="hidden" [value]='param2' />
                            <input id="Content-Type" name="Content-Type" type="hidden" [value]='param3' />

                            <div class="container text-center mt-10">
                                <button class="p-button-success custom-button margin" type="submit" onclick="submit()">{{signButtonName}}</button>
                            </div>
                        </form>
                        <b *ngIf="paymentSucessDialog">{{ paymentSucessText }}</b>
                        <button *ngIf="voucherGenerationDto.swapTransactionDto.signaturePresident" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                    </p-toolbar>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog
    [(visible)]="deleteDialog"
    [style]="{ width: '500px', height: '200px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
    (onHide)="hideDialog()"
  >
    <p-header [ngClass]="fontService.headingClass">
     <b class="text-danger">Reject Dialog</b>
    </p-header>
    <p>Something went wrong with the voucher, please reject the voucher</p>
    <ng-template pTemplate="footer">
      <button pButton pRipple
        class="p-button-danger">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Reject"
          (click)="reject()"
          >Reject</span
        >
      </button>
    </ng-template>

  </p-dialog>