import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { AdminUserDtoCreatedby, AdminUserDtoUpdatedBy } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { DepartmentList } from "../department-list/department-list"


export class PersonalVendors {
    id: number
    name: string
    staffId: string
    designation: string
    aadhaarNo: string
    micr: any
    panNo: string
    department: DepartmentList
    scheme: SchemeList
    mobile: string
    email: string
    state: string
    district: string
    city: string
    doorNo: string
    street: string
    area: any
    companyState: string
    companyDistrict: string
    companyCity: string
    companyDoorNo: string
    companyStreet: string
    companyArea: any
    companyPinCode: string
    bankAccName: any
    bankId: any
    bankName: string
    bankBranch: string
    pinCode: string
    ifscCode: string
    accountNumber: string
    createdOn: string
    updatedOn: string
    adminUserCreatedBy: number
    adminUserUpdatedBy: number
    activeFlag: string
    uniqueId:string;
  }

  export class PersonalVendorsDto {
    id: number
    name: string
    companyName: any
    designation: string
    staffId: string
    aadhaarNo: string
    micr: any
    panNo: string
    gstNo: any
    mobile: string
    email: string
    serviceTaxNo: any
    tanNo: any
    tinNo: any
    state: string
    district: string
    city: string
    doorNo: string
    street: string
    area: any
    pinCode: string
    companyState: string
    companyDistrict: string
    companyCity: string
    companyDoorNo: string
    companyStreet: string
    companyArea: any
    companyPinCode: string
    bankAccName: any
    bankId: any
    bankName: string
    bankBranch: string
    accountNumber: string
    activeFlag: string
    ifscCode: string
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedBy: number
    adminUserDtoUpdatedBy: number
    departmentDto: DepartmentList
    schemeDto: SchemeList
  }

  export class ValidationResponse{
    accountNo:string;
    accountName:string;
    bankTxnStatus:string;
    ifsc:string;
    bankResponse:string;
  }