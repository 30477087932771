import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ComponentFund, OnBoardReportDto } from './report.model';
import { createRequestOption } from '../auth/request/request-util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  protected onboardReportUrl = environment.SERVER_API_URL+('api/report/onBoardReport');
  protected onboardVillageUrl = environment.SERVER_API_URL+('api/report/getOnBoardVillageList');
  protected villageListUrl = environment.SERVER_API_URL+('api/report/getVillageList');
  protected getOnboardReportUrl = environment.SERVER_API_URL+('api/report/getOnboardReport');
  protected getOnboardReportBlockUrl = environment.SERVER_API_URL+('api/report/getOnboardReportBlock');
  protected getOnboardReportVillageUrl = environment.SERVER_API_URL+('api/report/getOnboardReportVillage');
  protected onboardReportDownloadUrl = environment.SERVER_API_URL+('api/report/getOnBoardReportDownload');
  protected onboardReportBlockDownloadUrl = environment.SERVER_API_URL+('api/report/getOnBoardReportBlockDownload');
  protected onboardReportVillageDownloadUrl = environment.SERVER_API_URL+('api/report/getOnBoardReportVillageDownload');
  protected getPaymentReportByDistrictUrl = environment.SERVER_API_URL+('api/report/getPaymentReportByDistrict');
  protected getPaymentReportByBlockUrl = environment.SERVER_API_URL+('api/report/getPaymentReportByBlock');
  protected getPaymentReportByVillageUrl = environment.SERVER_API_URL+('api/report/getPaymentReportByVillage');
  protected getPaymentVouchersByVillageUrl = environment.SERVER_API_URL+('api/report/getPaymentVouchersByVillage');
  protected getEmployeeCountByDistrictUrl = environment.SERVER_API_URL+('api/report/getEmployeeCountByDistrict');
  protected getEmployeeCountByBlockUrl = environment.SERVER_API_URL+('api/report/getEmployeeCountByBlock');
  protected getEmployeeCountByVillageUrl = environment.SERVER_API_URL+('api/report/getEmployeeCountByVillage');
  protected getVoucherStatusDistrictUrl = environment.SERVER_API_URL+('api/report/getVoucherStatusDistrict');
  protected getVoucherStatusBlockUrl = environment.SERVER_API_URL+('api/report/getVoucherStatusBlock');
  protected getVoucherStatusVillageUrl = environment.SERVER_API_URL+('api/report/getVoucherStatusVillage');
  protected getComponentFundsAmountUrl=environment.SERVER_API_URL+('api/getComponentFundsAmount');
  protected getDistrictComponentFundsUrl=environment.SERVER_API_URL+('api/getDistrictComponentFunds');
  protected getBlockComponentFundsUrl=environment.SERVER_API_URL+('api/getBlockComponentFunds');
  protected getVillageComponentFundsUrl=environment.SERVER_API_URL+('api/getVillageComponentFunds');
  protected getVendorTransactionReportUrl=environment.SERVER_API_URL+('api/report/getVendorTransactionReport');
  protected getTotalCountNilBalanceUrl=environment.SERVER_API_URL+('api/report/getTotalCountNilBalance');
  protected getLevelMasterCountHavingNilBalanceUrl=environment.SERVER_API_URL+('api/report/getLevelMasterCountHavingNilBalance');
  protected getVillageBulkPaymentReportUrl = environment.SERVER_API_URL+('api/report/getVillageBulkPaymentReport');
  protected getBulkElectedRepVouchersByVendorIdUrl = environment.SERVER_API_URL+('api/getBulkElectedRepVouchersByVendorId');
  protected getBulkEmployeeVouchersByVendorIdUrl = environment.SERVER_API_URL+('api/getBulkEmployeeVouchersByVendorId');
  protected employeeCountReportUrl = environment.SERVER_API_URL+('api/employeeCountReport');
  protected paymentNotInitiatedReportUrl = environment.SERVER_API_URL+('api/paymentNotInitiatedReport');
  protected vendorTransactionReportUrl = environment.SERVER_API_URL+('api/vendorTransactionReport');
  protected paymentStatusReportUrl = environment.SERVER_API_URL+('api/paymentStatusReport');
  protected paymentTransReportUrl = environment.SERVER_API_URL+('api/paymentTransReport');
  protected apiReqResReportUrl = environment.SERVER_API_URL+('api/apiReqResReport');
  protected iaComponentReportUrl = environment.SERVER_API_URL+('api/iaComponentReport');
  protected iaComponentTransReportUrl = environment.SERVER_API_URL+('api/iaComponentTransReport');
  protected accountValidationLogUrl = environment.SERVER_API_URL+('api/accountValidationLog');
  protected dscReportUrl = environment.SERVER_API_URL+('api/dscReport');
  protected employeeTransferReportUrl = environment.SERVER_API_URL+('api/employeeTransferReport');
  protected villageLimitDetailsExcelUrl = environment.SERVER_API_URL+('api/villageLimitDetailsExcel');
  protected getTangedcoDemandReportUrl = environment.SERVER_API_URL+('api/getTangedcoDemandReport');
  protected getMenuEnabledListUrl = environment.SERVER_API_URL+('api/getMenuEnabledList');
  protected voucherPendingExcelUrl = environment.SERVER_API_URL+('api/voucherPendingExcel');
  protected getVendorTransactionDaysUrl = environment.SERVER_API_URL+('api/getVendorTransactionDays');
  protected getDeductionAccountReportUrl = environment.SERVER_API_URL+('api/getDeductionAccountsReport');
  //?levelType=STATE&levelMasterId=1
  protected getTwadSuccessListUrl = environment.SERVER_API_URL+('api/getTwadSuccessList');
  protected twadSuccessListExcelUrl = environment.SERVER_API_URL+('api/twadSuccessListExcel');
  protected getAllDeductionsUrl = environment.SERVER_API_URL+('api/getAllDeductions');
  protected getAllDeductionsExcelDownloadUrl = environment.SERVER_API_URL+('api/getAllDeductionsExcelDownload');
  protected getVoucherPendingUrl = environment.SERVER_API_URL+('api/getVoucherPending');
  protected reportAsyncDownloadUrl = environment.SERVER_API_URL+('api/reportAsyncDownload');
  protected reportAsyncStatusCheckUrl = environment.SERVER_API_URL+('api/reportAsyncStatusCheck');
  protected getTangedcoTransactionUrl = environment.SERVER_API_URL+('api/getTangedcoTransaction');
  protected getComponentFundsDilldownUrl = environment.SERVER_API_URL+('api/getComponentFundsDilldown');
  
  constructor(protected http: HttpClient) { }
  getTangedcoDemandReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getTangedcoDemandReportUrl,{
      params: options,
      observe: 'response'
    });
  }
  getTangedcoTransaction(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getTangedcoTransactionUrl,{
      params: options,
      observe: 'response'
    });
  }
  getVoucherPending(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getVoucherPendingUrl,{
      params: options,
      observe: 'response'
    });
  }
  getTwadReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getTwadSuccessListUrl,{
      params: options,
      observe: 'response'
    });
  }

  getTwadDownload(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.twadSuccessListExcelUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  reportAsyncDownload(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.reportAsyncDownloadUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  reportAsyncStatusCheck(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.reportAsyncStatusCheckUrl,{
      params: options,
      observe: 'response',
     
    });
  }
  getOnboardReport(){
    return this.http.post<OnBoardReportDto>(this.onboardReportUrl,{observe: 'response' })
  }
  onboardVillageList(req:any){
    const options = createRequestOption(req);
    return this.http.post<any>(this.onboardVillageUrl,null,{
      params: options,
      observe: 'response',
    });
  }
  onboardDistrictReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getOnboardReportUrl,{
      params: options,
      observe: 'response',
    });
  }
  onboardBlockReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getOnboardReportBlockUrl,{
      params: options,
      observe: 'response',
    });
  }
  onboardVillageReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getOnboardReportVillageUrl,{
      params: options,
      observe: 'response'
    });
  }
  getVillageList(req:any,filter:any){
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.villageListUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  VendorTransactionDays(req:any,filter:any){
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.getVendorTransactionDaysUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  onboardReportDownload(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.onboardReportDownloadUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  onboardReportBlockDownload(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.onboardReportBlockDownloadUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  onboardReportVillageDownload(req:any){
    const options = createRequestOption(req);
    return this.http.get(this.onboardReportVillageDownloadUrl,{
      params: options,
      observe: 'response',
      responseType:'text'
    });
  }
  paymentDistrictReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getPaymentReportByDistrictUrl,{
      params: options,observe: 'response'});
  }
  paymentBlockReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getPaymentReportByBlockUrl,{
      params: options,observe: 'response'});
  }
  paymentVillageReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getPaymentReportByVillageUrl,{
      params: options,observe: 'response'});
  }

  getPaymentVouchersByVillage(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getPaymentVouchersByVillageUrl,{
      params:options,observe: 'response'});
  }
  getEmployeeCountByVillage(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getEmployeeCountByVillageUrl,{
      params:options,observe: 'response'});
  }
  getEmployeeCountByDistrict(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getEmployeeCountByDistrictUrl,{
      params:options,observe: 'response'});
  }
  getEmployeeCountByBlock(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getEmployeeCountByBlockUrl,{
      params:options,observe: 'response'});
  }
  getVoucherStatusDistrict(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getVoucherStatusDistrictUrl,{
      params:options,observe: 'response'});
  }
  getVoucherStatusBlock(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getVoucherStatusBlockUrl,{
      params:options,observe: 'response'});
  }
  getVoucherStatusVillage(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getVoucherStatusVillageUrl,{
      params:options,observe: 'response'});
  }
  getComponentFundsAmount(req){
    const options = createRequestOption(req);
    return this.http.get<ComponentFund>(this.getComponentFundsAmountUrl,{params:options,observe: 'response' })
  }

  getComponentFundsDilldown(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getComponentFundsDilldownUrl,{
      params:options,observe: 'response'});
  }

  getCompFundByDistrict(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getDistrictComponentFundsUrl,{
      params:options,observe: 'response'});
  }
  getCompFundByBlock(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getBlockComponentFundsUrl,{
      params:options,observe: 'response'});
  }
  getCompFundByVillage(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getVillageComponentFundsUrl,{
      params:options,observe: 'response'});
  }

  getAllDeductions(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getAllDeductionsUrl,{
      params:options,observe: 'response'});
  }

  getVendorTransactionReport(req:any) {
    const options = createRequestOption(req);
    return this.http.get(this.getVendorTransactionReportUrl,{
      params:options,observe: 'response', responseType:'text'
    });
  }
  getAllDeductionsExcelDownload(req:any) {
    const options = createRequestOption(req);
    return this.http.get(this.getAllDeductionsExcelDownloadUrl,{
      params:options,observe: 'response', responseType:'text'
    });
  }

  employeeCountReport() {
    return this.http.get(this.employeeCountReportUrl,{
      observe: 'response', responseType:'text'
    });
  }

  employeeTransferReport() {
    return this.http.get(this.employeeTransferReportUrl,{
      observe: 'response', responseType:'text'
    });
  }

  villageLimitDetailsReport(req) {
    const options = createRequestOption(req);
    return this.http.get(this.villageLimitDetailsExcelUrl,{
      params:options,observe: 'response', responseType:'text'
    });
  }

  voucherPendingExcel() {
    return this.http.get(this.voucherPendingExcelUrl,{
      observe: 'response', responseType:'text'
    });
  }

  paymentNotInitiatedReport(req:any) {
    const options = createRequestOption(req);
    return this.http.get(this.paymentNotInitiatedReportUrl,{
      params:options,observe: 'response', responseType:'text'
    });
  }

  getTotalCountNilBalance(levelType:string){
    return this.http.get<any>(`${this.getTotalCountNilBalanceUrl}?levelTypeName=${levelType}`, { observe:'response'});
  }
  getLevelMasterCountHavingNilBalance(req:any) {
    const options = createRequestOption(req);
    return this.http.get<any>(this.getLevelMasterCountHavingNilBalanceUrl,{
      params:options,observe: 'response'});
  }

  getVillageBulkPaymentReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getVillageBulkPaymentReportUrl,{
      params:options,observe: 'response'});
  }
  getBulkElectedRepVouchersByVendorId(vendorId:number): Observable<any> {
    return this.http.get(`${this.getBulkElectedRepVouchersByVendorIdUrl}?vendorId=${vendorId}`, { observe: 'response' });
  }
  getBulkEmployeeVouchersByVendorId(vendorId:number): Observable<any> {
    return this.http.get(`${this.getBulkEmployeeVouchersByVendorIdUrl}?vendorId=${vendorId}`, { observe: 'response' });
  }

  vendorTransactionReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.vendorTransactionReportUrl,{
      params:options,observe: 'response'});
  }

  paymentStatusReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.paymentStatusReportUrl,{
      params:options,observe: 'response'});
  }

  paymentTransReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.paymentTransReportUrl,{
      params:options,observe: 'response'});
  }

  apiReqResReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.apiReqResReportUrl,{
      params:options,observe: 'response'});
  }

  iaComponentReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.iaComponentReportUrl,{
      params:options,observe: 'response'});
  }

  iaComponentTransReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.iaComponentTransReportUrl,{
      params:options,observe: 'response'});
  }

  accountValidationLog(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.accountValidationLogUrl,{
      params:options,observe: 'response'});
  }

  dscReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.dscReportUrl,{
      params:options,observe: 'response'});
  }

  getDeductionAccountReport(req:any){
    const options = createRequestOption(req);
    return this.http.get<any>(this.getDeductionAccountReportUrl,{
      params: options,
      observe: 'response'
    });
  }
  

}
