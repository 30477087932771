import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { UniversalVendor } from './universal-vendor.model';
export type EntityResponseType = HttpResponse<UniversalVendor[]>;

@Injectable({ providedIn: 'root' })
export class UniversalVendorService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getAllUniversalVendors');
    protected resourceFliterVaildUrl = environment.SERVER_API_URL+('api/getProfilesValidByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveUniversalVendor');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    protected getProfileUrl = environment.SERVER_API_URL+('api/getUniversalVendorById');
    protected getAllOtherDepartmentsUrl = environment.SERVER_API_URL+('api/getAllOtherDepartments');
   

    constructor(protected http: HttpClient) {}
    filter(req?: any): Observable<EntityResponseType> {
      
      const options = createRequestOption(req);
        return this.http
        .get<UniversalVendor[]>(this.resourceFliterUrl, { params:options, observe: 'response' })
    }

    departmentFilter(req?: any) {
      
        const options = createRequestOption(req);
          return this.http
          .get<UniversalVendor[]>(this.getAllOtherDepartmentsUrl, { observe: 'response' })
      }

   
    save(profile?:UniversalVendor) {
        return this.http
        .post<UniversalVendor>(this.resourceSaveUrl,profile, {observe: 'response' })
    }
    // delete(id: number): Observable<HttpResponse<{}>> {
    //   return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    // }
   
    getVendor(id: number): Observable<HttpResponse<any>> {
      return this.http.get<UniversalVendor>(`${this.getProfileUrl}?id=${id}`, { observe: 'response' });
    }

   

   

    

     

}
