import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FundsService } from './funds.service';
import { Funds, FundsDto } from './funds';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LimitTransListService } from '../limit-translist/limit-translist.service';
import { FinYear } from '../limit-translist/limit-translist.module';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss'],
})
export class FundsComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  fundsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Funds> = new SearchResult<Funds>();
  fund?: FundsDto;
  selectedFunds: Funds[] = [];
  submitted?: boolean;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  finYear: FinYear[];
  //required fields property
  ngSelectInvalid = false;
  finYearError: any;

  constructor(
    private fundsService: FundsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private limitTransListService: LimitTransListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage(1);
    // this.fundsService.getCommercialVendors().then(data => this.funds = data);

    this.cols = [
      {
        field: 'department',
        header: 'Dept Name',
        jhiTranslate: 'tableHead.department.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'scheme',
        header: 'Scheme Name',
        jhiTranslate: 'tableHead.scheme.name',
        isSortable: true,
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'finYear',
        header: 'Fin Year',
        jhiTranslate: 'tableHead.fin.year.name',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDown',
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.fundsService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Funds[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: Funds[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/funds'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
  }

  openNew() {
    this.fund = new FundsDto();
    this.submitted = false;
    this.fundsDialog = true;
    this.loadValue();
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.fund = new FundsDto();
    this.ngSelectInvalid = false;

    this.fundsDialog = false;
    this.submitted = false;
  }

  checkFinYear(){
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'finYear.id',
        operation: 'equals',
        value: this.fund.finYearDto.id,
      },
     
    ];
    if (this.fund.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: this.fund.id})
      };
    
    this.fundsService.validFilter({ ...filterQuery }, filter).subscribe(
      (res: HttpResponse<Funds[]>) => {
        if(res.body.length != 0){
          this.finYearError = "Fin Year Already Exists"
        }
        else{
          this.finYearError = null
        }
      },
      () => {
        this.onError();
        
      }
    );
  
  }

  saveData() {
    const isFormValid =
      this.fund.departmentDto &&
      this.fund.schemeDto &&
      this.fund.finYearDto &&
      this.fund.budgetAmount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.fundsService.save(this.fund).subscribe(
      (res: HttpResponse<any>) => {
        this.fundsDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );


  }
  onSelectRow($event) {
    if ($event.type === 'select') {
      this.fundsService.getFunds($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.fund = res.body;
          this.fundsDialog = true;

          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.fundsService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}
