import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ReportService } from '../report.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

interface QueryType {
  page: number;
  size: any;
  [key: string]: any; 
}
@Component({
  selector: 'app-vendor-transaction-report',
  templateUrl: './vendor-transaction-report.component.html',
  styleUrls: ['./vendor-transaction-report.component.scss']
})
export class VendorTransactionReportComponent implements OnInit {
  finYearList: FinYear[];
  finYear: FinYear;
  lgdCode:string;
  voucherNo:string;
  cols:any;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  flag = [
    { name: 'Personal', value: 'Yes' },
    { name: 'Commercial', value: 'No' },
    { name: 'Employee', value: 'E' },
    { name: 'ElectedRepresentative', value: 'ER' },
    { name: 'BulkEmployee', value: 'BE' },
    { name: 'BulkER', value: 'BER' },
  ];
  selectedFlag: string;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;
  dateError: boolean;

  constructor(public fontService:FontService,
      private datePipe: DatePipe,
      protected reportService:ReportService,
    protected limitTransListService:LimitTransListService,
    protected spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYearList = res.body;
      },
      () => {}
    );
    this.cols=[
      {field:'id',header:'ID',isSelectcolumn:true},
      {field:'voucherNo',header:'Voucher No',isSelectcolumn:true},
      {field:'levelMasterName',header:'Level Master',isSelectcolumn:true},
      {field:'transactionAmount',header:'Transaction Amount',isSelectcolumn:true},
      {field:'transDate',header:'Transaction Date',isSelectcolumn:true,type:'date'},
      {field:'transactionStatus',header:'Transaction Status',isSelectcolumn:true},
      {field:'utrNo',header:'UTR NO',isSelectcolumn:true},
      {field:'personalFlag',header:'Vendor Type',isSelectcolumn:false},
      {field:'grossAmount',header:'Gross Amount',isSelectcolumn:false},
      {field:'commercialVendorName',header:'Commercial Vendor Name',isSelectcolumn:false},
      {field:'personalVendorName',header:'Personal Vendor Name',isSelectcolumn:false},
      {field:'finyear',header:'Fin Year',isSelectcolumn:false,type:'dropDown'},
    ]
  }

  onSearch(){
    if(this.fromDate && this.toDate)
    {
  this.startDate=this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
  this.endDate=this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
    }
  if (this.startDate > this.endDate) {
    this.dateError = true;
    return
  }
    this.dateError = false;
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query:QueryType = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      finYearId:this.finYear.id,
      ...(this.lgdCode && { lgdCode: this.lgdCode }),
      ...(this.selectedFlag && { personalFlag: this.selectedFlag }),
      ...(this.voucherNo && { voucherNo: this.voucherNo }),
      ...(this.startDate && { fromDate: this.startDate }),
      ...(this.endDate && { toDate: this.endDate }),
    };
    
    this.reportService.vendorTransactionReport({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.spinner.hide();
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
}

