import { LevelMasterDto } from './../../module/level-master/level-master';
import { filterQuery } from './../../shared/common.model';
import {
  ComponentDto,
  ComponentType,
} from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import {
  CommercialVendorsDto,
  Vendor,
} from 'src/app/module/commercial-vendors/commercial-vendors';
import {
  CommercialVendorDto,
  paymentVoucherGen,
  VoucherBillDescDto,
  vendorTransactionDto,
  ComponentTypeDto,
  VendorTransaction,
  EmuthraResponseDto,
  EmudhraRequest,
} from './payment-voucher.model';
import { PaymentService } from './payment-voucher.service';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserService } from 'src/app/module/user/user.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';

@Component({
  selector: 'app-payment-voucher',
  templateUrl: './payment-voucher.component.html',
  styleUrls: ['./payment-voucher.component.scss'],
})
export class PaymentVoucherComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  navbarComponent!: NavbarComponent;
  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  pdfString: any;
  schemeList: SchemeList[];
  selectedVendor: CommercialVendorsDto;
  levelType: LevelType[];
  grantLevel: ComponentType;
  grantlevel: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: Vendor[];
  selectedscheme: any;
  profileDialog: boolean = false;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  totalDeduction: number;
  dropDownLabel: any;
  maxDate = new Date();
  amount: number;
  description: string;
  hsn: string;
  quantity: number;
  signButtonName: any;
  noRecord: boolean;
  isFileUploaded: boolean;
  uFilename: string;
  viewDoc: boolean = false;
  vFileExt: string;
  vFileUrl: string;
  currentPage: number = 0;
  loadingMore: boolean = false;
  vendorSearchOption: string = 'selectOption';
  enableFlagDay: boolean = false;
  accountNo: string;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Rate',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
  ];

  // validation fields properties
  public ifscError = false;
  public panError = false;
  public hsnError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  filterProps: any;
  securityDeposit: number;
  enableGstOnTds: boolean;
  uploadedFiles: any;
  enableLabourChess: boolean;
  enableSecurityDeposit: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean = false;
  downloadButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enablesign: boolean = false;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean = true;
  buttonText: string;
  checkstatusButton: boolean;
  interactionId: any;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any;
  enableZonalButton: boolean;
  enableApproved: boolean;
  checkForPaymentStatus: boolean;
  componentMasterTable: any;
  typedValue: any;
  filterTimeout: any;
  hasMoreItemsToLoad: boolean = true;
  deleteDialog: boolean;
  enableView: boolean;
  bulkVoucherDialog: boolean;
  finYearList: any;
  finYear: any;
  uniqueId: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private userService: UserService,
    private http: HttpClient,
    private sessionStorageService : SessionStorageService,
    private limitTransListService: LimitTransListService
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
            .leveltype
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            if (this.roleCode.role.name.startsWith('Village')) {
              this.grantLevel = res.body.filter(item => item.name === 'Village Minimum Grant' || item.name === 'Village General Funds');
            } else if (this.roleCode.role.name.startsWith('Block')) {
              this.grantLevel = res.body.filter(item => item.name === 'Panchayat Union General Fund');
            } else if (this.roleCode.role.name.startsWith('District')) {
              this.grantLevel = res.body.filter(item => item.name === 'District Population Grant');
            } else {
              this.grantLevel = res.body;
            }
          },
          () => { }
        );
      this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
      this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));
      this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);

    });
  }

  async ngOnInit(): Promise<void> {
    this.componentMasterTable = this.sessionStorageService.retrieve('componentMasterTable');
    if (!this.componentMasterTable) {
      await this.loadComp();
    }
    await this.getRole();



    this.paymentService
      .getByImplemeting(this.roleCode.implementingAgencyId)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto =
            res.body;

          this.levelTypeService
            .getbyId(
              this.voucherGenerationDto.vendorTransactionDto
                .implementingAgencyDto.leveltype
            )
            .subscribe(
              (res: HttpResponse<LevelType>) => {
                this.levelMasterDto = res.body;
              },
              () => { }
            );
          this.componentTypeService
            .find(
              this.voucherGenerationDto.vendorTransactionDto
                .implementingAgencyDto.leveltype
            )
            .subscribe(
              (res: HttpResponse<any>) => {
                if (this.roleCode.role.name.startsWith('Village')) {
                  this.grantLevel = res.body.filter(item => item.name === 'Village Minimum Grant' || item.name === 'Village General Funds');
                } else if (this.roleCode.role.name.startsWith('Block')) {
                  this.grantLevel = res.body.filter(item => item.name === 'Panchayat Union General Fund');
                } else if (this.roleCode.role.name.startsWith('District')) {
                  this.grantLevel = res.body.filter(item => item.name === 'District Population Grant');
                } else {
                  this.grantLevel = res.body;
                }
              },
              () => { }
            );
        },
        () => { }
      );

    if (
      this.roleCode.role.code === 'DMAKER' ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER'
    ) {
      if (this.roleCode.role.code === 'BMAKER') {
        this.paymentService
          .getZonalCheckers(this.roleCode.levelMasterId)
          .subscribe(
            (res: HttpResponse<LevelType>) => {
              this.zonalList = res.body;
            },
            () => { }
          );
      }
      this.signButtonName = 'Perform e-Sign Secretary';
    } else if (

      this.roleCode.role.code === 'DC1' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'VC1' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableApproved = true;
      if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
        this.checkAmount = true;
      }
      this.signButtonName = 'Perform e-Sign VP';
      this.enableRevertButton = true;
      this.readonly = true;
    } else if (
      this.roleCode.role.code === 'DC2' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.enableApproved = true;
      this.signButtonName = 'Perform e-Sign President';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }
    if (
      this.roleCode.role.code === 'BMAKER' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableZonalButton = true;
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError('Error Occurred: ' + this.activatedRoute.snapshot.queryParamMap.get('error'), '');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = res.body;
            this.finYear = this.voucherGenerationDto.vendorTransactionDto.finYearDto
            // if(this.voucherGenerationDto.vendorTransactionDto.parentVoucherNumber && !this.enableView){
            //   this.readonly = true;
            //   this.bulkVoucherDialog = true;
            // }
            if (this.voucherGenerationDto.vendorTransactionDto.invoiceImage) {
              this.uFilename = this.voucherGenerationDto.vendorTransactionDto.invoiceImage;
              this.isFileUploaded = true;
            }
            this.spinner.hide();
            if (
              this.checkAmount &&
              res.body.vendorTransactionDto.transactionAmount <= 500000 &&
              res.body.vendorTransactionDto.signatureVicePresident
            ) {
              res.body.vendorTransactionDto.signaturePresident = 'signed';
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
                .getUser(res.body?.vendorTransactionDto?.zonalChecker)
                .subscribe(
                  (res: HttpResponse<any>) => {
                    this.selectedZonalUser = res.body;
                  },
                  () => { }
                );
            }

            if (this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              switch (this.voucherGenerationDto.vendorTransactionDto.transactionStatus) {
                case 'O.K.':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Success';
                  break;
                case 'failed':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Failed';
                  break;
                case 'processing':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Processing';
                  break;
                default:
                  break;
              }
            }
            if (this.voucherGenerationDto.vendorTransactionDto)
              if (this.voucherGenerationDto.vendorTransactionDto.signStatus && !this.enableView) {
                this.readonly = true;
                this.enableSignButton = true;

              }
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            this.securityDeposit =
              this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount;
            if (this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount) {
              this.enableGstOnTds = true;
            } else {
              this.enableGstOnTds = false;
            }
            if (
              this.voucherGenerationDto.vendorTransactionDto
                .securityDepositAmount
            ) {
              this.enableSecurityDeposit = true;
            } else {
              this.enableSecurityDeposit = false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.labourCess) {
              this.enableLabourChess = true;
            } else {
              this.enableLabourChess = false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.flagDay) {
              this.enableFlagDay = true;
            }
            this.totalDeduction =
              this.voucherGenerationDto.vendorTransactionDto.totlaInRupees;
            this.selectedVendor =
              this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto;
            if (this.selectedVendor?.gstNo?.slice(0, 2) === '33') {
              this.enableStateGst = true;
              this.enableIGst = false;
            } else {
              this.enableIGst = true;
              this.enableStateGst = false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.tdsAmount) {
              this.voucherGenerationDto.vendorTransactionDto.tdsApplicable =
                true;
            } else {
              this.voucherGenerationDto.vendorTransactionDto.tdsApplicable =
                false;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.invoiceDate) {
              this.voucherGenerationDto.vendorTransactionDto.invoiceDate =
                new Date(
                  this.voucherGenerationDto.vendorTransactionDto.invoiceDate
                );
            }
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
            this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;
                },
                () => { }
              );
            this.componentTypeService
              .find(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<any>) => {
                  if (this.roleCode.role.name.startsWith('Village')) {
                    this.grantLevel = res.body.filter(item => item.name === 'Village Minimum Grant' || item.name === 'Village General Funds');
                  }
                  else {
                    this.grantLevel = res.body;
                  }
                },
                () => { }
              );

            this.majaorComponentdto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.majorComponentId);
            this.minorComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.minorComponentId);
            this.subComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.subComponentId);
            this.spinner.hide();
            this.levelTypeService
              .getbyId(
                this.voucherGenerationDto.vendorTransactionDto
                  .implementingAgencyDto.leveltype
              )
              .subscribe(
                (res: HttpResponse<LevelType>) => {
                  this.levelMasterDto = res.body;

                  this.onLevelType();
                  this.ongrandLevel('true');
                  this.onMajorComponent('true');
                  this.onMinorComponent('true');
                },
                () => { }

              );
          },
          () => { }
        );

      if (
        this.roleCode.role.code === 'DC2' ||
        this.roleCode.role.code === 'BC2' ||
        this.roleCode.role.code === 'VC2'
      ) {
        if (
          this.voucherGenerationDto.vendorTransactionDto.signaturePresident !==
          null
        ) {
          this.enablesign = true;
        }
      }
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
        new CommercialVendorsDto();
      this.enableGstOnTds = false;
      this.enableLabourChess = false;
      this.enableSecurityDeposit = false;
      this.selectedVendor = new CommercialVendorsDto();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.tdsApplicable = false;
      this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
        new ComponentDto();
      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYearList = res.body;
          let FinYear = ''
          if (new Date().getMonth() + 1 >= 4) {
            FinYear =
              new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
          } else {
            FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
          }
          this.finYear = this.finYearList.find((_) => _.name === FinYear);


        },
        () => { }
      )
    }
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => { }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => { }
    );
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode)
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  loadComp(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.paymentService.componentMasterList().subscribe(
        (res) => {
          this.spinner.show();
          this.sessionStorageService.store('componentMasterTable', res.body)
          this.componentMasterTable = res.body;
          resolve(this.componentMasterTable)
          this.spinner.hide();
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  ngAfterViewInit() {
    this.updateDialogFont();
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  onLevelType() {
    this.componentTypeService
      .find(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
          .leveltype
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          if (this.roleCode.role.name.startsWith('Village')) {
            this.grantLevel = res.body.filter(item => item.name === 'Village Minimum Grant' || item.name === 'Village General Funds');
          }
          else {
            this.grantLevel = res.body;
          }
        },
        () => { }
      );
  }

  ongrandLevel(edit?: any) {
    if (!edit) {
      this.majaorComponentdto = null;
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }
    this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);

    this.paymentService
      .getLimit(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => { }
      );
  }
  onMajorComponent(edit?: any) {
    if (!edit) {
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }
    this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));

  }
  viewDocument() {
    this.paymentService.viewimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl = "data:image/" + this.vFileExt + ";base64," + res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc = true;
      });
  }
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument() {
    this.paymentService.getimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
      .subscribe((res) => {
        const filename = res.body.fileName;
        const fileExtension = filename.split('.').pop();

        if (fileExtension == 'pdf') {
          const base64Data = "data:application/pdf;base64," + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
        else {
          const base64Data = "data:image/" + fileExtension + ";base64," + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
      },
        (onError) => {

        })
  }
  removeDocument() {
    this.paymentService.deleteimage(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)
      .subscribe((res) => {
        this.notificationService.alertSuccess("File Deleted", '');
        this.isFileUploaded = false;
        this.voucherGenerationDto.vendorTransactionDto.invoiceImage = null;
      },
        (error) => {
          this.notificationService.alertError("Error occured, try again", '');
        });
  }

  onUpload($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.voucherGenerationDto.vendorTransactionDto.invoiceImage =
          res.body.fileName;
        this.uFilename = this.voucherGenerationDto.vendorTransactionDto.invoiceImage;
        this.isFileUploaded = true;
        this.notificationService.alertSuccess('Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  onMinorComponent(edit?: any) {
    if (!edit) {
      this.subComponentDto = null;
    }
    this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);

  }

  getActionUrl() {
    this.sessionStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  submitForm() {
    this.spinner.show();
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          console.log('ress', res.body);
          this.spinner.hide();
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          if (!this.voucherGenerationDto.vendorTransactionDto.id) {
            this.router.navigate(['/PaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          this.sessionStorageService.store('refresh', 'Yes');
          const formElement = document.createElement('form');
          formElement.method = 'POST';
          formElement.action = environment.CDAC_URL;
          let inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "eSignRequest";
          inputElement.value = res.body.paramOne;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "aspTxnID";
          inputElement.value = res.body.paramTwo;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "Content-Type";
          inputElement.value = res.body.paramThree;
          formElement.appendChild(inputElement);
          document.body.appendChild(formElement);
          formElement.submit();
        },
        (error) => {
          this.spinner.hide();
          console.log('error', error);
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ) {
            this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
            this.router.navigate(['/payment'], {});
          }
          else {
            this.deleteDialog = true;
          }
        }
      );

  }

  vendorFilterByAccount(type) {
    let filter = [];
    if (this.accountNo && type === 'AccountNo') {
      filter = [{
        key: 'accountNumber',
        operation: 'equals',
        value: this.accountNo,
      }];
    }
    else {
      if (this.uniqueId) {
        filter = [{
          key: 'uniqueId',
          operation: 'equals',
          value: this.uniqueId,
        }];
      }
    }
    this.commercialVendorService
      .validFilter({ size: 1, page: 0 }, filter)
      .subscribe(
        (res: HttpResponse<any>) => {
          if (res.body.length > 0) {
            this.vendorList = res.body;
            this.selectedVendor = res.body[0];
            this.popupValue();
          }
          else {
            this.selectedVendor = null;
            if (type === 'AccountNo') {
              this.notificationService.alertInfo('Vendor with given Account number not found', '');
            }
            else {
              this.notificationService.alertInfo('Vendor with given Unique number not found', '');
            }
          }
        },
        () => { }
      );

  }

  search($event) {
    this.typedValue = $event.term;
    this.hasMoreItemsToLoad = true;
    this.currentPage = 0;
  }

  vendorFilter() {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if (this.typedValue) {
      this.filterTimeout = setTimeout(() => {
        let filter = new filterQuery();
        filter.key = 'name';
        filter.operation = 'contains';
        filter.value = this.typedValue.toUpperCase();
        let filterquery = [];
        filterquery.push(filter);
        this.commercialVendorService
          .filter({ size: 10, page: 0 }, filterquery)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.vendorList = res.body;
            },
            () => { }
          );
      }, 2000);
    } else {
      this.commercialVendorService.filter({ size: 10, page: 0 }, []).subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => { }
      );
    }
  }

  loadMoreItems(page: number) {
    if (this.hasMoreItemsToLoad) {
      let filter = [];
      if (this.typedValue) {
        filter = [{
          key: 'name',
          operation: 'contains',
          value: this.typedValue.toUpperCase(),
        }];
      }

      const paginationOptions = { size: 10, page };

      return this.commercialVendorService.filter(paginationOptions, filter);
    }
    return
  }

  onScrollToEnd() {
    console.log('scroll to end triggered')
    if (!this.loadingMore && this.hasMoreItemsToLoad) {
      this.loadingMore = true;
      this.currentPage++;

      this.loadMoreItems(this.currentPage).subscribe(
        (res: HttpResponse<any>) => {
          const additionalItems = res.body;

          if (additionalItems && additionalItems.length > 0) {
            if (additionalItems.length < 10) {
              this.vendorList = this.vendorList.concat(additionalItems);
              this.hasMoreItemsToLoad = false;
            } else {
              this.vendorList = this.vendorList.concat(additionalItems);
            }
          } else {
            this.hasMoreItemsToLoad = false;
          }

          this.loadingMore = false;
        },
        () => {
          this.loadingMore = false;
        }
      );
    }
  }

  popupValue() {
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
    this.voucherGenerationDto.voucherBillDescDtoList = [];
    let voucherList = new VoucherBillDescDto();
    voucherList.quantity = 0;
    voucherList.amount = 0;
    voucherList.gstPercentage = 0;
    this.voucherGenerationDto.voucherBillDescDtoList.push(voucherList);
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
      new CommercialVendorsDto();
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.id =
      this.selectedVendor.id;
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo =
      this.selectedVendor.gstNo;

    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.panNo =
      this.selectedVendor.panNo;
    if (this.selectedVendor.gstNo === null) {
      this.enableStateGst = false;
      this.enableIGst = false;
    }
    if (
      this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto.gstNo
    ) {
      if (this.selectedVendor.gstNo.slice(0, 2) === '33') {
        this.enableStateGst = true;
        this.enableIGst = false;
      } else {
        this.enableIGst = true;
        this.enableStateGst = false;
      }
      this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
    }
    this.getSercurityDesposit();
    this.calculateTotalAmount();
  }
  addRow() {
    this.voucherGenerationDto.voucherBillDescDtoList = this.voucherGenerationDto
      .voucherBillDescDtoList
      ? this.voucherGenerationDto.voucherBillDescDtoList
      : [];
    let voucherList = new VoucherBillDescDto();
    voucherList.quantity = 0;
    voucherList.amount = 0;
    voucherList.gstPercentage = 0;
    this.voucherGenerationDto.voucherBillDescDtoList.push(voucherList);
  }
  calculatetotal(gst: VoucherBillDescDto) {
    if (gst.quantity === null) {
      gst.quantity = 0;
    }
    if (gst.amount === null) {
      gst.amount = 0;
    }
    this.amount = gst.amount;
    this.quantity = gst.quantity;

    gst.grossAmount = gst.quantity * gst.amount;
    let totalAmount = gst.quantity * gst.amount;

    let totalvalue = (gst.gstPercentage / 100) * totalAmount;
    gst.gstAmount = totalvalue;
    if (this.enableStateGst) {
      gst.cgstPercentage = Number((gst.gstPercentage / 2).toFixed(2));
      gst.sgstPercentage = Number((gst.gstPercentage / 2).toFixed(2));
      gst.cgstAmount = Number((totalvalue / 2).toFixed(2));
      gst.sgstAmount = Number((totalvalue / 2).toFixed(2));
    } else {
      gst.igstPercentage = gst.gstPercentage;
      gst.igstAmount = totalvalue;
    }
    gst.grossAmount = totalAmount + totalvalue;

    this.calculateTotalAmount();
    this.calculateTds();
  }
  delete(index: number) {
    this.voucherGenerationDto.voucherBillDescDtoList.splice(index, 1);
    if (this.voucherGenerationDto.voucherBillDescDtoList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.grossAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.totalCgst = null;
      this.voucherGenerationDto.vendorTransactionDto.totalSgst = null;
      this.voucherGenerationDto.vendorTransactionDto.totalIgst = null;
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      this.totalDeduction = null;
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount = 0;

      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
    } else {
      this.calculateTotalAmount();
      this.calculateTds();
    }
  }

  getSercurityDesposit() {
    let query = new filterQuery();
    query.key = 'commercialVendor';
    query.operation = 'equals';
    query.value = this.selectedVendor.id.toString();
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    let Query = {
      page: 0,
      size: 1,
    };
    this.paymentService.filter({ ...Query }, this.filterProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        if (res.body) {
          // this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 2;
        } else {
          // this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
        }
      },
      () => { }
    );
  }

  calculateTds() {
    this.voucherGenerationDto.vendorTransactionDto.tdsAmount = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto.tdsPercentage / 100) *
        this.voucherGenerationDto.vendorTransactionDto.grossAmount
      ).toFixed(2)
    );
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
        this.voucherGenerationDto.vendorTransactionDto.grossAmount
      ).toFixed(2)
    );
    this.calculateTotalAmount();
  }

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.selectedVendor.panNo
    );
  }
  // validateHsn(list: string) {
  //   this.hsnError = !this.validationService.isHsnValid(list);
  // }

  // addHsn(list: string) {
  //   this.hsn = list;
  // }
  addDescritption(list: string) {
    this.description = list;
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.levelMasterDto &&
      this.grantlevel &&
      this.majaorComponentdto &&
      this.minorComponentDto &&
      this.subComponentDto &&
      this.selectedVendor.name &&
      this.selectedVendor.id &&
      this.selectedVendor.panNo &&

      this.voucherGenerationDto.vendorTransactionDto
        .panchayatResolutionNumber &&
      this.voucherGenerationDto.vendorTransactionDto.nameOfWork &&
      this.voucherGenerationDto.vendorTransactionDto.remark &&
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount;

    if ((this.roleCode?.role.code == 'BMAKER' || this.roleCode?.role.code == 'DMAKER' || this.roleCode?.role.code == 'VMAKER') && !this.activatedRoute.snapshot.queryParamMap.get('id')) {
      const isFormValid = this.voucherGenerationDto.vendorTransactionDto.invoiceNo &&
        this.voucherGenerationDto.vendorTransactionDto.invoiceDate;
      if (!this.isFileUploaded) {
        this.notificationService.alertError("Please Upload a file to continue *", '');
        return;
      }
      if (!isFormValid) {
        this.ngSelectInvalid = true;
        this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
        return;
      }

    }

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }
    if (this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser) {
      this.notificationService.alertError('Please Select the Zonal User', '')
      return;
    }
    this.voucherGenerationDto.voucherBillDescDtoList.forEach((element) => {
      const isVal = element.description && element.quantity && element.description.length >= 30
        && element.amount;
      if (!isVal && !this.voucherGenerationDto?.vendorTransactionDto?.id) {
        this.ngSelectInvalid = true;
        if (element.description.length <= 30) {
          this.notificationService.alertError('Title should be minimum 30 characters', '');
        }

      }
    });
    if (this.ngSelectInvalid) {
      return;
    }
    this.ngSelectInvalid = false;
    if (this.selectedZonalUser?.id) {
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker =
        this.selectedZonalUser?.id;
    }
    this.spinner.show();
    if (
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount ===
      null ||
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount === 0
    ) {
      this.voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
    }
    if (
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount === null ||
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount === 0
    ) {
      this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 0;
    }
    this.voucherGenerationDto.vendorTransactionDto.commercialVendorDto =
      this.selectedVendor;
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;
    this.voucherGenerationDto.vendorTransactionDto.invoiceAmount =
      this.voucherGenerationDto.vendorTransactionDto.netAmount;
    this.voucherGenerationDto.vendorTransactionDto.majorComponentId =
      this.majaorComponentdto.id;
    this.voucherGenerationDto.vendorTransactionDto.majorComponentName =
      this.majaorComponentdto.name;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentId =
      this.minorComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentName =
      this.minorComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.subComponentId =
      this.subComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.subComponentName =
      this.subComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'No';
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;

    this.paymentService.genVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        // const blob = new Blob([response.body], { type: 'application/pdf' });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'file.pdf';
        // document.body.appendChild(link);
        // window.open(url, '_blank');
        // link.click();
        // URL.revokeObjectURL(url);
        this.readonly = true;
        this.spinner.hide();
        // this.router.navigate(['payment']);
        this.enableSignButton = true;
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher', '');

        } else if (error.status == 500) {
          this.notificationService.alertError('An unexpected error occurred, please delete the voucher if generated', '');
          this.router.navigate(['/payment'], {});
        } else {
          this.notificationService.alertError('An unexpected error occurred', '');
        }
      }
    );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          if (!this.voucherGenerationDto.vendorTransactionDto.id) {
            this.router.navigate(['/PaymentVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        (error) => {
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ) {
            this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
            this.router.navigate(['/payment'], {});
          }
          else {
            this.deleteDialog = true;
          }
        }
      );
  }

  hideDialog() {
    this.deleteDialog = false;
    this.router.navigate(['/payment'], {});
  }

  bulkVoucherRedriect() {
    this.router.navigate(['/blukpaymentTable'], {});
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/payment'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.paymentService
      .deletedVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/payment'], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }

          this.spinner.hide();
        }
      );
  }

  submit() {
  }

  calculateTotalAmount() {
    if (this.voucherGenerationDto?.voucherBillDescDtoList.length !== 0) {
      if (!this.voucherGenerationDto.vendorTransactionDto.tdsAmount) {
        this.voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
      }

      let initaiTotal = 0;
      let intitalCgst = 0;
      let intitalSgst = 0;
      let intitalTotalWithTax = 0;
      let initialIgst = 0;
      this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.labourCess = 0;
      this.voucherGenerationDto?.voucherBillDescDtoList.forEach((element) => {
        initaiTotal += element.amount * element.quantity;
        if (element.sgstPercentage) {
          intitalCgst += element.cgstAmount;
          intitalSgst += element.sgstAmount;
        }
        if (element.igstPercentage) {
          initialIgst += element.igstAmount;
        }
        intitalTotalWithTax += element.grossAmount;
      });
      this.voucherGenerationDto.vendorTransactionDto.grossAmount = initaiTotal;
      this.voucherGenerationDto.vendorTransactionDto.totalCgst = intitalCgst;
      this.voucherGenerationDto.vendorTransactionDto.totalSgst = intitalSgst;
      this.voucherGenerationDto.vendorTransactionDto.totalIgst = initialIgst;
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = 0;
      if (
        this.voucherGenerationDto.vendorTransactionDto.grossAmount &&
        this.enableGstOnTds
      ) {
        this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
            this.voucherGenerationDto.vendorTransactionDto.grossAmount
          ).toFixed(2)
        );
      }
      if (this.enableSecurityDeposit) {
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount =
          Number(
            (
              (this.voucherGenerationDto.vendorTransactionDto.securityDeposit /
                100) *
              this.voucherGenerationDto.vendorTransactionDto.grossAmount
            ).toFixed(2)
          );
      }
      if (this.enableLabourChess) {
        this.voucherGenerationDto.vendorTransactionDto.labourCess = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto
              .labourCessPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.grossAmount
          ).toFixed(2)
        );
      }
      this.totalDeduction =
        this.voucherGenerationDto.vendorTransactionDto.labourCess +
        this.voucherGenerationDto.vendorTransactionDto?.tdsAmount +
        this.voucherGenerationDto.vendorTransactionDto.securityDepositAmount +
        this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount +
        this.voucherGenerationDto.vendorTransactionDto.flagDay;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount -
        this.totalDeduction;
    }
  }

  getvendorTrans() {
    if (this.selectedVendor) {
      this.paymentService
        .getListVendorTransction(this.selectedVendor.id)
        .subscribe(
          (res: any) => {
            this.noRecord = true;
            this.searchResult.items = res.body ?? [];
            if (this.searchResult.items.length > 0) {
              this.noRecord = false;
            }
            this.searchResult.total = 5;
            this.searchResult = { ...this.searchResult };
            this.profileDialog = true;
          },
          () => { }
        );
    }
  }

  paymentStatus() {
    this.paymentService
      .getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          if (res.body.transactionStatus === 'O.K.') {
            this.disablepaymentButton = true;
          }
          else if (res.body.transactionStatus !== null) {
            this.buttonText = 'Re-Initiate payment';
            this.checkstatusButton = true;
            this.interactionId = res.body.apiInteractionId;
            if (this.checkForPaymentStatus) {
              this.checkPaymentStatus();
            }
          }
        },
        (err) => { }
      );
  }

  checkTransactionStatus() {
    this.checkForPaymentStatus = true;
    this.paymentStatus();
  }

  checkPaymentStatus() {
    this.checkForPaymentStatus = false;
    this.spinner.show();
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:" + res.body.transactionStatus.JRNL_NO, '')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false;
          // this.disablepaymentButton = false; 
          this.spinner.hide();
        }
        else if (res.body.transactionStatus.TRANSTATUS === "Success") {
          this.checkstatusButton = false;
          this.paymentSucessDialog = true;
          this.paymentSucessText = "Transaction Success for Reference No:", res.body.transactionStatus.JRNL_NO;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  redirect() {
    this.router.navigate(['/PaymentVoucher'], {
      queryParams: {
        id: String(this.voucherGenerationDto.vendorTransactionDto.id),
      },
    });
  }

  PaymentSucess() {
    this.spinner.show();
    this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.paymentSucessDialog = true;
        this.paymentSucessText = res.body;
        this.disablepaymentButton = true;
      },
      (err) => {
        console.log(err);
        if (String(err.status).startsWith('5')) {
          this.checkstatusButton = true;
          this.spinner.hide();
        }
        else if (err.status === 400) {
          this.notificationService.alertError(err.error, '');
        }
        this.spinner.hide();
      }
    );
  }
}
