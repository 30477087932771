<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Vendor Transaction Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <input pInputText pKeyFilter="alphanum" placeholder="Enter LGD Code" [(ngModel)]="lgdCode" />
        <input pInputText pKeyFilter="alphanum" placeholder="Enter Voucher No" [(ngModel)]="voucherNo" />
        <p-dropdown class="req" placeholder="Select Fin Year" [options]="finYearList" [(ngModel)]="finYear" 
        optionLabel="name" showClear="true"></p-dropdown>
        <p-dropdown [options]="flag" placeholder="Select Vendor Option" [(ngModel)]="selectedFlag" 
        optionLabel="name" optionValue="value"></p-dropdown>
        <p-calendar class='req'  id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate"
         [showIcon]="true" styleClass="datepicker"
        [maxDate]="maxDate"></p-calendar>
        <p-calendar class='req'  id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate"
         [showIcon]="true" styleClass="datepicker"
         [maxDate]="maxDate"></p-calendar>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div *ngIf="dateError" class="text-danger mb-2">
            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                choose valid from and to
                date</small>
        </div>
    </div>
    <div class="row mt-4">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false"
        (searchParam)="onLazyLoad($event)" [showMultiSelect]=true></custom-filter-table>
    </div>
    