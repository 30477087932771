<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
          <h5 [ngClass]="fontService.headingClass" jhiTranslate="sna.sna.master.list.name">
            SNA Master List
          </h5>
        </ng-template>
        <ng-template pTemplate="right">
          <button *ngIf="searchResult.total === 0" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()"
            styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
        </ng-template>
      </p-toolbar>
    </div>
  </div>
  <div class="row">
    <div #customerTable id="entities" class="col-12 px-4">
      <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)"
        (emitRow)="onSelectRow($event)" [disableDeleteIcon]="true" [disableEditIcon]="false"></custom-filter-table>
    </div>
  </div>
  <p-dialog #dialog [(visible)]="accountSnaDialog" [modal]="true" styleClass="ib-dialog" [style]="{ width: '850px' }"
    (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
      {{ "sna.detail.name" | translate }}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">
          <div class="row gy-2">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="department.department.details.name">
                Department Details
              </h6>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="department.name"
                for="department">Department</label>
              <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                [clearable]="true" class="form-control" [items]="departmentList" [disabled]="accountSna.id" [(ngModel)]="accountSna.departmentDto"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.departmentDto }">
              </ng-select>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="payments.scheme.name" for="scheme">Scheme</label>
              <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="accountSna.schemeDto" [items]="schemeList" bindLabel="name"
                appearance="outline" [closeOnSelect]="true" class="form-control" [disabled]="accountSna.id" [clearable]="true"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.schemeDto }">
              </ng-select>
            </div>
          </div>
          <div class="row gy-2 mt-3">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="sna.detail.name">
                SNA Details
              </h6>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.name.name"
                for="snaName">SNA Name</label>
              <input type="text" id="snaName" name="snaName" autocomplete="off" maxlength="80"
                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" class="form-control"
                [(ngModel)]="accountSna.name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.name }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.code.name"
                for="snaCode">SNA Code</label>
              <input type="text" id="snaCode" name="snaCode" autocomplete="off" class="form-control" maxlength="20"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [(ngModel)]="accountSna.code"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.code }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.short.name"
                for="shortName">Short Name</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="20"
                [(ngModel)]="accountSna.shortName" name="snaCode" autocomplete="off" id="shortName"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.shortName }" />
            </div>
            <div class="col-sm-12 col-md-8">
              <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="sna.description.name"
                for="snaDescription">SNA Description</label>
              <textarea name="snaDescription" [(ngModel)]="accountSna.snaDesc" id="snaDescription" maxlength="200"
                cols="30" rows="2"></textarea>
            </div>
          </div>
          <div class="row gy-2 mt-3">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="sna.account.details">
                Account Details
              </h6>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="sna.account.no.name" for="bankAccountNo">Account No</label>
              <input type="text"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" maxlength="17" class="form-control"
                [(ngModel)]="accountSna.accountNumber" name="bankAccountNo" autocomplete="off" id="bankAccountNo"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.accountNumber }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.ifscode.name"
                for="ifsc">IFSC Code</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase" maxlength="11"
                [(ngModel)]="accountSna.ifscCode" name="ifsc" autocomplete="off" id="ifsc"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.ifscCode }" (input)="validateIfsc()" />
              <div *ngIf="ifscError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                  IFSCode.</small>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.branch.name"
                for="branch">Branch</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="60"
                [(ngModel)]="accountSna.branch" name="branch" autocomplete="off" id="branch"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.branch }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.address.name"
                for="branchAddress">Address</label>
              <input type="text" class="form-control" name="branchAddress" autocomplete="off" maxlength="300"
                [(ngModel)]="accountSna.address" id="branchAddress"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.address }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="sna.branch.manager.no.name" for="branchMangerNo">Branch Manager No</label>
              <input type="text" class="form-control" name="branchMangerNo" autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" inputmode="numeric" pattern="\d*" maxlength="10"
                [(ngModel)]="accountSna.managerMobNo" id="branchMangerNo" (input)="validateMobile()"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.managerMobNo }" />
              <div *ngIf="showMobileError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter a valid Mobile
                  Number.</small>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.manager.name"
                for="managerName">Manager Name</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="45"
                name="managerName" autocomplete="off" [(ngModel)]="accountSna.managerName" id="managerName"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.managerName }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="sna.branch.email.name" for="branchEmail">Branch E-mail</label>
              <input type="text" class="form-control" name="branchEmail" autocomplete="off"
                [(ngModel)]="accountSna.branchEmail" id="branchEmail" (input)="validateEmail()"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.branchEmail }" maxlength="60" />
              <div *ngIf="emailError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                  Address.</small>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="sna.amount.name"
                for="amount">Amount</label>
              <input type="text" class="form-control" name="amount" autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" [(ngModel)]="accountSna.amount" id="amount" readonly />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" custom-button jhiTranslate="common.cancelbutton">
        </span>
      </button>
      <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveSna()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>