import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ReportService } from '../report.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';

interface QueryType {
  page: number;
  size: any;
  [key: string]: any; 
}
@Component({
  selector: 'app-api-req-res-report',
  templateUrl: './api-req-res-report.component.html',
  styleUrls: ['./api-req-res-report.component.scss']
})
export class ApiReqResReportComponent implements OnInit {

  apiInteractionId:string;
  cols:any;
  page: number;
  itemsPerPage: any = 10;
  searchResult:SearchResult<any>=new SearchResult<any>();
  
  constructor(public fontService:FontService,
    protected reportService:ReportService,
    protected spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.cols=[
      {
        field:'id',
        header:'Id',
        isSelectcolumn:true,
      },
      {
        field:'apiRequest',
        header:'Api Request',
        isSelectcolumn:true,
      },
      {
        field:'apiResponse',
        header:'Api response',
        isSelectcolumn:true,
      },
      {
        field:'apiName',
        header:'Api Name',
        isSelectcolumn:true,
      },
      {
        field:'apiIntercationId',
        header:'Api Interaction Id',
        isSelectcolumn:true,
      },
      {
        field:'requestTimestamp',
        header:'Request Time',
        isSelectcolumn:true,
        type:'date'
      },
      {
        field:'responseTimestamp',
        header:'Response Time',
        isSelectcolumn:true,
        type:'date'
      }
    ];
  }

  onSearch(){
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query:QueryType = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.apiInteractionId && { apiInteractionId: this.apiInteractionId }),
    };
    
    this.reportService.apiReqResReport({ ...Query }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.spinner.hide();
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
}

