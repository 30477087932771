<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Office Master List
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10">

        </div>
        <div class="col-lg-2">
            <button *ngIf="roleCode?.role?.code === 'SA'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
              </button>
        </div>


    </div>


    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [lazyLoadOnInit]="true" [enableAction]="false"></custom-filter-table>
    </div>

    <p-dialog #dialog [(visible)]="twadOfficedialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Twad Office
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row">
                        <div class="row gy-2">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.personal.details">
                                    Twad Office
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" name">Office Name</label>
                            <input type="text" class="form-control" [(ngModel)]="twadOffice.twadOfficeName" name="name" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOffice.twadOfficeName }" id="name" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" name">Office Code</label>
                            <input type="text" class="form-control" [(ngModel)]="twadOffice.officeId" name="name" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !twadOffice.officeId}" id="name" />
                        </div>
                    </div>

                </div>

            </div>

        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
      </button>

            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveTwadOffice()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
        </ng-template>
    </p-dialog>

</div>