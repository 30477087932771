import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { EmployeeDTO } from './employee.model';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  protected employeeListUrl =
    environment.SERVER_API_URL + 'api/getEmployeeByFilter';
    protected getEmployeeTypeValidByFilterUrl =
    environment.SERVER_API_URL + 'api/getEmployeeTypeValidByFilter'; 
  protected getemployeeUrl = environment.SERVER_API_URL + 'api/getEmployee';
  protected employeeTypeListUrl =
    environment.SERVER_API_URL + 'api/employeeTypeList';
  protected subEmployeeListUrl =
    environment.SERVER_API_URL + 'api/getSubEmployeeTypeByEmployeeId';
  protected employeeSaveUrl = environment.SERVER_API_URL + 'api/saveEmployee';
  protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deletEmployee');
  // protected CommercialListUrl = environment.SERVER_API_URL+('api/commercialList');
  // protected commercialGetUrl = environment.SERVER_API_URL+('api/getCommercialVendor');
  protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getEmployeeValidByFilter');
  constructor(protected http: HttpClient) {}
  filter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.employeeListUrl, filter, {
      params: options,
      observe: 'response',
    });
  }

  getEmployeeList(): Observable<any> {
    return this.http.get<any[]>(this.employeeTypeListUrl, {
      observe: 'response',
    });
  }

  getEmployeeTypeValidByFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.getEmployeeTypeValidByFilterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }

  getSubEmployeeList(id: number): Observable<any> {
    return this.http.get<any[]>(`${this.subEmployeeListUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  getemployee(id: number): Observable<HttpResponse<any>> {
    return this.http.get<EmployeeDTO>(`${this.getemployeeUrl}?id=${id}`, { observe: 'response' });
  }

  deletemployee(id: number): Observable<HttpResponse<any>> {
    return this.http.get<EmployeeDTO>(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  }

  save(employee: EmployeeDTO): Observable<any> {
    return this.http.post<EmployeeDTO>(this.employeeSaveUrl, employee, {
      observe: 'response',
    });
  }

  validFilter(req?: any,filter?:any): Observable<any> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<any[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
  }
}
