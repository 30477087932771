import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeTransferService } from './employee-transfer-page.service';
import { HttpResponse } from '@angular/common/http';
import { filterQuery } from 'src/app/shared/common.model';
import { EmployeeService } from '../employee/employee.service';
import { EmployeeTransfer } from './employee-transfer.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Employee, EmployeeDTO } from '../employee/employee.model';
import { LevelMasterService } from '../level-master/level-master.service';
import { LevelMasterDto } from '../level-master/level-master';
import { UserService } from '../user/user.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService , SessionStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-employee-transfer-page',
  templateUrl: './employee-transfer-page.component.html',
  styleUrls: ['./employee-transfer-page.component.scss']
})
export class EmployeeTransferPageComponent implements OnInit {
  typedValue: any;
  filterTimeout: any;
  hasMoreItemsToLoad: boolean=true;
  currentPage: number = 0;
  loadingMore: boolean = false;
  vendorList: any;
  employeeTransfer:EmployeeTransfer;
  selectedVendor:Employee
  profile: any;
  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelList: LevelMasterDto[];
  villageLevelDto:any;
  userList: any;
  userDto:any;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  isFileUploaded: boolean;
  uFilename: any;
  maxDate = new Date();
  employee: any;
  ngSelectInvalid: boolean;
  constructor(
    protected activatedRoute: ActivatedRoute,
    private employeeTransferService: EmployeeTransferService,
    private employeeService: EmployeeService,
    public fontService: FontService,
    private levelMasterService: LevelMasterService,
    protected userService:UserService,
    protected paymentService:PaymentService,
    protected notificationService: NotificationService,
    protected router: Router,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private sessionStorageService : SessionStorageService
  ) { }
  
  ngOnInit(): void {
    this.employeeTransfer = new EmployeeTransfer();
    if(this.activatedRoute.snapshot.queryParamMap.get('profileId')) {
      this.employeeTransfer.isProfileIncluded = true;
      this.vendorFilter();
      this.isProfileChange();
    }
    else {
      this.employeeTransfer.isProfileIncluded = false;
      this.employee = this.sessionStorageService.retrieve('employeedetails');
      this.selectedVendor = this.employee.employee;
      this.onDistrictLevelChange();
    }
  }


  search($event){
    this.typedValue=$event.term;
    this.hasMoreItemsToLoad = true;
    this.currentPage= 0;
  }

  vendorFilter() {
    let filter = new filterQuery();
      filter.key = 'levelMasterId';
      filter.operation = 'equals';
      filter.value = Number((this.activatedRoute.snapshot.queryParamMap.get('villageId')));
      let filterquery = [];
      filterquery.push(filter);
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if(this.typedValue){
      this.filterTimeout = setTimeout(() => {
      filter = new filterQuery();
      filter.key = 'name';
      filter.operation = 'contains';
      filter.value =this.typedValue.toUpperCase();
      filterquery.push(filter);
      this.employeeService
        .validFilter({ size: 10, page: 0 }, filterquery)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.vendorList = res.body;
          },
          () => {}
        );
      }, 2000);
    } else {
      this.employeeService.validFilter({ size: 10, page: 0 },filterquery).subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => {}
      );
    }
  }

 onVillageChange() {
  this.userDto = null;
  this.userList = [];
    let filter = new filterQuery();
      filter.key = 'levelMasterId';
      filter.operation = 'equals';
      filter.value = this.villageLevelDto.id;
      let filterquery = [];
      filterquery.push(filter);
      this.userService.validFilter({ size: 10, page: 0 },filterquery).subscribe(
        (res: HttpResponse<any>) => {
          this.userList = res.body.filter(item => item.role.code === 'VMAKER');
          this.userDto = this.userList[0];
        },
        () => {}
      );
    
  }

  loadMoreItems(page: number) {
    let filter = new filterQuery();
    filter.key = 'levelMasterId';
    filter.operation = 'equals';
    filter.value = Number((this.activatedRoute.snapshot.queryParamMap.get('villageId')));
    let filterquery = [];
    filterquery.push(filter)
    if (this.hasMoreItemsToLoad) {
      if(this.typedValue){
      filterquery.push({
      key: 'name',
      operation: 'contains',
      value: this.typedValue.toUpperCase(),
    });
    
  }
    const paginationOptions = { size: 10, page };
    return this.employeeService.validFilter(paginationOptions,filterquery);
  }
  return
  }

    onScrollToEnd(){
      console.log('scroll to end triggered')
      if (!this.loadingMore && this.hasMoreItemsToLoad) {
        this.loadingMore = true;
        this.currentPage++;

        this.loadMoreItems(this.currentPage).subscribe(
          (res: HttpResponse<any>) => {
            const additionalItems = res.body;

            if (additionalItems && additionalItems.length > 0) {
              if(additionalItems.length < 10){
                this.vendorList = this.vendorList.concat(additionalItems);
                this.hasMoreItemsToLoad = false;
              } else {
              this.vendorList = this.vendorList.concat(additionalItems);
              }
            } else {
              this.hasMoreItemsToLoad = false;
            }

            this.loadingMore = false;
          },
          () => {
            this.loadingMore = false;
          }
        );
      }
  }





  isProfileChange() {
    
    if(this.employeeTransfer.isProfileIncluded === true) {
      console.log('loggg',this.employeeTransfer.isProfileIncluded)
    }
    
      this.employeeTransferService.getProfile({districtId:Number((this.activatedRoute.snapshot.queryParamMap.get('levelMasterId'))),profileId:Number((this.activatedRoute.snapshot.queryParamMap.get('profileId')))}).subscribe(
        (res: HttpResponse<any>) => {
          console.log('res',res.body);
          this.profile = res.body;
          this.onDistrictLevelChange();
        },
        () => {
          
        }
      );
    
   }

   viewDocument(){
    this.paymentService.viewimage(this.employeeTransfer.filePath)
    .subscribe((res)=>
    {
      const filename = res.body.fileName; 
      this.vFileExt  = filename.split('.').pop();
      this.vFileUrl = "data:image/"+this.vFileExt+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc=true;
  });
}
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument(){
  this.paymentService.getimage(this.employeeTransfer.filePath)
  .subscribe((res)=>
  {
    const filename = res.body.fileName; 
    const fileExtension = filename.split('.').pop();

    if(fileExtension=='pdf')
    {
    const base64Data = "data:application/pdf;base64,"+res.body.fileType; // Replace with your Base64-encoded image string
    this.downloadImage(base64Data, filename);
  }
    else{
    const base64Data = "data:image/"+fileExtension+";base64,"+res.body.fileType; // Replace with your Base64-encoded image string
    this.downloadImage(base64Data, filename);
    }
  },
  (onError)=>
  {

  })
  }
  removeDocument(){
    this.paymentService.deleteimage(this.employeeTransfer.filePath)
    .subscribe((res)=>{
    this.notificationService.alertSuccess("File Deleted",'');
    this.isFileUploaded=false;
    this.employeeTransfer.filePath=null;
    },
    (error)=>{
    this.notificationService.alertError("Error occured, try again",'');
    });
  }

  onUpload($event) {
    const formData = new FormData();
    const i=$event.files.length-1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.employeeTransfer.filePath =
          res.body.fileName;
          this.uFilename=this.employeeTransfer.filePath;
          this.isFileUploaded=true;
        this.notificationService.alertSuccess('Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

   onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

 
  onDistrictLevelChange($event?) {
   
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.profile?.districtId?this.profile.districtId:this.employee.districtId,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.profile?.districtId?this.profile.districtId:this.employee.districtId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }



  onBlockLevelChange($event?) {
   this.villageLevelDto = null;
   this.villageLevelList = [];
   this.userDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onsave(){
    this.ngSelectInvalid = false;
    const isFormValid =
    this.employeeTransfer.filePath &&
    this.employeeTransfer.transaferredDate &&
    this.BlockLevelDto &&
    this.villageLevelDto;
  if (!isFormValid) {
    this.ngSelectInvalid = true;
    this.notificationService.alertError('please Fill All Details','');
    return;
  }
    this.employeeTransfer.employeeId = this.selectedVendor.id;
    this.employeeTransfer.blockFromId = this.profile?.blockId?this.profile.blockId:this.employee.blockId;
    this.employeeTransfer.blockFromName = this.profile?.blockName?this.profile.blockName:this.employee.blockName;
    this.employeeTransfer.districtFromId = this.profile?.districtId?this.profile.districtId:this.employee.districtId;
    this.employeeTransfer.districtFromName = this.profile?.districtName?this.profile.districtName:this.employee.districtName;
    this.employeeTransfer.villageFromId = this.profile?.villageId?this.profile.villageId:this.employee.villageId;
    this.employeeTransfer.villageFromName = this.profile?.villageName?this.profile.villageName:this.employee.villageName;
    this.employeeTransfer.blockToId = this.BlockLevelDto.id;
    this.employeeTransfer.blockToName = this.BlockLevelDto.name;
    this.employeeTransfer.districtToId = this.profile?.districtId?this.profile.districtId:this.employee.districtId;
    this.employeeTransfer.districtToName = this.profile?.districtName?this.profile.districtName:this.employee.districtName;
    this.employeeTransfer.villageToId = this.villageLevelDto.id;
    this.employeeTransfer.villageToName = this.villageLevelDto.name;
    this.employeeTransfer.villageFromLgd = this.profile?.villageLgdCode?this.profile.villageLgdCode:this.employee.lgdCode;
    this.employeeTransfer.villageToLgd = this.villageLevelDto.code;
    if(this.employeeTransfer.isProfileIncluded === true) {
      this.employeeTransfer.transferToUserId = this.userDto.id;
      this.employeeTransfer.profileId = this.profile.profileId;
    }
    this.spinner.show();
    this.employeeTransferService.employeeTransfer(this.employeeTransfer).subscribe(
      (res) => {
        console.log('saveData',res)
        this.spinner.hide();
        this.notificationService.alertSuccess(res,'');
        this.router.navigate(['/employee-transfer'], {});
        this.sessionStorageService.clear('employeedetails');
      },
      () => {
        
      }
    );
   
  }
}
